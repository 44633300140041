<template>
  <b-form
    v-if="dataLoaded"
    id="request-correct-form"
    ref="request"
    :model="request"
    class="request-correct-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="div mb-2">
      <h3>
        {{ $t("requests.request_sum") }}: {{ totalSum(request) }}
        {{ $t("common.cur") }} | {{ $t("requests.assignee_earn") }}:
        {{ earnTotalSumWithPresetRecord(request) }} |
        {{ $t("requests.assignee_in_hand") }}:
        {{ recalculateTotalProfitSum(request) }}
      </h3>
      <hr />

      <b-button-group class="mb-2 mr-2">
        <button
          class="btn"
          :class="request.hasRecord ? 'btn-outline-primary' : 'btn-primary'"
          @click.prevent="toggleRecord(false)"
        >
          {{ $t("requests.no_record") }}
        </button>
        <button
          class="btn"
          :disabled="!!request.fromExchange"
          :class="request.hasRecord ? 'btn-primary' : 'btn-outline-primary'"
          @click.prevent="toggleRecord(true)"
        >
          <font-awesome-icon icon="star" />
          {{ $t("requests.has_record") }}
        </button>
      </b-button-group>

      <b-button-group class="mb-2 mr-2">
        <button
          class="btn"
          :class="request.wereNovice ? 'btn-outline-primary' : 'btn-primary'"
          @click.prevent="toggleNovice(false)"
        >
          {{ $t("requests.assignee_regular") }}
        </button>
        <button
          class="btn"
          :class="request.wereNovice ? 'btn-primary' : 'btn-outline-primary'"
          @click.prevent="toggleNovice(true)"
        >
          {{ $t("requests.assignee_novice") }}
        </button>
      </b-button-group>

      <b-button-group class="mb-2">
        <button
          v-for="(aPercent, pIndex) in availablePercents"
          :key="pIndex"
          class="btn"
          :class="isPercent(aPercent) ? 'btn-primary' : 'btn-outline-primary'"
          disabled
          @click.prevent="setPercent(aPercent)"
        >
          {{ aPercent }}%
        </button>
      </b-button-group>
      <div v-if="!!request.fromExchange" class="mb-2">
        <span class="text-danger">
          * Нельзя назначить рекорд - заявка из биржи
        </span>
      </div>

      <b-form-group label="" prop="title">
        <b-form-textarea
          v-model="managerComment"
          :placeholder="$t('requests.manager_comment')"
          tabindex="1"
          required
        ></b-form-textarea>
      </b-form-group>

      <b-button variant="primary" @click="createOrUpdate('ok')">
        <font-awesome-icon icon="check" />
        {{ $t("requests.confirm_correction") }}
      </b-button>

      <button
        v-if="id !== 'new'"
        class="ml-2 btn btn-default"
        @click="cancelUpdate"
      >
        <font-awesome-icon icon="times" />
        {{ $t("common.cancel") }}
      </button>
    </div>
  </b-form>
</template>

<script>
import { VueMaskDirective } from "v-mask";
import { getUserName } from "../../../../crm/common.js";
import {
  getClientName,
  getClientNameWithContacts,
} from "@/modules/crm/decorators/client.js";
import { getStatusTitle } from "@/modules/crm/decorators/status.js";
import {
  calcEquipmentSum,
  calcProfitSum,
  earnSum,
  earnSumWithPresetRecord,
  getEarnPercentManual,
  recalculateProfitSum,
  recordDiff,
  recordString,
  servicesSum,
  totalSum,
  earnTotalSumWithPresetRecord,
  recalculateTotalProfitSum,
} from "@/modules/pest/decorators/requests.js";
import { EQUIPMENT_PAYMENT_PERCENT } from "@/modules/pest/decorators/equipments.js";
import { TYPE_ADMIN, TYPE_LOGIST } from "@/modules/users/decorators/users.js";

export default {
  name: "RequestCorrectResultForm",
  directives: { mask: VueMaskDirective },
  components: {},
  props: ["requestModel", "dataLoaded"],
  data() {
    return {
      EQUIPMENT_PAYMENT_PERCENT,
      dateFormat: "DD.MM.YYYY",
      timeFormat: "HH:MM",
      labelWidth: "150px",
      loading: false,
      statusesList: [],
      resourcesList: [],
      servicesList: [],
      resources: [],
      request: {},
      managerComment: "",
      percent: 30,
    };
  },
  computed: {
    availablePercents() {
      if (this.request.isAssigneeInTeam) {
        return [20, 25, 40, 45];
      }
      return [20, 30, 40, 50];
    },
    id() {
      return this.request.id;
    },
    equipmentSum() {
      return this.calcEquipmentSum(this.request);
    },
    recordDiffSum() {
      return recordDiff(this.request, this.request.recordSum);
    },
    canDecline() {
      return (
        !this.$store.state.user.isGuest &&
        (this.$store.state.user.type === TYPE_LOGIST ||
          this.$store.state.user.type === TYPE_ADMIN)
      );
    },
  },
  watch: {
    requestModel: {
      immediate: true,
      handler(obj) {
        this.request = Object.assign({}, obj);
      },
    },
  },
  created() {
    this.fetchStatuses();
    this.fetchServices();
    this.fetchResources();
    this.updatePercent();
  },
  methods: {
    recalculateTotalProfitSum,
    totalSum,
    earnTotalSumWithPresetRecord,
    earnSum,
    earnSumWithPresetRecord,
    recalculateProfitSum,
    calcEquipmentSum,
    calcProfitSum,
    servicesSum,
    getClientName,
    recordString,
    recordDiff,
    getUserName,
    getStatusTitle,
    getClientNameWithContacts,
    clientNameF() {
      return this.request && this.request.clientId && this.request.client
        ? getClientName(this.request.client)
        : "";
    },
    update: function () {
      let updateUrl;
      updateUrl = "/pest/request/correct/" + this.id;
      this.loading = true;
      let data = {
        record: this.request.hasRecord,
        comment: this.managerComment,
        wereNovice: this.request.wereNovice,
      };
      this.$http
        .post(updateUrl, data)
        .then((response) => {
          this.request = response.data;
          this.loading = false;
          this.managerComment = "";
          this.$bvToast.toast(this.$t("requests.request_updated"), {
            title: this.$t("common.data_saved"),
            request: "success",
          });
          this.$emit("request-corrected", this.request);
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("requests.cant_update_request"), {
              title: this.$t("common.error"),
              request: "danger",
            });
          }
        });
    },
    fetchResources: function () {
      this.resourcesList = [];
      let resourcesUrl = "/pest/resources";
      let params = {
        all: true,
        onlyEnabled: true,
      };
      this.$http
        .get(resourcesUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.resourcesList.push({
              value: item.id,
              text: this.getStatusTitle(item),
            });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    fetchServices: function () {
      this.servicesList = [];
      let servicesUrl = "/pest/services";
      let params = {
        all: true,
      };
      this.$http
        .get(servicesUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.servicesList.push({
              value: item.id,
              text: this.getStatusTitle(item),
            });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    fetchStatuses: function () {
      this.statusesList = [];
      // this.statusesList.push({value: '', text: 'Все'});
      let statusesUrl = "/crm/statuses";
      let params = {
        page: this.page,
      };
      this.$http
        .get(statusesUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.statusesList.push({
              value: item.id,
              text: this.getStatusTitle(item),
            });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },

    createOrUpdate: function (status) {
      let form = document.getElementById("request-correct-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast(this.$t("common.data_is_saving"), {
            title: this.$t("common.please_wait"),
            variant: "info",
          });
          return;
        }
        this.update();
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast(this.$t("common.form_has_errors"), {
          variant: "danger",
        });
        return false;
      }
    },
    cancelUpdate() {
      this.$emit("cancel-updating", this.request);
    },
    onReset(evt) {
      evt.preventDefault();
    },
    updatePercent() {
      this.percent = getEarnPercentManual(this.request);
    },
    toggleRecord(status) {
      this.request.hasRecord = status;
      this.$forceUpdate();
      this.updatePercent();
    },
    setPercent(percent) {
      this.percent = percent;
    },
    toggleNovice(status) {
      this.request.wereNovice = status;
      this.updatePercent();
    },
    isPercent(percent) {
      return this.percent === percent;
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}

.resource-select {
  min-width: 150px;
}

.resource-count {
  width: 50px;
}

.service-select {
  min-width: 150px;
}

.service-count {
  width: 50px;
}
</style>
