import { objectSelectValues } from "@/modules/crm/decorators/decorator";
import { getAftercallFailTitle } from "./aftercall-fail";
import { getUserName } from "@/modules/crm/common";
import t from "@/i18n";

export const getAftercallFailStatusTitle = function (aftercall) {
  if (!aftercall || !aftercall.fail) {
    return t("common.not_set");
  }
  return getAftercallFailTitle(aftercall.fail);
};

export const AFTERCALL_STATUS_WITHOUT = 0;
export const AFTERCALL_STATUS_REGISTERED = 1;
export const AFTERCALL_STATUS_CALL_TODAY = 2;
export const AFTERCALL_STATUS_CALL = 3;
export const AFTERCALL_STATUS_TEMPORARY_UNAVAILABLE = 4;
export const AFTERCALL_STATUS_NOT_REGISTERED = 5;

export const aftercallStatusTitles = {
  [AFTERCALL_STATUS_WITHOUT]: "Без прозвона",
  [AFTERCALL_STATUS_REGISTERED]: "Оформлена",
  [AFTERCALL_STATUS_CALL_TODAY]: "Перезвонить сегодня",
  [AFTERCALL_STATUS_CALL]: "Созвон",
  [AFTERCALL_STATUS_TEMPORARY_UNAVAILABLE]: "Временный недозвон",
  [AFTERCALL_STATUS_NOT_REGISTERED]: "Не оформлена",
};

export const aftercallStatusOptions = objectSelectValues(
  aftercallStatusTitles,
  true
);

export const getAftercallManagerTitle = function (aftercall) {
  if (!aftercall.manager) {
    return t("common.not_set");
  }
  return getUserName(aftercall.manager);
};

export const getAftercallStatusTitle = function (aftercall) {
  if (!aftercall.status) {
    return t("common.not_set");
  }
  return aftercallStatusTitles[aftercall.status];
};
