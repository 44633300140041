import t from "@/i18n";

const getTaskTypeTitle = function (taskType) {
  if (!taskType || !taskType.title) {
    return t("common.not_set");
  }
  return taskType.title;
};

export { getTaskTypeTitle };
