export const ru = {
  clients: {
    fullname: "ФИО",
    phone: "Телефон",
    address: "Адрес",

    lastName: "Фамилия",
    firstName: "Имя",
    patronymic: "Отчество",
    firm: "Организация",
    post: "Должность",
    new_phone: "Новый телефон",
    responsible_person: "Ответственный",
    add_phone: "Добавить телефон",
    enter_phone: "Введите телефон",
    choose_phone: "Выберите телефон",
    add_contact: "Добавить контакт",
    primary_contact: "Основной контакт",
    delete_contact: "Удалить контакт",
    remove_phone_from_contact: "Удалить телефон из контакта",

    clients: "Контакты",
    client_list: "Перечень контактов",
    client: "Контакт",
    add_client: "Добавить контакт",
    new_client: "Новый контакт",
    client_update: "Изменение контакта",
    client_updated: "Контакт отредактирован",
    phone_added: "Телефон отредактирован",
    cant_create_phone: "Не удалось добавить телефон",
    cant_create_client: "Не удалось добавить контакт",
    cant_update_client: "Не удалось обновить контакт",
    cant_delete_client: "Не удалось удалить контакт",
    cant_archive_client: "Не удалось отправить клиента в архив",
    cant_unarchive_client: "Не удалось вернуть клиента из архива",
    client_archived: "Клиент отправлен в архив",
    client_unarchived: "Клиент вернлулся из архива",
    client_created: "Контакт добавлен",
    client_deleted: "Контакт удален",
    delete_client_question: "Вы действительно хотите удалить контакт?",
    contact_set_as_primary: "Контакт сделан основным",

    status_m_processing: "обработке",
    status_m_verified: "Подтвержденные",
    status_m_declined: "Отклоненные",
    status_m_paid: "Оплаченные",
    status_m_not_paid: "Неоплаченные",
    status_m_deposited: "В кассе",
    status_m_not_deposited: "Не в кассе",

    make_call: "Позвонить",
  },
};
export const en = {
  clients: {
    fullname: "Full name",
    phone: "Phone",
    address: "Address",

    lastName: "Last name",
    firstName: "First name",
    patronymic: "Patronymic",
    firm: "Firm",
    post: "Post",
    new_phone: "New phone",

    responsible_person: "Responsible person",
    add_phone: "Add phone",
    add_contact: "Add contact",
    primary_contact: "Add primary",
    delete_contact: "Delete contact",
    remove_phone_from_contact: "Remove phone from contact",

    clients: "Clients",
    client_list: "Client list",
    client: "Client",
    add_client: "Add client",
    enter_phone: "Enter phone",
    choose_phone: "Choose phone",
    new_client: "New client",
    change_client: "Change client",
    client_update: "Update client",
    client_updated: "Client changed",
    phone_added: "Phone added",
    cant_create_phone: "Can't add phone",
    cant_create_client: "Can't add client",
    cant_update_client: "Can't update client",
    cant_delete_client: "Can't delete client",
    cant_archive_client: "Can't archive client",
    cant_unarchive_client: "Can't unarchive client",
    client_archived: "Client archived",
    client_unarchived: "Client unarchived",
    client_created: "Client added",
    client_deleted: "Client deleted",
    delete_client_question: "Do you want to delete the client?",
    contact_set_as_primary: "Contact set as primary",
    make_call: "Make call",
  },
};
