export const ru = {
  files: {
    file_deleted: "Файл удален",
    no_files: "Нет файлов",
    error_with_file_removing: "Ошибка удаления файла",
    upload_agreement_file: "Загрузите фото договора",
    dnd_file_hover: "Перенесите файлы сюда для загрузки",
    dnd_file_inactive_msg:
      "\n" +
      "          Перенесите файл или <strong><em>нажмите сюда</em></strong\n" +
      "          >, чтобы загрузить файл\n" +
      "        ",
  },
};
export const en = {
  files: {
    file_deleted: "File deleted",
    no_files: "No files",
    error_with_file_removing: "Error with file removing",
    upload_agreement_file: "Upload agreement file",
    dnd_file_hover: "Drop files here to upload",
    dnd_file_inactive_msg:
      "Drag and drop files or " +
      "<strong><em>click here</em></strong> to upload",
  },
};
