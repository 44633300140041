<template>
  <b-modal
    id="modal-schedule-record"
    ref="modal"
    :title="modalTitle"
    :cancel-title="$t('common.cancel')"
    :ok-title="$t('common.save')"
    title-tag="h3"
    @ok="handleTaskModalOk"
  >
    <b-form-group :label="$t('schedule.status')" prop="statu">
      <b-button-group>
        <button
          class="btn"
          :class="getBtnClass(SCHEDULE_ACTIVE_NONE)"
          @click.prevent="setStatus(SCHEDULE_ACTIVE_NONE)"
        >
          <span v-html="$t('schedule.st_off')" />
        </button>
        <button
          class="btn"
          :class="getBtnClass(SCHEDULE_ACTIVE_ACTIVE)"
          @click.prevent="setStatus(SCHEDULE_ACTIVE_ACTIVE)"
        >
          <span v-html="$t('schedule.st_work')" />
        </button>
        <button
          class="btn"
          :class="getBtnClass(SCHEDULE_ACTIVE_DUTY_1)"
          @click.prevent="setStatus(SCHEDULE_ACTIVE_DUTY_1)"
        >
          <span v-html="$t('schedule.st_duty_1')" />
        </button>
        <button
          class="btn"
          :class="getBtnClass(SCHEDULE_ACTIVE_DUTY_2)"
          @click.prevent="setStatus(SCHEDULE_ACTIVE_DUTY_2)"
        >
          <span v-html="$t('schedule.st_duty_2')" />
        </button>
        <button
          class="btn"
          :class="getBtnClass(SCHEDULE_ACTIVE_DAY_OFF)"
          @click.prevent="setStatus(SCHEDULE_ACTIVE_DAY_OFF)"
        >
          <span v-html="$t('schedule.st_day_off')" />
        </button>
      </b-button-group>
    </b-form-group>

    <b-form-group :label="$t('common.comment')" prop="title">
      <b-form-textarea v-model="comment" tabindex="1"></b-form-textarea>
    </b-form-group>
  </b-modal>
</template>

<script>
import { getAssigneeName } from "@/modules/crm/common";
import dayjs from "dayjs";
import {
  SCHEDULE_ACTIVE_ACTIVE,
  SCHEDULE_ACTIVE_DAY_OFF,
  SCHEDULE_ACTIVE_DUTY_1,
  SCHEDULE_ACTIVE_DUTY_2,
  SCHEDULE_ACTIVE_NONE,
} from "@/modules/pest/decorators/schedule";

export default {
  name: "ScheduleRecordPopup",
  components: {},
  data() {
    return {
      SCHEDULE_ACTIVE_NONE,
      SCHEDULE_ACTIVE_ACTIVE,
      SCHEDULE_ACTIVE_DUTY_1,
      SCHEDULE_ACTIVE_DUTY_2,
      SCHEDULE_ACTIVE_DAY_OFF,
      status: 0,
      comment: "",
      record: {},
      assignee: {},
      date: null,
    };
  },
  computed: {
    modalTitle() {
      return (
        this.getAssigneeName(this.assignee) +
        " – " +
        dayjs(this.date).format("DD.MM.YYYY")
      );
    },
    formattedDate(date) {
      return dayjs(date).format("DD.MM.YYYY");
    },
  },
  watch: {},
  created() {
    this.$eventHub.$on("schedule-record-show", this.openPopup);
  },
  beforeDestroy() {
    // this.$eventHub.$off('time-popup-show');
  },
  methods: {
    getAssigneeName,
    handleSuccess(time) {
      this.$bvModal.hide("modal-schedule-record");
      if (this.successHandler) {
        this.successHandler(time);
      }
      this.successHandler = false;
      this.data = {};
    },
    handleTaskModalOk() {
      this.handleSuccess({
        status: this.status,
        comment: this.comment,
      });
      // this.$refs.recordForm.createOrUpdate(bvModalEvt, this.handleSuccess);
    },
    openPopup(info) {
      this.record = Object.assign({}, info.record);
      this.status = info.record.active;
      this.comment = info.record.comment;
      this.assignee = Object.assign({}, info.assignee);
      this.date = info.date;
      this.successHandler = info.success;
      this.cancelHandler = info.cancel;
      this.$bvModal.show("modal-schedule-record");
    },
    getBtnClass(status) {
      if (status === SCHEDULE_ACTIVE_NONE) {
        return this.status === status
          ? "btn-secondary none"
          : "btn-outline-secondary  ";
      }
      if (status === SCHEDULE_ACTIVE_ACTIVE) {
        return this.status === status
          ? "btn-green active"
          : "btn-outline-green";
      }
      if (status === SCHEDULE_ACTIVE_DUTY_1) {
        return this.status === status
          ? "btn-warning duty1"
          : "btn-outline-warning";
      }
      if (status === SCHEDULE_ACTIVE_DUTY_2) {
        return this.status === status
          ? "btn-success duty2"
          : "btn-outline-success";
      }
      if (status === SCHEDULE_ACTIVE_DAY_OFF) {
        return this.status === status
          ? "btn-danger dayOff"
          : "btn-outline-danger";
      }
      return "btn-outline-default";
    },
    setStatus(status) {
      this.status = status;
    },
  },
};
</script>

<style lang="scss" scoped>
.update-duration-modal {
  position: absolute;
  //top: 10px;
  //left: 10px;
  border: 1px solid black;
  z-index: 10000;
  padding: 50px;
  background: #ffffff;
}

ul {
  list-style: none;
  padding: 0;
  font-size: larger;
}

ul li {
  padding: 5px 10px;
}

ul li:hover {
  background: rgba(215, 212, 212, 0.5);
}
</style>
