<template>
  <b-modal
    id="modal-time"
    ref="modal"
    title="Длительность выполнения"
    :cancel-title="$t('common.cancel')"
    :ok-disabled="true"
    :ok-title="$t('common.save')"
    title-tag="h3"
    @ok="handleTaskModalOk"
  >
    <ul>
      <li @click.prevent="selectTime(30)"><a href="">0:30</a></li>
      <li @click.prevent="selectTime(60)"><a href="">1:00</a></li>
      <li @click.prevent="selectTime(90)"><a href="">1:30</a></li>
      <li @click.prevent="selectTime(120)"><a href="">2:00</a></li>
      <li @click.prevent="selectTime(150)"><a href="">2:30</a></li>
      <li @click.prevent="selectTime(180)"><a href="">3:30</a></li>
      <li @click.prevent="selectTime(210)"><a href="">3:30</a></li>
      <li @click.prevent="selectTime(240)"><a href="">4:00</a></li>
    </ul>
  </b-modal>
</template>

<script>
export default {
  name: "RequestTimePopup",
  components: {},
  data() {
    return {
      request: {},
    };
  },
  computed: {},
  watch: {},
  created() {
    this.$eventHub.$on("time-popup-show", this.openPopup);
  },
  beforeDestroy() {
    // this.$eventHub.$off('time-popup-show');
  },
  methods: {
    handleSuccess(time) {
      this.$bvModal.hide("modal-time");
      if (this.successHandler) {
        this.successHandler(time);
      }
      this.successHandler = false;
      this.data = {};
    },
    handleTaskModalOk(bvModalEvt) {
      // this.$refs.requestForm.createOrUpdate(bvModalEvt, this.handleSuccess);
    },
    selectTime(time) {
      this.handleSuccess(time);
    },
    openPopup(info) {
      this.request = info.request;
      this.successHandler = info.success;
      this.cancelHandler = info.cancel;
      this.$bvModal.show("modal-time");
    },
  },
};
</script>

<style lang="scss" scoped>
.update-duration-modal {
  position: absolute;
  //top: 10px;
  //left: 10px;
  border: 1px solid black;
  z-index: 10000;
  padding: 50px;
  background: #ffffff;
}

ul {
  list-style: none;
  padding: 0;
  font-size: larger;
}

ul li {
  padding: 5px 10px;
}

ul li:hover {
  background: rgba(215, 212, 212, 0.5);
}
</style>
