export const ru = {
  common: {
    load_more: "Загрузить еще",
    loading: "Загружается",
    refresh: "Обновить",
    close: "Закрыть",
    save: "Сохранить",
    update: "Изменить",
    saving: "Сохраняется",
    cancel: "Отмена",
    edit: "Редактировать",
    archive: "В архив",
    unarchive: "Убрать из архива",
    add: "Добавить",
    remove: "Удалить",
    delete: "Удалить",
    error: "Ошибка",
    success: "Успешно",
    all: "Все",
    yes: "Да",
    no: "Нет",
    other: "Другое",
    unit: "Единица измерения",
    unit_l: "l",
    unit_ml_l: "мл/л",
    unit_ml: "мл",
    unit_km: "км",
    not_set: "Не задано",
    no_data: "Данных нет",
    currency: "Рублей",
    cur: "р",
    back_to_list: "Назад к списку",

    data_saved: "Данные сохранены",
    data_not_saved: "Данные не сохранены",

    select: "Выберите",
    select_date: "Выберите дату",
    select_period: "Выберите период",
    since: "С",

    updating_enabled: "Редактирование включено",
    updating_disabled: "Редактирование отключено",
    without_title: "[Без названия]",
    without_name: "[Без имени]",
    title: "Название",
    status: "Статус",
    type: "Тип",
    form_has_errors: "В форме содержатся ошибки",
    error_during_saving: "Не удалось сохранить данные",
    error_during_changing_status: "Не удалось изменить статус",
    please_wait: "Подождите",
    data_is_saving: "Данные сохраняются",
    sort_order: "Сортировка",
    description: "Описание",
    comment: "Комментарий",
    comments: "Комментарии",
    town: "Город",
    color: "Цвет",
    add_new: "Добавить нового",
    send: "Отправить",
    sending: "Отправляется",
    choose_at_least_one_record: "Выберите хотя бы одну запись",
  },
};
export const en = {
  common: {
    load_more: "Load more",
    loading: "Loading",
    refresh: "Refresh",
    close: "Close",
    save: "Save",
    saving: "Saving",
    cancel: "Cancel",
    edit: "Cancel",
    archive: "Archive",
    unarchive: "Unarchive",
    add: "Add",
    remove: "Remove",
    delete: "Delete",
    error: "Error",
    success: "Success",
    all: "All",
    yes: "Yes",
    no: "No",
    other: "Other",
    unit: "Unit",
    unit_l: "l",
    unit_ml_l: "ml/l",
    unit_ml: "ml",
    unit_km: "km",
    not_set: "[Not set]",
    no_data: "No data",
    currency: "AED",
    cur: "AED",
    back_to_list: "Back to list",

    data_saved: "Data saved",
    data_not_saved: "Data not saved",

    select: "Select",
    select_date: "Select date",
    select_period: "Select period",
    since: "Since",

    updating_enabled: "Updating enabled",
    updating_disabled: "Updating disabled",
    without_title: "Without title",
    without_name: "Without name",
    title: "Title",
    status: "Status",
    type: "Type",
    form_has_errors: "Form has errors",
    error_during_saving: "Error during saving",
    please_wait: "Please wait",
    data_is_saving: "Data is saving",
    sort_order: "Sort order",
    description: "Description",
    comment: "Comment",
    comments: "Comments",
    town: "Town",
    color: "Color",
    add_new: "Add new",
    send: "Send",
    sending: "Sending",
    choose_at_least_one_record: "Choose at least one record",
  },
};
