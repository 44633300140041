<template>
  <b-modal
    id="modal-schedule-multiple-record"
    ref="modal"
    :title="modalTitle"
    :cancel-title="$t('common.cancel')"
    :ok-title="$t('common.save')"
    title-tag="h3"
    @cancel="handleTaskModalCancel"
    @close="handleTaskModalCancel"
    @hidden="handleTaskModalCancel"
    @ok="handleTaskModalOk"
  >
    <b-form-group label="Статус" prop="status">
      <b-button-group>
        <button
          class="btn"
          :class="getBtnClass(SCHEDULE_ACTIVE_NONE)"
          @click.prevent="setStatus(SCHEDULE_ACTIVE_NONE)"
        >
          {{ $t("schedule.st_off") }}
        </button>
        <button
          class="btn"
          :class="getBtnClass(SCHEDULE_ACTIVE_ACTIVE)"
          @click.prevent="setStatus(SCHEDULE_ACTIVE_ACTIVE)"
        >
          {{ $t("schedule.st_work") }}
        </button>
        <button
          class="btn"
          :class="getBtnClass(SCHEDULE_ACTIVE_DUTY_1)"
          @click.prevent="setStatus(SCHEDULE_ACTIVE_DUTY_1)"
        >
          {{ $t("schedule.st_duty_1") }}
        </button>
        <button
          class="btn"
          :class="getBtnClass(SCHEDULE_ACTIVE_DUTY_2)"
          @click.prevent="setStatus(SCHEDULE_ACTIVE_DUTY_2)"
        >
          {{ $t("schedule.st_duty_2") }}
        </button>
        <button
          class="btn"
          :class="getBtnClass(SCHEDULE_ACTIVE_DAY_OFF)"
          @click.prevent="setStatus(SCHEDULE_ACTIVE_DAY_OFF)"
        >
          Выходной
        </button>
      </b-button-group>
    </b-form-group>
  </b-modal>
</template>

<script>
import { getAssigneeName } from "@/modules/crm/common";
import dayjs from "dayjs";
import {
  SCHEDULE_ACTIVE_ACTIVE,
  SCHEDULE_ACTIVE_DAY_OFF,
  SCHEDULE_ACTIVE_DUTY_1,
  SCHEDULE_ACTIVE_DUTY_2,
  SCHEDULE_ACTIVE_NONE,
} from "@/modules/pest/decorators/schedule";

export default {
  name: "ScheduleMultipleRecordPopup",
  components: {},
  data() {
    return {
      SCHEDULE_ACTIVE_NONE,
      SCHEDULE_ACTIVE_ACTIVE,
      SCHEDULE_ACTIVE_DUTY_1,
      SCHEDULE_ACTIVE_DUTY_2,
      SCHEDULE_ACTIVE_DAY_OFF,
      status: 0,
      dates: [],
      comment: "",
      record: {},
      assignee: {},
      date: null,
      ok: false,
    };
  },
  computed: {
    modalTitle() {
      let dd = [];
      for (let date of this.dates) {
        dd.push(dayjs(date).format("DD.MM.YYYY"));
      }
      return this.getAssigneeName(this.assignee) + " – " + dd.join(", ");
    },
    formattedDate(date) {
      return dayjs(date).format("DD.MM.YYYY");
    },
  },
  watch: {},
  created() {
    this.$eventHub.$on("schedule-record-multiple-show", this.openPopup);
  },
  beforeDestroy() {
    // this.$eventHub.$off('time-popup-show');
  },
  methods: {
    getAssigneeName,
    handleSuccess() {
      this.$bvModal.hide("modal-schedule-multiple-record");
      if (this.successHandler) {
        this.successHandler({
          status: this.status,
        });
      }
      this.successHandler = false;
      this.data = {};
    },
    handleTaskModalOk() {
      this.ok = true;
      this.handleSuccess();
      // this.$refs.recordForm.createOrUpdate(bvModalEvt, this.handleSuccess);
    },
    handleTaskModalCancel() {
      if (!this.ok) {
        this.cancelHandler();
      }
    },
    openPopup(info) {
      this.ok = false;
      this.dates = info.dates;
      this.assignee = info.assignee;
      this.successHandler = info.success;
      this.cancelHandler = info.cancel;
      this.$bvModal.show("modal-schedule-multiple-record");
    },
    getBtnClass(status) {
      if (this.status === status) {
        if (status === SCHEDULE_ACTIVE_NONE) {
          return "btn-secondary none";
        }
        if (status === SCHEDULE_ACTIVE_ACTIVE) {
          return "btn-primary active";
        }
        if (status === SCHEDULE_ACTIVE_DUTY_1) {
          return "btn-warning duty1";
        }
        if (status === SCHEDULE_ACTIVE_DUTY_2) {
          return "btn-danger duty2";
        }
        if (status === SCHEDULE_ACTIVE_DAY_OFF) {
          return "btn-info dayOff";
        }
      }
      return "btn-outline-primary";
    },
    setStatus(status) {
      this.status = status;
    },
  },
};
</script>

<style lang="scss" scoped>
.update-duration-modal {
  position: absolute;
  //top: 10px;
  //left: 10px;
  border: 1px solid black;
  z-index: 10000;
  padding: 50px;
  background: #ffffff;
}

ul {
  list-style: none;
  padding: 0;
  font-size: larger;
}

ul li {
  padding: 5px 10px;
}

ul li:hover {
  background: rgba(215, 212, 212, 0.5);
}
</style>
