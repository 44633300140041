export const ru = {
  equipment: {
    equipment: "Оборудование",
    equipment_settings_hint: "Оборудование, передаваемое исполнителям",
    equipment_list: "Перечень оборудования",
    add_equipment: "Добавить оборудование",
    new_equipment: "Новов оборудование",
    equipment_update: "Изменение оборудования",
    equipment_updated: "Оборудование отредактировано",
    cant_create_equipment: "Не удалось добавить оборудование",
    cant_delete_equipment: "Не удалось удалить оборудование",
    equipment_created: "Оборудование добавлено",
    equipment_deleted: "Оборудование удалено",
    fixed_price: "Фиксированная стоимость",
    percent_price: "Процент от сделки",
    payment_type: "Тип оплаты",
    delete_equipment_question: "Вы действительно хотите удалить оборудование?",
  },
};
export const en = {
  equipment: {
    equipment: "Equipment",
    equipment_settings_hint: "Equipment transferred to masters",
    equipment_list: "Equipment list",
    add_equipment: "Add equipment",
    new_equipment: "New equipment",
    change_equipment: "Change equipment",
    equipment_update: "Update equipment",
    equipment_updated: "Equipment changed",
    cant_create_equipment: "Can't add equipment",
    cant_delete_equipment: "Can't delete equipment",
    equipment_created: "Equipment added",
    equipment_deleted: "Equipment deleted",
    fixed_price: "Fixed price",
    percent_price: "Percent from request",
    payment_type: "Payment type",
    delete_equipment_question: "Do you want to delete the equipment?",
  },
};
