export const ru = {
  notifications: {
    notifications: "Уведомления",
    notification_added: "Напоминание добавлено",
    notification_list: "Перечень уведомлений",
    date_from: "Дата от",
    date_to: "Дата до",
    type: "Тип",
    assignee: "Исполнитель",
    no_notifications_yet: "Еще нет уведомлений",
    time: "Время события",
    user: "Пользователь",
    description: "Описание",
    status: "Статус",
    request_assigned: "Назначена заявка",
    not_read: "Не прочитано",
    read_at: "Прочитано в",

    unknown_msg: "Что-то непонятное. Позовите программиста.",
    change_assignee: "Смена мастера",
    fine_approved: "Назначен штраф",
    new_record: "Новый рекорд",
    message: "Сообщение",
    follow_up_msg: "Подпишитесь на нас в Telegram для получения уведомлений",
    follow_up_link_msg: "Перейдите по этой ссылке:",
    follow_up_text:
      " Перейдите по этой ссылке:\n" +
      " <strong>" +
      '<a :href="telegramUrl" target="_blank">@' +
      import.meta.env.VITE_ASSIGNEE_BOT +
      "</a></strong>" +
      " и, когда откроется Telegram, нажмите <strong>start</strong>. <br />" +
      " Или самостоятельно найдите контакт <strong>@" +
      import.meta.env.VITE_ASSIGNEE_BOT +
      "</strong> и" +
      " отправьте ваш номер телефона.",
    initial_pledge_required_title: "Депозит недостаточен",
    initial_pledge_required_msg:
      " До накопления минимальной суммы необходимо сдавать кассу каждый день.\n" +
      "          Несдача кассы влечёт за собой штраф в размере 1000₽. Своевременность\n" +
      "          сдачи кассы влияет на приоритет распределения заявок.",
    blocked_title: "Ваш личный кабинет заблокирован.",
    blocked_msg:
      "Для продолжения работы необходимо сдать кассу. Своевременность сдачи\n" +
      "          кассы влияет на приоритет распределения заявок.",
    unblocked_title: "Ваш личный кабинет временно разблокирован.",
    unblocked_msg:
      "Для продолжения работы необходимо сдать кассу. Своевременность сдачи\n" +
      "          кассы влияет на приоритет распределения заявок.",
    half_pledge_title: " Использовано 50% депозита",
    half_pledge_msg:
      "Во избежание блокировки личного кабинета сдайте кассу в ближайшее\n" +
      "          время. Своевременность сдачи кассы влияет на приоритет распределения\n" +
      "          заявок.",
    message_from_admin: "Сообщение от администрации",
    you_ve_made_the_record: "Вы сделали рекорд",
    request_assigned_to_you: "Вам назначена заявка",
    you_ve_got_the_fine_msg: "Вам назначен штраф на сумму",
    you_have_no_open_shifts: "У вас нет незакрытых смен",
    you_re_at_the_wrong_place: "Вы не находитесь на месте",
    geolocation_wrong_title: "Вы не находитесь в точке инкассации",
    geolocation_wrong_msg:
      " По вашим координатам не найден магазин. Если вы прибыли в нужную точку,\n" +
      "        попробуйте перезагрузить устройство для более точного определения\n" +
      "        местоположения или свяжитесь с администратором.",
    geolocation_blocked: "У вас заблокирована геолокация",
    geolocation_blocked_msg:
      "Вы не сможете сдать кассу, пока не разрешите определение\n" +
      "        месторасположения для сайта в вашем браузере и не обновите страницу.\n" +
      "      ",
    instruction_title: "",
    instruction_msg:
      "            <li>Выберите ниже смены, за которые хотите сдать кассу.</li>\n" +
      "            <li>Сверьте сумму в конверте со значением в форме.</li>\n" +
      "            <li>Подпишите конверт (период, за который сдается касса и ФИО)</li>\n" +
      "            <li>\n" +
      '              Подтвердите сдачу кассы нажатием на кнопку "сдать кассу"\n' +
      "              <br />(потребуется разрешение определения вашего местоположения)\n" +
      "            </li>\n" +
      "          ",
    required_client_sms_msg:
      "Убедись, что клиент получил смс во время разговора",
    message_sent: "Сообщение отправлено",
    admin_message: "Сообщение от администрации",
  },
};
export const en = {
  notifications: {
    notifications: "Notifications",
    notification_list: "Notification list",
    notification_added: "Notification added",
    date_from: "Date from",
    date_to: "Date to",
    type: "Type",
    time: "Time",
    user: "User",
    assignee: "Assignee",
    no_notifications_yet: "No notifications yet",
    request_assigned: "Request assigned",
    unknown_msg: "Something unknown. Call support.",
    read_at: "Read at",
    not_read: "Not read",

    change_assignee: "Master changed",
    fine_approved: "Fine assigned",
    new_record: "New record",
    message: "Message",
    follow_up_msg: "Subscribe us in Telegram to receive notifications",
    follow_up_link_msg: "Follow up by this link:",
    follow_up_text:
      "Follow up by this link:" +
      " <strong>" +
      '<a :href="telegramUrl" target="_blank">@' +
      import.meta.env.VITE_ASSIGNEE_BOT +
      "</a></strong>" +
      " when telgram opens, press start <strong>start</strong>. <br />" +
      " Or find contact <strong>@" +
      import.meta.env.VITE_ASSIGNEE_BOT +
      "</strong> and" +
      " send your phone number.",
    initial_pledge_required_title: "Pledge insufficient",
    initial_pledge_required_msg:
      " Until the minimum amount is accumulated, you must return the cash register every day.\n" +
      "Failure to return the checkout will result in a fine of RUB 1,000. Timeliness\n" +
      "cash deposits affect the priority of the distribution of requests.",
    blocked_title: "Your account is blocked.",
    blocked_msg:
      "To continue working, you must hand over the cash register. The timely delivery of the cash register affects the priority of the distribution of requests",
    unblocked_title: "Your account is temporary unblocked.",
    unblocked_msg:
      "To continue working, you must hand over the cash register. The timely delivery of the cash register affects the priority of the distribution of requests",
    half_pledge_title: "50% of deposit is used",
    half_pledge_msg:
      "To avoid blocking your personal account, return the cash register as soon as possible. The timely delivery of the cash register affects the priority of the distribution of requests.",
    message_from_admin: "Message from administrator",
    you_ve_made_the_record: "You've made the record",
    request_assigned_to_you: "Request assigned to you",
    you_ve_got_the_fine_msg: "You've been fined:",
    geolocation_blocked: "Geolocation blocked",
    you_have_no_open_shifts: "You have no open shifts",
    you_re_at_the_wrong_place: "You're at the wrong place",
    geolocation_wrong_title: "You are not at the shop point",
    geolocation_wrong_msg:
      "No store found at your coordinates. If you have arrived at the right point,\n" +
      "         try rebooting your device for a more accurate determination\n" +
      "         location or contact the administrator.",
    geolocation_blocked_msg:
      "You will not be able to return the cash register until you resolve the determination.\n" +
      "         location for the site in your browser and do not refresh the page.",
    instruction_title: "Instruction",
    instruction_msg:
      "<li>Please select the shifts for which you would like to submit your checkout below </li>\n" +
      "<li>Check the amount in the envelope against the value on the form </li>\n" +
      "<li>Sign the envelope (period for which the cash register and full name are handed over) </li>\n" +
      '<li>Confirm the checkout by clicking on the "Check in" button ' +
      "<br/> (your location permission will be required) </li>",
    required_client_sms_msg:
      "Make sure the client receives SMS during the conversation",
    message_sent: "Message sent",
    admin_message: "Administration message",
  },
};
