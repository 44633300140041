<template>
  <div class="ibox">
    <div class="ibox-title" :class="{ editing: editing }">
      <div class="search">
        <div class="d-flex flex-wrap align-items-center">
          <h2 class="mr-2">{{ $t("schedule.schedule") }}</h2>
          <div class="mr-2">
            <town-filter
              :value="search.townId"
              @change="selectTown"
            ></town-filter>
          </div>
          <div class="mr-2">
            <date-range-picker
              v-model="dateRange"
              @change="fetchData"
            ></date-range-picker>
          </div>

          <div class="align-self-center ml-auto">
            <button
              v-if="editing"
              class="btn btn-danger ml-1"
              @click="toggleEdit"
            >
              <font-awesome-icon icon="exclamation-triangle" />&nbsp;{{
                $t("common.updating_enabled")
              }}
            </button>
            <button
              v-if="!editing"
              class="btn btn-outline-primary ml-1"
              @click="toggleEdit"
            >
              <font-awesome-icon icon="check" />&nbsp;
              {{ $t("common.updating_disabled") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div ref="ibox" class="ibox-content p-0">
      <div v-if="!loading" class="table-div">
        <table
          class="table table-bordered table-schedule"
          :class="{ editing: editing }"
        >
          <thead>
            <tr>
              <th class="user-name">{{ $t("requests.assignee") }}</th>
              <th
                v-for="(date, ddIndex) in dates"
                :key="'dd' + ddIndex"
                class="statusCell"
                :class="getDateHeaderCellClass(date)"
              >
                {{ formattedDateHeader(date) }}
                – {{ getShiftCount(date) }}
              </th>
            </tr>
          </thead>
          <tbody ref="tbody" :style="tbodyStyle">
            <tr v-for="(info, aIndex) in schedule" :key="'a' + aIndex">
              <th
                class="user-name d-flex"
                @click.prevent="changeAssigneeComment(info.assignee)"
              >
                <div>
                  {{ getAssigneeName(info.assignee) }}
                </div>

                <div
                  v-if="info.assignee.scheduleComment"
                  v-b-tooltip.hover
                  class="comment-icon ml-auto"
                  :title="info.assignee.scheduleComment"
                  @click.prevent
                >
                  <font-awesome-icon icon="comment" />
                </div>
              </th>
              <!--                @mouseenter="cellEnter(dIndex,info)"-->
              <!--                @mouseleave="cellLeave(date,info)"-->
              <td
                v-for="(date, dIndex) in info.dates"
                :key="'d' + dIndex"
                :class="getStatusClass(date, dIndex)"
                @mousedown="startSelect(info, date)"
                @mouseover="processSelect(info, date)"
                @mouseup="endSelect(info, date)"
                @click.prevent="toggleStatus(info.assignee, dIndex, date)"
              >
                <div
                  v-if="date.comment"
                  v-b-tooltip.hover
                  class="comment-icon ml-1 mt-1"
                  :title="date.comment"
                  @click.prevent
                >
                  <font-awesome-icon icon="comment" />
                </div>

                <div v-else-if="!date.selected" class="cell-info">
                  {{ formattedDate(dIndex) }}<br />
                  {{ getAssigneeName(info.assignee) }}
                </div>
                <div v-if="date.selected" class="selected">
                  <font-awesome-icon icon="check" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-else class="p-2">{{ $t("common.loading") }}</div>
    </div>

    <schedule-record-popup></schedule-record-popup>
    <schedule-multiple-record-popup></schedule-multiple-record-popup>
    <assignee-comment-popup></assignee-comment-popup>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getAssigneeName, getUserName } from "@/modules/crm/common";
import { MSK, SPB, DXB } from "@/modules/pest/decorators/towns";
import {
  guessNextActivityStatus,
  SCHEDULE_ACTIVE_ACTIVE,
  SCHEDULE_ACTIVE_DAY_OFF,
  SCHEDULE_ACTIVE_DUTY_1,
  SCHEDULE_ACTIVE_DUTY_2,
} from "@/modules/pest/decorators/schedule";
import ScheduleRecordPopup from "@/modules/pest/views/schedule/ScheduleRecordPopup";
import AssigneeCommentPopup from "@/modules/pest/views/schedule/AssigneeCommentPopup.vue";
import { canEditSchedule } from "@/modules/users/access/common.js";
import ScheduleMultipleRecordPopup from "@/modules/pest/views/schedule/ScheduleMultipleRecordPopup.vue";
import DateRangePicker from "@/components/shared/DateRangePicker";
import TownFilter from "@/components/shared/TownFilter.vue";

export default {
  name: "AssigneeSchedule",
  components: {
    TownFilter,
    DateRangePicker,
    ScheduleMultipleRecordPopup,
    AssigneeCommentPopup,
    ScheduleRecordPopup,
  },
  data() {
    return {
      MSK,
      SPB,
      DXB,
      schedule: {},
      search: {
        dateFrom: "",
        townId: MSK,
        dateTo: "",
      },
      curHoveredDate: "",
      dates: [],
      datesLastStatus: [],
      assigneeLastStatus: [],
      daysOfWeek: {
        0: this.$t("schedule.sunday_sh"),
        1: this.$t("schedule.monday_sh"),
        2: this.$t("schedule.tuesday_sh"),
        3: this.$t("schedule.wednesday_sh"),
        4: this.$t("schedule.thursday_sh"),
        5: this.$t("schedule.friday_sh"),
        6: this.$t("schedule.saturday_sh"),
      },
      loading: true,
      editing: false,
      selecting: false,
      selectedAssignee: false,
    };
  },
  computed: {
    dateRange: {
      get() {
        return [this.search.dateFrom, this.search.dateTo];
      },
      set(val) {
        this.search.dateFrom = val[0];
        this.search.dateTo = val[1];
      },
    },
    tbodyStyle() {
      return "max-height: " + this.matchHeight + "px;";
    },
    matchHeight() {
      return window.innerHeight - 200;
    },
    canEditSchedule() {
      return canEditSchedule(this.$store.state.user);
    },
  },
  watch: {
    $route(to, from) {
      // this.checkUrl();
    },
  },
  created: function () {
    this.search.dateFrom = dayjs().format("YYYY-MM-DD");
    this.search.dateTo = dayjs().add(14, "d").format("YYYY-MM-DD");
    this.fetchData();
  },
  methods: {
    getUserName,
    getAssigneeName,
    guessNextActivityStatus,
    getDateHeaderCellClass(date) {
      if (this.curHoveredDate === date) {
        return "date-hovered";
      }
      return "";
    },
    getStatusClass(cell, dIndex) {
      let hoverClass = dIndex === this.curHoveredDate ? "hover" : "";
      let cellClass = "inactive";
      let active = cell.active;
      if (active === SCHEDULE_ACTIVE_ACTIVE) {
        cellClass = "active";
      } else if (active === SCHEDULE_ACTIVE_DUTY_1) {
        cellClass = "duty1";
      } else if (active === SCHEDULE_ACTIVE_DUTY_2) {
        cellClass = "duty2";
      } else if (active === SCHEDULE_ACTIVE_DAY_OFF) {
        cellClass = "dayOff";
      }
      return cellClass + " " + hoverClass;
    },
    toggleAssigneeStatus(assigneeId) {
      return false;
    },
    changeAssigneeComment(assignee) {
      if (!this.canEditSchedule) {
        return;
      }

      let $this = this;
      this.$eventHub.$emit("assignee-comment-popup", {
        assignee: assignee,
        success: function ({ comment }) {
          assignee.scheduleComment = comment;
          $this.$forceUpdate();
          $this.updateAssigneeComment(assignee, comment);
        },
        cancel: function () {},
      });
    },
    changeAssigneeCommentOld(assigneeId) {
      // if (!this.editing) {
      //   return;
      // }
      let lastStatus = this.assigneeLastStatus[assigneeId];
      let status = lastStatus ? 0 : 1;
      for (let info of this.schedule) {
        if (info.assignee.id === assigneeId) {
          for (let date of this.dates) {
            info.dates[date].active = status;
          }
          break;
        }
      }
      this.assigneeLastStatus[assigneeId] = status;
      this.saveAssigneeStatus(assigneeId, status);
    },
    saveAssigneeStatus(assigneeId, active) {
      let scheduleUrl = "/pest/schedule/set-assignee";
      let params = {
        assigneeId: assigneeId,
        active: active,
      };
      this.$http
        .put(scheduleUrl, params)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          this.$bvToast.toast(this.$t("common.error"), {
            title: this.$t("common.error_during_changing_status"),
            variant: "danger",
          });
          this.processRequestError(error);
        });
    },
    updateAssigneeComment(assignee, comment) {
      let scheduleUrl = "/pest/schedule/assignee-comment";
      let params = {
        assigneeId: assignee.id,
        comment: comment,
      };
      this.$http
        .put(scheduleUrl, params)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          this.$bvToast.toast(this.$t("common.error"), {
            title: this.$t("common.error_during_changing_status"),
            variant: "danger",
          });
          this.processRequestError(error);
        });
    },

    toggleDateStatus(date) {
      return false;
    },
    toggleDateStatusOld(date) {
      // if (!this.editing) {
      //   return;
      // }
      let lastStatus = this.datesLastStatus[date];
      let status = lastStatus ? 0 : 1;
      for (let info of this.schedule) {
        info.dates[date].active = status;
      }
      this.datesLastStatus[date] = status;
      this.saveDateStatus(date, status);
    },
    saveDateStatus(date, active) {
      let scheduleUrl = "/pest/schedule/set-date";
      let params = {
        date: date,
        active: active,
        townId: this.search.townId,
      };
      this.$http
        .put(scheduleUrl, params)
        .then((response) => {})
        .catch((error) => {
          this.$bvToast.toast(this.$t("common.error"), {
            title: this.$t("common.error_during_changing_status"),
            variant: "danger",
          });
          this.processRequestError(error);
        });
    },
    toggleStatus(assignee, date, record) {
      if (!this.canEditSchedule || this.selecting) {
        return;
      }
      // if (!this.editing) {
      //   return;
      // }
      this.openRecordPopup(record, assignee, date);
      // this.saveRecordStatus(assignee.id, date, record)
    },
    saveRecordStatus(assigneeId, date, record) {
      let prevActive = record.active;
      // record.active = this.guessNextActivityStatus(record.active);
      let scheduleUrl = "/pest/schedule/set-record";
      let params = {
        assigneeId: assigneeId,
        date: date,
        active: record.active,
        comment: record.comment,
        setNextStatus: false,
      };
      this.$http
        .put(scheduleUrl, params)
        .then((response) => {
          // record.active = response.data.record.active;
        })
        .catch((error) => {
          record.active = prevActive;
          this.$bvToast.toast(this.$t("common.error"), {
            title: this.$t("common.error_during_changing_status"),
            variant: "danger",
          });
          this.processRequestError(error);
        });
    },
    formattedDate(date) {
      return dayjs(date).format("DD.MM");
    },
    formattedDateHeader(date) {
      let dayOfWeekI = dayjs(date).day();
      let dayOfWeek = this.daysOfWeek[dayOfWeekI];
      let day = dayjs(date).format("DD.MM");
      return day + ", " + dayOfWeek;
    },
    fetchData: function () {
      let scheduleUrl = "/pest/schedule/index";
      this.loading = true;
      let params = {
        dateFrom: this.search.dateFrom,
        dateTo: this.search.dateTo,
        townId: this.search.townId,
      };
      this.$http
        .get(scheduleUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.schedule = response.data;
          this.dates = Object.keys(
            this.schedule[Object.keys(this.schedule)[0]].dates
          );
          this.clearSelection(false);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    toggleEdit() {
      this.editing = !this.editing;
    },
    cellLeave(e) {
      this.curHoveredDate = "";
    },
    cellEnter(date, info) {
      this.curHoveredDate = date;
    },

    openRecordPopup(record, assignee, date) {
      let $this = this;
      this.$eventHub.$emit("schedule-record-show", {
        record: record,
        assignee: assignee,
        date: date,
        success: function (info) {
          record.active = info.status;
          record.comment = info.comment;
          $this.saveRecordStatus(assignee.id, date, record);
          $this.$forceUpdate();
          // $this.request.duration = duration;
          // $this.updateTime(duration)
        },
        cancel: function () {},
      });
    },
    getShiftCount(date) {
      let count = 0;
      for (let assignee of this.schedule) {
        for (let day in assignee.dates) {
          if (day === date) {
            if (assignee.dates[day].active > 0) {
              count++;
            }
          }
        }
      }
      return count;
    },
    selectTown(town) {
      this.search.townId = town;
      this.fetchData();
    },
    startSelect(info, date) {
      if (!this.editing) {
        return;
      }
      this.selecting = true;
      this.selectedAssignee = info.assignee;
    },
    processSelect(info, date) {
      if (
        this.editing &&
        this.selecting &&
        this.selectedAssignee &&
        this.selectedAssignee.id === info.assignee.id
      ) {
        date.selected = true;
        this.$forceUpdate();
      }
    },
    clearSelection(forceUpdate) {
      for (let info of this.schedule) {
        for (let date in info.dates) {
          info.dates[date].selected = false;
        }
      }
      if (forceUpdate) {
        this.$forceUpdate();
      }
    },

    getSelectedDates() {
      let dates = [];
      for (let info of this.schedule) {
        for (let date in info.dates) {
          if (info.dates[date].selected) {
            dates.push(date);
          }
        }
      }
      return dates;
    },

    updateSelectedDates(status) {
      for (let info of this.schedule) {
        for (let date in info.dates) {
          if (info.dates[date].selected) {
            info.dates[date].active = status;
          }
        }
      }
    },

    saveSelectedDates(status) {
      // record.active = this.guessNextActivityStatus(record.active);
      let scheduleUrl = "/pest/schedule/set-several-records";
      let params = {
        status: status,
        assigneeId: this.selectedAssignee.id,
        dates: this.getSelectedDates(),
      };
      this.$http
        .put(scheduleUrl, params)
        .then((response) => {
          this.$toast.open({
            message: this.$t("schedule.schedule_updated"),
            type: "success",
          });

          // record.active = response.data.record.active;
        })
        .catch((error) => {
          this.$toast.open({
            message: this.$t("schedule.cant_save_user"),
            type: "danger",
          });
          this.processRequestError(error);
        });
    },

    endSelect(info, date) {
      if (!this.selecting) {
        return;
      }
      this.selecting = false;
      let $this = this;
      let selectedDates = this.getSelectedDates();
      this.$eventHub.$emit("schedule-record-multiple-show", {
        dates: selectedDates,
        assignee: this.selectedAssignee,
        success: function (info) {
          console.log(info.status);
          // $this.saveRecordStatus(assignee.id, date, record)
          $this.updateSelectedDates(info.status);
          $this.saveSelectedDates(info.status);
          $this.clearSelection(true);
          $this.selectedAssignee = false;
        },
        cancel: function () {
          this.selectedAssignee = false;
          $this.clearSelection(true);
        },
      });
    },
  }, // methods
};
</script>

<style lang="scss" scoped>
table {
  width: auto;
}

.user-name {
  width: 200px;
}

td {
  width: 75px;
}

td.active {
  background: #35bea1;
}

td.inactive {
  background: #d2cccd;
}

td.duty1,
th.duty1 {
  background: #f5be25;
}

td.duty2,
th.duty2 {
  background: #1c84c6;
}

td.dayOff,
th.dayOff {
  background: #ee7981;
}

.ibox-title.editing {
  background: rgba(245, 150, 155, 0.85);
}

.table-schedule.editing th,
.table-schedule.editing td {
  cursor: pointer;
}

.table-schedule td {
  padding: 0;
}

.cell-info {
  font-size: 8px;
  overflow: hidden;
  padding: 4px 2px;
  width: 50px !important;
  height: 30px !important;
  //padding: 5px;
  display: none;
  white-space: nowrap;
}

td:hover .cell-info {
  display: block;
}

tr:hover td {
  opacity: 0.8;
}

td.hover {
  opacity: 0.8;
}

tr:hover th {
  background: #e7e7e7;
}

.comment-icon,
.comment-icon:hover {
  color: #000000;
}

.table thead {
  position: sticky;
  top: 0;
}

.statusCell {
  width: 75px;
  min-width: 75px;
}

table {
  border-collapse: collapse;
}

td {
  //border:1px solid lightgray;
}

.table-div {
  max-width: 100%;
  overflow-x: auto;
  //border:1px solid black;
  //background-color:lightgray;
}

.table {
  //background-color:transparent;
  min-width: 100%;
}

.table thead,
.table tbody {
  display: block;
}

.table thead {
  //border-bottom:1px solid black;
}

.table thead > tr {
  //width:calc(100% - 12px);
}

.table tbody {
  //max-height: 1000px;
  //max-height: 100%;
  //max-height set via vue computed style
  overflow-x: hidden;
}

.table tr {
  display: flex;
}
</style>
