<template>
  <div class="ibox">
    <div class="ibox-content">
      <h1>Реклама по вредителям</h1>
      <div class="mb-2"></div>

      <div class="search">
        <div class="d-flex flex-wrap align-items-center">
          <div class="mr-2">
            <b-form-group label="Дата" prop="date">
              <date-range-picker v-model="dateRange"></date-range-picker>
            </b-form-group>
          </div>

          <div class="mr-2 d-none">
            <b-form-group label="Вредитель" prop="pestId">
              <multiply-autocomplete
                ref="pestAutocomplete"
                :get-data="fetchPestData"
                :get-title="getPestTitle"
                @selected="selectMultiplePest"
              ></multiply-autocomplete>
            </b-form-group>
          </div>

          <div class="mr-2">
            <b-form-group label="Регион" prop="pestId">
              <multiply-autocomplete
                ref="townAutocomplete"
                :get-data="fetchRegionData"
                :get-title="getRegionTitle"
                @selected="selectMultipleRegion"
              ></multiply-autocomplete>
            </b-form-group>
          </div>

          <div class="align-self-center mt-2 mr-2">
            <b-button class="btn btn-primary ml-1" @click="fetchData">
              <font-awesome-icon icon="search" />&nbsp;Искать
            </b-button>
          </div>

          <div v-if="!loading" class="mt-2 mr-2">
            {{ $t("board_common.pagination_total") }}: {{ pagination.total }}
          </div>
        </div>
      </div>

      <table class="table table-striped table-condensed">
        <thead>
          <tr>
            <th>
              <sort-column-title
                v-model="search.sort"
                title="Дата"
                param="dateD"
                @change="fetchData"
              />
            </th>
            <th>
              <sort-column-title
                v-model="search.sort"
                title="Регион"
                param="regionId"
                @change="fetchData"
              />
            </th>
            <th>
              <sort-column-title
                v-model="search.sort"
                title="Вредитель"
                param="pestId"
                @change="fetchData"
              />
            </th>
            <th>
              <sort-column-title
                v-model="search.sort"
                title="Количество успешных первичек на дату создания"
                param="count"
                @change="fetchData"
              />
            </th>
            <th>
              <sort-column-title
                v-model="search.sort"
                title="Сумма"
                param="sumD"
                @change="fetchData"
              />
            </th>
            <th>
              <sort-column-title
                v-model="search.sort"
                title="CPO"
                param="cpoD"
                @change="fetchData"
              />
            </th>
          </tr>
        </thead>
        <tbody v-if="!loading">
          <tr v-for="(result, rIndex) in results" :key="'r' + rIndex">
            <td>
              <!-- Дата -->
              {{ getResultDate(result) }}
            </td>
            <td>
              <!-- Регион -->
              {{ getResultRegionName(result) }}
            </td>
            <td>
              <!-- Вредитель -->
              {{ getResultPestName(result) }}
            </td>
            <td>
              <!-- Количество -->
              {{ result.count }}
            </td>
            <td>
              <!-- Сумма -->
              {{ getResultFormattedSum(result) }}
            </td>
            <td>
              <!-- CPO -->
              {{ formatNumber(result.cpo) }}
            </td>
          </tr>
          <tr>
            <td colspan="3"></td>
            <td>
              <font-awesome-icon v-if="loadingStats" spin icon="rotate" />
              <strong v-else>{{ formatNumber(stats.total.count) }}</strong>
            </td>
            <td>
              <font-awesome-icon v-if="loadingStats" spin icon="rotate" />
              <strong v-else>{{ formatNumber(stats.total.sum) }}</strong>
            </td>
            <td>
              <font-awesome-icon v-if="loadingStats" spin icon="rotate" />
              <strong v-else>{{ formatNumber(stats.total.cpo) }}</strong>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8">Загружается</td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-pagination-nav
      v-if="pagination.count > 1"
      :link-gen="linkGen"
      :value="pagination.page"
      :number-of-pages="pagination.count"
      limit="10"
      use-router
      class="mt-2"
    >
    </b-pagination-nav>
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import {
  getResultDate,
  getResultFormattedSum,
  getResultPestName,
  getResultRegionName,
  getResultStatusTitle,
  statusOptions,
} from "@/modules/direct/decorators/results";
import MultiplyAutocomplete from "@/components/shared/MultiplyAutocomplete";
import { getPestTitle } from "@/modules/pest/decorators/pests";
import { MSK, SPB, DXB } from "@/modules/pest/decorators/towns";
import SortColumnTitle from "@/components/shared/SortColumnTitle";
import { formatNumber } from "@/modules/pest/decorators/common";
import DateRangePicker from "@/components/shared/DateRangePicker";

export default {
  name: "DirectResultList",
  components: {
    DateRangePicker,
    SortColumnTitle,
    MultiplyAutocomplete,
  },
  data() {
    return {
      results: [{}],
      search: {
        title: "",
        status: [],
        date: null,
        dateFrom: null,
        dateTo: null,
        pestId: null,
        regionId: null,
        sort: null,
      },
      loading: true,
      loadingStats: true,
      stats: [],

      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      header: "Отчета",
      fields: [
        {
          key: "date",
          label: "Дата",
          sortable: false,
        },
        {
          key: "regionId",
          label: "Регион",
          sortable: false,
        },
        {
          key: "pestId",
          label: "Вредитель",
          sortable: false,
        },

        {
          key: "count",
          label: "Количество",
          sortable: false,
        },
        {
          key: "sum",
          label: "Сумма",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    dateRange: {
      get() {
        return [this.search.dateFrom, this.search.dateTo];
      },
      set(val) {
        this.search.dateFrom = val[0];
        this.search.dateTo = val[1];
      },
    },
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
  },
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  mounted: function () {
    this.fetchData();
  },
  created() {},
  methods: {
    getUserName,
    getResultStatusTitle,
    getPestTitle,
    getResultDate,
    formatNumber,
    getResultFormattedSum,
    getResultPestName,
    getResultRegionName,
    fetchData: function () {
      this.loading = true;
      let resultsUrl = "/direct/results";
      let params = {
        page: this.page,
        title: this.search.name,
        status: this.search.status,
        date: this.search.date,
        dateFrom: this.search.dateFrom,
        dateTo: this.search.dateTo,
        pestId: this.search.pestId,
        regionId: this.search.regionId,
        sort: this.search.sort,
        pageSize: 200,
      };
      this.$http
        .get(resultsUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.results = response.data;
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          window.scrollTo(0, 0);
          this.fetchStats();
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    fetchStats: function () {
      this.loadingStats = true;

      let resultsUrl = "/direct/result/stats";
      let params = {
        page: this.page,
        title: this.search.name,
        status: this.search.status,
        date: this.search.date,
        dateFrom: this.search.dateFrom,
        dateTo: this.search.dateTo,
        pestId: this.search.pestId,
        regionId: this.search.regionId,
        sort: this.search.sort,
        pageSize: 200,
      };
      this.$http
        .get(resultsUrl, {
          params: params,
        })
        .then((response) => {
          this.loadingStats = false;
          this.stats = response.data;
          console.log(this.stats);
        })
        .catch((error) => {
          this.loadingStats = false;
          this.processRequestError(error);
        });
    },
    handleGroupIndex(command) {
      this.sampleGroupIndex = command;
    },
    linkGen(pageNum) {
      return {
        name: "direct-result-list",
        params: { page: pageNum },
      };
    },

    fetchPestData(search, resolve) {
      let pestUrl = "/pest/pests";
      let params = {
        search: search,
      };
      this.$http
        .get(pestUrl, {
          params: params,
        })
        .then((response) => {
          let data = [{ id: -1, title: "Не назначен" }, ...response.data];
          resolve(data);
        })
        .catch((error) => {
          resolve([]);
        });
    },
    selectMultiplePest(info) {
      this.search.pestId = [];
      if (info) {
        for (let pest of info) {
          this.search.pestId.push(pest.id);
        }
      }
      this.fetchData();
    },

    fetchRegionData(search, resolve) {
      resolve([
        {
          id: -1,
          text: "Не назначено",
        },
        {
          id: MSK,
          text: "Москва",
        },
        {
          id: SPB,
          text: "Санкт-Петербург",
        },
      ]);
    },
    getRegionTitle(region) {
      return region.text;
    },
    selectMultipleRegion(info) {
      this.search.regionId = [];
      if (info) {
        for (let region of info) {
          this.search.regionId.push(region.id);
        }
      }
      this.fetchData();
    },

    fetchStatusData(search, resolve) {
      resolve(statusOptions);
    },
    getStatusTitle(status) {
      return status.text;
    },
    selectMultipleStatus(info) {
      this.search.status = [];
      if (info) {
        for (let status of info) {
          this.search.status.push(status.id);
        }
      }
      this.fetchData();
    },
  }, //methods
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.search {
  border-top: 1px lightgray solid;
  border-bottom: 1px lightgray solid;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
