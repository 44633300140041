<template>
  <b-overlay :show="loading" rounded="sm">
    <b-form id="call-cause-form" ref="call-cause" class=" ">
      <b-form-group label="Статус" prop="statusId">
        <b-form-select
          v-model="call.callCauseId"
          :options="causesList"
          tabindex="1"
          required
        ></b-form-select>
      </b-form-group>
      <b-form-group
        v-if="call.callCauseId === -1"
        :label="$t('common.description')"
        prop="title"
      >
        <b-form-textarea
          v-model="call.callCauseText"
          placeholder="Другая причина"
          tabindex="1"
          required
        ></b-form-textarea>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import { VueMaskDirective } from "v-mask";
import { getCallCauseTitle } from "@/modules/pest/decorators/call-causes.js";

export default {
  name: "RequestCallCause",
  directives: { mask: VueMaskDirective },
  components: {},
  props: ["call"],
  data() {
    return {
      callCause: "",
      callCauseOther: "",
      causesList: [],
      loading: [],
    };
  },
  computed: {},
  watch: {},
  created: function () {
    this.fetchCauses();
  },
  methods: {
    getCallCauseTitle,
    fetchCauses: function () {
      this.loading = true;
      this.causesList = [];
      // this.causesList.push({value: '', text: 'Все'});
      let statusesUrl = "/pest/call-causes";
      let params = {
        all: true,
      };
      this.$http
        .get(statusesUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.causesList.push({
              value: item.id,
              text: this.getCallCauseTitle(item),
            });
          }
          this.causesList.push({ value: -1, text: "Другая причина" });
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
  },
};
</script>

<style lang="scss"></style>
