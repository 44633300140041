<template>
  <div class="pest-town-page">
    <div class="row">
      <div class="col-md-6" style="margin: auto">
        <div class="ibox mb-1">
          <div class="ibox-title">
            <h1>{{ $t("projects.new_project") }}</h1>
          </div>
          <div class="ibox-content">
            <pest-project-form :project="project"></pest-project-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectForm from "@/modules/pest/views/project/ProjectForm.vue";

export default {
  name: "ProjectUpdate",
  components: {
    "pest-project-form": ProjectForm,
  },
  data() {
    return {
      project: {
        id: "new",
        title: "",
      },
      loading: false,
    };
  },
  computed: {
    id() {
      return "new";
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    checkUrl: function () {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped></style>
