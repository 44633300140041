<template>
  <div>
    <div v-if="log.fromCall && !log.replaceId">
      {{ $t("logs.request_opened_by_call_cause") }}
    </div>
    <div v-if="log.replaceId">
      {{ $t("logs.request_opened_duplicate_cause") }} {{ log.replaceId }}
    </div>
    <div v-if="log.originalId">
      {{ $t("logs.request_opened_instead_of_duplicate_cause") }}
      {{ log.originalId }}
    </div>
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";

export default {
  name: "RequestLogOpen",
  components: {},
  props: ["log"],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: { getUserName },
};
</script>

<style lang="scss" scoped></style>
