export const ru = {
  facilities: {
    facilities: "Типы объектов",
    facility_list: "Перечень типов объектов",
    facility: "Тип объекта",
    add_facility: "Добавить тип объекта",
    new_facility: "Новый тип объекта",
    facility_update: "Изменение типа объектов",
    facility_updated: "Тип объекта отредактирован",
    cant_create_facility: "Не удалось добавить тип объекта",
    cant_delete_facility: "Не удалось удалить тип объекта",
    facility_created: "Тип объекта добавлен",
    facility_deleted: "Тип объекта удален",
    type: "Тип",
    delete_facility_question: "Вы действительно хотите удалить тип объекта?",
  },
};
export const en = {
  facilities: {
    facilities: "Facilities",
    facility_list: "Facility list",
    facility: "Facility",
    add_facility: "Add facility",
    new_facility: "New facility",
    change_facility: "Change facility",
    facility_update: "Update facility",
    facility_updated: "Facility changed",
    cant_create_facility: "Can't add facility",
    cant_delete_facility: "Can't delete facility",
    facility_created: "Facility added",
    facility_deleted: "Facility deleted",
    facility_type: "Type",
    type: "Type",
    delete_facility_question: "Do you want to delete the facility?",
  },
};
