export const ru = {
  towns: {
    towns: "Города",
    town_list: "Перечень городов",
    town: "Город",
    add_town: "Добавить город",
    new_town: "Новый город",
    town_update: "Изменение города",
    town_updated: "Город отредактирован",
    cant_create_town: "Не удалось добавить город",
    cant_delete_town: "Не удалось удалить город",
    town_created: "Город добавлен",
    town_deleted: "Город добавлен",
    delete_town_question: "Вы действительно хотите удалить город?",
  },
};
export const en = {
  towns: {
    towns: "Towns",
    town_list: "Town list",
    town: "Town",
    add_town: "Add town",
    new_town: "New town",
    town_update: "Town update",
    change_town: "Change town",
    town_updated: "Town changed",
    cant_create_town: "Can't add town",
    cant_delete_town: "Can't delete town",
    town_created: "Town added",
    town_deleted: "Town deleted",
    delete_town_question: "Do you want to delete the town?",
  },
};
