<template>
  <div>
    <board-modal :show-modal="showModal" @hide="hideModal">
      <template #tabs>
        <div class="reqtabs">
          <button
            v-if="loadingSimilar || !similarRequests.length"
            class="btn mr-2"
            :class="getRequestTabClass(firstRequestId)"
            @click.prevent="switchRequest(firstRequestId)"
          >
            <span v-if="request">
              {{ getRequestPopupTitle(request) }}
            </span>
            <span v-else> {{ $t("common.loading") }} </span>
          </button>

          <button
            v-if="loadingSimilar"
            class="btn btn-outline-danger"
            @click.prevent
          >
            <font-awesome-icon icon="rotate" spin />
            {{ $t("requests.looking_up_duplicates") }}
          </button>
          <template v-else>
            <button
              v-for="(sRequest, srIndex) in similarRequests"
              :key="'sr' + srIndex"
              class="btn mr-2"
              :class="getRequestTabClass(sRequest.id)"
              @click.prevent="switchRequest(sRequest.id)"
            >
              {{ getRequestPopupTitle(sRequest) }}
            </button>
          </template>
        </div>
      </template>

      <template #header>
        <div v-if="!loading" class="d-flex align-items-center contract-title">
          <div class="pl-3">
            <strong v-if="isContract && !requestOpened">{{
              getRequestPopupTitle(request)
            }}</strong>
            <strong v-else>{{ getRequestStageTitle(request) }}</strong>
          </div>

          <ul
            v-if="isContract"
            class="nav nav-tabs ml-5 d-none d-md-block"
            role="tablist"
          >
            <li>
              <a
                :class="tabClass('contacts')"
                class="nav-link"
                data-toggle="tab"
                @click="openTab('contacts')"
                >{{ $t("requests.requests_contacts") }}
              </a>
            </li>
            <li>
              <a
                :class="tabClass('requests')"
                class="nav-link"
                data-toggle="tab"
                @click="openTab('requests')"
              >
                {{ $t("requests.requests_visits") }}
              </a>
            </li>
            <li>
              <a
                :class="tabClass('logs')"
                class="nav-link"
                data-toggle="tab"
                @click="openTab('logs')"
              >
                {{ $t("requests.requests_history") }}
              </a>
            </li>
          </ul>
        </div>
        <div v-else class="pl-3">
          <strong>{{ $t("common.loading") }}</strong>
        </div>
      </template>

      <template #toptabs>
        <ul
          v-if="isContract"
          class="d-block d-sm-none m-0 p-0 text-center"
          role="tablist"
        >
          <li class="inline">
            <a
              :class="tabClass('contacts')"
              class="nav-link"
              data-toggle="tab"
              @click="openTab('contacts')"
            >
              {{ $t("requests.requests_contacts") }}
            </a>
          </li>
          <li class="inline">
            <a
              :class="tabClass('requests')"
              class="nav-link"
              data-toggle="tab"
              @click="openTab('requests')"
            >
              {{ $t("requests.requests_visits") }}
            </a>
          </li>
          <li class="inline">
            <a
              :class="tabClass('logs')"
              class="nav-link"
              data-toggle="tab"
              @click="openTab('logs')"
            >
              {{ $t("requests.requests_history") }}
            </a>
          </li>
        </ul>
      </template>
      <template #header-buttons>
        <button
          v-if="shouldSearchContractButton"
          class="btn btn-outline-primary mr-2"
          @click="openSearchContractPopup"
        >
          {{ $t("requests.follow_up_request") }}
        </button>

        <popup-contact-buttons
          v-if="contractTab === 'contacts'"
          class="mr-3"
          @add-new-contact="addNewContact"
        ></popup-contact-buttons>

        <popup-request-buttons
          v-if="contractTab === 'requests'"
          class="mr-3"
          :request-saving="requestSaving"
          :is-contract="isContract"
          :request-updating="!isClosed(request) && requestOpened"
          :request-opened="requestOpened"
          :request-opening="loading"
          @close-request-card="closeRequestCard"
          @save-request="saveRequest"
          @add-new-request="addNewRequest"
        ></popup-request-buttons>
      </template>

      <template #default>
        <b-overlay :show="loading" rounded="sm" style="height: 100%">
          <div v-if="request">
            <div v-if="requestOpened">
              <requests-sequence-list
                ref="sequence"
                :request-id="request.id"
                :adding-request="addingSimilarRequest"
                @open-request="openSelectedRequest"
                @sequence-loaded="onSequenceLoaded"
              />

              <request-popup-info
                v-if="isClosed(request)"
                :request-model="request"
                :sequence="sequence"
                :data-loaded="true"
                :embedded="true"
                @reanimate-request="reanimateRequest"
                @request-changed="requestChanged"
                @add-new-request="addNewRequest"
              ></request-popup-info>

              <request-form
                v-else
                ref="request-form"
                :request-model="request"
                :sequence="sequence"
                :data-loaded="true"
                :embedded="true"
                @add-new-request="addNewRequest"
                @request-created="requestCreated"
                @start-creating="creationStarted"
                @end-creating="creationStopped"
              ></request-form>
            </div>

            <div v-if="isContract" :class="{ 'd-none': requestOpened }">
              <contract-contacts
                ref="contactList"
                :class="{ 'd-none': contractTab !== 'contacts' }"
                :contract-id="request.contractId"
              ></contract-contacts>

              <contract-requests
                ref="requestList"
                :class="{ 'd-none': contractTab !== 'requests' }"
                :contract-id="request.contractId"
                @open-request="openSelectedRequest"
                @duplicate-request="duplicateRequest"
                @number-list-updated="requestListUpdated"
              ></contract-requests>
              <!--                  v-on:start-updating="requestUpdating=true"-->
              <!--                  v-on:end-updating="requestUpdating=false"-->
              <!--                  v-on:start-saving="requestSaving=true"-->
              <!--                  v-on:end-saving="requestSaving=false"-->

              <request-logs-wrapper
                ref="requestLogWrap"
                :class="{ 'd-none': contractTab !== 'logs' }"
                :request-number-list="requestNumberList"
                :contract-id="request.contractId"
              ></request-logs-wrapper>
            </div>

            <div v-else-if="!loading" class="ibox">
              <div class="ibox-title d-flex">
                <h3>{{ $t("logs.changes_history") }}</h3>
              </div>
              <div class="ibox-content">
                <request-logs
                  v-if="request && request.linkIds"
                  ref="request-logs"
                  :show-search="true"
                  :show-requests="
                    isContract ||
                    (request && request.linkIds && request.linkIds.length)
                  "
                  :request-number-list="[request.id, ...request.linkIds]"
                  :item-id="[request.id, ...request.linkIds]"
                ></request-logs>
              </div>
            </div>
          </div>
          <div v-else></div>
        </b-overlay>
      </template>
    </board-modal>

    <contact-popup />
    <new-contact-phone-popup />
    <request-search-popup />
  </div>
</template>

<script>
import {
  getRequestNumber,
  getRequestPopupTitle,
  getRequestStageTitle,
  isClosed,
  STAGE_DEAL,
  STAGE_LEAD,
  STATUS_NEW,
} from "@/modules/pest/decorators/requests";
import { getAssigneeName } from "@/modules/crm/common";
import BoardModal from "@/modules/pest/views/requests/kanban/BoardModal";
import RequestForm from "@/modules/pest/views/requests/view/RequestForm";
import RequestPopupInfo from "@/modules/pest/views/requests/dispatching/_request/RequestPopupInfo";
import ContractContacts from "@/modules/pest/views/requests/contract/ContractContacts";
import ContractRequests from "@/modules/pest/views/requests/contract/ContractRequests";
import PopupContactButtons from "@/modules/pest/views/requests/kanban/popup/buttons/PopupContactButtons";
import ContactPopup from "@/modules/pest/views/requests/contract/contacts/ContactPopup";
import NewContactPhonePopup from "@/modules/pest/views/requests/contract/contacts/NewContactPhonePopup";
import RequestLogs from "@/modules/pest/views/logs/RequestLogs";
import RequestLogsWrapper from "@/modules/pest/views/requests/kanban/popup/RequestLogsWrapper";
import PopupRequestButtons from "@/modules/pest/views/requests/kanban/popup/buttons/PopupRequestButtons";
import RequestSearchPopup from "@/modules/pest/views/requests/kanban/RequestSearchPopup";
import RequestsSequenceList from "@/modules/pest/views/requests/card/RequestsSequenceList.vue";
import clone from "just-clone";
import { mapGetters } from "vuex";

export default {
  components: {
    RequestsSequenceList,
    RequestSearchPopup,
    PopupRequestButtons,
    RequestLogsWrapper,
    RequestLogs,
    NewContactPhonePopup,
    ContactPopup,
    PopupContactButtons,
    ContractRequests,
    ContractContacts,
    RequestPopupInfo,
    RequestForm,
    BoardModal,
  },
  props: {},
  data() {
    return {
      STAGE_DEAL,
      STAGE_LEAD,
      showModal: false,
      loadingSimilar: false,
      request: false,
      loading: false,
      saving: false,
      requestId: false,
      projectId: false,
      sequence: false,
      firstRequestId: false,
      tabRequestId: false,
      requestOpened: true,
      requestSaving: false,
      addingSimilarRequest: false,
      similarRequests: [],
      contractTab: "contacts",
      requestNumberList: [],
    };
  },
  computed: {
    ...mapGetters({
      userId: "user/userId",
      profile: "user/profile",
    }),
    shouldSearchContractButton() {
      return !this.isContract && this.request.stage === STAGE_LEAD;
    },
    isContract() {
      return (
        this.request && this.request.contractId && this.request.contractId > 0
      );
    },
    definedProjectId() {
      if (this.isContract) {
        return this.sequence[0].projectId;
      }
      return 0;
    },
  },
  watch: {},
  created() {
    this.$eventHub.$on("s-request-update", this.onRequestUpdate);
    this.$eventHub.$on("s-request-reanimate", this.onRequestReanimate);
  },
  mounted() {
    this.$eventHub.$on("show-request-global-popup", this.openPopup);
  },
  beforeDestroy() {
    this.$eventHub.$off("show-request-global-popup");
  },
  methods: {
    isClosed,
    getAssigneeName,
    getRequestNumber,
    getRequestPopupTitle,
    getRequestStageTitle,
    //////////// modal
    handleSuccess(info) {
      this.$bvModal.hide("request-global-popup");
      if (this.successHandler) {
        this.successHandler(info);
      }
      this.successHandler = false;
      this.request = {};
    },

    hideModal() {
      console.log("hide");
      this.showModal = false;
    },
    handleTaskModalOk() {
      this.$refs["request-form"].createOrUpdate(this.handleSuccess);
    },
    //////////// modal logic
    onRequestUpdate(info) {
      if (this.$refs.requestList) {
        this.$refs.requestList.onRequestUpdate(info);
      }
    },
    onRequestReanimate() {
      this.fetchData();
    },
    checkOpen: function () {
      if (this.requestId === "new") {
        this.header = "Создать клиента";
      } else {
        this.fetchData();
        this.fetchSimilar();
      }
    },
    fetchData: function (tab) {
      let requestsUrl = "/pest/requests/open/" + this.requestId;
      let params = {
        fromCall: this.fromCall,
      };
      this.loading = true;
      this.addingSimilarRequest = false;
      this.$http
        .get(requestsUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.request = response.data.request;
          this.tabRequestId = this.request.id;
          if (
            (this.request.contractId && response.data.status === "trash") ||
            tab === "requests"
          ) {
            this.contractTab = "requests";
            this.requestOpened = false;
          } else {
            this.requestOpened = true;
          }
          this.addingSimilarRequest = false;
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    fetchSimilar: function () {
      let requestsUrl = "/pest/requests/similar/" + this.requestId;
      this.loadingSimilar = true;
      this.$http
        .get(requestsUrl)
        .then((response) => {
          this.loadingSimilar = false;
          this.similarRequests = response.data;
        })
        .catch((error) => {
          this.loadingSimilar = false;
          this.processRequestError(error);
        });
    },
    onSequenceLoaded(sequence) {
      console.log(sequence);
      this.sequence = sequence;
    },
    openSelectedRequest(info) {
      this.requestId = info.requestId;
      this.fetchData();
      this.fetchSimilar();
    },
    reanimateRequest(request) {
      this.request.status = STATUS_NEW;
      this.request.reanimated = true;
      console.log("reanimated");
    },
    duplicateRequest(request) {
      this.addNewRequest(false, request);
    },
    openPopup(info) {
      this.showModal = true;
      this.requestId = info.requestId;
      this.projectId = info.projectId;
      this.firstRequestId = info.requestId;
      this.tabRequestId = info.requestId;
      this.fromCall = !!info.fromCall;
      this.contractTab = "requests";
      // this.contractTab = 'contacts'; // //todo

      this.request = false;
      if (info.requestId === "new" && info.request) {
        this.request = info.request;
      }

      this.similarRequests = [];
      this.checkOpen();

      this.successHandler = info.success;
      this.cancelHandler = info.cancel;
      this.hideHandler = info.hide;
    },
    //////////// contractTabs
    tabClass(tab) {
      if (this.contractTab === tab) {
        return "active";
      } else {
        return "";
      }
    },
    openTab(tab) {
      this.requestOpened = false;
      this.contractTab = tab;
      if (tab === "logs") {
        this.$refs.requestLogWrap.$refs["request-logs"].fetchData(false, false);
      }
    },
    //////////// contracts

    requestListUpdated(list) {
      this.requestNumberList = [
        ...new Set([
          this.request.id,
          ...list.map((e) => e.value),
          ...this.request.linkIds,
        ]),
      ];
      this.$refs.requestList.$forceUpdate();
    },

    //////////// requests
    closeRequestCard() {
      if (this.isContract) {
        this.requestOpened = false;
      } else {
        this.hideModal();
      }
    },
    saveRequest() {
      this.requestSaving = true;
      this.$refs["request-form"].createOrUpdate(
        (data) => {
          this.request = data;
          this.requestSaving = false;
        },
        () => {
          this.requestSaving = false;
        }
      );
    },
    addNewRequest(previous, duplicate) {
      let request = {};
      if (previous) {
        request = clone(previous);
        request.previousId = previous.id;
        // на повторы из рекорда назначаем тех же мастеров PCRM-1754
        if (request.hasRecord) {
          request.assignee = previous.assignee;
          request.assigneeId = previous.assigneeId;
          request.hasPredefinedAssignee = true;
        } else {
          request.hasPredefinedAssignee = false;
        }
        this.addingSimilarRequest = true;
      } else if (duplicate) {
        request = clone(duplicate);
        request.duplicateId = duplicate.id;
        request.previousId = null;
        request.hasPredefinedAssignee = false;
        this.addingSimilarRequest = true;
      } else {
        this.addingSimilarRequest = false;
        request.hasPredefinedAssignee = false;
        request = {
          id: "new",
          externalId: null,
          communications: [],
        };
      }
      request.id = "new";
      request.stage = STAGE_LEAD;
      request.contractId = this.request.contractId;
      request.projectId = this.definedProjectId;
      if (!previous || !request.hasRecord) {
        // на повторы назначаем тех же мастеров PCRM-1754
        request.assigneeId = null;
        request.assignee = null;
      }
      request.callNotifications = [];
      request.status = STATUS_NEW;
      request.externalId = null;
      request.sum = null;
      request.dateDay = null;
      request.dateTime = null;
      request.sum = null;
      request.comment = "";
      request.warning = "";
      request.managerId = this.userId;
      request.sourceId = null;
      request.source = null;
      request.manager = this.profile;
      request.contractId = this.request.contractId;
      request.clientPaymentReceived = 0;
      request.assigneeCancelCause = "";
      this.request = request;
      this.showModal = true;
      this.requestId = "new";
      this.contractTab = "requests";
      this.requestOpened = true;
    },

    //////////// contacts
    addNewContact() {
      this.$eventHub.$emit("modal-client-popup", {
        client: {
          requestId: this.request.id,
          contractId: this.request.contractId,
          phones: [
            {
              phone: "",
              type: "mobile",
            },
          ],
        },
        success: ({ client }) => {
          this.$refs.contactList.addNewClient(client);
        },
      });
    },

    //////////// search request
    openSearchContractPopup() {
      this.$eventHub.$emit("modal-search-contract-popup", {
        requestId: this.request.id,
        projectId: this.request.projectId,
        phone: this.request.phone,
        success: ({ requestId, contractId }) => {
          this.loading = true;
          let updateUrl =
            "/pest/request/close-as-duplicate?duplicateId=" +
            this.requestId +
            "&rightId=" +
            requestId;
          this.$http
            .post(updateUrl, {})
            .then((response) => {
              this.requestId = requestId;
              this.fetchData();
              this.fetchSimilar();
              this.$bvToast.toast(this.$t("requests.close_as_duplicate_msg"), {
                title: this.$t("common.success"),
                variant: "success",
              });
            })
            .catch((error) => {
              this.loading = false;
              if (!this.processRequestError(error)) {
                this.$bvToast.toast(
                  this.$t("requests.close_as_duplicate_error_msg"),
                  {
                    title: this.$t("common.error"),
                    variant: "danger",
                  }
                );
              }
            });
        },
      });
    },

    //////////// etc
    //// ??????????

    toggleUpdate(updating) {
      this.updating = updating;
    },
    afterUpdate(request) {
      this.request = request;
      this.toggleUpdate(false);
    },
    requestCreated(request) {
      this.saving = false;
      this.request = request;
      this.addingSimilarRequest = false;
      this.requestId = request.id;
      this.$refs.requestList.fetchRequests();
      this.fetchData();
    },
    creationStarted(request) {
      this.saving = true;
    },
    creationStopped(request) {
      this.saving = false;
    },
    requestChanged(request) {
      this.request = request;
      this.$forceUpdate();
    },
    handleHide() {
      if (this.hideHandler) {
        this.hideHandler(this.$refs["request-form"].request);
      }
    },
    switchRequest(id) {
      this.requestId = id;
      if (this.tabRequestId !== id) {
        this.fetchData();
      }
      this.tabRequestId = id;
    },
    getRequestTabClass(id) {
      if (id === this.tabRequestId) {
        return "btn-danger";
      } else {
        return "btn-outline-danger";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-tabs {
  border-bottom: none !important;
}

.nav-link {
  border-bottom: none;
}

a.nav-link:hover {
  background: #f3f3f4 !important;
  //background: #fff !important;
  color: $inspinia-primary !important;
  //border: 1px solid black;
}

.nav-link.active {
  background: #f3f3f4 !important;
  border-bottom: none;
  color: $inspinia-primary !important;
}

.reqtabs {
  white-space: nowrap;
}

@media (max-width: 640px) {
  .contract-title > div {
    margin-bottom: 1.5rem;
  }
}
</style>
