export const ru = {
  pests: {
    pests: "Вредители",
    pest_list: "Перечень вредителей",
    pest: "Вредитель",
    add_pest: "Добавить вредителя",
    new_pest: "Новый вредитель",
    pest_update: "Изменение вредителя",
    pest_updated: "Вредитель отредактирован",
    cant_create_pest: "Не удалось добавить вредителя",
    cant_delete_pest: "Не удалось удалить вредителя",
    pest_created: "Вредитель добавлен",
    pest_deleted: "Вредитель удален",
    delete_pest_question: "Вы действительно хотите удалить вредителя?",
    percent: "Процент",
    percent_type: "Ставка",
    percent_default: "По-умолчанию",
    percent_manual: "Вручную",
    regular_percent: "Обычная ставка",
    record_percent: "Рекордная ставка",
    service_type: "Тип",
    service_regular: "Обычный",
    service_double: "Сервисный (скрыть от всех)",
    several_types_label: "Тип вредителя (несколько)",
    selected: "Выбранные",
    clear: "Очистить",
  },
};
export const en = {
  pests: {
    pests: "Pests",
    pest_list: "Pest list",
    pest: "Pest",
    add_pest: "Add pest",
    new_pest: "New pest",
    change_pest: "Change pest",
    pest_update: "Update pest",
    pest_updated: "Pest changed",
    cant_create_pest: "Can't add pest",
    cant_delete_pest: "Can't delete pest",
    pest_created: "Pest added",
    pest_deleted: "Pest deleted",
    delete_pest_question: "Do you want to delete the pest?",
    percent: "Percent",
    percent_type: "Percent type",
    percent_default: "Default",
    percent_manual: "Manual",
    regular_percent: "Regular percent",
    record_percent: "Record percent",
    service_type: "Percent type",
    service_regular: "Regular",
    service_double: "Service (hidden)",
    several_types_label: "Several pest types",
    selected: "Selected",
    clear: "Очистить",
  },
};
