<template>
  <div class="ibox">
    <div class="ibox-title d-flex align-items-center pr-4">
      <h1>Цены</h1>
      <div class="ml-2">
        <router-link
          :to="{ name: 'pest-price-create' }"
          class="btn btn-primary"
        >
          <font-awesome-icon icon="edit" />
          Изменить цены
        </router-link>
      </div>
    </div>
    <div class="ibox-content">
      <div class="search">
        <div class="d-flex flex-wrap align-items-center">
          <b-form-group label="Город" prop="townIds" class="mr-2">
            <town-autocomplete
              ref="townAutocomplete"
              required=""
              :default-value="search.townId ? getTownTitle(search.town) : ''"
              @selected="selectTown"
            ></town-autocomplete>
          </b-form-group>
          <b-form-group label="Тип вредителя" prop="title" class="mr-2">
            <pest-autocomplete
              v-model="search.pestId"
              :default-value="getRequestPestName(search)"
              @selected="selectPest"
            ></pest-autocomplete>
          </b-form-group>
          <b-form-group label="Объект" prop="title" class="mr-2">
            <facility-autocomplete
              v-model="search.facilityId"
              :add-new="true"
              :default-value="getRequestFacilityName(search)"
              @selected="selectFacility"
            ></facility-autocomplete>
          </b-form-group>
          <b-form-group label="Физ\Юр. лицо" prop="title" class="mr-2">
            <b-button-group>
              <button
                class="btn"
                :class="getClientTypeClass(null)"
                @click.prevent="selectClientType(null)"
              >
                Все
              </button>
              <button
                class="btn"
                :class="getClientTypeClass(CLIENT_TYPE_IND)"
                @click.prevent="selectClientType(CLIENT_TYPE_IND)"
              >
                {{ $t("requests.type_individual") }}
              </button>
              <button
                class="btn"
                :class="getClientTypeClass(CLIENT_TYPE_ORG)"
                @click.prevent="selectClientType(CLIENT_TYPE_ORG)"
              >
                {{ $t("requests.type_organization") }}
              </button>
            </b-button-group>
          </b-form-group>
          <b-form-group label="Тип оплаты" prop="title" class="mr-2">
            <b-button-group>
              <button
                class="btn"
                :class="getClientPaymentTypeClass(null)"
                @click.prevent="selectClientPaymentType(null)"
              >
                Все
              </button>
              <button
                class="btn"
                :class="getClientPaymentTypeClass(PT_CASH)"
                @click.prevent="selectClientPaymentType(PT_CASH)"
              >
                Нал
              </button>
              <button
                class="btn"
                :class="getClientPaymentTypeClass(PT_CASHLESS)"
                @click.prevent="selectClientPaymentType(PT_CASHLESS)"
              >
                Безнал
              </button>
            </b-button-group>
          </b-form-group>

          <div class="align-self-center mt-2 mr-2">
            <b-button class="btn btn-primary ml-1" @click="fetchData">
              <font-awesome-icon icon="search" />&nbsp;Искать
            </b-button>
          </div>

          <div v-if="!loading" class="mt-2 mr-2">
            {{ $t("board_common.pagination_total") }}: {{ pagination.total }}
          </div>
        </div>
      </div>

      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>
              <sort-column-title
                v-model="search.sort"
                title="Город"
                param="townId"
                @change="fetchData"
              />
            </th>
            <th>
              <sort-column-title
                v-model="search.sort"
                title="Тип вредителя"
                param="pestId"
                @change="fetchData"
              />
            </th>
            <th>
              <sort-column-title
                v-model="search.sort"
                title="Объект"
                param="facilityId"
                @change="fetchData"
              />
            </th>
            <th>
              <sort-column-title
                v-model="search.sort"
                title="Физ\Юр. лицо"
                param="clientType"
                @change="fetchData"
              />
            </th>
            <th>
              <sort-column-title
                v-model="search.sort"
                title="Тип оплаты"
                param="paymentType"
                @change="fetchData"
              />
            </th>
            <th>
              <sort-column-title
                v-model="search.sort"
                title="Стоимость"
                param="price"
                @change="fetchData"
              />
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(price, index) in prices" :key="index">
            <td>{{ getTownTitle(price.town) }}</td>
            <td>{{ getRequestPestName(price) }}</td>
            <td>{{ getRequestFacilityName(price) }}</td>
            <td>
              {{ price.clientType == CLIENT_TYPE_ORG ? "Юр. лицо" : "Физ" }}
            </td>
            <td>{{ price.paymentType == PT_CASHLESS ? "Безнал" : "Нал" }}</td>
            <td>{{ price.price }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-pagination-nav
      v-if="pagination.count > 1"
      :link-gen="linkGen"
      :value="pagination.page"
      :number-of-pages="pagination.count"
      limit="10"
      use-router
      class="mt-2"
    >
    </b-pagination-nav>
  </div>
</template>

<script>
import {
  getTownTitle,
  DXB,
  MSK,
  MSK_C,
  SPB,
  SPB_C,
} from "@/modules/pest/decorators/towns.js";
import {
  CLIENT_TYPE_IND,
  CLIENT_TYPE_ORG,
  getRequestClientName,
  getRequestFacilityName,
  getRequestManagerName,
  getRequestPestName,
  PT_CASH,
  PT_CASHLESS,
} from "@/modules/pest/decorators/requests.js";
import SortColumnTitle from "@/components/shared/SortColumnTitle.vue";
import TownAutocomplete from "@/modules/pest/views/towns/TownAutocomplete.vue";
import PestAutocomplete from "@/modules/pest/views/pests/PestAutocomplete.vue";
import FacilityAutocomplete from "@/modules/pest/views/facilities/FacilityAutocomplete.vue";
import {
  getClientName,
  getClientNameWithContacts,
} from "@/modules/crm/decorators/client.js";
import { getUserName } from "@/modules/crm/common.js";
import { getStatusTitle } from "@/modules/crm/decorators/status.js";

export default {
  name: "PestPriceList",
  components: {
    SortColumnTitle,

    TownAutocomplete,
    PestAutocomplete,
    FacilityAutocomplete,
  },
  data() {
    return {
      MSK,
      SPB,
      DXB,
      MSK_C,
      SPB_C,
      PT_CASHLESS,
      PT_CASH,
      CLIENT_TYPE_ORG,
      CLIENT_TYPE_IND,
      prices: [],
      search: {
        sort: null,
        townId: null,
        pestId: null,
        facilityId: null,
        clientType: null,
        paymentType: null,
        price: null,
      },
      loading: true,
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      header: "Цена",
    };
  },
  computed: {
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
  },
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  mounted: function () {
    this.fetchData();
  },
  created() {},
  methods: {
    getTownTitle,
    getRequestPestName,
    getRequestManagerName,
    getRequestClientName,
    getRequestFacilityName,
    getClientName,
    getUserName,
    getStatusTitle,
    getClientNameWithContacts,

    fetchData: function () {
      this.loading = true;

      let pricesUrl = "/pest/prices";
      let params = {
        page: this.page,
        sort: this.search.sort,
        townId: this.search.townId,
        pestId: this.search.pestId,
        facilityId: this.search.facilityId,
        clientType: this.search.clientType,
        paymentType: this.search.paymentType,
        price: this.search.price,
      };
      this.$http
        .get(pricesUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.prices = response.data;
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    handleGroupIndex(command) {
      this.sampleGroupIndex = command;
    },
    linkGen(pageNum) {
      return {
        name: "pest-price-list",
        params: { page: pageNum },
      };
    },

    selectPest(data) {
      if (data && data.value) {
        this.search.pest = data.value;
        this.search.pestId = data.value.id;
      } else {
        this.search.pest = {};
        this.search.pestId = null;
      }
      this.$forceUpdate();
    },
    selectFacility(data) {
      if (data && data.value) {
        this.search.facility = data.value;
        this.search.facilityId = data.value.id;
      } else {
        this.search.facility = {};
        this.search.facilityId = null;
      }
      this.$forceUpdate();
    },
    selectTown(data) {
      if (data && data.value) {
        this.search.town = data.value;
        this.search.townId = data.value.id;
      } else {
        this.search.town = {};
        this.search.townId = null;
      }
      this.$forceUpdate();
    },
    getTownClass(town) {
      if (this.search.townId === town) {
        return "btn-primary";
      } else {
        return "btn-outline-primary";
      }
    },
    selectClientType(type) {
      this.search.clientType = type;
      this.$forceUpdate();
    },
    getClientTypeClass(type) {
      if (this.search.clientType === type) {
        return "btn-primary";
      } else {
        return "btn-outline-primary";
      }
    },
    selectClientPaymentType(type) {
      this.search.paymentType = type;
    },
    getClientPaymentTypeClass(type) {
      if (this.search.paymentType === type) {
        return "btn-primary";
      } else {
        return "btn-outline-primary";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.search {
  border-top: 1px lightgray solid;
  border-bottom: 1px lightgray solid;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
