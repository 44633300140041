<template>
  <b-button-group>
    <button
      v-if="activeTowns.includes(MSK)"
      class="btn"
      :class="getTownClass(MSK)"
      @click.prevent="selectTown(MSK)"
    >
      {{ getTitle(MSK) }}
    </button>
    <button
      v-if="activeTowns.includes(MSK_C) && withRegion"
      class="btn"
      :class="getTownClass(MSK_C)"
      @click.prevent="selectTown(MSK_C)"
    >
      {{ getTitle(MSK_C) }}
    </button>
    <button
      v-if="activeTowns.includes(SPB)"
      class="btn"
      :class="getTownClass(SPB)"
      @click.prevent="selectTown(SPB)"
    >
      {{ getTitle(SPB) }}
    </button>
    <button
      v-if="activeTowns.includes(SPB_C) && withRegion"
      class="btn"
      :class="getTownClass(SPB_C)"
      @click.prevent="selectTown(SPB_C)"
    >
      {{ getTitle(SPB_C) }}
    </button>
    <button
      v-if="activeTowns.includes(DXB)"
      class="btn"
      :class="getTownClass(DXB)"
      @click.prevent="selectTown(DXB)"
    >
      {{ getTitle(DXB) }}
    </button>
  </b-button-group>
</template>
<script>
import {
  DXB,
  getTownTitle,
  MSK,
  SPB,
  MSK_C,
  SPB_C,
} from "@/modules/pest/decorators/towns";
import { mapGetters } from "vuex";

export default {
  name: "TownFilter",
  props: {
    value: Number,
    withRegion: { Number, default: false },
  },
  data() {
    return {
      MSK,
      SPB,
      DXB,
      MSK_C,
      SPB_C,
    };
  },
  computed: {
    ...mapGetters({
      getProp: "data/getProp",
    }),
    towns() {
      return this.getProp("towns");
    },

    activeTowns() {
      let towns = this.getProp("towns");
      if (!towns) return [];
      return towns.map((t) => t.id);
    },
  },
  created() {},
  methods: {
    getTitle(town) {
      return getTownTitle(this.towns.find((t) => t.id === town));
    },
    getTownClass(town) {
      if (this.value === town) {
        return "btn-primary";
      } else {
        return "btn-outline-primary";
      }
    },
    selectTown(town) {
      this.$emit("change", town);
    },
  },
};
</script>
<style scoped lang="scss"></style>
