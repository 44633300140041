<template>
  <b-form
    id="pest-region-form"
    ref="region"
    :model="region"
    class="pest-region-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="div">
      <div class="row">
        <div class="col-12">
          <b-form-group :label="$t('common.title')" prop="title">
            <b-form-input
              v-model="region.title"
              :placeholder="$t('common.title')"
              tabindex="1"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <!--      <b-form-group :label="this.$t('common.town')" prop="townId">-->
      <!--        <autocomplete :search="searchTown"-->
      <!--                      class=""-->
      <!--                      :default-value="townTitle"-->
      <!--                      placeholder="Поиск по городам"-->
      <!--                      aria-label="Поиск по городам"-->
      <!--                      :get-result-value="getResultTownAutocompleteValue"-->
      <!--                      ref="searchTown"-->
      <!--                      @submit="handleTownSubmit"-->
      <!--        ></autocomplete>-->
      <!--      </b-form-group>-->

      <div class="row">
        <div class="col-md-5">
          <b-form-group :label="$t('common.color')" prop="color">
            <b-form-input v-model="region.color" type="color"></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group :label="$t('common.description')" prop="title">
            <b-form-textarea
              v-model="region.comment"
              :placeholder="$t('common.description')"
              tabindex="1"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-button variant="primary" @click="createOrUpdate">
            <font-awesome-icon icon="check" />
            {{ $t("common.save") }}
          </b-button>

          <a
            v-if="region.id !== 'new'"
            href=""
            class="btn btn-outline btn-danger ml-2"
            @click.prevent="deleteRegion"
          >
            <font-awesome-icon icon="trash" />
            {{ $t("common.delete") }}
          </a>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import { VueMaskDirective } from "v-mask";
import { getTownTitle } from "@/modules/pest/decorators/towns";

export default {
  name: "PestRegionForm",
  directives: { mask: VueMaskDirective },
  components: {},
  props: ["region"],
  data() {
    return {
      phoneMask: false,
      loading: false,
    };
  },
  computed: {
    id() {
      return this.region.id;
    },
    name() {
      let name = this.region.name
        ? this.region.name
        : this.$t("regions.new_region");
      return name ? name : "region";
    },
  },
  watch: {},
  created: function () {},
  methods: {
    getUserName,
    getTownTitle,
    update: function () {
      let updateUrl;
      updateUrl = "/pest/regions/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.region)
        .then((response) => {
          this.region = response.data;
          this.loading = false;
          this.$eventHub.$emit("update-pest-region-info", this.region);
          // this.$router.push({name: 'pest-region-view', params: {id: this.region.id}});
          this.$bvToast.toast(this.$t("common.data_saved"), {
            title: this.$t("regions.region_updated"),
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: this.$t("common.error_during_saving"),
              type: "danger",
            });
          }
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "/pest/regions";
      this.loading = true;
      this.$http
        .post(updateUrl, this.region)
        .then((response) => {
          this.region = response.data;
          this.$eventHub.$emit("update-pest-region-info", this.region);
          this.$router.push({
            name: "pest-region-update",
            params: { id: this.region.id },
          });
          this.$bvToast.toast(this.$t("regions.region_added"), {
            title: this.$t("regions.region_added"),
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.error"), {
              title: this.$t("regions.cant_create_region"),
              variant: "danger",
            });
          }
        });
    },
    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById("pest-region-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast(this.$t("common.data_is_saving"), {
            title: this.$t("common.please_wait"),
            variant: "info",
          });
          return;
        }
        if (this.id === "new") {
          this.create();
        } else {
          this.update();
        }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast(this.$t("common.form_has_errors"), {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },

    handleTownSubmit(result) {
      this.region.townId = result.id;
    },
    getResultTownAutocompleteValue(town) {
      return this.getTownTitle(town);
    },
    searchTown(input) {
      return new Promise((resolve) => {
        // this.error = null;
        let categoriesUrl = "/pest/towns/search";
        this.loadingTownsList = true;
        this.$http
          .get(categoriesUrl, {
            params: { term: input },
          })
          .then((response) => {
            this.loadingTownsList = false;
            resolve(response.data);
            // this.task = response.data;
            // this.header = this.task.email;
          })
          .catch((error) => {
            this.loadingTownsList = false;
            this.processRequestError(error);
            resolve([]);
          });
      });
    },
    deleteRegion() {
      if (confirm(this.$t("regions.delete_region_question"))) {
        let deleteUrl = "/pest/regions/" + this.town.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            this.loading = false;
            this.$router.push({ name: "pest-town-list" });
            this.$bvToast.toast(this.$t("regions.region_deleted"), {
              title: this.$t("regions.region_deleted"),
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast(this.$t("common.error"), {
                title: this.$t("regions.cant_delete_region"),
                variant: "danger",
              });
            }
          });
      }
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}
</style>
