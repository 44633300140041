import t from "@/i18n";

const TYPE_EMPLOYEE = 0;
const TYPE_ORGANIZATION = 1;
const TYPE_PERSON = 2;

const NUMERATION_COMMON = 0;
const NUMERATION_CLIENT = 1;
const GENDER_MALE = "male";
const GENDER_FEMALE = "female";

const contactTypes = [
  { value: "personal", text: "Личный" },
  { value: "mobile", text: "Мобильный" },
  { value: "work", text: "Рабочий" },
];

const emailContactTypes = [
  { value: "personal", text: "Личный" },
  { value: "work", text: "Рабочий" },
];

const getContactType = function (type) {
  if (type === "personal") {
    return "(Личный)";
  }
  if (type === "mobile") {
    return "(Мобильный)";
  }
  return "";
};

const getClientNameWithContacts = function (client) {
  let name = getClientName(client);
  if (client && client.phone) {
    name += " (" + client.phone + ")";
  }
  return name;
};

const getClientName = function (client, defaultTitle) {
  if (!client) {
    return defaultTitle ? defaultTitle : t("common.not_set");
  }
  if (client.type == TYPE_ORGANIZATION) {
    let title = client.title || "";
    if (title) {
      return title;
    } else {
      return "[Без названия]";
    }
  } else {
    let firstName = client.firstName || "";
    let lastName = client.lastName || "";
    let patronymic = client.patronymic || "";
    let name = firstName + " " + lastName + " " + patronymic;
    name = name.trim();
    if (name && name !== "") {
      return name;
    }
    if (!name && client.username) {
      return client.username;
    } else {
      return t("common.without_name");
    }
  }
};

const getClientFirstPhone = function (client) {
  if (
    !client.phones ||
    client.phones.length === 0 ||
    !Array.isArray(client.phones)
  ) {
    return "";
  }
  return client.phones[0].phone;
};
const getClientFirstEmail = function (client) {
  if (
    !client.emails ||
    client.emails.length === 0 ||
    !Array.isArray(client.emails)
  ) {
    return "";
  }
  return client.emails[0].email;
};
const getClientGender = function (client) {
  if (!client.gender) {
    return "";
  }
  if (client.gender === GENDER_FEMALE) {
    return "Женский";
  } else {
    return "Мужской";
  }
};
const getClientOrganization = function (client) {
  if (!client.organization) {
    return "";
  }
  return getClientName(client.organization);
};

const getNumerationTypeTitle = function (client) {
  if (client.numeration == NUMERATION_CLIENT) {
    return "Собственная";
  } else {
    return "Общая";
  }
};

export {
  getClientOrganization,
  getClientNameWithContacts,
  getClientName,
  getClientFirstPhone,
  getClientFirstEmail,
  getNumerationTypeTitle,
  getClientGender,
  getContactType,
  contactTypes,
  emailContactTypes,
  GENDER_MALE,
  GENDER_FEMALE,
  NUMERATION_CLIENT,
  NUMERATION_COMMON,
  TYPE_ORGANIZATION,
  TYPE_EMPLOYEE,
  TYPE_PERSON,
};
