<template>
  <div class="ibox">
    <div class="ibox-content">
      <h3>Отчет по рекламе</h3>

      <div class="search">
        <div class="d-flex flex-wrap align-items-center">
          <div class="mr-2">
            <b-form-group label="Дата" prop="date">
              <date-range-picker v-model="dateRange"></date-range-picker>
            </b-form-group>
          </div>

          <div class="mr-2 d-none">
            <b-form-group label="Вредитель" prop="pestId">
              <multiply-autocomplete
                ref="pestAutocomplete"
                :get-data="fetchPestData"
                :get-title="getPestTitle"
                @selected="selectMultiplePest"
              ></multiply-autocomplete>
            </b-form-group>
          </div>

          <div class="align-self-center mt-2 mr-2">
            <b-button class="btn btn-primary ml-1" @click="fetchData">
              <font-awesome-icon icon="search" />&nbsp;Искать
            </b-button>
          </div>
        </div>
      </div>

      <table
        class="table table-striped table-condensed table-bordered table-hover"
      >
        <thead>
          <tr>
            <th></th>
            <th colspan="3" class="text-center">МСК и МО</th>
            <th colspan="3" class="text-center">СПБ и ЛО</th>
          </tr>
          <tr>
            <th>
              <!--            <sort-column-title v-model="search.sort" @change="fetchData" title="Вредитель" param="pestId"/>-->
              Вредитель
            </th>
            <th>
              <!--            <sort-column-title v-model="search.sort" @change="fetchData" title="МСК и МО, ₽" param="mskRub"/>-->
              Затраты, ₽
            </th>
            <th>
              Успешные первички
              <!--            <sort-column-title v-model="search.sort" @change="fetchData"-->
              <!--                               title="Успешные первички, МСК и МО" param="firstTimeSuccessCountMsk"/>-->
            </th>
            <!--          <th>-->
            <!--            <sort-column-title v-model="search.sort" @change="fetchData"-->
            <!--                               title="Количество продаж в директе, МСК и МО" param="count"/>-->
            <!--          </th>-->

            <th>
              <!--            <sort-column-title v-model="search.sort" @change="fetchData" title="CPO, МСК и МО" param="mskCPO"/>-->
              CPO
            </th>

            <th>
              <!--            <sort-column-title v-model="search.sort" @change="fetchData" title="СПБ и ЛО, ₽" param="spbRub"/>-->
              Затраты, ₽
            </th>
            <th>
              <!--            <sort-column-title v-model="search.sort" @change="fetchData"-->
              <!--                               title="Успешные первички, СПБ и ЛО" param="firstTimeSuccessCountSpb"/>-->
              Успешные первички
            </th>

            <!--          <th>-->
            <!--            <sort-column-title v-model="search.sort" @change="fetchData"-->
            <!--                               title="Количество продаж в директе, СПБ и ЛО" param="count"/>-->
            <!--          </th>-->
            <th>
              <!--            <sort-column-title v-model="search.sort" @change="fetchData" title="CPO, СПБ и ЛО" param="spbCPO"/>-->
              CPO
            </th>
          </tr>
        </thead>
        <tbody v-if="!loading">
          <tr v-for="(result, rIndex) in results" :key="'r' + rIndex">
            <td>
              <!--   Вредитель-->
              {{ getResultPestName(result) }}
            </td>

            <td>
              <!-- МСК и МО, ₽ -->
              {{ formatNumber(result.mskRub) }}
            </td>
            <td>
              <!-- Количество успешных первичек, МСК и МО -->
              {{ formatNumber(result.firstTimeSuccessCountMsk) }}
            </td>
            <!--          <td>-->
            <!--            &lt;!&ndash; Количество продаж в директе, МСК и МО &ndash;&gt;-->
            <!--            {{ formatNumber(result.directSalesCountMsk) }}-->
            <!--          </td>-->
            <td>
              <!-- CPO, МСК и МО -->
              {{ formatNumber(result.mskCPO) }}
            </td>
            <td>
              <!-- СПБ и ЛО, ₽ -->
              {{ formatNumber(result.spbRub) }}
            </td>
            <td>
              <!-- Количество успешных первичек, СПБ и ЛО -->
              {{ formatNumber(result.firstTimeSuccessCountSpb) }}
            </td>
            <!--          <td>-->
            <!--            &lt;!&ndash; Количество продаж в директе, СПБ и ЛО &ndash;&gt;-->
            <!--            {{ formatNumber(result.directSalesCountSpb) }}-->
            <!--          </td>-->
            <td>
              <!-- CPO, СПБ и ЛО -->
              {{ formatNumber(result.spbCPO) }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8">Загружается</td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-pagination-nav
      v-if="pagination.count > 1"
      :link-gen="linkGen"
      :value="pagination.page"
      :number-of-pages="pagination.count"
      limit="10"
      use-router
      class="mt-2"
    >
    </b-pagination-nav>
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import {
  getResultDate,
  getResultFormattedSum,
  getResultPestName,
  getResultRegionName,
  getResultStatusTitle,
  statusOptions,
} from "@/modules/direct/decorators/results";
import MultiplyAutocomplete from "@/components/shared/MultiplyAutocomplete";
import { getPestTitle } from "@/modules/pest/decorators/pests";
import { MSK, SPB, DXB } from "@/modules/pest/decorators/towns";
import { formatNumber } from "@/modules/pest/decorators/common";
import DateRangePicker from "@/components/shared/DateRangePicker";
import dayjs from "dayjs";

export default {
  name: "DirectAdReport",
  components: {
    DateRangePicker,
    MultiplyAutocomplete,
  },
  data() {
    return {
      results: [],
      search: {
        title: "",
        status: [],
        date: null,
        dateFrom: null,
        dateTo: null,
        pestId: null,
        regionId: null,
        sort: null,
      },
      loading: true,
      loadingStats: true,
      stats: [],

      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      header: "Отчета",
    };
  },
  computed: {
    dateRange: {
      get() {
        return [this.search.dateFrom, this.search.dateTo];
      },
      set(val) {
        this.search.dateFrom = val[0];
        this.search.dateTo = val[1];
      },
    },
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
  },
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  mounted: function () {
    this.fetchData();
  },
  created() {
    if (!this.search.dateTo) {
      this.search.dateTo = dayjs().format("YYYY-MM-DD");
    }
    if (!this.search.dateFrom) {
      this.search.dateFrom = dayjs().subtract(1, "month").format("YYYY-MM-DD");
    }
  },
  methods: {
    getUserName,
    getResultStatusTitle,
    getPestTitle,
    getResultDate,
    formatNumber,
    getResultFormattedSum,
    getResultPestName,
    getResultRegionName,
    fetchData: function () {
      this.loading = true;
      let resultsUrl = "/direct/default/ad-report";
      let params = {
        date: this.search.date,
        dateFrom: this.search.dateFrom,
        dateTo: this.search.dateTo,
        pestId: this.search.pestId,
        // regionId: this.search.regionId,
        // sort: this.search.sort,
        // pageSize: 200,
      };
      this.$http
        .get(resultsUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.results = response.data.pests;
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    handleGroupIndex(command) {
      this.sampleGroupIndex = command;
    },
    linkGen(pageNum) {
      return {
        name: "direct-result-list",
        params: { page: pageNum },
      };
    },

    fetchPestData(search, resolve) {
      let pestUrl = "/pest/pests";
      let params = {
        search: search,
      };
      this.$http
        .get(pestUrl, {
          params: params,
        })
        .then((response) => {
          let data = [{ id: -1, title: "Не назначен" }, ...response.data];
          resolve(data);
        })
        .catch((error) => {
          resolve([]);
        });
    },
    selectMultiplePest(info) {
      this.search.pestId = [];
      if (info) {
        for (let pest of info) {
          this.search.pestId.push(pest.id);
        }
      }
      this.fetchData();
    },

    fetchRegionData(search, resolve) {
      resolve([
        {
          id: -1,
          text: "Не назначено",
        },
        {
          id: MSK,
          text: "Москва",
        },
        {
          id: SPB,
          text: "Санкт-Петербург",
        },
      ]);
    },
    getRegionTitle(region) {
      return region.text;
    },
    selectMultipleRegion(info) {
      this.search.regionId = [];
      if (info) {
        for (let region of info) {
          this.search.regionId.push(region.id);
        }
      }
      this.fetchData();
    },

    fetchStatusData(search, resolve) {
      resolve(statusOptions);
    },
    getStatusTitle(status) {
      return status.text;
    },
    selectMultipleStatus(info) {
      this.search.status = [];
      if (info) {
        for (let status of info) {
          this.search.status.push(status.id);
        }
      }
      this.fetchData();
    },
  }, //methods
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.search {
  border-top: 1px lightgray solid;
  border-bottom: 1px lightgray solid;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
