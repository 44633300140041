export const ru = {
  regions: {
    regions: "Регионы",
    region_list: "Перечень регионов",
    region: "Регион",
    add_region: "Добавить регион",
    new_region: "Новый регион",
    region_update: "Изменение региона",
    region_updated: "Регион отредактирован",
    cant_create_region: "Не удалось добавить регион",
    cant_delete_region: "Не удалось удалить регион",
    region_created: "Регион добавлен",
    region_deleted: "Регион удален",
    delete_region_question: "Вы действительно хотите удалить регион?",
  },
};
export const en = {
  regions: {
    regions: "Regions",
    region_list: "Region list",
    region: "Region",
    add_region: "Add region",
    new_region: "New region",
    change_region: "Change region",
    region_update: "Update region",
    region_updated: "Region changed",
    cant_create_region: "Can't add region",
    cant_delete_region: "Can't delete region",
    region_created: "Region added",
    region_deleted: "Region deleted",
    delete_region_question: "Do you want to delete the region?",
  },
};
