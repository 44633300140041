export const ru = {
  search: {
    search: "Поиск",
    reset: "Сбросить",
    find: "Искать",
    city: "Город",
    filter: "Фильтр",
    filter_prompt: "Имя, телефон, адрес, id",
    search_prompt: "адрес, телефон, номер карточки",
    address_prompt: "город, поселок, улица",
    phone_prompt: "номер телефона",
    number: "Номер заявки/сделки",
    number_prompt: "номер заявки/сделки",
    request_sum: "Сумма услуг",
    request_sum_from: "Сумма услуг, от",
    request_sum_to: "Сумма услуг, до",
    date_from: "Дата от",
    date_to: "Дата до",
    col_filter_msg: "Показывать отмеченные столбцы",
  },
};
export const en = {
  search: {
    search: "Search",
    reset: "Reset",
    find: "Find",
    city: "City",
    filter: "Filter",
    filter_prompt: "Name, phone, adress, id",
    search_prompt: "address, phone, number",
    address_prompt: "city, town, street",
    phone_prompt: "phone number",
    number: "Request number",
    number_prompt: "request number",
    request_sum: "Request sum",
    request_sum_from: "Request sum, from",
    request_sum_to: "Request sum, to",
    date_from: "Date from",
    date_to: "Date to",
    col_filter_msg: "Show selected columns",
  },
};
