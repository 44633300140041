<template>
  <b-form
    id="pest-payment-form"
    ref="payment"
    :model="payment"
    class="pest-payment-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="div">
      <div class="row">
        <div class="col-12">
          <div class="ibox mb-1">
            <div class="ibox-title">
              <div class="d-flex align-items-center">
                <h1>{{ $t("payments.new_payment") }}</h1>
                <b-button
                  variant="primary"
                  class="ml-5"
                  @click="createOrUpdate"
                >
                  <font-awesome-icon icon="check" />
                  <span v-if="payment.id === 'new'">
                    {{ $t("payments.add_payment") }}
                  </span>
                  <span v-else>{{ $t("common.save") }}</span>
                </b-button>

                <a
                  v-if="payment.id !== 'new'"
                  href=""
                  class="btn btn-outline btn-danger ml-2"
                  @click.prevent="deletePayment"
                >
                  <font-awesome-icon icon="trash" />
                  {{ $t("common.delete") }}
                </a>
              </div>
            </div>
            <div class="ibox-content">
              <div class="row">
                <div class="col-12">
                  <b-form-group
                    :label="$t('requests.assignee')"
                    prop="employeeId"
                  >
                    <user-autocomplete
                      v-model="payment.employeeId"
                      :default-value="getEmployeeName(payment.employee)"
                      @selected="selectManager"
                    />
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <b-form-group :label="$t('common.description')" prop="sum">
                    <b-form-textarea
                      v-model="payment.comment"
                      :placeholder="$t('common.description')"
                      tabindex="1"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <b-form-group :label="$t('payments.sum')" prop="sum">
                    <b-form-input
                      v-model="payment.sum"
                      :placeholder="$t('payments.sum')"
                      tabindex="1"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="ibox-footer">
              <div class="row">
                <div class="col-12">
                  <h3>
                    {{ $t("requests.requests") }}
                    <small>
                      <span v-if="requestLoading">
                        <font-awesome-icon icon="rotate" spin />
                      </span>
                      <span v-else>{{ requests.length }}</span>
                      |
                      <span
                        >{{ $t("requests.earned") }}:
                        <strong>{{ formatNumber(profit) }}</strong></span
                      >
                      |
                      <span
                        >{{ $t("fines.fines") }}:
                        <strong>{{ formatNumber(fineSum) }}</strong></span
                      >
                      |
                      <span
                        >{{ $t("payments.sum_to_pay") }}:
                        <strong>{{ formatNumber(toPayCounted) }}</strong></span
                      >
                    </small>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-8">
          <div class="ibox mb-1">
            <div class="ibox-title">
              <h2>{{ $t("requests.requests") }}</h2>
            </div>
            <div class="ibox-content">
              <div v-if="!requestLoading">
                <div class="">
                  <!--            <h3> {{ daysRequest.date }}</h3>-->
                  <table class="table table-condensed">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{{ $t("requests.date") }}</th>
                        <th>{{ $t("requests.number") }}</th>
                        <th>{{ $t("requests.client") }}</th>
                        <th>{{ $t("requests.services") }}</th>
                        <th>{{ $t("requests.earned") }}</th>
                        <th>{{ $t("requests.trip") }}</th>
                        <th>{{ $t("requests.equipment") }}</th>
                        <th>{{ $t("payments.raw_cost") }}</th>
                        <th>{{ $t("payments.pledge") }}</th>
                        <th>{{ $t("payments.sum_to_pay") }}</th>
                        <!--                        <th>Заработано</th>-->
                        <!--                        <th>Транспорт</th>-->
                        <!--                        <th>Оборудование</th>-->
                        <!--                        <th>Себестоимость</th>-->
                        <!--                        <th>Депозит</th>-->
                        <!--                        <th>К выплате</th>-->
                      </tr>
                    </thead>

                    <tbody
                      v-for="(daysRequest, index) in requestsByDays"
                      :key="index"
                    >
                      <tr class="">
                        <th>
                          <b-form-checkbox
                            v-if="daysRequest.requests.length"
                            v-model="daysRequest.checked"
                            @change="toggleAllDaysRequests(daysRequest)"
                          >
                          </b-form-checkbox>
                        </th>
                        <th colspan="3">{{ daysRequest.dateF }}</th>
                        <th>
                          {{
                            formatNumber(getDayTotalSum(daysRequest.requests))
                          }}
                          <!--                    Услуги-->
                        </th>
                        <th>
                          {{ formatNumber(getDayEarned(daysRequest.requests)) }}
                          <!--                    Заработано-->
                        </th>
                        <th>
                          {{ formatNumber(getTripSum(daysRequest.requests)) }}
                          <!--                    Транспорт-->
                        </th>
                        <th>
                          {{
                            formatNumber(
                              getDayEquipmentSum(daysRequest.requests)
                            )
                          }}
                          <!--                    Оборудование-->
                        </th>
                        <th>
                          {{
                            formatNumber(
                              getDayProductsNetCostSum(daysRequest.requests)
                            )
                          }}
                          <!-- Cебестоимость товаров -->
                        </th>
                        <th>
                          {{ formatNumber(getDayPledgeSum(daysRequest.date)) }}
                          <!--                    Депозит-->
                        </th>
                        <th>
                          {{ formatNumber(getToPaySum(daysRequest)) }}
                          <!--                    К выплате-->
                        </th>
                      </tr>

                      <tr
                        v-for="(request, rIndex) in daysRequest.requests"
                        :key="'r' + rIndex"
                      >
                        <td>
                          <b-form-checkbox
                            v-model="request.checked"
                            @change="toggleDaysRequest(daysRequest, request)"
                          >
                          </b-form-checkbox>
                        </td>
                        <td>
                          {{ getRequestTime(request) }}
                        </td>
                        <td>
                          <router-link
                            v-if="request.clientId"
                            :to="{
                              name: 'pest-request-view',
                              params: { id: request.id },
                            }"
                            class=""
                          >
                            {{ getRequestNumber(request) }}
                          </router-link>
                        </td>
                        <td>
                          <font-awesome-icon
                            v-if="request.isOrganization"
                            icon="building"
                            fixed-width
                            class="mr-1"
                          />

                          <font-awesome-icon
                            v-else
                            icon="user"
                            fixed-width
                            class="mr-1"
                          />
                          {{ getRequestClientName(request) }}
                        </td>
                        <td>
                          {{ formatNumber(totalSum(request)) }}
                        </td>
                        <td>
                          <span v-if="request.earned">
                            {{ formatNumber(request.earned) }}
                          </span>
                          <span v-if="request.hasRecord">
                            <font-awesome-icon
                              v-b-tooltip.hover
                              icon="star"
                              :title="$t('requests.record')"
                            />
                          </span>
                        </td>

                        <td>
                          {{
                            formatNumber(request.tripSum + request.tripBackSum)
                          }}
                        </td>
                        <td>
                          {{
                            formatNumber(
                              request.equipmentSum
                                ? request.equipmentSum
                                : request.equipmentUserSum
                            )
                          }}
                        </td>
                        <td>
                          {{ formatNumber(productNetCost(request)) }}
                        </td>
                        <td></td>
                        <td>
                          {{ formatNumber(paymentSum(request)) }}
                          <span v-if="request.isCashless">
                            ({{ $t("requests.payment_cashless") }})
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="ibox mb-1">
            <div class="ibox-title">
              <h2>{{ $t("fines.fines") }}</h2>
            </div>
            <div class="ibox-content">
              <div v-if="!requestLoading">
                <div class="">
                  <!--            <h3> {{ daysFine.date }}</h3>-->
                  <table class="table table-condensed">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{{ $t("fines.date") }}</th>
                        <th>{{ $t("fines.fine") }}</th>
                        <th>{{ $t("fines.sum") }}</th>
                        <th>
                          {{ $t("fines.who_assigned") }}<br />
                          {{ $t("fines.comment") }}
                        </th>
                      </tr>
                    </thead>

                    <tbody
                      v-for="(daysFine, index) in finesByDays"
                      :key="index"
                    >
                      <tr
                        v-for="(fine, rIndex) in daysFine.fines"
                        :key="'r' + rIndex"
                      >
                        <td>
                          <b-form-checkbox
                            v-model="fine.checked"
                            @change="toggleDaysFine(daysFine, fine)"
                          >
                          </b-form-checkbox>
                        </td>
                        <td>
                          {{ getFineTime(fine) }}
                        </td>
                        <td>
                          <router-link
                            :to="{
                              name: 'pest-fine-update',
                              params: { id: fine.id },
                            }"
                            class=""
                          >
                            {{ fine.comment }}
                          </router-link>
                        </td>
                        <td>
                          {{ formatNumber(fine.sum) }}
                        </td>
                        <td>
                          {{ getUserName(fine.manager) }} <br />
                          {{ fine.comment }}
                          {{ fine.review }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import { VueMaskDirective } from "v-mask";
import { getClientName } from "@/modules/crm/decorators/client";
import { TYPE_LOGIST } from "@/modules/users/decorators/users";
import {
  getRequestClientName,
  getRequestDate,
  getRequestDatetime,
  getRequestNumber,
  getRequestTime,
  getRequestTitle,
  paymentSum,
  productNetCost,
  productSum,
  servicesSum,
  toPaySum,
  totalSum,
} from "@/modules/pest/decorators/requests";
import { getFineDate, getFineTime } from "@/modules/pest/decorators/fines";
import UserAutocomplete from "@/modules/users/views/user/UserAutocomplete";
import { formatNumber } from "../../decorators/common";
import dayjs from "dayjs";

export default {
  name: "PestPaymentForm",
  directives: { mask: VueMaskDirective },
  components: { UserAutocomplete },
  props: ["payment"],
  data() {
    return {
      phoneMask: false,
      loading: false,
      fineLoading: false,
      requestLoading: false,
      requests: [],
      pledges: [],
      fines: [],
      requestsByDays: [],
      finesByDays: [],
      toPay: 0,
      profit: 0,
      fineSum: 0,
      toPayCounted: 0,
    };
  },
  computed: {
    id() {
      return this.payment.id;
    },

    name() {
      let name = this.payment.name
        ? this.payment.name
        : this.$t("payments.new_payment");
      return name ? name : "payment";
    },
  },
  watch: {
    $route() {
      let userId =
        this.$route.params.userId !== undefined
          ? this.$route.params.userId
          : null;
      if (userId) {
        this.payment.assigneeId = userId;
        this.loadUnpaidRequest(userId);
      }
    },
  },
  created: function () {},
  mounted() {
    let userId =
      this.$route.params.userId !== undefined
        ? this.$route.params.userId
        : null;
    if (userId) {
      this.payment.employeeId = userId;
      this.loadUnpaidRequest(userId);
    }
  },
  methods: {
    totalSum,
    formatNumber,
    getRequestNumber,
    getRequestTitle,
    getRequestTime,
    getRequestDatetime,
    getRequestClientName,
    getUserName,
    getFineTime,
    servicesSum,
    toPaySum,
    paymentSum,
    productNetCost,
    countToPay() {
      let sum = 0;
      for (let request of this.requests) {
        if (request.checked) {
          sum += paymentSum(request);
        }
      }
      for (let fine of this.fines) {
        if (fine.checked) {
          sum += fine.sum;
        }
      }
      let prevSum = this.toPayCounted;
      this.toPayCounted = sum;
      if (!this.payment.sum || this.payment.sum === prevSum) {
        this.payment.sum = sum;
      }
    },
    getDayServicesSum(requests) {
      let sum = 0;
      for (let request of requests) {
        sum += servicesSum(request);
      }
      return sum;
    },
    getDayProductSum(requests) {
      let sum = 0;
      for (let request of requests) {
        sum += productSum(request);
      }
      return sum;
    },
    getDayTotalSum(requests) {
      return this.getDayProductSum(requests) + this.getDayServicesSum(requests);
    },
    getDayProductsNetCostSum(requests) {
      let sum = 0;
      for (let request of requests) {
        sum += productNetCost(request);
      }
      return sum;
    },
    getDayEarned(requests) {
      let sum = 0;
      for (let request of requests) {
        sum += request.earned;
      }
      return sum;
    },
    getTripSum(requests) {
      let sum = 0;
      for (let request of requests) {
        sum += request.tripSum + request.tripBackSum;
      }
      return sum;
    },
    getDayEquipmentSum(requests) {
      let sum = 0;
      for (let request of requests) {
        sum += +(request.equipmentSum ? parseInt(request.equipmentSum) : 0);
      }
      return sum;
    },
    getDayPledgeSum(date) {
      let sum = 0;
      console.log(this.pledges);
      for (let pledge of this.pledges) {
        if (pledge.date === date) {
          sum += +pledge.sum;
        }
      }
      return sum;
    },
    getToPaySum(daysRequest) {
      let sum = 0;
      for (let request of daysRequest.requests) {
        sum += paymentSum(request);
      }
      return sum;
    },
    toggleAllDaysRequests(daysRequest) {
      this.$nextTick(() => {
        for (let request of daysRequest.requests) {
          request.checked = daysRequest.checked;
        }
        this.countToPay();
      });
    },
    toggleDaysRequest(daysRequest, request) {
      this.$nextTick(() => {
        let allChecked = true;
        for (let anRequest of daysRequest.requests) {
          allChecked = allChecked && anRequest.checked;
        }
        daysRequest.checked = allChecked;
        this.countToPay();
      });
    },

    toggleDaysFine(daysFine, fine) {
      this.$nextTick(() => {
        let allChecked = true;
        for (let anFine of daysFine.fines) {
          allChecked = allChecked && anFine.checked;
        }
        daysFine.checked = allChecked;
        this.countToPay();
      });
    },

    processRequestsByDays() {
      if (!this.requests) {
        return [];
      }
      let days = [];
      let daysRequest = [];

      for (let request of this.requests) {
        // let date = getRequestDate(request);
        let date = request.shiftDate;
        if (!days.includes(date)) {
          days.push(date);
        }
      }

      days.sort((a, b) => {
        return new Date(a) - new Date(b);
      });

      for (let date of days) {
        let fines = 0;
        if (date in this.finesByDays) {
          fines = this.finesByDays[date];
        }
        let day = dayjs(date).format("DD.MM.YYYY");
        let items = {
          date: date,
          dateF: day,
          checked: false,
          requests: this.requests.filter((r) => getRequestDate(r) === day),
        };
        daysRequest.push(items);
      }

      this.requestsByDays = daysRequest;
    },
    processFinesByDays() {
      if (!this.fines) {
        return [];
      }
      let days = [];
      let daysFine = [];

      for (let fine of this.fines) {
        let date = getFineDate(fine);
        if (!days.includes(date)) {
          days.push(date);
        }
      }

      for (let day of days) {
        let fines = 0;
        if (day in this.finesByDays) {
          fines = this.finesByDays[day];
        }
        let items = {
          date: day,
          checked: true,
          fines: this.fines.filter((r) => getFineDate(r) === day),
        };
        daysFine.push(items);
      }

      this.finesByDays = daysFine;
    },

    update: function () {
      let updateUrl;
      updateUrl = "/pest/payments/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.payment)
        .then((response) => {
          this.payment = response.data;
          this.loading = false;
          this.$eventHub.$emit("pest-payment-list", this.payment);
          // this.$router.push({name: 'pest-payment-view', params: {id: this.payment.id}});
          this.$bvToast.toast(this.$t("common.data_saved"), {
            sum: this.$t("payments.payment_updated"),
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              sum: this.$t("common.error_during_saving"),
              type: "danger",
            });
          }
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "/pest/payments";
      this.loading = true;
      this.$http
        .post(updateUrl, this.payment)
        .then((response) => {
          this.payment = response.data;
          this.$bvToast.toast(this.$t("payments.payment_added"), {
            sum: this.$t("payments.payment_added"),
            variant: "success",
          });
          this.$router.push({
            name: "pest-settlements",
            params: { townId: this.payment.employee.townId },
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.error"), {
              sum: this.$t("payments.cant_create_payment"),
              variant: "danger",
            });
          }
        });
    },
    prepareRequestIds() {
      let ids = [];
      for (let request of this.requests) {
        if (request.checked) {
          ids.push(request.id);
        }
      }
      this.payment.requestIds = ids;
    },
    preparePledgesIds() {
      let ids = [];
      for (let day of this.requestsByDays) {
        if (day.checked) {
          for (let pledge of this.pledges) {
            if (pledge.date === day.date) {
              ids.push(pledge.id);
            }
          }
        }
      }
      this.payment.pledgesIds = ids;
    },
    prepareFineIds() {
      let ids = [];
      for (let fine of this.fines) {
        if (fine.checked) {
          ids.push(fine.id);
        }
      }
      this.payment.fineIds = ids;
    },
    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById("pest-payment-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast(this.$t("common.data_is_saving"), {
            sum: this.$t("common.please_wait"),
            variant: "info",
          });
          return;
        }
        this.prepareRequestIds();
        this.prepareFineIds();
        this.preparePledgesIds();
        if (this.id === "new") {
          this.create();
        } else {
          this.update();
        }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast(this.$t("common.form_has_errors"), {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },

    deletePayment() {
      if (confirm(this.$t("payments.delete_payment_question"))) {
        let deleteUrl = "/pest/payments/" + this.town.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            this.loading = false;
            this.$router.push({ name: "pest-town-list" });
            this.$bvToast.toast(this.$t("payments.payment_deleted"), {
              sum: this.$t("payments.payment_deleted"),
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast(this.$t("common.error"), {
                sum: this.$t("payments.cant_delete_payment"),
                variant: "danger",
              });
            }
          });
      }
    },
    getManagerName(user) {
      return getUserName(user);
    },
    getClientName(user) {
      return getClientName(user);
    },
    searchManager(input) {
      return new Promise((resolve) => {
        // this.error = null;
        let clientsUrl = "/users/admin/search";
        this.loading = true;
        this.$http
          .get(clientsUrl, {
            params: { term: input, type: TYPE_LOGIST },
          })
          .then((response) => {
            this.loading = false;
            resolve(response.data);
            // this.payment = response.data;
            // this.header = this.payment.email;
          })
          .catch((error) => {
            this.loading = false;
            this.processRequestError(error);
            resolve([]);
          });
      });
    },
    getEmployeeName(user) {
      return getUserName(user);
    },
    loadUnpaidRequest(assigneeId) {
      let clientsUrl = "/pest/request/unpaid";
      if (this.requestLoading) {
        return;
      }
      this.requestLoading = true;
      this.$http
        .get(clientsUrl, {
          params: { assigneeId: assigneeId },
        })
        .then((response) => {
          this.requestLoading = false;
          this.requests = response.data.requests;
          this.pledges = response.data.pledges;
          this.toPay = response.data.toPay;
          this.profit = response.data.profit;
          this.fines = response.data.fines;
          this.fineSum = response.data.finesTotal;
          this.payment.employee = response.data.assignee;
          for (let request of this.requests) {
            request.checked = false;
          }
          for (let fine of this.fines) {
            fine.checked = false;
          }

          this.processRequestsByDays();
          this.processFinesByDays();
        })
        .catch((error) => {
          this.requestLoading = false;
          this.processRequestError(error);
        });
    },

    selectManager(data) {
      if (data && data.value) {
        this.payment.employeeId = data.value.id;
        this.payment.employee = data.value;
        this.loadUnpaidRequest(data.value.id);
      }
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}

td,
th {
  vertical-align: middle !important;
}
</style>
