import { getPestTitle } from "@/modules/pest/decorators/pests";
import {
  CURRENCY_USD,
  getAccountCurrencySign,
  getAccountTitle,
} from "@/modules/direct/decorators/accounts";
import { townOptions } from "@/modules/pest/decorators/towns";
import dayjs from "dayjs";
import { getReportTitle } from "@/modules/direct/decorators/reports";
import { getCampaignTitle } from "@/modules/direct/decorators/campaigns";
import { formatNumber } from "@/modules/pest/decorators/common";
import t from "@/i18n";

const getRecordTitle = function (record) {
  if (!record || !record.title) {
    return t("common.not_set");
  }
  return record.title;
};

export { getRecordTitle };

export const getRecordPestName = function (record) {
  if (!record.pest) {
    return t("common.not_set");
  }
  return getPestTitle(record.pest);
};

export const getRecordReportTitle = function (record) {
  if (!record.report) {
    return t("common.not_set");
  }
  return getReportTitle(record.report);
};

export const getRecordDate = function (record) {
  if (!record.date) {
    return t("common.not_set");
  }
  return dayjs(record.date).format("DD.MM.YYYY");
};

export const getRecordRegionName = function (record) {
  if (!record.regionId || !townOptions[record.regionId]) {
    return t("common.not_set");
  }
  return townOptions[record.regionId].text;
};

export const getRecordAccountName = function (record) {
  if (!record.account) {
    return t("common.not_set");
  }
  return getAccountTitle(record.account);
};
export const getRecordCampaignName = function (record) {
  if (!record.campaign) {
    return t("common.not_set");
  }
  return getCampaignTitle(record.campaign);
};

export const getRecordFormattedSum = function (record) {
  let sum = formatNumber(record.sum);
  if (!record.account) {
    return sum;
  }
  let sign = getAccountCurrencySign(record.account);
  if (record.account.currency === CURRENCY_USD) {
    return sign + sum;
  } else {
    return sum + sign;
  }
};

export const STATUS_NEW = 0; // новый
export const STATUS_QUEUED = 2; // в очереди на запрос
export const STATUS_REQUESTED = 4; // отправлен запрос
export const STATUS_WAITING = 6; // ожидание офлайн отчета
export const STATUS_RECEIVED = 8; // отчет получен
export const STATUS_PROCESSING = 10; // отчет обрабатывается
export const STATUS_DONE = 12; // отчет обработан

export const statusTitles = {
  [STATUS_NEW]: "Новый",
  [STATUS_QUEUED]: "В очереди на запрос",
  [STATUS_REQUESTED]: "Отправлен запрос",
  [STATUS_WAITING]: "Ожидание офлайн отчета",
  [STATUS_RECEIVED]: "Отчет получен",
  [STATUS_PROCESSING]: "Отчет обрабатывается",
  [STATUS_DONE]: "Отчет обработан",
};

export const statusOptions = Object.entries(statusTitles).map(([k, v]) => {
  return { id: k, text: v };
});

export const getRecordStatusTitle = function (record) {
  if (!record.status) {
    return t("common.not_set");
  }
  return statusTitles[record.status];
};
