import { getTownTitle } from "@/modules/pest/decorators/towns";
import t from "@/i18n";

const getRegionTitle = function (region) {
  if (!region || !region.title) {
    return t("common.not_set");
  }
  return region.title;
};
const getRegionTownTitle = function (region) {
  if (!region || !region.town) {
    return t("common.not_set");
  }
  return getTownTitle(region.town);
};

export { getRegionTitle, getRegionTownTitle };
