<template>
  <b-overlay :show="loading" rounded="sm">
    <b-form
      id="login-form"
      ref="loginForm"
      :model="loginForm"
      label-position="top"
      class="form--login"
      :show-message="false"
      novalidate
      @submit="submit"
    >
      <b-form-group :label="$t('users.login')" prop="login">
        <b-form-input v-model="loginForm.login" required></b-form-input>
        <b-form-invalid-feedback>{{
          $t("users.enter_login")
        }}</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group :label="$t('users.password')" prop="password">
        <b-form-input
          v-model="loginForm.password"
          type="password"
          auto-complete="off"
          required
        ></b-form-input>
        <b-form-invalid-feedback>{{
          $t("users.enter_password")
        }}</b-form-invalid-feedback>
      </b-form-group>

      <div class="text-center">
        <b-button variant="primary" @click="submit">{{
          $t("users.log_in")
        }}</b-button>
      </div>
    </b-form>
  </b-overlay>
</template>

<script>
import { TYPE_FINE_MANAGER } from "@/modules/users/decorators/users.js";
import { isWorker } from "@/modules/users/access/common.js";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
      loginForm: {
        login: "",
        password: "",
      },
      isGuest: true,
    };
  },
  computed: {
    userName() {
      return this.$store.state.user.name;
    },
  },
  mounted() {
    this.checkHomePage();
  },
  methods: {
    ...mapActions({
      setToken: "auth/setToken",
      setUserInfo: "user/setUserInfo",
      logout: "user/logout",
    }),
    submit: function (e) {
      e.preventDefault();

      let form = document.getElementById("login-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (!valid) {
        this.$toast.open({
          message: this.$t("common.form_has_errors"),
          type: "danger",
        });
        return false;
      }
      this.loading = true;
      if (valid) {
        let loginUrl;
        loginUrl = "/users/security/login";
        this.$http
          .post(loginUrl, {
            "login-form": {
              login: this.loginForm.login,
              password: this.loginForm.password,
            },
          })
          .then((response) => {
            let user = response.data.user;
            this.loading = false;
            this.setToken(user.authKey);
            this.setUserInfo({
              id: user.id,
              userType: user.type,
              name: user.name,
              authKey: user.authKey,
              access: user.access,
              profile: user.profile,
              projectIds: user.projectIds,
              isGuest: false,
            });

            if (isWorker(this.$store.state.user)) {
              document.body.classList.add("fixed-nav");
            } else {
              document.body.classList.remove("fixed-nav");
            }

            this.$eventHub.$emit("start");
            this.$eventHub.$emit("authorized");
            this.checkHomePage();
          })
          .catch((error) => {
            this.loading = false;
            this.$toast.open({
              message: this.$t("users.incorrect_login_or_password"),
              type: "danger",
            });
          });
      } else {
        this.$toast.open({
          message: this.$t("users.incorrect_login_or_password"),
          type: "danger",
        });
        console.log("error submit!!");
        return false;
      }
    },
    checkHomePage() {
      let isGuest = this.$store.state.user.isGuest;
      if (isGuest) {
        return;
      }
      if (this.$store.state.user.profile.type === TYPE_FINE_MANAGER) {
        this.$router.push({ name: "pest-fine-list" });
        this.$eventHub.$emit("select-header-menu-item", "pest-fine-list");
      } else if (this.$store.state.user.access.canDispatch === true) {
        this.$router.push({ name: "request-dispatching-board" });
        this.$eventHub.$emit(
          "select-header-menu-item",
          "request-dispatching-board"
        );
      } else {
        this.$router.push({ name: "pest-employee-current-request-list" });
        this.$eventHub.$emit(
          "select-header-menu-item",
          "pest-employee-current-request-list"
        );
      }
    },
  },
};
</script>

<style lang="scss">
.form--login {
  border-radius: 2px;
  padding: 25px 20px;
}
</style>
