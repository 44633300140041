<template>
  <div v-click-outside="cancelSearch">
    <div class="input-group">
      <div
        v-for="(param, pIndex) in searchParams"
        :key="'p' + pIndex"
        class="input-group-prepend"
      >
        <button
          class="btn btn-outline-secondary"
          type="button"
          @click="removeParam(param, pIndex)"
        >
          {{ param.label }}
          <font-awesome-icon icon="times" />
        </button>
      </div>

      <input
        v-model="text"
        type="text"
        class="form-control search-field"
        :placeholder="$t('search.search')"
        :aria-label="$t('search.search_prompt')"
        aria-describedby="basic-addon1"
        @focus="openPopup"
        @keydown.enter.prevent="startSearch"
      />

      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary"
          type="button"
          @click="resetSearch"
        >
          <font-awesome-icon icon="times" />
        </button>
        <button v-if="loading" class="btn btn-outline-secondary" type="button">
          <font-awesome-icon icon="rotate" spin />
        </button>
        <button
          v-else
          class="btn btn-outline-secondary"
          type="button"
          @click="startSearch(true)"
        >
          <font-awesome-icon icon="search" />
        </button>
      </div>
    </div>

    <div class="additional-search p-3" :class="{ visible: showPopup }">
      <form @submit.prevent="startSearch">
        <div class="row">
          <div v-if="showDate" class="col-12">
            <b-form-group :label="$t('requests.work_date')" prop="date">
              <date-range-picker
                v-model="dateRange"
                @clicked="justSelect"
                @change="justSelect"
              ></date-range-picker>
            </b-form-group>
          </div>

          <div v-if="showDate" class="col-12">
            <b-form-group
              :label="$t('requests.request_create_date')"
              prop="date"
            >
              <date-range-picker
                v-model="createDateRange"
                @clicked="justSelect"
                @change="justSelect"
              ></date-range-picker>
            </b-form-group>
          </div>

          <div v-if="showOrderDate" class="col-12">
            <b-form-group :label="$t('requests.order_date')" prop="orderDate">
              <date-range-picker
                v-model="orderDateRange"
                @clicked="justSelect"
                @change="justSelect"
              ></date-range-picker>
            </b-form-group>
          </div>
          <div v-if="showProject" class="col-12">
            <b-form-group :label="$t('requests.project')" prop="projectIds">
              <multiply-autocomplete
                ref="projectAutocomplete"
                :get-data="fetchFilterProjects"
                :get-title="getProjectTitle"
                @selected="selectMultipleProjects"
              ></multiply-autocomplete>
            </b-form-group>
          </div>
          <div v-if="showSource" class="col-12">
            <b-form-group :label="$t('requests.lead_source')" prop="sourceIds">
              <multiply-autocomplete
                ref="sourceAutocomplete"
                :get-data="fetchFilterSources"
                :get-title="getSourceTitle"
                @selected="selectMultipleSources"
              ></multiply-autocomplete>
            </b-form-group>
          </div>
          <div v-if="showStatus" class="col-12">
            <b-form-group :label="$t('requests.status')" prop="statusIds">
              <multiply-autocomplete
                ref="statusAutocomplete"
                :get-data="getFilterStatuses"
                :get-title="getFilterStatusTitle"
                @selected="selectStatuses"
              ></multiply-autocomplete>
            </b-form-group>
          </div>
          <div v-if="showAddress" class="col-12">
            <b-form-group :label="$t('requests.address')">
              <input
                v-model="address"
                type="text"
                prop="address"
                class="form-control"
                :placeholder="$t('search.address_prompt')"
              />
            </b-form-group>
          </div>
          <div v-if="showPhone" class="col-6">
            <b-form-group :label="$t('requests.phone')">
              <input
                v-model="phoneNumber"
                type="text"
                prop="phoneNumber"
                class="form-control"
                :placeholder="$t('search.phone_prompt')"
                aria-label=""
              />
            </b-form-group>
          </div>
          <div v-if="showCardId" class="col-6">
            <b-form-group :label="$t('search.number')">
              <input
                v-model="cardId"
                type="text"
                prop="cardId"
                class="form-control"
                :placeholder="$t('search.number_prompt')"
                aria-label=""
              />
            </b-form-group>
          </div>
          <div v-if="showTown" class="col-12">
            <b-form-group :label="$t('search.city')" prop="townIds">
              <multiply-autocomplete
                ref="townAutocomplete"
                :get-data="fetchTownData"
                :get-title="getTownTitle"
                @selected="selectMultipleTowns"
              ></multiply-autocomplete>
            </b-form-group>
          </div>
          <div v-if="showPest" class="col-12">
            <b-form-group :label="$t('requests.pest')" prop="pestIds">
              <multiply-autocomplete
                ref="pestAutocomplete"
                :get-data="fetchPestData"
                :get-title="getPestTitle"
                @selected="selectMultiplePests"
              ></multiply-autocomplete>
            </b-form-group>
          </div>
          <div v-if="showFacility" class="col-12">
            <b-form-group :label="$t('requests.facility')" prop="facilityIds">
              <multiply-autocomplete
                ref="facilityAutocomplete"
                :get-data="fetchFacilityData"
                :get-title="getFacilityTitle"
                @selected="selectMultipleFacilities"
              ></multiply-autocomplete>
            </b-form-group>
          </div>
          <div v-if="showAssignee" class="col-12">
            <b-form-group :label="$t('requests.assignee')" prop="assigneeId">
              <multiply-autocomplete
                ref="assigneeAutocomplete"
                :get-data="fetchWorkerUserData"
                :get-title="getUserName"
                @selected="selectMultipleAssignees"
              ></multiply-autocomplete>
            </b-form-group>
          </div>
          <div v-if="showManager" class="col-12">
            <b-form-group :label="$t('requests.manager')" prop="managerId">
              <multiply-autocomplete
                ref="managerAutocomplete"
                :get-data="fetchManagerUserData"
                :get-title="getUserName"
                @selected="selectMultipleManagers"
              ></multiply-autocomplete>

              <user-autocomplete
                v-if="false"
                v-model="managerId"
                :type="(TYPE_MANAGER, TYPE_LOGIST)"
                :default-value="getUserName(manager)"
                @selected="selectManager"
              ></user-autocomplete>
            </b-form-group>
          </div>

          <div v-if="showFirstSecond" class="col-12">
            <b-form-group
              :label="$t('requests.first_follow_up')"
              prop="firstSecond"
            >
              <multiply-autocomplete
                ref="isFirstSecondAutocomplete"
                :get-data="getFirstSecondStatuses"
                :get-title="getFirstSecondStatusTitle"
                @selected="selectFirstSecondStatus"
              ></multiply-autocomplete>
            </b-form-group>
          </div>
          <div v-if="showClient" class="col-12">
            <b-form-group :label="$t('requests.client_type')" prop="clientType">
              <multiply-autocomplete
                ref="clientTypeAutocomplete"
                :get-data="getClientTypeTitles"
                :get-title="getClientTypeTitle"
                @selected="selectClientTypeTitle"
              ></multiply-autocomplete>
            </b-form-group>
          </div>
          <div v-if="showAftercall" class="col-12">
            <b-form-group
              :label="$t('requests.follow_up_call_result')"
              prop="aftercallStatus"
            >
              <multiply-autocomplete
                ref="aftercallStatusAutocomplete"
                :get-data="getAfterCallStatuses"
                :get-title="getAftercallStatusTitle"
                @selected="selectAftercallStatus"
              ></multiply-autocomplete>
            </b-form-group>
          </div>
          <div v-if="showAftercallFail" class="col-12">
            <b-form-group
              :label="$t('requests.follow_up_call_fail_reason')"
              prop="aftercallFail"
            >
              <multiply-autocomplete
                ref="aftercallFailAutocomplete"
                :get-data="getAfterCallFails"
                :get-title="getAftercallFailTitle"
                @selected="selectAftercallFail"
              ></multiply-autocomplete>
            </b-form-group>
          </div>
          <div v-if="showpaymentTypes" class="col-12">
            <b-form-group :label="$t('requests.payment_type')" prop="payments">
              <multiply-autocomplete
                ref="paymentsAutocomplete"
                :get-data="getPayments"
                :get-title="getPaymentTitle"
                @selected="selectMultiplePayments"
              ></multiply-autocomplete>
            </b-form-group>
          </div>
          <div v-if="showServiceSum" class="col-12">
            <div class="row">
              <div class="col-6">
                <b-form-group
                  :label="$t('search.request_sum_from')"
                  prop="serviceSumFrom"
                >
                  <b-form-input v-model="serviceSumFrom" />
                </b-form-group>
              </div>
              <div class="col-6">
                <b-form-group
                  :label="$t('search.request_sum_to')"
                  prop="serviceSumTo"
                >
                  <b-form-input v-model="serviceSumTo" />
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { getPestTitle } from "@/modules/pest/decorators/pests.js";
import { getFacilityTitle } from "@/modules/pest/decorators/facilities.js";
import {
  TYPE_MANAGER,
  TYPE_WORKER,
  TYPE_LOGIST,
} from "@/modules/users/decorators/users.js";
import UserAutocomplete from "@/modules/users/views/user/UserAutocomplete.vue";
import { getUserName } from "@/modules/crm/common.js";
import MultiplyAutocomplete from "@/components/shared/MultiplyAutocomplete.vue";
import { getTownTitle } from "@/modules/pest/decorators/towns.js";
import {
  CLIENT_TYPE_IND,
  CLIENT_TYPE_ORG,
  STAGE_LEAD,
  PT_CASH,
  PT_CASHLESS,
} from "@/modules/pest/decorators/requests.js";
import { mapActions, mapGetters } from "vuex";
import { aftercallStatusOptions } from "@/modules/pest/decorators/aftercall";
import DateRangePicker from "@/components/shared/DateRangePicker.vue";
import { getPaymentTitle } from "@/modules/pest/decorators/payments";
import { getSourceTitle } from "@/modules/pest/decorators/sources";
import { getProjectTitle } from "@/modules/pest/decorators/project";
export default {
  name: "CommonFilter",
  components: {
    DateRangePicker,
    MultiplyAutocomplete,
    UserAutocomplete,
  },
  props: {
    loading: {},
    allStatuses: {},
    dataKey: {
      type: String,
      default: "filter",
    },
    showDate: { default: true },
    showStatus: { default: true },
    showSource: { default: true },
    showProject: { default: true },
    showCardId: { default: true },
    showPhone: { default: true },
    showAddress: { default: true },
    showOrderDate: { default: true },
    showpaymentTypes: { default: true },
    showTown: { default: true },
    showPest: { default: true },
    showFacility: { default: false },
    showAssignee: { default: true },
    showManager: { default: true },
    showFirstSecond: { default: true },
    showClient: { default: true },
    showAftercall: { default: true },
    showAftercallFail: { default: true },
    showServiceSum: { default: true },
  },
  data() {
    return {
      TYPE_WORKER,
      TYPE_MANAGER,
      TYPE_LOGIST,

      paymentList: [
        { title: this.$t("requests.payment_cashless"), id: PT_CASHLESS },
        { title: this.$t("requests.payment_cash"), id: PT_CASH },
      ],
      showPopup: false,
      text: "",
      phoneNumber: "",
      address: "",
      cardId: "",
      paymentTypes: [],
      assignee: null,
      assigneeId: null,
      manager: null,
      managerId: null,
      date: "",
      dateFrom: "",
      dateTo: "",
      createDateFrom: "",
      createDateTo: "",
      orderDateFrom: "",
      orderDateTo: "",
      assignees: null,
      assigneeIds: null,
      assigneeLabel: null,
      managerLabel: null,
      managers: null,
      managerIds: null,
      serviceSumFrom: "",
      serviceSumTo: "",

      isFirstSecond: [],
      isFirstSecondLabel: "",
      clientType: [],
      clientTypeLabel: "",
      statuses: "",
      statusesLabel: "",
      townIds: [],
      townLabel: "",
      paymentsLabel: "",
      facilityIds: "",
      facilityLabel: "",
      aftercallStatusIds: [],
      aftercallStatusLabel: "",
      aftercallFailIds: [],
      aftercallFailLabel: "",
      pestIds: [],
      pestLabel: "",
      sourceIds: [],
      projectIds: [],
      sourceLabel: "",
      dateFormat: "DD.MM.YYYY",
      justOpened: false,
      justSelected: false,

      savedFields: [
        "text",
        "cardId",
        "phoneNumber",
        "address",
        "paymentTypes",
        "assignee",
        "assigneeId",
        "manager",
        "managerId",
        "date",
        "orderDate",
        "assignees",
        "assigneeIds",
        "assigneeLabel",
        "managerLabel",
        "managers",
        "managerIds",
        "isFirstSecond",
        "isFirstSecondLabel",
        "clientType",
        "clientTypeLabel",
        "statuses",
        "statusesLabel",
        "townIds",
        "townLabel",
        "paymentsLabel",
        "facilityIds",
        "sourceIds",
        "projectIds",
        "sourceLabel",
        "projectLabel",
        "facilityLabel",
        "pestIds",
        "pestLabel",
        "aftercallStatusIds",
        "aftercallStatusLabel",
        "aftercallFailIds",
        "aftercallFailLabel",
      ],
    };
  },
  computed: {
    projectId() {
      return this.$route.params.project !== undefined
        ? this.$route.params.project
        : "";
    },
    dateRange: {
      get() {
        return [this.dateFrom, this.dateTo];
      },
      set(val) {
        this.dateFrom = val[0];
        this.dateTo = val[1];
      },
    },
    createDateRange: {
      get() {
        return [this.createDateFrom, this.createDateTo];
      },
      set(val) {
        this.createDateFrom = val[0];
        this.createDateTo = val[1];
      },
    },
    orderDateRange: {
      get() {
        return [this.orderDateFrom, this.orderDateTo];
      },
      set(val) {
        this.orderDateFrom = val[0];
        this.orderDateTo = val[1];
      },
    },
    searchParams() {
      let list = [];
      let $this = this;
      if (this.assigneeId) {
        list.push({
          param1: "assigneeId",
          param2: "assignee",
          label: this.getUserName(this.assignee),
        });
      }
      if (this.cardId) {
        list.push({
          label: this.$t("requests.cardId") + ": " + this.cardId,
          clearCallback() {
            $this.cardId = "";
          },
        });
      }
      if (this.phoneNumber) {
        list.push({
          label: this.$t("requests.phone") + ": " + this.phoneNumber,
          clearCallback() {
            $this.phoneNumber = "";
          },
        });
      }
      if (this.address) {
        list.push({
          label: this.$t("requests.address") + ": " + this.address,
          clearCallback() {
            $this.address = "";
          },
        });
      }
      if (this.managerId) {
        list.push({
          param1: "managerId",
          param2: "manager",
          label: this.getUserName(this.manager),
        });
      }
      if (this.dateFrom && this.dateTo) {
        list.push({
          label:
            this.$t("requests.work_date") +
            ": " +
            this.dateFrom +
            " – " +
            this.dateTo,
          clearCallback() {
            $this.dateFrom = "";
            $this.dateTo = "";
          },
        });
      }
      if (this.createDateFrom && this.createDateTo) {
        list.push({
          label:
            this.$t("requests.created") +
            ": " +
            ": " +
            this.createDateFrom +
            " – " +
            this.createDateTo,
          clearCallback() {
            $this.createDateFrom = "";
            $this.createDateTo = "";
          },
        });
      }
      if (this.orderDateFrom && this.orderDateTo) {
        list.push({
          label:
            this.$t("requests.order_date") +
            ": " +
            ": " +
            this.orderDateFrom +
            " - " +
            this.orderDateTo,
          clearCallback() {
            $this.orderDateFrom = "";
            $this.orderDateTo = "";
          },
        });
      }
      if (this.townIds && this.townIds.length) {
        list.push({
          label: this.townLabel,
          clearCallback() {
            $this.$refs["townAutocomplete"].clear();
            $this.townIds = [];
            $this.townLabel = "";
          },
        });
      }
      if (this.paymentTypes && this.paymentTypes.length) {
        list.push({
          label: this.paymentsLabel,
          clearCallback() {
            $this.$refs["paymentsAutocomplete"].clear();
            $this.paymentTypes = [];
            $this.paymentsLabel = "";
          },
        });
      }
      if (this.pestIds && this.pestIds.length) {
        list.push({
          label: this.pestLabel,
          clearCallback() {
            $this.$refs["pestAutocomplete"].clear();
            $this.pestIds = [];
            $this.pestLabel = "";
          },
        });
      }
      if (this.sourceIds && this.sourceIds.length) {
        list.push({
          label: this.sourceLabel,
          clearCallback() {
            $this.$refs["sourceAutocomplete"].clear();
            $this.sourceIds = [];
            $this.sourceLabel = "";
          },
        });
      }
      if (this.projectIds && this.projectIds.length) {
        list.push({
          label: this.projectLabel,
          clearCallback() {
            $this.$refs["projectAutocomplete"].clear();
            $this.projectIds = [];
            $this.projectLabel = "";
          },
        });
      }
      if (this.facilityIds && this.facilityIds.length) {
        list.push({
          label: this.facilityLabel,
          clearCallback() {
            $this.$refs["facilityAutocomplete"].clear();
            $this.facilityIds = [];
            $this.facilityLabel = "";
          },
        });
      }
      if (this.statuses && this.statuses.length) {
        list.push({
          label: this.statusesLabel,
          clearCallback() {
            $this.$refs["statusAutocomplete"].clear();
            $this.statuses = [];
            $this.statusesLabel = "";
          },
        });
      }
      if (this.isFirstSecond && this.isFirstSecond.length) {
        list.push({
          label: this.isFirstSecondLabel,
          clearCallback() {
            $this.$refs["isFirstSecondAutocomplete"].clear();
            $this.isFirstSecond = [];
            $this.isFirstSecondLabel = "";
          },
        });
      }
      if (this.clientType && this.clientType.length) {
        list.push({
          label: this.clientTypeLabel,
          clearCallback() {
            $this.$refs["clientTypeAutocomplete"].clear();
            $this.clientType = [];
            $this.clientTypeLabel = "";
          },
        });
      }
      if (this.assigneeIds && this.assigneeIds.length) {
        list.push({
          label: this.assigneeLabel,
          clearCallback() {
            $this.$refs["assigneeAutocomplete"].clear();
            $this.assigneeIds = [];
            $this.assigneeLabel = "";
          },
        });
      }
      if (this.managerIds && this.managerIds.length) {
        list.push({
          label: this.managerLabel,
          clearCallback() {
            $this.$refs["managerAutocomplete"].clear();
            $this.managerIds = [];
            $this.managerLabel = "";
          },
        });
      }
      if (this.aftercallStatusIds && this.aftercallStatusIds.length) {
        list.push({
          label: this.aftercallStatusLabel,
          clearCallback() {
            $this.$refs["aftercallStatusAutocomplete"].clear();
            $this.aftercallStatusIds = [];
            $this.aftercallStatusLabel = "";
          },
        });
      }
      if (this.aftercallFailIds && this.aftercallFailIds.length) {
        list.push({
          label: this.aftercallFailLabel,
          clearCallback() {
            $this.$refs["aftercallFailAutocomplete"].clear();
            $this.aftercallFailIds = [];
            $this.aftercallFailLabel = "";
          },
        });
      }
      if (this.serviceSumFrom || this.serviceSumTo) {
        let serviceSumLabel = (
          this.$t("search.request_sum") +
          ": " +
          this.serviceSumFrom
        ).trim();
        if (this.serviceSumTo) {
          serviceSumLabel +=
            " " + this.$t("common.to") + " " + this.serviceSumTo;
        }
        list.push({
          label: serviceSumLabel,
          clearCallback() {
            $this.serviceSumFrom = "";
            $this.serviceSumTo = "";
          },
        });
      }
      return list;
    },
  },
  watch: {},
  mounted: function () {
    window.addEventListener("keyup", this.onEnterSearch);
    this.restoreFilterData();
  },
  destroyed: function () {
    window.removeEventListener("keyup", this.onEnterSearch);
  },
  methods: {
    ...mapActions({
      setProp: "data/setProp",
    }),
    ...mapGetters({
      getProp: "data/getProp",
    }),
    onEnterSearch: function (event) {
      if (event.code === "Enter" && this.showPopup) {
        this.startSearch();
      }
    },
    getTownTitle,
    getPestTitle,
    getFacilityTitle,
    getSourceTitle,
    getProjectTitle,
    getPaymentTitle,
    getUserName,
    selectFacility(data) {
      if (data && data.value) {
        this.facility = data.value;
        this.facilityId = data.value.id;
      } else {
        this.facility = {};
        this.facilityId = null;
      }
      this.justSelect();
    },
    selectAssignee(data) {
      if (data && data.value) {
        this.assignee = data.value;
        this.assigneeId = data.value.id;
      } else {
        this.assignee = {};
        this.assigneeId = null;
      }
      this.justSelect();
    },
    selectManager(data) {
      if (data && data.value) {
        this.manager = data.value;
        this.managerId = data.value.id;
      } else {
        this.manager = {};
        this.managerId = null;
      }
      this.justSelect();
    },
    clearAutoCompletes() {
      this.$refs["townAutocomplete"].clear();
      this.$refs["paymentsAutocomplete"].clear();
      this.$refs["pestAutocomplete"].clear();
      this.$refs["statusAutocomplete"].clear();
      this.$refs["isFirstSecondAutocomplete"].clear();
      this.$refs["clientTypeAutocomplete"].clear();
      this.$refs["assigneeAutocomplete"].clear();
      this.$refs["managerAutocomplete"].clear();
      this.$refs["aftercallStatusAutocomplete"].clear();
      this.$refs["aftercallFailAutocomplete"].clear();
      this.$refs["sourceAutocomplete"].clear();
    },
    resetSearch() {
      this.text = "";
      this.phoneNumber = "";
      this.paymentTypes = [];
      this.cardId = "";
      this.address = "";
      this.dateFrom = "";
      this.dateTo = "";
      this.createDateFrom = "";
      this.createDateTo = "";
      this.orderDateFrom = "";
      this.orderDateTo = "";
      this.pest = {};
      this.pestIds = null;
      this.pestLabel = "";
      this.facility = {};
      this.facilityId = null;
      this.assignee = {};
      this.assigneeId = null;
      this.assigneeIds = null;
      this.assigneeLabel = "";
      this.managerIds = null;
      this.managerLabel = "";
      this.aftercallStatusIds = null;
      this.aftercallStatusLabel = "";
      this.aftercallFailIds = null;
      this.aftercallFailLabel = "";
      this.isFirstSecond = [];
      this.clientType = [];
      this.townIds = null;
      this.townLabel = "";
      this.sourceIds = [];
      this.projectIds = [];
      this.sourceLabel = "";
      this.projectLabel = "";
      this.statuses = [];
      this.dealStatusesIds = [];
      this.leadStatusesIds = [];
      this.dealStatusesIds = [];
      this.serviceSumFrom = "";
      this.serviceSumTo = "";
      this.clearAutoCompletes();
      this.closePopup();
      this.startSearch(true);
    },
    cancelSearch() {
      this.closePopup();
    },
    startSearch(intentionally) {
      this.closePopup();
      this.saveFilterData();
      this.$emit("search", {
        text: this.text,
        phoneNumber: this.phoneNumber,
        cardId: this.cardId,
        address: this.address,
        paymentTypes: this.paymentTypes,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        createDateFrom: this.createDateFrom,
        createDateTo: this.createDateTo,
        orderDateFrom: this.orderDateFrom,
        orderDateTo: this.orderDateTo,
        pestId: this.pestIds,
        facilityId: this.facilityId,
        sourceId: this.sourceIds,
        projectId: this.projectIds,
        assigneeId: this.assigneeIds,
        managerId: this.managerIds,
        isFirstTime: this.isFirstSecond,
        clientType: this.clientType,
        townId: this.townIds,
        leadStatusesIds: this.leadStatusesIds,
        dealStatusesIds: this.dealStatusesIds,
        aftercallStatusId: this.aftercallStatusIds,
        aftercallFailId: this.aftercallFailIds,
        serviceSumFrom: this.serviceSumFrom,
        serviceSumTo: this.serviceSumTo,
        date: this.date,
        intentionally: !!intentionally,
      });
    },
    openPopup() {
      this.showPopup = true;
      this.justOpened = true;
      setTimeout(() => (this.justOpened = false), 300);
    },
    closePopup() {
      if (!this.justOpened && !this.justSelected) {
        this.showPopup = false;
      }
    },
    removeParam(param, index) {
      if (param.param1) {
        this[param.param1] = null;
      }
      if (param.param2) {
        this[param.param2] = {};
      }
      if (param.clearCallback) {
        param.clearCallback();
      }
      this.startSearch();
    },
    selectMultiplePayments(info) {
      this.paymentTypes = [];
      this.paymentsSelected = info;
      this.paymentsLabel = "";
      if (info) {
        let titles = [];
        for (let payment of info) {
          this.paymentTypes.push(payment.id);
          titles.push(payment.title);
        }
        this.paymentsLabel =
          this.$t("requests.payment_type") + ": " + titles.join(", ");
      }
      this.justSelect();
    },
    selectMultipleTowns(info) {
      this.townIds = [];
      this.townSelected = info;
      this.townLabel = "";
      if (info) {
        let titles = [];
        for (let town of info) {
          this.townIds.push(town.id);
          titles.push(town.title);
        }
        this.townLabel = this.$t("requests.town") + ": " + titles.join(", ");
      }
      this.justSelect();
    },
    getPayments(search, resolve) {
      resolve(this.paymentList);
    },
    fetchTownData(search, resolve) {
      let townUrl = "/pest/towns";
      let params = {
        search: search,
      };
      this.$http
        .get(townUrl, {
          params: params,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve([]);
        });
    },
    selectMultipleSources(info) {
      this.sourceIds = [];
      this.sourceSelected = info;
      this.sourceLabel = "";
      if (info) {
        let titles = [];
        for (let source of info) {
          this.sourceIds.push(source.id);
          titles.push(source.title);
        }
        this.sourceLabel =
          this.$t("requests.request_source") + ": " + titles.join(", ");
      }
      this.justSelect();
    },
    selectMultipleProjects(info) {
      this.projectIds = [];
      this.projectSelected = info;
      this.projectLabel = "";
      if (info) {
        let titles = [];
        for (let project of info) {
          this.projectIds.push(project.id);
          titles.push(project.title);
        }
        this.projectLabel =
          this.$t("requests.project") + ": " + titles.join(", ");
      }
      this.justSelect();
    },
    selectMultiplePests(info) {
      this.pestIds = [];
      this.pestSelected = info;
      this.pestLabel = "";
      if (info) {
        let titles = [];
        for (let pest of info) {
          this.pestIds.push(pest.id);
          titles.push(pest.title);
        }
        this.pestLabel = this.$t("requests.pest") + ": " + titles.join(", ");
      }
      this.justSelect();
    },
    justSelect() {
      this.justSelected = true;
      setTimeout(() => (this.justSelected = false), 300);
    },
    fetchPestData(search, resolve) {
      let pestsUrl = "/pest/pests";
      let params = {
        search: search,
        all: 1,
      };
      this.$http
        .get(pestsUrl, {
          params: params,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve([]);
        });
    },

    selectMultipleFacilities(info) {
      this.facilityIds = [];
      this.facilitySelected = info;
      this.facilityLabel = "";
      if (info) {
        let titles = [];
        for (let facility of info) {
          this.facilityIds.push(facility.id);
          titles.push(facility.title);
        }
        this.facilityLabel =
          this.$t("requests.facility") + ": " + titles.join(", ");
      }
      this.justSelect();
    },
    fetchFilterSources(search, resolve) {
      let sourcesUrl = "pest/sources/search";
      let params = { term: search, all: true, projectId: this.projectIds };
      this.$http
        .get(sourcesUrl, {
          params: params,
        })
        .then((response) => {
          response.data.unshift({
            title: this.$t("sources.without_source"),
            id: 0,
          });
          resolve(response.data);
        })
        .catch((error) => {
          resolve([]);
        });
    },
    fetchFilterProjects(search, resolve) {
      let sourcesUrl = "pest/project/search";
      let params = { search: search };
      this.$http
        .get(sourcesUrl, {
          params: params,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve([]);
        });
    },
    fetchFacilityData(search, resolve) {
      let facilitiesUrl = "pest/facilities";
      let params = {
        search: search,
      };
      this.$http
        .get(facilitiesUrl, {
          params: params,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve([]);
        });
    },

    getFilterStatuses(search, resolve) {
      let statuses = [];
      for (let status of this.allStatuses) {
        statuses.push({
          title: status.status.title,
          id: status.status.id,
          stage: status.stage,
          dealStatus: status.status.dealStatus ? status.status.dealStatus : 0,
        });
      }
      resolve(statuses);
    },
    getFilterStatusTitle(info) {
      return info.title;
    },
    selectStatuses(info) {
      this.statuses = info;
      this.leadStatusesIds = [];
      this.dealStatusesIds = [];
      this.statusesLabel = "";
      if (info) {
        let titles = [];
        for (let status of info) {
          if (status.stage === STAGE_LEAD) {
            this.leadStatusesIds.push(status.id);
          } else {
            this.dealStatusesIds.push(status.dealStatus);
          }
          titles.push(status.title);
        }
        this.statusesLabel =
          this.$t("requests.status") + " : " + titles.join(", ");
      }
      this.justSelect();
    },

    getFirstSecondStatuses(search, resolve) {
      resolve([
        { title: this.$t("requests.first_request_sh"), id: 1 },
        { title: this.$t("requests.follow_up_request_sh"), id: 0 },
      ]);
    },
    getFirstSecondStatusTitle(info) {
      return info.title;
    },
    selectFirstSecondStatus(info) {
      this.isFirstSecond = [];
      this.isFirstSecondLabel = "";
      if (info) {
        let titles = [];
        for (let status of info) {
          this.isFirstSecond.push(status.id);
          titles.push(status.title);
        }
        this.isFirstSecondLabel =
          this.$t("requests.status") + ": " + titles.join(", ");
      }
      this.justSelect();
    },
    getClientTypeTitles(search, resolve) {
      resolve([
        { title: this.$t("requests.type_individual"), id: CLIENT_TYPE_IND },
        { title: this.$t("requests.type_organization"), id: CLIENT_TYPE_ORG },
      ]);
    },
    getClientTypeTitle(info) {
      return info.title;
    },
    selectClientTypeTitle(info) {
      this.clientType = [];
      this.clientTypeLabel = "";
      if (info) {
        let titles = [];
        for (let status of info) {
          this.clientType.push(status.id);
          titles.push(status.title);
        }
        this.clientTypeLabel =
          this.$t("requests.client_type") + ": " + titles.join(", ");
      }
      this.justSelect();
    },
    selectAftercallFail(info) {
      this.aftercallFailIds = [];
      this.aftercallFailLabel = "";
      if (info) {
        let titles = [];
        for (let status of info) {
          this.aftercallFailIds.push(status.id);
          titles.push(status.title);
        }
        this.aftercallFailLabel = this.$t(
          "requests.follow_up_call_fail_reason"
        );
        ": " + titles.join(", ");
      }
      this.justSelect();
    },
    getAfterCallFails(search, resolve) {
      let aftercallFailsUrl = "/pest/aftercall-fails";
      let params = {
        all: 1,
      };
      this.$http
        .get(aftercallFailsUrl, {
          params: params,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve([]);
        });
    },
    getAftercallFailTitle(info) {
      return info.title;
    },
    selectAftercallStatus(info) {
      this.aftercallStatusIds = [];
      this.aftercallStatusLabel = "";
      if (info) {
        let titles = [];
        for (let status of info) {
          this.aftercallStatusIds.push(status.id);
          titles.push(status.title);
        }
        this.aftercallStatusLabel =
          this.$t("requests.follow_up_call_result") + ": " + titles.join(", ");
      }
      this.justSelect();
    },
    getAfterCallStatuses(search, resolve) {
      let statuses = [];
      for (let call of aftercallStatusOptions) {
        statuses.push({ id: call.value, title: call.text });
      }
      resolve(statuses);
    },
    getAftercallStatusTitle(info) {
      return info.title;
    },
    fetchUserData(type, search, resolve) {
      let usersUrl = "/users/admin/";
      let params = {
        search: search,
        type: type,
      };
      this.$http
        .get(usersUrl, {
          params: params,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve([]);
        });
    },
    fetchWorkerUserData(search, resolve) {
      this.fetchUserData(TYPE_WORKER, search, resolve);
    },
    fetchManagerUserData(search, resolve) {
      this.fetchUserData([TYPE_MANAGER, TYPE_LOGIST], search, resolve);
    },
    selectMultipleAssignees(info) {
      this.assigneeIds = [];
      this.assigneeSelected = info;
      this.assigneeLabel = "";
      if (info) {
        let titles = [];
        for (let user of info) {
          this.assigneeIds.push(user.id);
          titles.push(getUserName(user));
        }
        this.assigneeLabel =
          this.$t("requests.assignee") + " : " + titles.join(", ");
      }
      this.justSelect();
    },
    selectMultipleManagers(info) {
      this.managerIds = [];
      this.managerSelected = info;
      this.managerLabel = "";
      if (info) {
        let titles = [];
        for (let user of info) {
          this.managerIds.push(user.id);
          titles.push(getUserName(user));
        }
        this.managerLabel =
          this.$t("requests.manager") + ": " + titles.join(", ");
      }
      this.justSelect();
    },

    restoreFilterData() {
      let info = this.getProp()("common-filter-" + this.dataKey);
      if (info) {
        for (let field of this.savedFields) {
          if (field in info) {
            this[field] = info[field];
          }
        }
      }
      this.startSearch(true);
    },
    saveFilterData() {
      let data = {};
      for (let field of this.savedFields) {
        data[field] = this[field];
      }
      this.setProp({
        key: "common-filter-" + this.dataKey,
        value: data,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search-field {
  //height: 34px;
  width: 400px;
}

.input-group .search-field {
  height: 34px;
}

.input-group-text {
  padding: 0 0.75rem !important;
}

.additional-search {
  display: none;
  border: 1px solid rgba(0, 0, 0, 0.25);
  height: auto;
  width: 600px;
  margin-top: 15px;
  //background: #f3f3f3;
  background: white;
  position: absolute;
  z-index: 1000;
  max-height: 600px;
  overflow-y: auto;
}

.additional-search.visible {
  display: block;
  left: 30%;
}
</style>
