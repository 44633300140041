<template>
  <div>
    <input
      id="file-input"
      ref="file"
      class="d-none"
      type="file"
      @input="inputChanged"
    />
    <button
      v-if="isAdmin"
      class="btn btn-primary"
      :disabled="loading"
      @click="openFileInput"
    >
      {{ $t("shopResources.load_from_csv") }}
    </button>

    <font-awesome-icon v-if="loading" icon="rotate" class="ml-2" spin />
  </div>
</template>

<script>
import DropZone from "@/components/shared/DropZone.vue";
import { mapGetters } from "vuex";

export default {
  name: "ShopResourceImport",
  components: {},
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters({
      isAdmin: "auth/isAdmin",
    }),
    id() {
      return "new";
    },
  },
  watch: {
    $route(to, from) {},
  },
  created: function () {},

  methods: {
    openFileInput() {
      this.$refs.file.click();
    },
    inputChanged(e) {
      this.submitFiles(e.target.files);
    },
    submitFiles(files) {
      if (!files) {
        return;
      }
      if (this.loading) return;
      this.loading = true;
      for (let file of files) {
        let formData = new FormData();
        formData.append("file", file);
        let postUrl = "/pest/shop-resource/import";
        this.$http
          .post(postUrl, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.loading = false;
            this.$emit("updated", {});
          })
          .catch((error) => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
