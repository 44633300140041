export const ru = {
  comments: {
    add_comment: "Написать",
    cant_create_comment: "Не удалось добавить комментарий",
    comment_created: "Комментарий добавлен",
    no_comments_yet: "Еще нет комментариев",
  },
};
export const en = {
  comments: {
    add_comment: "Add comment",
    cant_create_comment: "Cant create comment",
    comment_created: "Comment created",
    no_comments_yet: "No comments yet",
  },
};
