<template>
  <div>
    <div class="ibox">
      <div class="ibox-title d-flex align-items-center pr-2">
        <h3>{{ $t("cpo.cpo_report_by_creation_date") }}</h3>

        <div class="ml-2">
          <date-range-picker
            v-model="dateRange"
            @change="fetchData"
          ></date-range-picker>
        </div>

        <common-filter
          class="ml-2"
          data-key="cpo-report"
          :show-date="false"
          :show-status="false"
          :show-town="true"
          :show-pest="true"
          :show-facility="false"
          :show-assignee="false"
          :show-manager="false"
          :show-first-second="false"
          :show-client="false"
          :show-aftercall="false"
          :show-aftercall-fail="false"
          @search="startSearch"
        />
        <div class="ml-4">
          <button class="btn btn-primary" @click.prevent="showEmptyRows">
            <span v-if="showEmpty">{{ $t("cpo.hide_source_with_zeros") }}</span>
            <span v-else>{{ $t("cpo.show_source_with_zeros") }}</span>
            <font-awesome-icon icon="eye" />
          </button>
        </div>

        <div v-if="showChart" class="ml-auto">
          <button class="btn btn-outline-primary" @click.prevent="hideChart">
            {{ $t("cpo.hide") }}
            <font-awesome-icon icon="chart-area" />
          </button>
        </div>
      </div>

      <div class="ibox-content">
        <b-overlay :show="loadingData" rounded="sm">
          <line-chart
            ref="chart"
            :source-data="data"
            :has-data="hasData"
            :date-to="dateTo"
            :date-from="dateFrom"
            :selected-towns="selectedTowns"
          ></line-chart>
          <div :class="{ 'd-none': hasData }">
            {{ $t("cpo.report_is_not_ready_yet") }}
          </div>
          <div class="table-responsive" :class="{ 'd-none': !hasData }">
            <c-p-o-data-table
              ref="reportTable"
              :selected-pests="selectedPests"
              :has-data="hasData"
              :source-data="data"
              :pests="true"
              :show-empty="showEmpty"
            />
          </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import CPODataTable from "./CPODataTable.vue";
import dayjs from "dayjs";
import CommonFilter from "@/components/shared/CommonFilter.vue";
import DateRangePicker from "@/components/shared/DateRangePicker";
import { TYPE_ADMIN } from "@/modules/users/decorators/users";
import LineChart from "@/modules/pest/views/reports/cpo/LineChart.vue";

export default {
  name: "ReportCPO",
  components: {
    LineChart,
    DateRangePicker,
    CommonFilter,
    CPODataTable,
  },
  data() {
    return {
      dateFormat: "DD.MM.YYYY",
      selectedTowns: [],
      selectedPests: [],
      dateFrom: null,
      dateTo: null,
      data: [],
      hasData: false,
      allSelected: true,

      pestFilterTitle: "",
      loadingTowns: false,
      loadingPests: false,
      loadingData: false,
      showEmpty: false,
      showChart: false,
    };
  },
  computed: {
    dateRange: {
      get() {
        return [this.dateFrom, this.dateTo];
      },
      set(val) {
        this.dateFrom = val[0];
        this.dateTo = val[1];
      },
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  mounted: function () {
    let filterDates = this.getProp()("cpo-filter-dates");
    if (filterDates) {
      this.dateTo = filterDates.dateTo;
      this.dateFrom = filterDates.dateFrom;
    }
  },
  beforeDestroy() {
    this.$eventHub.$off("toggle-chart");
  },
  created: function () {
    this.$eventHub.$on("toggle-chart", this.toggleChart);

    let filterDates = this.getProp()("cpo-filter-dates");
    if (filterDates) {
      this.dateTo = filterDates.dateTo;
      this.dateFrom = filterDates.dateFrom;
    }

    if (!this.dateTo) {
      this.dateTo = dayjs().format("YYYY-MM-DD");
    }
    if (!this.dateFrom) {
      this.dateFrom = dayjs().subtract(1, "month").format("YYYY-MM-DD");
    }

    // this.fetchData();
  },
  methods: {
    ...mapActions({
      setProp: "data/setProp",
    }),
    ...mapGetters({
      getProp: "data/getProp",
    }),
    hideChart() {
      console.log("hide");
      this.$eventHub.$emit("toggle-chart", { off: true });
      this.showChart = false;
      console.log("hidden");
    },
    toggleChart({ off }) {
      this.showChart = !off;
    },
    checkUrl: function () {
      if (this.$store.state.user.type !== TYPE_ADMIN) {
        this.$router.push({ name: "/" });
      }
      window.scrollTo(0, 0);
    },
    fetchData: function () {
      this.loadingData = true;

      let reportUrl = "/pest/report/cpo";
      let params = {
        dateTo: this.dateTo,
        dateFrom: this.dateFrom,
        townIds: this.selectedTowns,
      };
      this.saveFilterData();
      this.refreshChart();
      this.$http
        .post(reportUrl, params)
        .then((response) => {
          this.loadingData = false;
          this.data = response.data;
          console.log(this.data);
          this.hasData = true;
          this.$refs.reportTable.createGradient();
          this.$refs.reportTable.$forceUpdate();
        })
        .catch((error) => {
          this.loadingData = false;
          this.processRequestError(error);
        });
    },
    startSearch({ intentionally, townId, pestId }) {
      let changed = this.selectedTowns !== townId;
      if (
        this.selectedTowns &&
        townId &&
        this.selectedTowns.length === townId.length
      ) {
        changed = false;
      }
      this.selectedTowns = townId;
      this.selectedPests = pestId;

      if (changed || intentionally) {
        this.fetchData();
      } else {
        this.$refs.reportTable.$forceUpdate();
      }
    },
    refreshChart() {
      this.$nextTick(() => {
        this.$refs.chart.$forceUpdate();
        this.$refs.chart.fetchData();
      });
    },
    saveFilterData() {
      this.setProp({
        key: "cpo-filter-dates",
        value: {
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
        },
      });
    },
    showEmptyRows() {
      this.showEmpty = !this.showEmpty;
    },
  },
};
</script>

<style lang="scss" scoped>
.hasValue td,
.hasValue input {
  background: lightblue;
}

.checked td,
.checked input {
  //background: lightgreen;
}

.hasValue td,
.hasValue input {
  background: lightblue;
}

.filterRow th {
  border-top: 2px black solid;
  border-bottom: 2px black solid;
}

.checkboxCell {
  color: #000000;
  cursor: pointer;
}

input:focus::placeholder {
  color: transparent;
}

.pest-title {
  cursor: pointer;
}

.townBtn {
  width: 210px;
}

.xmx-datepicker-range {
  width: 250px;
}
</style>
