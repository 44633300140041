import { getPestTitle } from "@/modules/pest/decorators/pests";
import {
  CURRENCY_USD,
  getAccountCurrencySign,
  getAccountTitle,
} from "@/modules/direct/decorators/accounts";
import { townOptions } from "@/modules/pest/decorators/towns";
import dayjs from "dayjs";
import { getReportTitle } from "@/modules/direct/decorators/reports";
import { getCampaignTitle } from "@/modules/direct/decorators/campaigns";
import { formatNumber } from "@/modules/pest/decorators/common";
import t from "@/i18n";

const getResultTitle = function (result) {
  if (!result || !result.title) {
    return t("common.not_set");
  }
  return result.title;
};

export { getResultTitle };

export const getResultPestName = function (result) {
  if (!result.pest) {
    return t("common.not_set");
  }
  return getPestTitle(result.pest);
};

export const getResultReportTitle = function (result) {
  if (!result.report) {
    return t("common.not_set");
  }
  return getReportTitle(result.report);
};

export const getResultDate = function (result) {
  if (!result.date) {
    return t("common.not_set");
  }
  return dayjs(result.date).format("DD.MM.YYYY");
};

export const getResultRegionName = function (result) {
  if (!result.regionId || !townOptions[result.regionId]) {
    return t("common.not_set");
  }
  return townOptions[result.regionId].text;
};

export const getResultAccountName = function (result) {
  if (!result.account) {
    return t("common.not_set");
  }
  return getAccountTitle(result.account);
};
export const getResultCampaignName = function (result) {
  if (!result.campaign) {
    return t("common.not_set");
  }
  return getCampaignTitle(result.campaign);
};

export const getResultFormattedSum = function (result) {
  let sum = formatNumber(result.sum);
  if (!result.account) {
    return sum;
  }
  let sign = getAccountCurrencySign(result.account);
  if (result.account.currency === CURRENCY_USD) {
    return sign + sum;
  } else {
    return sum + sign;
  }
};

export const STATUS_NEW = 0; // новый
export const STATUS_QUEUED = 2; // в очереди на запрос
export const STATUS_REQUESTED = 4; // отправлен запрос
export const STATUS_WAITING = 6; // ожидание офлайн отчета
export const STATUS_RECEIVED = 8; // отчет получен
export const STATUS_PROCESSING = 10; // отчет обрабатывается
export const STATUS_DONE = 12; // отчет обработан

export const statusTitles = {
  [STATUS_NEW]: "Новый",
  [STATUS_QUEUED]: "В очереди на запрос",
  [STATUS_REQUESTED]: "Отправлен запрос",
  [STATUS_WAITING]: "Ожидание офлайн отчета",
  [STATUS_RECEIVED]: "Отчет получен",
  [STATUS_PROCESSING]: "Отчет обрабатывается",
  [STATUS_DONE]: "Отчет обработан",
};

export const statusOptions = Object.entries(statusTitles).map(([k, v]) => {
  return { id: k, text: v };
});

export const getResultStatusTitle = function (result) {
  if (!result.status) {
    return t("common.not_set");
  }
  return statusTitles[result.status];
};
