<template>
  <b-form
    id="pest-fine-review-form"
    ref="fine"
    :model="fine"
    class="pest-fine-review-form"
    novalidate
    @submit="createOrUpdate"
  >
    <div class="div">
      <div class="row">
        <div class="col-12"></div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group :label="$t('fines.result')" prop="sum">
            <b-form-textarea
              v-model="fine.review"
              :placeholder="$t('fines.result')"
              :disabled="!canBeEdited"
              required
              tabindex="1"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>

      <div v-if="canReviewFines" class="row">
        <div v-if="canBeEdited" class="col">
          <b-button
            v-if="fine.status === FINE_STATUS_NEW"
            variant="info"
            @click="setProcessingStatus"
          >
            <font-awesome-icon icon="rotate" :spin="updatingProcessStatus" />
            {{ $t("fines.status_p_process") }}
          </b-button>

          <b-button
            v-else-if="fine.status === FINE_STATUS_PROCESSING"
            variant="info"
            disabled
          >
            <font-awesome-icon icon="rotate" />
            {{ $t("fines.status_p_processing") }}
          </b-button>

          <b-button
            variant="primary"
            class="ml-2"
            @click="createOrUpdate($event, FINE_STATUS_VERIFIED)"
          >
            <font-awesome-icon icon="check" />
            {{ $t("fines.status_p_verified") }}
          </b-button>

          <b-button
            variant="danger"
            class="ml-2"
            @click="createOrUpdate($event, FINE_STATUS_DECLINED)"
          >
            <font-awesome-icon icon="times" />
            {{ $t("fines.status_p_decline") }}
          </b-button>
        </div>
        <div v-else class="col">
          <b-button
            v-if="fine.status === FINE_STATUS_VERIFIED && canReviewFines"
            variant="danger"
            class="ml-2"
            @click="createOrUpdate($event, FINE_STATUS_NEW)"
          >
            <font-awesome-icon icon="times" />
            {{ $t("fines.status_p_cancel") }}
          </b-button>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import { VueMaskDirective } from "v-mask";
import {
  FINE_STATUS_DECLINED,
  FINE_STATUS_NEW,
  FINE_STATUS_PROCESSING,
  FINE_STATUS_VERIFIED,
} from "@/modules/pest/decorators/fines";
import { canReviewFines } from "@/modules/users/access/common.js";

export default {
  name: "PestFineReviewForm",
  directives: { mask: VueMaskDirective },
  components: {},
  props: ["fine"],
  data() {
    return {
      FINE_STATUS_NEW,
      FINE_STATUS_PROCESSING,
      FINE_STATUS_VERIFIED,
      FINE_STATUS_DECLINED,
      phoneMask: false,
      loading: false,
      updatingProcessStatus: false,
      requestLoading: false,
      toPay: 0,
      earn: 0,
    };
  },
  computed: {
    id() {
      return this.fine.id;
    },
    name() {
      let name = this.fine.name ? this.fine.name : this.$t("fines.new_fine");
      return name ? name : "fine";
    },
    canReviewFines() {
      return canReviewFines(this.$store.state.user);
    },
    canBeEdited() {
      return (
        this.canReviewFines &&
        (this.fine.status === FINE_STATUS_NEW ||
          this.fine.status === FINE_STATUS_PROCESSING)
      );
    },
  },
  watch: {
    $route() {
      let userId =
        this.$route.params.userId !== undefined
          ? this.$route.params.userId
          : null;
      if (userId) {
        this.fine.assigneeId = userId;
      }
    },
  },
  created: function () {},
  mounted() {
    let userId =
      this.$route.params.userId !== undefined
        ? this.$route.params.userId
        : null;
    if (userId) {
      this.fine.employeeId = userId;
    }
  },
  methods: {
    setProcessingStatus: function () {
      if (this.updatingProcessStatus) {
        return;
      }
      let updateUrl;
      updateUrl = "/pest/fines/processing/" + this.id;
      this.updatingProcessStatus = true;
      this.$http
        .post(updateUrl, this.fine)
        .then((response) => {
          this.fine.status = FINE_STATUS_PROCESSING;
          this.updatingProcessStatus = false;
          this.$emit("update-pest-fine-info", this.fine);
          // this.$router.push({name: 'pest-fine-view', params: {id: this.fine.id}});
          this.$bvToast.toast(this.$t("common.data_saved"), {
            title: "Заявка",
            type: "success",
          });
        })
        .catch((error) => {
          this.updatingProcessStatus = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: this.$t("fines.cant_change_status"),
              type: "danger",
            });
          }
        });
    },
    update: function (status) {
      let updateUrl;
      updateUrl = "/pest/fines/review/" + this.id;
      this.fine.status = status;
      this.loading = true;
      this.$http
        .post(updateUrl, this.fine)
        .then((response) => {
          this.loading = false;
          this.$eventHub.$emit("update-pest-fine-info", this.fine);
          // this.$router.push({name: 'pest-fine-view', params: {id: this.fine.id}});
          if (status === FINE_STATUS_VERIFIED) {
            this.$bvToast.toast(this.$t("common.data_saved"), {
              title: this.$t("fines.fine_confirmed"),
              type: "success",
            });
          } else if (status === FINE_STATUS_DECLINED) {
            this.$bvToast.toast(this.$t("common.data_saved"), {
              title: this.$t("fines.fine_declined"),
              type: "success",
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: this.$t("common.error_during_saving"),
              type: "danger",
            });
          }
        });
    },
    createOrUpdate: function (event, status) {
      event.preventDefault();
      console.log(status);
      let form = document.getElementById("pest-fine-review-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast(this.$t("common.data_is_saving"), {
            title: this.$t("common.please_wait"),
            variant: "info",
          });
          return;
        }
        this.update(status);
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast(this.$t("common.form_has_errors"), {
          variant: "danger",
        });
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}
</style>
