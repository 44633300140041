import DepositList from "@/modules/pest/views/deposits/DepositList";
import DepositCreate from "@/modules/pest/views/deposits/DepositCreate";
import DepositUpdate from "@/modules/pest/views/deposits/DepositUpdate";
import DepositView from "@/modules/pest/views/deposits/DepositView";
import DepositEmployeeCreate from "@/modules/pest/views/deposits/DepositEmployeeCreate.vue";
import DepositEmployeeList from "@/modules/pest/views/deposits/DepositEmployeeList.vue";

export default [
  {
    path: "/deposits/new-employee",
    name: "pest-deposit-employee-create",
    components: {
      default: DepositEmployeeCreate,
    },
    meta: {
      requiresAuth: true,
      pageTitle: "menu.deposits_hand_over",
    },
  },

  {
    path: "/deposits/my:page?",
    name: "pest-deposit-employee-list",
    components: {
      default: DepositEmployeeList,
    },
    meta: {
      requiresAuth: true,
      pageTitle: "menu.deposits_history",
    },
  },

  {
    path: "/deposits/page:page?",
    name: "pest-deposit-list",
    components: {
      default: DepositList,
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/deposits/new",
    name: "pest-deposit-create",
    components: {
      default: DepositCreate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/deposits/:id/update",
    name: "pest-deposit-update",
    components: {
      default: DepositUpdate,
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/deposits/:id",
    name: "pest-deposit-view",
    components: {
      default: DepositView,
    },
    meta: { requiresAuth: true },
  },
];
