<template>
  <div>
    {{ $t("requests.service_price") }}: <strong>{{ log.serviceSum }}</strong
    ><br />
    {{ $t("requests.new_record") }}: <strong>{{ log.newSum }}</strong
    ><br />
    {{ $t("requests.prev_record") }}: <strong>{{ log.recordCurSum }}</strong
    ><br />

    <a v-if="show" href="" @click.prevent="toggle">{{ $t("logs.hide") }}</a>
    <a v-else href="" @click.prevent="toggle">{{ $t("logs.show") }}</a>

    <div v-if="show">
      {{ $t("requests.town") }}: <strong>{{ log.town }}</strong
      ><br />
      <span v-if="log.newSum1">
        <strong> {{ $t("logs.record_was_reset") }}</strong
        ><br
      /></span>
      {{ $t("logs.record_compare_sum") }}:
      <strong>{{ log.compareRecordSum }}</strong
      ><br />
      {{ $t("settings.min_record_sum") }}:
      <strong>{{ log.recordSumMin }}</strong
      ><br />
      {{ $t("settings.max_record_sum") }}:
      <strong>{{ log.recordSumMax }}</strong
      ><br />
    </div>
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";

export default {
  name: "RequestLogNewRecord",
  components: {},
  props: ["log"],
  data() {
    return {
      show: false,
      request: {},
    };
  },
  computed: {},
  watch: {
    log: {
      immediate: true,
      handler(obj) {
        this.request = obj.record;
      },
    },
  },
  methods: {
    getUserName,
    toggle() {
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss" scoped></style>
