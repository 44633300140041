export const ru = {
  geo: {
    coordinates_checked: "Координаты не проверялись",
    coordinates_arent_checked: "Координаты проверены",
    geolocation_is_not_supported: "Геолокация не поддерживается",
    geolocation_is_not_blocked:
      "У вас заблокировано определение месторасположения",
  },
};
export const en = {
  geo: {
    coordinates_checked: "Coordinates checked",
    coordinates_arent_checked: "Coordinates aren't checked",
    geolocation_is_not_supported: "Geolocation is not supported",
    geolocation_is_not_blocked: "Geolocation is not blocked",
  },
};
