<template>
  <div class="ibox">
    <div class="ibox-title">
      <div class="search">
        <div class="d-flex">
          <div class="mr-2">
            <b-form-group :label="$t('deposit.date_from')">
              <datepicker
                v-model="search.dateFrom"
                input-class="form-control"
                :editable="true"
                lang="ru"
                :format="dateFormat"
                type="date"
                value-type="format"
                @change="fetchData"
              ></datepicker>
            </b-form-group>
          </div>
          <div class="mr-2">
            <b-form-group :label="$t('deposit.date_to')">
              <datepicker
                v-model="search.dateTo"
                input-class="form-control"
                :editable="true"
                lang="ru"
                :format="dateFormat"
                type="date"
                value-type="format"
                @change="fetchData"
              ></datepicker>
            </b-form-group>
          </div>
          <div class="mr-2">
            <b-form-group :label="$t('requests.assignee')">
              <user-autocomplete
                v-model="search.assigneeId"
                :default-value="
                  search.assigneeId
                    ? getAssigneeName(search.assignee, true)
                    : ''
                "
                @selected="selectAssignee"
              />
            </b-form-group>
          </div>
          <div class="align-self-center mt-2">
            <b-button class="btn btn-primary ml-1" @click="fetchData">
              <font-awesome-icon icon="search" />&nbsp;
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox-content">
      <div v-if="loading" class="pl-3 pr-3 pt-2 pb-4">
        {{ $t("common.loading") }}
      </div>

      <b-table
        v-else-if="deposits.length"
        responsive
        hover
        condensed
        bordered
        :items="deposits"
        :fields="fields"
        :busy="loading"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle table-spinner"></b-spinner>
            <strong> {{ $t("common.loading") }}</strong>
          </div>
        </template>

        <template #cell(assigneeId)="data">
          {{ getDepositAssigneeName(data.item) }}
        </template>

        <template #cell(time)="data">
          {{ getDepositDateTime(data.item) }}
        </template>

        <template #cell(totalSum)="data">
          {{ getDepositSumFormatted(data.item) }} {{ $t("common.cur") }}
        </template>

        <template #cell(status)="data">
          {{ getDepositStatusTitle(data.item) }}
        </template>
      </b-table>

      <div v-else class="pl-3 pr-3 pt-2 pb-4">
        {{ $t("deposit.empty_list_msg") }}
      </div>
    </div>
    <b-pagination-nav
      v-if="pagination.count > 1"
      :link-gen="linkGen"
      :value="pagination.page"
      :number-of-pages="pagination.count"
      limit="10"
      use-router
      class="mt-2"
    >
    </b-pagination-nav>
  </div>
</template>

<script>
import {
  getDepositAssigneeName,
  getDepositDateTime,
  getDepositStatusTitle,
  getDepositSum,
  getDepositSumFormatted,
} from "@/modules/pest/decorators/deposits";
import DatePicker from "vue2-datepicker";
import dayjs from "dayjs";
import UserAutocomplete from "@/modules/users/views/user/UserAutocomplete.vue";

import { getAssigneeName } from "@/modules/crm/common.js";

export default {
  name: "DepositEmployeeList",
  components: {
    UserAutocomplete,
    Datepicker: DatePicker,
  },
  data() {
    return {
      dateFormat: "DD.MM.YYYY",
      deposits: [],
      search: {
        assigneeId: null,
        dateFrom: "",
        dateTo: "",
        name: "",
      },
      loading: true,
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      fields: [
        {
          key: "time",
          label: this.$t("deposit.date"),
          sortable: false,
        },
        {
          key: "assigneeId",
          label: this.$t("requests.assignee"),
          sortable: false,
        },
        {
          key: "totalSum",
          label: this.$t("deposit.sum"),
          sortable: false,
        },
        {
          key: "status",
          label: this.$t("common.status"),
          sortable: false,
        },
      ],
    };
  },
  computed: {
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
  },
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  mounted: function () {
    this.fetchData();
  },
  created() {
    this.search.dateTo = dayjs().format("DD.MM.YYYY");
    this.search.dateFrom = dayjs().subtract(30, "d").format("DD.MM.YYYY");
  },
  methods: {
    getAssigneeName,
    getDepositAssigneeName,
    getDepositDateTime,
    getDepositSum,
    getDepositSumFormatted,
    getDepositStatusTitle,
    fetchData: function () {
      this.loading = true;

      let depositsUrl = "/pest/deposits";
      let params = {
        page: this.page,
        dateFrom: this.search.dateFrom,
        dateTo: this.search.dateTo,
        employeeId: this.search.assigneeId,
      };
      this.$http
        .get(depositsUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.deposits = response.data;
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    handleGroupIndex(command) {
      this.sampleGroupIndex = command;
    },
    linkGen(pageNum) {
      return {
        name: "pest-deposit-employee-list",
        params: { page: pageNum },
      };
    },
    selectAssignee(data) {
      if (data && data.value) {
        this.search.assigneeId = data.value.id;
        this.search.assignee = data.value;
      } else {
        this.search.assigneeId = null;
        this.search.assignee = {};
      }
      this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}
</style>
