<template>
  <div :data-counter="counter">
    <div class="mb-1 d-flex">
      <div>
        <font-awesome-icon icon="list-ol" fixed-width class="mr-1" />
        <!--              Описание-->
        <router-link
          v-if="!blocked || blocked !== BLOCKED"
          :to="{ name: 'pest-request-view', params: { id: request.id } }"
        >
          {{ getRequestNumber(request) }}
        </router-link>

        <span v-else> {{ getRequestNumber(request) }}</span>
      </div>
      <div class="ml-auto">
        <div class="handle">
          <font-awesome-icon icon="arrows-alt" fixed-width />
        </div>
      </div>
    </div>
    <div class="mb-1 d-flex">
      <div>
        <font-awesome-icon
          v-if="request.isOrganization"
          icon="building"
          fixed-width
          class="mr-1"
        />
        <font-awesome-icon v-else icon="house-user" fixed-width class="mr-1" />
        <span> {{ getRequestClientName(request) }}</span>
      </div>
    </div>

    <div
      class="mb-1"
      :class="{
        'text-success': request.isFirstTime,
        'text-info': !request.isFirstTime,
      }"
    >
      <font-awesome-icon icon="arrow-up-1-9" fixed-width class="mr-2" />
      <!--              Очередность -->
      <span v-if="request.isFirstTime">{{
        $t("requests.first_request_sh")
      }}</span>
      <span v-else>{{ $t("requests.follow_up_request_sh") }}</span>
    </div>

    <div
      v-if="request.isOrganization && request.organizationTitle"
      class="mb-1"
    >
      <font-awesome-icon icon="building" fixed-width class="mr-1" />
      <!--              Описание-->
      {{ request.organizationTitle }}
    </div>

    <div v-if="request.isOrganization && request.paymentType" class="mb-1">
      <font-awesome-icon icon="money-bill" fixed-width class="mr-1" />
      <!--              Описание-->
      {{ getPaymentTypeTitle(request) }}
    </div>

    <div class="mb-1">
      <font-awesome-icon icon="money-bill" fixed-width class="mr-1" />
      <!--              Сумма -->
      {{ getRequestDisplayableSum(request) }}

      <span v-if="request.earned"> / {{ request.earned }} </span>
      <span v-if="request.hasRecord">
        <font-awesome-icon v-b-tooltip.hover icon="star" title="Рекорд" />
      </span>

      <span v-if="request.isOrganization && request.paymentType == PT_CASHLESS">
        ($t('requests.payment_cashless'))
      </span>
    </div>

    <div class="mb-1">
      <font-awesome-icon icon="home" fixed-width class="mr-1" />
      <!-- Адрес -->
      {{ getRequestAddressFormatted(request) }}
    </div>

    <div v-if="request.subway" class="mb-1">
      <font-awesome-icon icon="map-marked-alt" fixed-width class="mr-1" />
      <!--              Метро -->
      {{ getSubwayTitle(request.subway) }}
    </div>
    <div
      v-if="request.pest"
      class="mb-1"
      :style="'color: ' + getRequestColor(request)"
    >
      <font-awesome-icon icon="bug" fixed-width class="mr-1" />
      <!--              Вредитель -->
      {{ getPestTitle(request.pest) }}
    </div>
    <div v-if="request.facility" class="mb-1">
      <font-awesome-icon icon="building" fixed-width class="mr-1" />
      <!--              Тип объекта -->
      {{ getFacilityTitle(request.facility) }}
    </div>
    <div v-if="request.town && false" class="mb-1">
      <font-awesome-icon icon="map-marker-alt" fixed-width class="mr-1" />
      <!--              Город -->
      {{ getRequestTownTitle(request) }}
    </div>
    <div class="mb-1 d-flex">
      <div>
        <!--              Время -->
        <font-awesome-icon icon="clock" fixed-width class="mr-1" />
        {{ getRequestDatetime(request) }}
      </div>
      <div class="ml-auto">
        <font-awesome-icon icon="stopwatch" fixed-width class="" />
        <a href="#" class="" @click.prevent="openTimePopup">
          {{ getRequestDuration(request) }}
        </a>
      </div>
    </div>

    <div v-if="request.warning" class="mb-1 text-warning">
      <font-awesome-icon icon="exclamation-triangle" fixed-width class="mr-1" />
      {{ request.warning }}
      <!--              Особое внимание -->
    </div>

    <div class="mb-1">
      <font-awesome-icon icon="comment" fixed-width class="mr-1" />
      {{ request.comment }}
      <!--              Время -->
    </div>
  </div>
</template>

<script>
import {
  getDecisionTime,
  getPaymentTypeTitle,
  getRequestAddressFormatted,
  getRequestAssigneeName,
  getRequestBorderType,
  getRequestClientName,
  getRequestColor,
  getRequestCreateTime,
  getRequestDatetime,
  getRequestDeadline,
  getRequestDuration,
  getRequestManagerName,
  getRequestNumber,
  getRequestSourceName,
  getRequestStatus,
  getRequestStatusColor,
  getRequestStatusTitle,
  getRequestTitle,
  getRequestTownTitle,
  getRequestUpdateTime,
  getRequestDisplayableSum,
  PT_CASHLESS,
  servicesSum,
  STAGE_DEAL,
  STATUS_DECLINE,
  STATUS_DONE,
} from "@/modules/pest/decorators/requests.js";
import { getFacilityTitle } from "@/modules/pest/decorators/facilities.js";
import { getPestTitle } from "@/modules/pest/decorators/pests.js";
import { getUserName } from "@/modules/crm/common.js";
import {
  BLOCKED,
  REGULAR,
  UNBLOCKED,
} from "@/modules/users/decorators/userDecorator.js";
import { getSubwayTitle } from "@/modules/pest/decorators/subways";

export default {
  name: "RequestCardEmployeeFields",
  components: {},
  props: ["blocked", "counter", "request"],
  data() {
    return {
      BLOCKED,
      UNBLOCKED,
      REGULAR,
      STATUS_DONE,
      STAGE_DEAL,
      STATUS_DECLINE,
      PT_CASHLESS,
    };
  },
  computed: {
    id() {
      return this.request.id;
      // return this.$route.params.id !== undefined ? this.$route.params.id : 'new'
    },
  },
  watch: {},
  created: function () {},
  methods: {
    getRequestDisplayableSum,
    getRequestNumber,
    getFacilityTitle,
    getRequestTitle,
    getPaymentTypeTitle,
    getRequestStatusColor,
    getRequestColor,
    getRequestBorderType,
    getPestTitle,
    getRequestDatetime,
    getRequestDuration,
    getRequestStatusTitle,
    getRequestManagerName,
    getRequestSourceName,
    getRequestAssigneeName,
    getRequestStatus,
    getRequestClientName,
    getRequestCreateTime,
    getRequestDeadline,
    getRequestUpdateTime,
    getUserName,
    getDecisionTime,
    servicesSum,
    getRequestTownTitle,
    getSubwayTitle,
    getRequestAddressFormatted,
    openRequest() {
      this.$emit("open-request");
    },
    updateTime(duration) {
      let updateUrl;
      updateUrl = "/pest/request/duration/" + this.id;
      // this.loading = true;
      this.$http
        .put(updateUrl, {
          duration: duration,
        })
        .then((response) => {
          this.$bvToast.toast(this.$t("common.data_saved"), {
            title: this.$t("requests.request_time_updated_mgs"),
            request: "success",
          });
        })
        .catch((error) => {
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: this.$t("requests.request_time_not_updated_mgs"),
              request: "danger",
            });
          }
        });
    },
    openTimePopup() {
      let $this = this;
      this.$eventHub.$emit("time-popup-show", {
        request: this.request,
        success: function (duration) {
          $this.request.duration = duration;
          $this.updateTime(duration);
        },
        cancel: function () {},
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
