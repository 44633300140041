import VueI18n from "vue-i18n";
import Vue from "vue";
import messages from "./messages";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "en", // set locale
  messages,
});

const t = function (m) {
  return i18n.tc(m);
};
export { i18n };
export default t;
