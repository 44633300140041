export const ru = {
  calls: {
    call_to_client: "Звонок с клиентом",
    make_call_to_client: "Позвонить клиенту",
    schedule_call: "Назначить звонок",
    call_duration: "Длительность звонка",
    not_answered: "Не дозвон",
    calling: "Осуществляется звонок",
    please_wait: "Ожидайте",
    cant_call_msg: "Не удалось совершить звонок",
    call_from_unknown_number: "Звонок с неизвестного номера",
    call_scheduled_on: "Назначен звонок на ",
    attempt: "Попытка",
    incoming_call: "Входящий звонок",
  },
};
export const en = {
  calls: {
    call_to_client: "Call to client",
    make_call_to_client: "Call to client",
    schedule_call: "Schedule call",
    call_duration: "Call duration",
    not_answered: "Not answered",
    assign: "Assign",
    calling: "Calling",
    please_wait: "Please wait",
    cant_call_msg: "Can't call msg",
    call_from_unknown_number: "Call from unknown number",
    call_scheduled_on: "Call scheduled on",
    attempt: "Attempt",
    incoming_call: "Incoming call",
  },
};
