export const ru = {
  requests_statuses: {
    order_new: "Оформлен заказ",
    order_declined: "Заказ провален",
    order_done: "Заказ успешен",

    r_new: "Оформлен заказ",
    r_dispatched: "Распределена",
    r_in_work: "В работе",
    r_paid: "Оплачена",
    r_success: "Заказ успешен",
    r_failed: "Заказ провален",
    r_not_set: "Не проставлен",
    r_declined: "Отклонена",
    r_done: "Закрыта",
  },
};
export const en = {
  requests_statuses: {
    order_new: "New order",
    order_declined: "Order failed",
    order_done: "Order done",

    r_new: "New order",
    r_dispatched: "Dispatched",
    r_in_work: "In work",
    r_paid: "Paid",
    r_success: "Success",
    r_failed: "Failed",
    r_not_set: "Not set",
    r_declined: "Declined",
    r_done: "Done",
  },
};
