<template>
  <div
    class="p-2 mb-2"
    :data-counter="counter"
    :class="{
      closed: isClosed(request),
      highlighted: request.highlighted,
      'counter-c': counter,
      'request-card': !forEmployee,
      'notification-soon': isCallNotificationSoon(request),
      'employee-request-card': forEmployee,
      'mr-2': contractBoard,
    }"
    :style="{
      border:
        '3px  ' +
        getRequestBorderType(request) +
        ' ' +
        getRequestColor(request),
    }"
    @dblclick="openRequest"
  >
    <request-card-employee-fields
      v-if="forEmployee"
      :blocked="blocked"
      :counter="counter"
      :request="request"
      @open-request="openRequest"
    />

    <request-card-dispatching-fields
      v-else-if="!shortVersion"
      :lead-board="leadBoard"
      :blocked="blocked"
      :counter="counter"
      :request="request"
      :dispatching-board="dispatchingBoard"
      :hide-handle="contractBoard"
      @open-request="openRequest"
    />

    <request-card-kanban-fields
      v-else
      :lead-board="leadBoard"
      :blocked="blocked"
      :counter="counter"
      :request="request"
      :status="status"
      :hide-handle="contractBoard"
      :show-duplicate="contractBoard"
      @open-request="openRequest"
      @duplicate-request="duplicateRequest"
    />
  </div>
</template>

<script>
import {
  getRequestBorderType,
  getRequestColor,
  isCallNotificationSoon,
  STAGE_DEAL,
  STATUS_DECLINE,
  STATUS_DONE,
  STATUS_PAID,
} from "@/modules/pest/decorators/requests.js";
import { VueMaskDirective } from "v-mask";
import { canChangeAssignee } from "@/modules/users/access/common.js";
import {
  BLOCKED,
  REGULAR,
  UNBLOCKED,
} from "@/modules/users/decorators/userDecorator.js";
import RequestCardDispatchingFields from "@/modules/pest/views/requests/card/RequestCardDispatchingFields.vue";
import RequestCardKanbanFields from "@/modules/pest/views/requests/card/RequestCardKanbanFields.vue";
import RequestCardEmployeeFields from "@/modules/pest/views/requests/card/RequestCardEmployeeFields.vue";

export default {
  name: "RequestCard",
  directives: { mask: VueMaskDirective },
  components: {
    RequestCardKanbanFields,
    RequestCardDispatchingFields,
    RequestCardEmployeeFields,
  },
  props: [
    "blocked",
    "counter",
    "request",
    "status",
    "leadBoard",
    "contractBoard",
    "updateButton",
    "forEmployee",
    "dispatchingBoard",
    "shortVersion",
    "hideHandle",
  ],
  data() {
    return {
      BLOCKED,
      UNBLOCKED,
      REGULAR,
      STATUS_DONE,
      STAGE_DEAL,
      loading: false,
      closeRequest: false,
      decisionForm: false,
    };
  },
  computed: {
    id() {
      return this.request.id;
      // return this.$route.params.id !== undefined ? this.$route.params.id : 'new'
    },
    canChangeAssignee() {
      return canChangeAssignee(this.$store.state.user);
    },
  },
  watch: {
    request: {
      immediate: true,
      handler(request) {},
    },
  },
  created: function () {},
  methods: {
    isCallNotificationSoon,
    getRequestColor,
    getRequestBorderType,
    isClosed(request) {
      if (request.status === STATUS_DONE) {
        return true;
      }
      if (request.status === STATUS_PAID) {
        return true;
      }
      if (request.status === STATUS_DECLINE) {
        return true;
      }
      return false;
    },
    duplicateRequest() {
      this.$emit("duplicate-request", this.request);
    },
    openRequest() {
      if (this.blocked !== BLOCKED) {
        if (this.updateButton) {
          this.showUpdatePopup();
        } else {
          this.$router.push({
            name: "pest-request-view",
            params: { id: this.request.id },
          });
        }
      }
    },

    showUpdatePopup() {
      this.$emit("show-popup", {
        request: this.request,
        success: function (info) {
          this.request = info;
        },
      });
    },
  },
};
</script>

<style lang="scss">
.request-card {
  background: #fafafb;
  border: 1px solid #e7eaec;
  margin: 0 0 10px 0;
  padding: 10px;
  border-radius: 2px;
  //border-left: 3px solid #f8ac59;
  //border-left: 3px solid #d09e23;
  //border-left: 3px solid #1c84c6;
  //border-left: 3px solid #ed5565;
  overflow-x: hidden;
  width: 240px;
  min-width: 240px !important;
}

.closed {
  opacity: 0.5;
}

.closed:hover {
  opacity: 1;
}

.request-card:hover {
  //cursor: pointer;
  background: #fff;
}

.comment-link {
  border-bottom: 1px dashed;
}

.edit-assignee-link {
  display: none;
}

.request-card:hover .edit-assignee-link {
  display: block;
}

.edit-assignee-link:hover {
  margin-bottom: -1px;
  border-bottom: 1px dashed;
}

.notification-soon {
  background-color: rgba(250, 0, 0, 0.3) !important;
}

.highlighted {
  background-color: rgba(116, 225, 250, 0.3) !important;
}

.request-card {
  transition: background-color 0.5s linear;
}

.handle {
  display: none;
}

.order-time {
  display: block;
}

.request-card:hover .handle {
  //display: block !important;
  cursor: move;
}

.request-card:hover .order-time {
  //display: none !important;
}

.request-card,
.employee-request-card {
  word-break: break-word;
}
</style>
<style></style>
