<template>
  <div class="pest-region-page">
    <div class="ibox">
      <div class="ibox-title pb-3">
        <div class="d-flex">
          <div class="ml-1 mr-2 mt-2">
            <router-link
              :to="{ name: 'pest-region-list' }"
              class="h4 text-muted"
            >
              <font-awesome-icon icon="arrow-left" />
            </router-link>
          </div>
          <div>
            <h1>
              {{ region.title }}
            </h1>
          </div>
        </div>
      </div>
      <div class="ibox-content">
        <pest-region-info
          :region="region"
          @update-prop="updateProp"
        ></pest-region-info>
      </div>
    </div>
  </div>
</template>

<script>
import RegionInfo from "./RegionInfo";
import { getUserName } from "@/modules/crm/common";

export default {
  name: "Region",
  components: {
    "pest-region-info": RegionInfo,
  },
  data() {
    return {
      tabIndex: 0,
      region: {
        id: "new",
      },
      loading: false,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    getUserName,
    checkUrl: function () {
      this.fetchData();
      window.scrollTo(0, 0);
    },
    fetchData() {
      this.region.phone = "";
      let regionsUrl = "/pest/regions/" + this.id;
      this.loading = true;
      this.$http
        .get(regionsUrl)
        .then((response) => {
          this.loading = false;
          this.region = response.data;
          this.region.managerName = this.region.manager.username;
          this.header = this.region.email;
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    updateProp(e) {
      this.update();
    },
    update: function () {
      let updateUrl;
      updateUrl = "/pest/regions/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.region)
        .then((response) => {
          this.region = response.data;
          this.loading = false;
          this.$eventHub.$emit("update-pest-region-info", this.region);
          this.$bvToast.toast(this.$t("common.data_saved"), {
            title: this.$t("regions.region_updated"),
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: this.$t("common.error_during_saving"),
              type: "danger",
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ibox-title {
  padding: 15px 15px 8px 15px;
}
</style>
