<template>
  <div>
    {{ $t("calls.call_to_client") }}:

    <div v-if="!emptyFirstInfo && !firstAnswered">
      <div>{{ $t("calls.not_answered") }}</div>
    </div>
    <div v-if="!emptySecondInfo && !secondAnswered">
      <div>{{ $t("calls.not_answered") }}</div>
    </div>
    <div v-else>{{ $t("calls.calling") }}</div>

    <div v-if="firstAnswered">
      {{ $t("calls.call_duration") }}: {{ firstDuration }}
      <div><audio :src="firstFile" controls></audio></div>
    </div>
    <div v-if="secondAnswered">
      {{ $t("calls.call_duration") }}: {{ secondDuration }}
      <div><audio :src="secondFile" controls></audio></div>
    </div>
  </div>
</template>

<script>
import { getUserTitle } from "@/modules/users/decorators/userDecorator.js";

export default {
  name: "RequestLogZadarmaCallRecord",
  components: {},
  props: ["log"],
  data() {
    return {};
  },
  computed: {
    firstFile() {
      return this.log.info.firstCallFileUrl;
    },
    secondFile() {
      return this.log.info.secondCallFileUrl;
    },
    firstAnswered() {
      return (
        this.log.info.firstCallInfo &&
        this.log.info.firstCallInfo.disposition === "answered"
      );
    },
    secondAnswered() {
      return (
        this.log.info.secondCallInfo &&
        this.log.info.secondCallInfo.disposition === "answered"
      );
    },
    firstDuration() {
      let seconds = this.log.info.firstCallInfo.duration;
      return new Date(seconds * 1000).toISOString().substr(14, 5);
    },
    secondDuration() {
      let seconds = this.log.info.secondCallInfo.duration;
      return new Date(seconds * 1000).toISOString().substr(14, 5);
    },
    emptyFirstInfo() {
      return (
        !this.log.info.firstCallInfo ||
        Object.keys(this.log.info.firstCallInfo).length === 0
      );
    },
    emptySecondInfo() {
      return (
        !this.log.info.secondCallInfo ||
        Object.keys(this.log.info.secondCallInfo).length === 0
      );
    },
  },
  watch: {},
  methods: { getUserTitle },
};
</script>

<style lang="scss" scoped></style>
