<template>
  <b-form
    id="client-form"
    ref="client"
    :model="client"
    class="client-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="div">
      <div class="ibox mb-1">
        <div class="ibox-content pb-1">
          <div v-if="client.type == TYPE_ORGANIZATION">
            <div class="row">
              <div class="col-12">
                <b-form-group label="Название организации" prop="title">
                  {{ client.title }}
                </b-form-group>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="row">
              <div class="col-12">
                <b-form-group label="Фамилия" prop="lastName">
                  {{ client.lastName }}
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <b-form-group label="Имя" prop="firstName">
                  {{ client.firstName }}
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <b-form-group label="Отчество" prop="patronymic">
                  {{ client.patronymic }}
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <b-form-group label="Должность" prop="post">
                  {{ client.post }}
                </b-form-group>
              </div>
            </div>
          </div>

          <div
            v-for="(phone, index) in client.phones"
            :key="'p' + index"
            class="row"
          >
            <div class="col-8">
              <b-form-group
                :label="'Телефон ' + getContactType(phone.type) + ''"
                prop="phone"
              >
                {{ phone.phone }}
              </b-form-group>
            </div>
          </div>

          <div
            v-for="(email, index) in client.emails"
            :key="'e' + index"
            class="row"
          >
            <div class="col-8">
              <b-form-group
                :label="'Электронная почта ' + getContactType(email.type) + ''"
                prop="email"
              >
                {{ email.email }}
              </b-form-group>
            </div>
          </div>

          <div v-if="client.type === TYPE_PERSON" class="row">
            <div class="col-6">
              <b-form-group label="День рождения" prop="birthday">
                {{ client.birthday }}
              </b-form-group>
            </div>

            <div class="col-6">
              <b-form-group label="Пол" prop="gender">
                {{ getClientGender(client) }}
              </b-form-group>
            </div>
          </div>

          <div v-if="client.type === TYPE_ORGANIZATION" class="">
            <b-form-group label="Полное название" prop="fullTitle">
              {{ client.fullTitle }}
            </b-form-group>
            <b-form-group label="Физический адрес" prop="address">
              {{ client.address }}
            </b-form-group>
            <b-form-group label="Почтовый адрес" prop="postAddress">
              {{ client.postAddress }}
            </b-form-group>
            <b-form-group label="Юридический адрес" prop="legalAddress">
              {{ client.legalAddress }}
            </b-form-group>
            <b-form-group label="ИНН" prop="inn">
              {{ client.inn }}
            </b-form-group>
            <b-form-group label="КПП" prop="kpp">
              {{ client.kpp }}
            </b-form-group>
            <b-form-group label="ОГРН" prop="ogrn">
              {{ client.ogrn }}
            </b-form-group>
            <b-form-group label="Банковский счет" prop="bankAccout">
              {{ client.bankAccout }}
            </b-form-group>
          </div>

          <div v-if="client.type === TYPE_PERSON" class="">
            <b-form-group label="Паспорт" prop="passportSerial">
              {{ client.passportSerial }} {{ client.passportNumber }} Выдан
              {{ client.passportIssuedDate }} {{ client.passportIssuedByTitle }}
              {{ client.passportIssuedByCode }}
            </b-form-group>
          </div>
          <div v-else class="">
            <b-form-group label="Адрес" prop="address">
              {{ client.address }}
            </b-form-group>
          </div>

          <div v-if="client.type !== TYPE_ORGANIZATION" class="">
            <fieldset class="form-group">
              <legend class="bv-no-focus-ring col-form-label pt-0">
                Организация
              </legend>
              <div v-if="client.organizationId">
                <router-link
                  :to="{
                    name: 'crm-client-view',
                    params: { id: client.organizationId },
                  }"
                  class=""
                >
                  {{ getClientOrganization(client) }}
                </router-link>
              </div>
            </fieldset>
          </div>

          <div class="row">
            <div class="col-12">
              <fieldset class="form-group">
                <legend class="bv-no-focus-ring col-form-label pt-0">
                  Ответственный
                </legend>
                <div>{{ getManagerName(client.manager) }}</div>
              </fieldset>
            </div>
            <div v-if="false" class="col-12"></div>
          </div>

          <div class="row">
            <div v-if="client.userId" class="col-12">
              <fieldset class="form-group">
                <legend class="bv-no-focus-ring col-form-label pt-0">
                  Пользователь
                </legend>
                <div>
                  <router-link
                    :to="{
                      name: 'users-user-view',
                      params: { id: client.userId },
                    }"
                    class=""
                  >
                    <font-awesome-icon icon="link" />
                    {{ getUserName(client.user) }}
                  </router-link>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="false" class="ibox mb-1">
      <div class="ibox-content pb-1">
        <div class="d-flex">
          <div class="">
            <b-form-group label="Серия" prop="passportSerial">
              <b-form-input
                v-model="client.passportSerial"
                tabindex="41"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="">
            <b-form-group label="Номер" prop="passportNumber">
              <b-form-input
                v-model="client.passportNumber"
                tabindex="42"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="">
            <b-form-group label="Когда" prop="passportIssuedDate">
              <datepicker
                v-model="client.passportIssuedDate"
                v-mask="'##.##.####'"
                input-class="form-control"
                value-type="format"
                lang="ru"
                :editable="true"
                placeholder=""
                :format="dateFormat"
                tabindex="43"
                required
              >
              </datepicker>
            </b-form-group>
          </div>
          <div class="">
            <b-form-group label="Кем выпущен" prop="passportIssuedByTitle">
              <b-form-input
                v-model="client.passportIssuedByTitle"
                tabindex="44"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="">
            <b-form-group label="Код" prop="passportIssuedByCode">
              <b-form-input
                v-model="client.passportIssuedByCode"
                tabindex="45"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>

    <div v-if="false" class="ibox mb-1">
      <div class="ibox-content">
        <b-button variant="primary" @click="createOrUpdate">
          <font-awesome-icon icon="check" />
          {{ $t("common.save") }}
        </b-button>

        <div v-if="false">
          <router-link
            :to="{ name: 'crm-client-list' }"
            class="btn btn-default ml-1 mr-1"
          >
            <font-awesome-icon icon="arrow-left" />
            Назад к списку
          </router-link>

          <b-button v-if="!client.archived" variant="danger" @click="archive">
            <font-awesome-icon icon="trash" />
            В архив
          </b-button>
          <b-button v-else variant="danger" @click="unarchive">
            <!--                    <font-awesome-icon icon="archive"/>-->
            Убрать из архива
          </b-button>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { getUserName } from "@/modules/crm/common";
import { VueMaskDirective } from "v-mask";
import {
  getClientGender,
  getClientOrganization,
  getContactType,
  TYPE_EMPLOYEE,
  TYPE_ORGANIZATION,
  TYPE_PERSON,
} from "@/modules/crm/decorators/client";

export default {
  name: "ClientForm",
  directives: { mask: VueMaskDirective },
  components: {
    Datepicker: DatePicker,
  },
  props: ["client"],
  data() {
    return {
      TYPE_ORGANIZATION,
      TYPE_EMPLOYEE,
      TYPE_PERSON,
      phoneMask: false,
      requestSources: [],
      managersList: [],
      genders: [
        { text: "M", value: "male" },
        { text: "Ж", value: "female" },
      ],
      types: [
        { text: "Клиент", value: TYPE_EMPLOYEE },
        { text: "Организация", value: TYPE_ORGANIZATION },
      ],
      dateFormat: "DD.MM.YYYY",
      labelWidth: "150px",
      loading: false,
      header: "Клиент",
    };
  },
  computed: {
    id() {
      return this.client.id;
      // return this.$route.params.id !== undefined ? this.$route.params.id : 'new'
    },
    name() {
      let name = this.client.name ? this.client.name : "Новый клиент";
      return name ? name : "Клиент";
    },
  },
  watch: {},
  created: function () {
    // this.checkUrl()
  },
  methods: {
    getClientGender,
    getContactType,
    getUserName,
    update: function () {
      let updateUrl;
      updateUrl = "/crm/clients/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.client)
        .then((response) => {
          this.client = response.data;
          this.loading = false;
          this.$eventHub.$emit("update-client-info", this.client);
          this.$bvToast.toast(this.$t("common.data_saved"), {
            title: "Клиент отредактирован",
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: "Не удалось удалить клиента",
              type: "danger",
            });
          }
        });
    },
    archive: function (event) {
      let updateUrl;
      updateUrl = "/crm/clients/archive/" + this.client.id;
      this.loading = true;
      this.$http
        .post(updateUrl, {})
        .then((response) => {
          this.client.archived = 1;
          this.loading = false;
          this.$eventHub.$emit("update-client-info", this.client);
          this.$bvToast.toast(this.$t("common.success"), {
            title: this.$t("clients.client_archived"),
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.error"), {
              title: this.$t("clients.cant_archive_client"),
              variant: "danger",
            });
          }
        });
    },
    unarchive: function (event) {
      let updateUrl;
      updateUrl = "/crm/clients/unarchive/" + this.client.id;
      this.loading = true;
      this.$http
        .post(updateUrl, this.client)
        .then((response) => {
          this.loading = false;
          this.client.archived = 0;
          this.$eventHub.$emit("update-client-info", this.client);
          this.$bvToast.toast(this.$t("common.success"), {
            title: this.$t("clients.client_archived"),
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.error"), {
              title: this.$t("clients.cant_unarchive_client"),
              variant: "danger",
            });
          }
        });
    },
    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById("client-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast(this.$t("common.data_is_saving"), {
            title: this.$t("common.please_wait"),
            variant: "info",
          });
          return;
        }
        if (this.id === "new") {
          this.create();
        } else {
          this.update();
        }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast(this.$t("common.form_has_errors"), {
          variant: "danger",
        });
        return false;
      }
    },
    remove: function () {
      let deleteUrl = "crm/client/" + this.id;
      this.true = false;
      this.$http
        .delete(deleteUrl)
        .then((response) => {
          this.loading = false;
          this.$router.push({ name: "shop-clients-list" });
          this.$bvToast.toast(this.$t("clients.client_deleted"), {
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("clients.cant_delete_client"), {
              variant: "danger",
            });
          }
        });
    },
    // image upload
    onReset(evt) {
      evt.preventDefault();
    },
    getClientOrganization(client) {
      return getClientOrganization(client);
    },
    getManagerName(user) {
      return getUserName(user);
    },
    canChangeManager() {
      return this.$store.state.user.access.canSetManagers;
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

legend.col-form-label {
  font-weight: bolder;
}
</style>
