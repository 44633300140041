import t from "@/i18n";

const getRejectCauseTitle = function (rejectCause) {
  if (!rejectCause || !rejectCause.title) {
    return t("common.not_set");
  }
  return rejectCause.title;
};

export { getRejectCauseTitle };
