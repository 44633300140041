import {
  TYPE_ADMIN,
  TYPE_CLIENT,
  TYPE_FINE_MANAGER,
  TYPE_LOGIST,
  TYPE_MANAGER,
  TYPE_SHADOW,
  TYPE_WORKER,
} from "@/modules/users/decorators/users";

export const ru = {
  users: {
    fullname: "ФИО",
    team: "Команда",

    users: "Пользователи",
    user_list: "Перечень пользователей",
    user: "Пользователь",
    add_user: "Добавить пользователя",
    new_user: "Новый пользователь",
    user_update: "Изменение пользователя",
    user_updated: "Пользователь отредактирован",
    cant_create_user: "Не удалось добавить пользователя",
    cant_update_user: "Не удалось отредактировать пользователя",
    cant_delete_user: "Не удалось удалить пользователя",
    user_created: "Пользователь добавлен",
    user_deleted: "Пользователь удален",
    users_deleted: "Пользователи удален",
    delete_user_question: "Вы действительно хотите удалить пользователя?",
    delete_selected_users: "Вы действительно хотите выбранных пользователей?",
    role: "Роль",
    town: "Город",
    search_term: "Имя, Номер, Телефон, Email",
    account: "Учетная запись",
    personal_data: "Личные данные",
    change_password: "Сменить пароль",
    equipment: "Оборудование",
    pests: "Типы вредителей",
    resources: "Материалы",
    internship: "Стажировка",

    novice: "Стажер",
    novice_since: "Дата начала отсчета",
    work_shift_count: "Количество отработанных смен",

    team_head: "Руководитель команды",
    in_team_since: "В команде с ",
    regular_percent_mst: "Обычный процент оплаты (30/50)",

    resource: "Материал",
    resources_at_masters_hand: "Материалы в наличии у мастера",
    resources_h_prompt: "Объем / Количество, мл/г",
    no_materials: "Нет материалов",
    changing_resources: "Изменение материалов",

    settings: "Настройки",
    equipment_fixed_price_mgs:
      "Оборудование с фиксированной суммой (ГХТ и другие)",
    equipment_percent_price_mgs:
      "Оборудование с процентной суммой (Штиль и др.)",
    only_own: "Только свое",
    common: "Общее",

    family: "Фамилия",
    name: "Имя",
    patronymic: "Отчество",
    email: "Электронная почта",
    phone: "Телефон",
    record: "Рекорд",
    current_record: "Текущая планка рекорда",

    pledge: "Депозит",
    pledge_goal: "Текущий депозит",
    pledge_sum: "Накопленный депозит",
    pledging: "Сдача кассы",
    pledging_shop: "Только в магазине",
    pledging_anywhere: "Где угодно",

    voip_settings: "Настройки телефонии",
    mango_number: "Внутренний номер в Mango",
    zadarma_number: "Внутренний номер в Zadarma",

    type: "Тип",
    enabled: "Активен",
    new_password: "Новый пароль",
    can_see_closed_reports: "Может видеть закрытые отчеты",
    can_see_service_sections: "Доступ к служебным разделам",
    project: "Проект",
    id_in_1c: "Id в 1c",
    id_in_telegram: "Id в telegram",
    discount_card: "Дисконтная карта",
    profile_photo: "Фото профиля",
    telegram_connected: "Подключен",

    type_shadow: "Поддержка",
    type_admin: "Администратор",
    type_logist: "Логист",
    type_manager: "Менеджер",
    type_fine_manager: "Контроллер",
    type_worker: "Исполнитель",
    type_client: "Клиент",

    log_in: "Войти",
    log_out: "Выйти",
    login: "Логин",
    login_header: "Вход в систему",
    enter_login: "Введите логин",
    password: "Пароль",
    enter_password: "Введите пароль",
    incorrect_login_or_password: "Неверный логин или пароль",
  },
};
export const en = {
  users: {
    fullname: "Fullname",
    phone: "Phone",
    email: "E-mail",
    team: "Team",

    users: "Users",
    user_list: "User list",
    user: "User",
    add_user: "Add user",
    new_user: "New user",
    change_user: "Change user",
    user_update: "Update user",
    user_updated: "User changed",
    cant_create_user: "Can't add user",
    cant_update_user: "Can't update user",
    cant_delete_user: "Can't delete user",
    user_created: "User added",
    user_deleted: "User deleted",
    users_deleted: "Users deleted",
    delete_user_question: "Do you want to delete the user?",
    delete_selected_users: "Do you want to delete selected user?",
    role: "Role",
    town: "Town",
    search_term: "Name, Number, Phone, Email",
    account: "Account",
    personal_data: "Personal data",
    change_password: "Change password",
    equipment: "Equipment",
    pests: "Pests",
    resources: "Resources",
    internship: "Internship",

    novice: "Novice",
    novice_since: "Novice since",
    work_shift_count: "Work shift count",

    team_head: "Team head",
    in_team_since: "In team since",
    regular_percent_mst: "Regular payment percent (30/50)",

    no_materials: "No material",
    resource: "Resource",
    resources_at_masters_hand: "Resources at masters hand",
    resources_h_prompt: "Volume / Amount, ml/g",
    changing_resources: "Changing resources",

    settings: "Settings",
    equipment_fixed_price_mgs: "Equipment with fixed price",
    equipment_percent_price_mgs: "Equipment with percent price",
    only_own: "Only own",
    common: "Common",

    family: "Family",
    name: "Name",
    patronymic: "Patronymic",
    record: "Record",
    current_record: "Current record",

    pledge: "Pledge",
    pledge_goal: "Current pledge goal",
    pledge_sum: "Current pledge sum",
    pledging: "Pledging",
    pledging_shop: "Only in shop",
    pledging_anywhere: "Anywhere",

    voip_settings: "VoIP settings",
    mango_number: "Internal Mango number",
    zadarma_number: "Internal Zadarma number",

    type: "Type",
    enabled: "Enabled",
    new_password: "New password",
    can_see_closed_reports: "Can see closed reports",
    can_see_service_sections: "Can see service sections",
    project: "Project",
    id_in_1c: "Id in 1c",
    id_in_telegram: "Id in telegram",
    discount_card: "Discount card",
    profile_photo: "Profile photo",
    telegram_connected: "Connected",

    type_shadow: "Support",
    type_admin: "Administrator",
    type_logist: "Logist",
    type_manager: "Manager",
    type_fine_manager: "Fine manager",
    type_worker: "Technician",
    type_client: "Client",

    login: "Login",
    log_in: "Log in",
    log_out: "Log out",
    login_header: "Log in",
    enter_login: "Enter login",
    password: "Password",
    enter_password: "Enter password",
    incorrect_login_or_password: "Incorrect login or password",
  },
};
