<template>
  <div>
    {{ $t("notifications.request_assigned_to_you") }}
    <router-link
      :to="{ name: 'pest-request-view', params: { id: notification.itemId } }"
      class=""
    >
      #{{
        notification.itemId
          ? notification.itemId
          : $t("requests.without_number")
      }}
    </router-link>
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";

export default {
  name: "NotificationUserChangedAssignee",
  components: {},
  props: ["notification"],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    getUserName,
  },
};
</script>

<style lang="scss" scoped></style>
