<template>
  <div class="ibox">
    <div class="ibox-title">{{ $t("logs.changes_history") }}</div>
    <div class="ibox-content">
      <div class="search">
        <div class="d-flex flex-wrap align-items-center">
          <div class="mr-2">
            <b-form-group :label="$t('search.date_from')">
              <datepicker
                v-model="dateFrom"
                input-class="form-control"
                :editable="true"
                lang="ru"
                :format="dateFormat"
                type="date"
                value-type="format"
                @change="fetchData"
              ></datepicker>
            </b-form-group>
          </div>

          <div class="mr-2">
            <b-form-group :label="$t('search.date_to')">
              <datepicker
                v-model="dateTo"
                input-class="form-control"
                :editable="true"
                lang="ru"
                :format="dateFormat"
                type="date"
                value-type="format"
                @change="fetchData"
              ></datepicker>
            </b-form-group>
          </div>

          <div class="mr-2">
            <b-form-group :label="$t('requests.number')">
              <b-form-input v-model="requestNumber" tabindex="1"></b-form-input>
            </b-form-group>
          </div>

          <div class="mr-2">
            <b-form-group :label="$t('logs.event')">
              <b-form-select
                v-model="type"
                :options="typeOptions"
                tabindex="1"
              ></b-form-select>
            </b-form-group>
          </div>

          <div class="mr-2">
            <b-form-group :label="$t('users.user')">
              <user-autocomplete
                v-model="userId"
                :default-value="userId ? getAssigneeName(user, true) : ''"
                @selected="selectUser"
              />
            </b-form-group>
          </div>

          <div class="align-self-center mt-2 mr-2">
            <button class="btn btn-primary mr-2" @click.prevent="fetchData">
              <font-awesome-icon icon="search" />&nbsp;{{ $t("search.find") }}
            </button>

            <button
              class="btn btn-outline-primary mr-2"
              @click.prevent="resetFilter"
            >
              {{ $t("search.reset") }}
            </button>
          </div>
        </div>
      </div>

      <div v-if="!logs.length && !loading">{{ $t("logs.no_record_msg") }}</div>
      <div v-else class="table-responsive">
        <b-overlay :show="loading" rounded="sm">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="col-1">{{ $t("requests.time") }}</th>
                <th class="col-1">{{ $t("requests.number") }}</th>
                <th class="col-2">{{ $t("users.user") }}</th>
                <th class="col-3">{{ $t("logs.event") }}</th>
                <th class=" ">{{ $t("common.comment") }}</th>
              </tr>
            </thead>
            <tbody>
              <request-log
                v-for="(log, index) in logs"
                :key="index"
                :show-request="true"
                :log="log"
              />
            </tbody>
          </table>
        </b-overlay>

        <b-pagination-nav
          v-if="pagination.count > 1"
          :link-gen="linkGen"
          :value="pagination.page"
          :number-of-pages="pagination.count"
          limit="10"
          use-router
          class="mt-2"
        >
        </b-pagination-nav>
      </div>
    </div>
  </div>
</template>

<script>
import RequestLog from "@/modules/pest/views/logs/RequestLog";
import DatePicker from "vue2-datepicker";
import { getAssigneeName } from "@/modules/crm/common.js";
import UserAutocomplete from "@/modules/users/views/user/UserAutocomplete.vue";
import { logOptions } from "@/modules/pest/decorators/logs.js";

export default {
  name: "LogsList",
  components: {
    UserAutocomplete,
    RequestLog,
    Datepicker: DatePicker,
    // 'log-list': LogList,
  },
  props: [],
  data() {
    return {
      dateFormat: "DD.MM.YYYY",
      dateFrom: "",
      dateTo: "",
      userId: null,
      user: null,
      requestNumber: "",
      type: null,
      logs: [],
      loading: false,
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
    };
  },
  computed: {
    typeOptions() {
      return [{ value: null, text: this.$t("common.all") }, ...logOptions()];
    },
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created() {
    this.checkUrl();
  },
  beforeDestroy() {},
  methods: {
    getAssigneeName,
    checkUrl: function () {
      this.fetchData();
    },
    fetchData: function () {
      let itemsUrl = "/pest/logs";
      let params = {
        page: this.page,
        userId: this.userId,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        type: this.type,
        requestNumber: this.requestNumber,
      };
      this.loading = true;
      this.$http
        .get(itemsUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.logs = response.data;
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    linkGen(pageNum) {
      return {
        name: "request-log-all-list",
        params: { page: pageNum },
      };
    },
    selectUser(data) {
      if (data && data.value) {
        this.userId = data.value.id;
        this.user = data.value;
      } else {
        this.userId = null;
        this.user = {};
      }
      this.fetchData();
    },
    resetFilter() {
      this.dateFrom = "";
      this.dateTo = "";
      this.userId = null;
      this.requestNumber = "";
    },
  },
};
</script>

<style scoped>
.log-list {
  max-height: 300px;
  overflow-y: auto;
}
</style>
