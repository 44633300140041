<template>
  <b-form
    id="pest-equipment-form"
    ref="equipment"
    :model="equipment"
    class="pest-equipment-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="div">
      <b-form-group :label="$t('common.title')" prop="title">
        <b-form-input
          v-model="equipment.title"
          :placeholder="$t('common.title')"
          tabindex="1"
        ></b-form-input>
      </b-form-group>

      <b-form-group :label="$t('equipment.payment_type')">
        <b-form-radio-group
          v-model="equipment.paymentType"
          :options="paymentTypesOptions"
          placeholder="100"
          tabindex="1"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group
        v-if="equipment.paymentType === EQUIPMENT_PAYMENT_PERCENT"
        :label="$t('equipment.percent_price')"
        prop="unit"
      >
        <b-form-input
          v-model="equipment.percent"
          placeholder=""
          tabindex="1"
        ></b-form-input>
      </b-form-group>

      <b-form-group v-else :label="$t('equipment.fixed_price')">
        <b-form-input
          v-model="equipment.price"
          placeholder="100"
          tabindex="1"
        ></b-form-input>
      </b-form-group>

      <b-form-group :label="$t('common.description')" prop="title">
        <b-form-textarea
          v-model="equipment.comment"
          :placeholder="$t('common.description')"
          tabindex="1"
        ></b-form-textarea>
      </b-form-group>

      <div class="row">
        <div class="col-6">
          <b-form-group :label="$t('pests.pests')" prop="title">
            <div v-if="!pestsList.length">
              <font-awesome-icon icon="rotate" spin />
              {{ $t("common.loading") }}
            </div>
            <b-form-checkbox-group
              v-else
              v-model="equipment.pests"
              :options="pestsList"
              stacked
              tabindex="1"
            ></b-form-checkbox-group>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group :label="$t('facilities.facilities')" prop="title">
            <div v-if="!facilitiesList.length">
              <font-awesome-icon icon="rotate" spin />
              {{ $t("common.loading") }}
            </div>
            <b-form-checkbox-group
              v-else
              v-model="equipment.facilities"
              :options="facilitiesList"
              stacked
              tabindex="1"
            ></b-form-checkbox-group>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-button variant="primary" @click="createOrUpdate">
            <font-awesome-icon icon="check" />
            {{ $t("common.save") }}
          </b-button>

          <a
            v-if="equipment.id !== 'new'"
            href=""
            class="btn btn-outline btn-danger ml-2"
            @click.prevent="deleteEquipment"
          >
            <font-awesome-icon icon="trash" />
            {{ $t("common.delete") }}
          </a>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import { VueMaskDirective } from "v-mask";
import { getPestTitle } from "@/modules/pest/decorators/pests";
import {
  EQUIPMENT_PAYMENT_FIXED,
  EQUIPMENT_PAYMENT_PERCENT,
} from "@/modules/pest/decorators/equipments";
import { getFacilityTitle } from "@/modules/pest/decorators/facilities";

export default {
  name: "PestEquipmentForm",
  directives: { mask: VueMaskDirective },
  components: {},
  props: ["equipment"],
  data() {
    return {
      EQUIPMENT_PAYMENT_PERCENT,
      phoneMask: false,
      loading: false,
      pestsList: [],
      facilitiesList: [],
      paymentTypesOptions: [
        {
          text: this.$t("equipment.fixed_price"),
          value: EQUIPMENT_PAYMENT_FIXED,
        },
        {
          text: this.$t("equipment.percent_price"),
          value: EQUIPMENT_PAYMENT_PERCENT,
        },
      ],
    };
  },
  computed: {
    id() {
      return this.equipment.id;
    },
    name() {
      let name = this.equipment.name
        ? this.equipment.name
        : this.$t("equipment.new_equipment");
      return name ? name : "equipment";
    },
  },
  watch: {},
  created: function () {
    this.fetchPests();
    this.fetchFacilities();
  },
  methods: {
    getUserName,
    update: function () {
      let updateUrl;
      updateUrl = "/pest/equipments/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.equipment)
        .then((response) => {
          this.equipment = response.data;
          this.loading = false;
          this.$eventHub.$emit("update-pest-equipment-info", this.equipment);
          // this.$router.push({name: 'pest-equipment-view', params: {id: this.equipment.id}});
          this.$bvToast.toast(this.$t("common.data_saved"), {
            title: this.$t("equipment.equipment_updated"),
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: this.$t("common.error_during_saving"),
              type: "danger",
            });
          }
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "/pest/equipments";
      this.loading = true;
      this.$http
        .post(updateUrl, this.equipment)
        .then((response) => {
          this.equipment = response.data;
          this.$eventHub.$emit("update-pest-equipment-info", this.equipment);
          this.$router.push({
            name: "pest-equipment-update",
            params: { id: this.equipment.id },
          });
          this.$bvToast.toast(this.$t("equipment.equipment_added"), {
            title: this.$t("equipment.equipment_added"),
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.error"), {
              title: this.$t("equipment.cant_create_equipment"),
              variant: "danger",
            });
          }
        });
    },
    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById("pest-equipment-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast(this.$t("common.data_is_saving"), {
            title: this.$t("common.please_wait"),
            variant: "info",
          });
          return;
        }
        if (this.id === "new") {
          this.create();
        } else {
          this.update();
        }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast(this.$t("common.form_has_errors"), {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
    deleteEquipment() {
      if (confirm(this.$t("equipment.delete_equipment_question"))) {
        let deleteUrl = "/pest/equipments/" + this.equipment.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            this.loading = false;
            this.$router.push({ name: "pest-equipment-list" });
            this.$bvToast.toast(this.$t("equipment.equipment_deleted"), {
              title: this.$t("equipment.equipment_deleted"),
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast(this.$t("common.error"), {
                title: this.$t("equipment.cant_delete_equipment"),
                variant: "danger",
              });
            }
          });
      }
    },

    fetchPests: function () {
      this.pestsList = [];
      let pestsUrl = "/pest/pests";
      let params = {
        all: true,
      };
      this.$http
        .get(pestsUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.pestsList.push({
              text: getPestTitle(item),
              value: item.id,
              // checked: false,
            });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    fetchFacilities: function () {
      this.facilitiesList = [];
      let facilitiesUrl = "/pest/facilities";
      let params = {
        all: true,
      };
      this.$http
        .get(facilitiesUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.facilitiesList.push({
              text: getFacilityTitle(item),
              value: item.id,
              // checked: false,
            });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}
</style>
