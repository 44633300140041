import { getStatusTitle } from "@/modules/crm/decorators/status";
import { getUserName } from "@/modules/crm/common";
import dayjs from "dayjs";
import { getClientName } from "@/modules/crm/decorators/client";
import t from "@/i18n";

const STATUS_NEW = 0;
const STATUS_DISPATCHED = 1;
const STATUS_PROGRESS = 2;
const STATUS_DONE = 9;
const STATUS_DECLINE = -1;
const STATUS_UNKNOWN = -999;

const statusTitles = {
  STATUS_NEW: "Новый",
  STATUS_DISPATCHED: "Распределенный",
  STATUS_PROGRESS: "В работе",
  STATUS_DONE: "Завершен",
  STATUS_DECLINE: "Отклонен",
  STATUS_UNKNOWN: "[Не проставлен]",
};

const getLeadStatusTitle = function (lead) {
  if (!lead.status) {
    return t("common.not_set");
  }
  return getStatusTitle(lead.status);
};

const getLeadTitle = function (lead) {
  if (!lead) {
    return t("common.not_set");
  }
  if (!lead.title) {
    return "[Без названия]";
  }
  return lead.title;
};

const getLeadManagerName = function (lead) {
  return getUserName(lead.manager);
};
const getLeadStatus = function (lead) {
  if (!lead.status) {
    return t("common.not_set");
  }
  return getStatusTitle(lead.status);
};

const getLeadClientName = function (lead) {
  if (!lead.client) {
    return t("common.not_set");
  }
  return getClientName(lead.client);
};
const getLeadCreateTime = function (lead) {
  return dayjs.unix(lead.timeCreated).format("DD.MM.YYYY");
};
const getLeadDeadline = function (lead) {
  return lead.deadline ? dayjs(lead.deadline).format("DD.MM.YYYY") : "";
};
const getLeadUpdateTime = function (lead) {
  return dayjs.unix(lead.timeUpdated).format("DD.MM.YYYY");
};
const getDecisionTime = function (lead) {
  return dayjs.unix(lead.decisionTime).format("DD.MM.YYYY HH:mm:ss");
};

export {
  getLeadStatusTitle,
  getLeadTitle,
  getLeadManagerName,
  getLeadStatus,
  getLeadClientName,
  getLeadCreateTime,
  getLeadDeadline,
  getLeadUpdateTime,
  getDecisionTime,
  STATUS_NEW,
  STATUS_DISPATCHED,
  STATUS_PROGRESS,
  STATUS_DONE,
  STATUS_DECLINE,
};
