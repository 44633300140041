<template>
  <b-modal
    id="modal-assignee"
    ref="modal"
    :title="title"
    size="lg"
    :cancel-title="$t('common.cancel')"
    :ok-disabled="true"
    :ok-title="$t('common.save')"
    title-tag="h3"
    @ok="handleTaskModalOk"
  >
    <div class="row mb-2 btn-row">
      <div class="col-md-3">
        <button
          class="btn btn-block"
          :class="{
            'btn-primary': isActive(false),
            'btn-outline-primary': !isActive(false),
          }"
          @click.prevent="selectAssignee(false)"
        >
          {{ $t("requests.assignee_not_set") }}
        </button>
      </div>

      <div class="col-md-3"></div>
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <button
          v-if="!showOther"
          class="btn btn-block"
          :class="{
            'btn-primary': showOther,
            'btn-outline-primary': !showOther,
          }"
          @click.prevent="openSearch"
        >
          {{ $t("requests.choose_another") }}
        </button>
        <div :class="{ 'd-none': !showOther }">
          <user-autocomplete
            ref="assigneeSearch"
            default-value=""
            :type="TYPE_WORKER"
            :town-id="townId"
            :hide-reset="true"
            @selected="selectAssigneeFromAutocomplete"
          ></user-autocomplete>
        </div>
      </div>
    </div>
    <hr />
    <div class="row">
      <div
        v-for="(group, gIndex) in processedAssignees"
        :key="'col' + gIndex"
        class="col-md-3"
      >
        <ul>
          <li
            v-for="(assignee, index) in group"
            :key="index"
            :class="{ active: isActive(assignee) }"
            @click.prevent="selectAssignee(assignee)"
          >
            {{ getAssigneeName(assignee) }}
          </li>
        </ul>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { TYPE_WORKER } from "@/modules/users/decorators/users";
import { getAssigneeName } from "@/modules/crm/common";
import {
  getRequestDatetime,
  getRequestTimeHours,
  getRequestTitle,
} from "@/modules/pest/decorators/requests.js";
import UserAutocomplete from "../../../../../users/views/user/UserAutocomplete.vue";
import { isTimeSlotUserBusy } from "@/modules/pest/helpers/dispatching/timeslots";

export default {
  name: "RequestAssigneePopup",
  components: { UserAutocomplete },
  props: {
    assignees: {
      type: Array,
      default: () => [],
    },
    townId: {
      type: Number,
      default: null,
    },
    requests: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      TYPE_WORKER,
      showOther: false,
      request: {},
    };
  },
  computed: {
    processedAssignees() {
      let all = [];
      let chunkSize = Math.floor(this.assignees.length / 4);
      let remainder = this.assignees.length - chunkSize * 4;
      if (remainder > 0) {
        chunkSize++;
      }
      for (let i = 0; i < this.assignees.length; i += chunkSize) {
        const chunk = this.assignees.slice(i, i + chunkSize);
        all.push(chunk);
      }
      return all;
    },
    title() {
      if (!this.request) {
        return "Смена исполнителя";
      }
      return (
        this.getRequestTitle(this.request) +
        " – " +
        this.getRequestDatetime(this.request)
      );
    },
  },
  watch: {},
  created() {
    this.$eventHub.$on("assignee-popup-show", this.openPopup);
  },
  beforeDestroy() {
    // this.$eventHub.$off('assignee-popup-show');
  },
  methods: {
    getRequestTitle,
    getRequestDatetime,
    getAssigneeName,
    isTimeSlotUserBusy,
    handleSuccess(assignee) {
      this.$bvModal.hide("modal-assignee");
      if (this.successHandler) {
        this.successHandler(assignee);
      }
      this.successHandler = false;
      this.data = {};
    },
    handleTaskModalOk(bvModalEvt) {
      // this.$refs.requestForm.createOrUpdate(bvModalEvt, this.handleSuccess);
    },
    selectAssigneeFromAutocomplete({ value }) {
      this.selectAssignee(value);
    },
    selectAssignee(assignee) {
      this.handleSuccess(assignee);
    },
    openPopup(info) {
      this.showOther = false;
      this.request = info.request;
      this.successHandler = info.success;
      this.cancelHandler = info.cancel;
      this.$bvModal.show("modal-assignee");
    },
    isActive(assignee) {
      if (!assignee) {
        return !this.request.assigneeId;
      }
      return assignee.id === this.request.assigneeId;
    },
    openSearch() {
      this.showOther = true;
      this.$nextTick(() => {
        this.$refs.assigneeSearch.focus();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.update-duration-modal {
  position: absolute;
  //top: 10px;
  //left: 10px;
  border: 1px solid black;
  z-index: 10000;
  padding: 50px;
  background: #ffffff;
}

ul {
  list-style: none;
  padding: 0;
  font-size: larger;
}

ul li {
  cursor: pointer;
  padding: 5px 10px;
}

ul li:hover {
  background: rgba(215, 212, 212, 0.5);
}

li.active {
  background: lightblue;
}

li.busy {
  opacity: 0.3;
  cursor: not-allowed;
}

.btn-row {
  height: 36px !important; // fix different size of button and autocomplete
}
</style>
