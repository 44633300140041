import {
  CALL_TO_CLIENT,
  CONVERTED_TO_CONTRACT,
  DEPOSIT_CREATED,
  DUPLICATE_LEAD,
  DUPLICATE_LEAD_ASSIGNED,
  EMPLOYEE_UNBLOCKED,
  LEAD_AUTO_INITIAL_CALL_ANSWERED,
  LEAD_AUTO_INITIAL_CALL_NOT_ANSWERED,
  LEAD_AUTO_INITIAL_CALL_QUEUED,
  LEAD_AUTO_RECURRING_CALL_ANSWERED,
  LEAD_AUTO_RECURRING_CALL_NOT_ANSWERED,
  LEAD_AUTO_RECURRING_CALL_QUEUED,
  LEAD_AUTO_RECURRING_CALL_STOPPED,
  LEAD_CHANGED_STATUS,
  LEAD_CREATED_MANUALLY,
  MANGO_CALL_RECORD,
  NOTIFICATION_CALL_ADDED,
  NOTIFICATION_CALL_DELETED,
  PAYMENT_CREATED,
  REQUEST_ACCESS_APPARTMENT_FAILURE,
  REQUEST_ACCESS_APPARTMENT_SUCCESS,
  REQUEST_AFTERCALL,
  REQUEST_APPARTMENT_HIDE_FROM_ASSIGNEE,
  REQUEST_APPARTMENT_SHOW_TO_ASSIGNEE,
  REQUEST_BITRIX_CHANGED_ASSIGNEE,
  REQUEST_BITRIX_CHANGED_DATE,
  REQUEST_BITRIX_CHANGED_STATUS,
  REQUEST_CHANGED_ASSIGNEE,
  REQUEST_CHANGED_DATE,
  REQUEST_CHANGED_FIELDS,
  REQUEST_CHANGED_MANAGER,
  REQUEST_CHANGED_STATUS,
  REQUEST_CORRECTED,
  REQUEST_CREATED_DUE_IMPORT,
  REQUEST_CREATED_FROM_ROISTAT,
  REQUEST_CREATED_MANUALLY,
  REQUEST_DECLINED,
  REQUEST_DONE,
  REQUEST_NEW_RECORD,
  REQUEST_OPEN,
  REQUEST_ORDER_CREATED,
  REQUEST_REANIMATED,
  REQUEST_REJECTED,
  REQUEST_RETURN_VISIT,
  REQUEST_YANDEX_CONVERSION_SEND,
  REQUEST_YANDEX_CONVERSION_SKIPPED,
  USER_CHANGE_PASSWORD,
  USER_COORDINATES,
  USER_LOGIN_ERROR,
  USER_LOGIN_OK,
  USER_LOGOUT,
  ZADARMA_CALL_RECORD,
} from "@/modules/pest/decorators/logs";
import {
  CHANGE_MANAGER_CAUSE_CHANGING_STATUS,
  CHANGE_MANAGER_CAUSE_LOADING_FROM_BITRIX,
  CHANGE_MANAGER_CAUSE_LOADING_FROM_LOGS,
  CHANGE_MANAGER_CAUSE_LOADING_FROM_LOGS_FIX,
  CHANGE_MANAGER_CAUSE_OPENING_CALL,
  CHANGE_MANAGER_CAUSE_UPDATING_REQUEST,
  CHANGE_MANAGER_IMPORT_DUPLICATE,
} from "@/modules/pest/decorators/logsChangeManager";

export const ru = {
  logs: {
    changes_history: "История событий",
    no_record_msg: "Еще нет записей",
    event: "Событие",
    // no_record_msg: "Время",
    // no_record_msg: "Заявка",
    // no_record_msg: "Пользователь",
    // no_record_msg: "Событие",
    // no_record_msg: "Описание",
    [REQUEST_OPEN]: "Просмотр заявки",
    [REQUEST_CHANGED_ASSIGNEE]: "Смена мастера",
    [REQUEST_CHANGED_MANAGER]: "Смена менеджера",
    [REQUEST_CHANGED_DATE]: "Смена даты",
    [REQUEST_CHANGED_STATUS]: "Смена статуса",
    [REQUEST_BITRIX_CHANGED_ASSIGNEE]: "Смена мастера из битрикса",
    [REQUEST_BITRIX_CHANGED_DATE]: "Смена даты из битрикса",
    [REQUEST_BITRIX_CHANGED_STATUS]: "Смена статуса из битрикса",
    [REQUEST_DONE]: "Закрытие заявки",
    [REQUEST_DECLINED]: "Отклонение заявки",
    [REQUEST_CREATED_MANUALLY]: "Создана новая заявка вручную",
    [REQUEST_CHANGED_FIELDS]: "Заявка изменена",
    [REQUEST_CREATED_DUE_IMPORT]: "Загружена новая заявка из битрикса",
    [REQUEST_CREATED_FROM_ROISTAT]: "Новая заявка из Roistat",
    [CALL_TO_CLIENT]: "Звонок клиенту",
    [ZADARMA_CALL_RECORD]: "Звонок клиенту",
    [MANGO_CALL_RECORD]: "Звонок c клиентом",
    [REQUEST_CORRECTED]: "Заявка скорректирована",
    [LEAD_CREATED_MANUALLY]: "Лид добавлен менеджером",
    [LEAD_CHANGED_STATUS]: "Смена статуса лида",
    [REQUEST_NEW_RECORD]: "Новый рекорд",
    [CONVERTED_TO_CONTRACT]: "Оформление контракта",
    [DUPLICATE_LEAD]: "Повторное обращение по лиду",
    [DUPLICATE_LEAD_ASSIGNED]: "Привязано повторное обращение по лиду",
    [REQUEST_RETURN_VISIT]: "Оформлена повторная заявка",

    [EMPLOYEE_UNBLOCKED]: "Мастер разблокирован",
    [REQUEST_ACCESS_APPARTMENT_SUCCESS]: "Мастер посмотрел квартиру",
    [REQUEST_ACCESS_APPARTMENT_FAILURE]: "Квартира недоступна для просмотра",
    [REQUEST_APPARTMENT_SHOW_TO_ASSIGNEE]: "Квартира открыта для мастера",
    [REQUEST_APPARTMENT_HIDE_FROM_ASSIGNEE]: "Квартира скрыта для мастера",
    [USER_COORDINATES]: "Координаты мастера",
    [DEPOSIT_CREATED]: "Внесена касса",
    [PAYMENT_CREATED]: "Зарегистрирован платеж",

    [LEAD_AUTO_INITIAL_CALL_QUEUED]:
      "Первичный звонок по заявке поставлен в очередь",
    [LEAD_AUTO_INITIAL_CALL_ANSWERED]: "Клиент ответил на звонок",
    [LEAD_AUTO_INITIAL_CALL_NOT_ANSWERED]: "Нет ответа на звонок",
    [LEAD_AUTO_RECURRING_CALL_QUEUED]:
      "Повторный звонок по заявке поставлен в очередь",
    [LEAD_AUTO_RECURRING_CALL_ANSWERED]: "Клиент ответил на повторный звонок",
    [LEAD_AUTO_RECURRING_CALL_NOT_ANSWERED]: "Нет ответа на повторный звонок",
    [LEAD_AUTO_RECURRING_CALL_STOPPED]: "Повторные звонки прекращены",

    [NOTIFICATION_CALL_ADDED]: "Назначено напоминание о звонке",
    [NOTIFICATION_CALL_DELETED]: "Удалено напоминание о звонке",

    [REQUEST_AFTERCALL]: "Внесен исход прозвона",
    [REQUEST_REJECTED]: "Заявка провалена",
    [REQUEST_REANIMATED]: "Сделка реанимирована",
    [REQUEST_ORDER_CREATED]: "Заказ оформлен",
    [REQUEST_YANDEX_CONVERSION_SEND]: "Отправлен результат оффлайн-конверсии",
    [REQUEST_YANDEX_CONVERSION_SKIPPED]:
      "Результат оффлайн-конверсии не будет отправляться",

    [USER_CHANGE_PASSWORD]: "Смена пароля пользователя",
    [USER_LOGIN_OK]: "Вход пользователя в систему",
    [USER_LOGIN_ERROR]: "Неудачный вход пользователя в систему",
    [USER_LOGOUT]: "Выход пользователя из системы",

    [CHANGE_MANAGER_IMPORT_DUPLICATE]: "Загружен дубль из ройстата",
    [CHANGE_MANAGER_CAUSE_OPENING_CALL]: "Открытие заявки",
    [CHANGE_MANAGER_CAUSE_CHANGING_STATUS]: "Смена статуса",
    [CHANGE_MANAGER_CAUSE_LOADING_FROM_BITRIX]: "Загружено из битрикса",
    [CHANGE_MANAGER_CAUSE_UPDATING_REQUEST]: "Редактирование вручную",
    [CHANGE_MANAGER_CAUSE_LOADING_FROM_LOGS]: "Проставлено из логов",
    [CHANGE_MANAGER_CAUSE_LOADING_FROM_LOGS_FIX]:
      "Проставлено из логов (итерация 2)",

    hide: "Скрыть",
    shop: "Показать подробности",
    ids: "Идентификаторы",

    record_was_reset: "Рекорд был сброшен",
    record_compare_sum: "Сумма, которую надо превысить",
    request_opened_by_call_cause: "Открыто по звонку",
    request_opened_duplicate_cause: "Дубликат. Открыли заявку",
    request_opened_instead_of_duplicate_cause: "Открыто вместо дубля",

    cause_stage_deal: "Статус: Оформлен",
    cause_manual: "Вручную",
  },
};
export const en = {
  logs: {
    changes_history: "Changes history",
    no_record_msg: "No records yet",
    event: "Event",

    [REQUEST_OPEN]: "Request open",
    [REQUEST_CHANGED_ASSIGNEE]: "Request changed assignee",
    [REQUEST_CHANGED_MANAGER]: "Request changed manager",
    [REQUEST_CHANGED_DATE]: "Request changed date",
    [REQUEST_CHANGED_STATUS]: "Request changed status",
    [REQUEST_BITRIX_CHANGED_ASSIGNEE]: "Request changed assignee from bitrix",
    [REQUEST_BITRIX_CHANGED_DATE]: "Request changed date from bitrix",
    [REQUEST_BITRIX_CHANGED_STATUS]: "Request changed status from bitrix",
    [REQUEST_DONE]: "Request done",
    [REQUEST_DECLINED]: "Request declined",
    [REQUEST_CREATED_MANUALLY]: "Request created manually",
    [REQUEST_CHANGED_FIELDS]: "Request changed fields",
    [REQUEST_CREATED_DUE_IMPORT]: "Request created due import",
    [REQUEST_CREATED_FROM_ROISTAT]: "Request created from roistat",
    [CALL_TO_CLIENT]: "Call to client",
    [ZADARMA_CALL_RECORD]: "Zadarma call record",
    [MANGO_CALL_RECORD]: "Mango call record",
    [REQUEST_CORRECTED]: "Request corrected",
    [LEAD_CREATED_MANUALLY]: "Lead created manually",
    [LEAD_CHANGED_STATUS]: "Lead changed status",
    [REQUEST_NEW_RECORD]: "Request new record",
    [CONVERTED_TO_CONTRACT]: "Converted to contract",
    [DUPLICATE_LEAD]: "Duplicate lead",
    [DUPLICATE_LEAD_ASSIGNED]: "Duplicate lead assigned",
    [REQUEST_RETURN_VISIT]: "Request return visit",

    [EMPLOYEE_UNBLOCKED]: "Employee unblocked",
    [REQUEST_ACCESS_APPARTMENT_SUCCESS]: "Request access appartment success",
    [REQUEST_ACCESS_APPARTMENT_FAILURE]: "Request access appartment failure",
    [REQUEST_APPARTMENT_SHOW_TO_ASSIGNEE]:
      "Request appartment show to assignee",
    [REQUEST_APPARTMENT_HIDE_FROM_ASSIGNEE]:
      "Request appartment hide from assignee",
    [USER_COORDINATES]: "User coordinates",
    [DEPOSIT_CREATED]: "Deposit created",
    [PAYMENT_CREATED]: "Payment created",

    [LEAD_AUTO_INITIAL_CALL_QUEUED]: "Lead auto initial call queued",
    [LEAD_AUTO_INITIAL_CALL_ANSWERED]: "Lead auto initial call answered",
    [LEAD_AUTO_INITIAL_CALL_NOT_ANSWERED]:
      "Lead auto initial call not answered",
    [LEAD_AUTO_RECURRING_CALL_QUEUED]: "Lead auto recurring call queued",
    [LEAD_AUTO_RECURRING_CALL_ANSWERED]: "Lead auto recurring call answered",
    [LEAD_AUTO_RECURRING_CALL_NOT_ANSWERED]:
      "Lead auto recurring call not answered",
    [LEAD_AUTO_RECURRING_CALL_STOPPED]: "Lead auto recurring call stopped",

    [NOTIFICATION_CALL_ADDED]: "Notification call added",
    [NOTIFICATION_CALL_DELETED]: "Notification call deleted",

    [REQUEST_AFTERCALL]: "Request aftercall",
    [REQUEST_REJECTED]: "Request rejected",
    [REQUEST_REANIMATED]: "Request reanimated",
    [REQUEST_ORDER_CREATED]: "Request order created",
    [REQUEST_YANDEX_CONVERSION_SEND]: "Request yandex conversion send",
    [REQUEST_YANDEX_CONVERSION_SKIPPED]: "Request yandex conversion skipped",

    [USER_CHANGE_PASSWORD]: "User change password",
    [USER_LOGIN_OK]: "User login ok",
    [USER_LOGIN_ERROR]: "User login error",
    [USER_LOGOUT]: "User logout",

    [CHANGE_MANAGER_IMPORT_DUPLICATE]: "Roistat duplicate",
    [CHANGE_MANAGER_CAUSE_OPENING_CALL]: "Opening call",
    [CHANGE_MANAGER_CAUSE_CHANGING_STATUS]: "Changing status",
    [CHANGE_MANAGER_CAUSE_LOADING_FROM_BITRIX]: "Loading from bitrix",
    [CHANGE_MANAGER_CAUSE_UPDATING_REQUEST]: "Updating request",
    [CHANGE_MANAGER_CAUSE_LOADING_FROM_LOGS]: "Loading from logs",
    [CHANGE_MANAGER_CAUSE_LOADING_FROM_LOGS_FIX]: "Loading from logs (fix 2)",

    hide: "Hide",
    show: "Show",
    ids: "Ids",
    record_was_reset: "Record was reset",
    record_compare_sum: "Record sum to overtake",
    request_opened_by_call_cause: "opened_by_call",
    request_opened_duplicate_cause: "Duplicate. Request opened",
    request_opened_instead_of_duplicate_cause: "Opened instead of duplicate",

    cause_stage_deal: "Status: Ordered",
    cause_manual: "Manual",
  },
};
