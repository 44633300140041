<template>
  <div class="ibox">
    <div class="ibox-title" :class="{ editing: editing }">
      <div class="search">
        <div class="d-flex align-items-center">
          <h2 class="mr-2">{{ $t("schedule.schedule") }}</h2>
          <div class="mr-2">
            <town-filter
              :value="search.townId"
              @change="selectTown"
            ></town-filter>
            <!--            <b-form-select v-model="search.townId" :options="townList" tabindex="1"></b-form-select>-->
          </div>
          <div class="mr-2">
            <date-picker
              v-model="search.date"
              @change="fetchData"
            ></date-picker>
          </div>

          <div>
            <button class="btn btn-outline-primary">
              <font-awesome-icon
                icon="rotate"
                :spin="loading"
                @click.prevent="fetchData"
              />
            </button>
          </div>

          <div v-if="canEditSchedule" class="align-self-center ml-auto">
            <button
              v-if="editing"
              class="btn btn-danger ml-1"
              @click="toggleEdit"
            >
              <font-awesome-icon icon="exclamation-triangle" />&nbsp;{{
                $t("common.updating_enabled")
              }}
            </button>
            <button
              v-if="!editing"
              class="btn btn-outline-primary ml-1"
              @click="toggleEdit"
            >
              <font-awesome-icon icon="check" />&nbsp;
              {{ $t("common.updating_disabled") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="ibox-content pl-0 pr-0" :class="{ editing: editing }">
      <div v-if="loaded" class="table-div">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Время</th>
              <th
                v-for="(info, aIndex) in schedule"
                :key="'a' + aIndex"
                :class="userDutyClass(info)"
              >
                {{ getAssigneeName(info.assignee, false) }}
              </th>
            </tr>
            <tr>
              <th></th>
              <th
                v-for="(info, aIndex) in schedule"
                :key="'a' + aIndex"
                class="shortTimeInfo"
              >
                <div
                  class="evenness_wrapper d-flex"
                  :class="getEvennessClass(info)"
                >
                  <a
                    class="evenness_odd"
                    @click.prevent="setEvennessClass(info, 'odd')"
                  >
                    <font-awesome-icon icon="arrow-down" />
                  </a>
                  <a
                    class="evenness_even"
                    @click.prevent="setEvennessClass(info, 'even')"
                  >
                    <font-awesome-icon icon="arrow-down" />
                  </a>
                </div>
                <div
                  v-for="(time, tIndex) in mainSlots"
                  :key="'t' + tIndex"
                  class="time"
                  :class="timeSlotClass(info, time)"
                  @click="toggleTime(info, time + ':00', info.assignee.id)"
                >
                  {{ time }}
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(timeSlot, tIndex) in slots"
              :key="'t' + tIndex"
              :class="{ highlighted: highlightedSlots.includes(timeSlot) }"
            >
              <th class="timeCell">
                {{ timeSlot }}
              </th>
              <td
                v-for="(info, dIndex) in schedule"
                :key="'h' + dIndex"
                :class="getCellClass(info, timeSlot)"
                @click="toggleTime(info, timeSlot, info.assignee.id)"
              >
                <div
                  v-if="
                    info.slots[timeSlot].status && info.slots[timeSlot].start
                  "
                >
                  <router-link
                    :to="{
                      name: 'pest-request-view',
                      params: { id: info.slots[timeSlot].request.id },
                    }"
                    target="_blank"
                  >
                    {{ timeSlot }}
                    <small>
                      {{ info.slots[timeSlot].request.number }}
                    </small>
                  </router-link>
                </div>
                <div v-else class="time">
                  {{ timeSlot }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-else class="p-2">{{ $t("common.loading") }}</div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getAssigneeName, getUserName } from "@/modules/crm/common";
import DatePicker from "@/components/shared/DatePicker.vue";
import {
  SCHEDULE_ACTIVE_ACTIVE,
  SCHEDULE_ACTIVE_DUTY_1,
  SCHEDULE_ACTIVE_DUTY_2,
  SCHEDULE_ACTIVE_NONE,
  SCHEDULE_ACTIVE_UNAVAILABLE,
} from "@/modules/pest/decorators/schedule";
import { MSK, SPB, DXB } from "@/modules/pest/decorators/towns";
import { canEditDaySchedule } from "@/modules/users/access/common.js";
import TownFilter from "@/components/shared/TownFilter.vue";

export default {
  name: "AssigneeSchedule",
  components: {
    TownFilter,
    DatePicker,
  },
  data() {
    return {
      MSK,
      SPB,
      DXB,
      loading: false,
      timer: false,
      loaded: false,
      editing: false,
      schedule: {},
      search: {
        dateFrom: "",
        townId: 2,
        dateTo: "",
      },
      dateFormat: "DD.MM.YYYY",
      townList: [],
      dates: [],
      datesLastStatus: [],
      assigneeLastStatus: [],
      mainSlots: [
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "00",
        "01",
        "02",
        "03",
        "04",
      ],
      highlightedSlots: [
        "08:00",
        "10:00",
        "12:00",
        "14:00",
        "16:00",
        "18:00",
        "20:00",
        "22:00",
      ],
      slots: [
        "05:00",
        "05:30",
        "06:00",
        "06:30",
        "07:00",
        "07:30",
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00",
        "23:30",
        "00:00",
        "00:30",
        "01:00",
        "01:30",
        "02:00",
        "02:30",
        "03:00",
        "03:30",
        "04:00",
        "04:30",
      ],
      daysOfWeek: {
        0: this.$t("schedule.sunday_sh"),
        1: this.$t("schedule.monday_sh"),
        2: this.$t("schedule.tuesday_sh"),
        3: this.$t("schedule.wednesday_sh"),
        4: this.$t("schedule.thursday_sh"),
        5: this.$t("schedule.friday_sh"),
        6: this.$t("schedule.saturday_sh"),
      },
    };
  },
  computed: {
    canEditSchedule() {
      return canEditDaySchedule(this.$store.state.user);
    },
  },
  watch: {
    $route(to, from) {
      // this.checkUrl();
    },
  },
  created: function () {
    this.search.date = dayjs().format("DD.MM.YYYY");
    if (import.meta.NODE_ENV === "production") {
      this.timer = setInterval(this.fetchData, 5000);
    }
  },
  mounted() {
    this.fetchData();
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    getAssigneeName,
    getUserName,
    saveTimeSlotStatus(assigneeId, timeSlot, active) {
      let scheduleUrl = "/pest/schedule/set-time-slot";
      let params = {
        assigneeId: assigneeId,
        date: this.search.date,
        slot: timeSlot,
        active: active,
      };
      this.$http
        .post(scheduleUrl, params)
        .then((response) => {})
        .catch((error) => {
          this.$bvToast.toast(this.$t("common.error"), {
            title: this.$t("common.error_during_changing_status"),
            variant: "danger",
          });
          this.processRequestError(error);
        });
    },
    formattedDateHeader(date) {
      let dayOfWeekI = dayjs(date).format("e");
      let dayOfWeek = this.daysOfWeek[dayOfWeekI];
      let day = dayjs(date).format("DD.MM.YYYY");
      return day + ", " + dayOfWeek;
    },
    userDutyClass(info) {
      if (info.active === SCHEDULE_ACTIVE_DUTY_1) {
        return "duty1";
      }
      if (info.active === SCHEDULE_ACTIVE_DUTY_2) {
        return "duty2";
      }
    },
    fetchData: function (force) {
      if (this.loading && !force) {
        return;
      }
      let scheduleUrl = "/pest/schedule/day";
      this.loading = true;
      let params = {
        date: this.search.date,
        townId: this.search.townId,
      };
      this.$http
        .get(scheduleUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.loaded = true;
          this.schedule = response.data;
          this.dates = Object.keys(
            this.schedule[Object.keys(this.schedule)[0]].dates
          );
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    getCellClass(info, slot) {
      let classes = ["statusCell"];
      if (info.slots[slot].status) {
        if (info.slots[slot].status === SCHEDULE_ACTIVE_UNAVAILABLE) {
          classes.push("unavailable");
        } else if (info.slots[slot].status === SCHEDULE_ACTIVE_DUTY_1) {
          classes.push("user-busy");
        } else {
          classes.push("busy");
        }
        if (info.slots[slot].start) {
          classes.push("start");
        }
        if (info.slots[slot].end) {
          classes.push("end");
        }
        if (info.slots[slot].status === 2) {
          classes.push("user-start");
        }
      } else {
        classes.push("inactive");
      }

      return classes;
    },
    selectTown(town) {
      this.search.townId = town;
      this.fetchData();
    },

    toggleTime(info, timeSlot, assigneeId) {
      if (!this.editing) {
        return;
      }
      if (info.slots[timeSlot].request) {
        info.slots[timeSlot].status =
          info.slots[timeSlot].status === SCHEDULE_ACTIVE_ACTIVE
            ? SCHEDULE_ACTIVE_DUTY_1
            : SCHEDULE_ACTIVE_ACTIVE;
      } else {
        if (info.slots[timeSlot].status) {
          info.slots[timeSlot].status = SCHEDULE_ACTIVE_NONE;
          // info.slots[timeSlot].start = 0;
        } else {
          info.slots[timeSlot].status = SCHEDULE_ACTIVE_DUTY_1;
          // info.slots[timeSlot].start = 1;
        }
      }
      this.saveTimeSlotStatus(
        assigneeId,
        timeSlot,
        info.slots[timeSlot].status
      );
    },
    addLeadingZero(hour) {
      let timeWithZero = [
        0,
        "0",
        "0",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
      ];
      return (timeWithZero.includes(hour) ? "0" : "") + hour;
    },
    getPrevHour(hour) {
      if ([0, "0", "00"].includes(hour)) {
        return 23;
      }
      return this.addLeadingZero(+hour - 1);
    },
    getNextHour(hour) {
      if ([23, "23"].includes(hour)) {
        return "00";
      }
      return this.addLeadingZero(+hour + 1);
    },
    isDutyHour(hour) {
      let dutyHours = [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "21",
        "22",
        "23",
      ];
      return dutyHours.includes(hour);
    },
    timeIsPassed(hour) {
      return false;
      // let d = new Date();
      // let curHour = d.getHours();
      // return +hour < curHour;
    },
    getEvennessClass(info) {
      if ("evenness" in info) {
        return info.evenness;
      }
      return "none";
    },
    setEvennessClass(info, evenness) {
      if (!this.editing) {
        return;
      }
      if ("evenness" in info && info.evenness === evenness) {
        info.evenness = "none";
        this.saveEvenness(info, "none");
      } else {
        info.evenness = evenness;
        this.saveEvenness(info, evenness);
      }
    },

    saveEvenness(info, evenness) {
      let scheduleUrl = "/pest/schedule/set-evenness";
      let params = {
        assigneeId: info.assignee.id,
        date: this.search.date,
        evenness: evenness,
      };
      this.$http
        .post(scheduleUrl, params)
        .then((response) => {})
        .catch((error) => {
          this.$bvToast.toast(this.$t("common.error"), {
            title: this.$t("common.error_during_changing_status"),
            variant: "danger",
          });
          this.processRequestError(error);
        });
    },

    timeSlotClass(info, hour) {
      let classes = [];
      // noinspection JSBitwiseOperatorUsage
      if (hour & 1) {
        classes.push("odd");
      } else {
        classes.push("even");
      }
      if (this.timeIsPassed(hour)) {
        classes.push("busy");
        return classes;
      }
      if (this.isDutyHour(hour)) {
        if (
          info.active !== SCHEDULE_ACTIVE_DUTY_1 &&
          info.active !== SCHEDULE_ACTIVE_DUTY_2
        ) {
          classes.push("unavailable");
          return classes;
        }
      }

      let prevHour = this.getPrevHour(hour);
      let nextHour = this.getNextHour(hour);
      let curHourSlots = [hour + ":00", hour + ":30"];
      let prevHourSlots = [prevHour + ":00", prevHour + ":30"];
      let nextHourSlots = [nextHour + ":00", nextHour + ":30"];

      // if (hour !== '05') {
      //   curHourSlots.push(prevHour + ':00');
      //   curHourSlots.push(prevHour + ':30');
      // }
      if (hour !== "04") {
        curHourSlots.push(nextHour + ":00");
        curHourSlots.push(nextHour + ":30");
      }

      for (let slot of curHourSlots) {
        if (slot in info.slots) {
          if (info.slots[slot].status === SCHEDULE_ACTIVE_UNAVAILABLE) {
            classes.push("unavailable");
            return classes;
          }
          if (info.slots[slot].status === SCHEDULE_ACTIVE_DUTY_1) {
            if (!nextHourSlots.includes(slot)) {
              classes.push("user-busy");
              return classes;
            }
          } else if (info.slots[slot].status) {
            classes.push("busy");
            return classes;
          }
        }
      }

      // позволяем выбирать только четное или нечетное время
      // noinspection JSBitwiseOperatorUsage
      if (hour & 1) {
        if (info.evenness === "even") {
          classes.push("unavailable");
          return classes;
        }
      } else {
        if (info.evenness === "odd") {
          classes.push("unavailable");
          return classes;
        }
      }

      classes.push("inactive");
      return classes;
    },
    toggleEdit() {
      this.editing = !this.editing;
    },
  }, // methods
};
</script>

<style lang="scss" scoped>
table {
  width: auto;
}

.timeCell {
  width: 40px;
  height: 22px;
}

td.unavailable {
  background: #d2cccd;
}

td.busy {
  background: rgba(189, 213, 234, 0.99);
  //background: #d2cccd;
}

td.user-busy {
  background: #ee7981;
  border-bottom: #ee7981;
  border-top: #ee7981;
}

td.duty1,
th.duty1 {
  background: #f5be25;
}

td.duty2,
th.duty2 {
  background: #ee7981;
}

td.dayOff,
th.dayOff {
  background: #17a2b8;
}

td.inactive {
  background: #35bea1;
}

th.statusCell {
  //cursor: pointer;
}

td.statusCell {
  //cursor: pointer;
}

table thead tr th {
  padding-left: 4px;
  padding-right: 4px;
  width: 80px !important;
  min-width: 80px !important;
  max-width: 80px !important;
  //font-size: smaller;
  white-space: nowrap;
  overflow-x: hidden;
}

td.statusCell.start {
  padding: 0;
  padding-left: 5px;
  padding-right: 5px;
}

td.statusCell.end {
}

td.statusCell:not(.start):not(.user-start) .time {
  display: none;
}

td.statusCell.busy .time {
  display: none;
}

td.statusCell:not(.start):hover .time {
  display: initial;
}

tr.highlighted td,
tr.highlighted th {
  border-top: 2px solid black;
  box-shadow: inset 0px 7px 5px -5px #888;
}

td a {
  color: black;
}

td a:hover {
  //color: ;
}

.ibox-title.editing {
  background: rgba(245, 150, 155, 0.85);
}

.shortTimeInfo {
}

.editing .shortTimeInfo div:hover {
  text-decoration: underline;
  cursor: pointer;
}

.editing .shortTimeInfo .busy:hover,
.editing .shortTimeInfo .user-busy:hover {
  cursor: pointer;
  text-decoration: line-through underline;
}

.shortTimeInfo .unavailable {
  color: #d2cccd;
  text-decoration: line-through;
}

.shortTimeInfo .busy {
  color: rgba(189, 213, 234, 0.99);
  text-decoration: line-through;
}

.shortTimeInfo .busy-near {
  color: rgba(132, 172, 231, 0.81);
  text-decoration: line-through;
}

.shortTimeInfo .inactive {
  //color: #35bea1;
  color: #212529;
}

.shortTimeInfo .user-busy {
  color: #ee7981;
  text-decoration: line-through;
}

.shortTimeInfo .user-busy2 {
  color: rgba(232, 124, 132, 0.97);
  text-decoration: line-through;
}

//.table thead {
//  position: sticky;
//  top: 0;
//}

.shortTimeInfo div {
  float: left;
  font-size: larger;
  display: block;
  padding: 0 8px;
}

.shortTimeInfo div.time:hover {
  background: #eaeaea;
}

.shortTimeInfo div.odd {
  //margin-left: 10px;
}

.shortTimeInfo div.even {
  //margin-left: 15px;
}

.shortTimeInfo:not(.editing) div {
  cursor: default;
}

.evenness_wrapper {
  padding: 0 !important;
  color: #d2cccd;
}

.evenness_wrapper.even a.evenness_even {
  //color: #d2cccd;
  color: #212529;
}

.evenness_wrapper.odd a.evenness_odd {
  //color: #d2cccd;
  color: #212529;
}

.evenness_wrapper a {
  padding: 0 10px;
}

.ibox-content.editing a {
  cursor: pointer;
}

.ibox-content:not(.editing) a {
  cursor: default;
}

.evenness_wrapper a:hover {
  background: #eaeaea;
}

//table {
//  table-layout: fixed
//}

table {
  border-collapse: collapse;
}

td {
  border: 1px solid lightgray;
}

.table-div {
  max-width: 75%;
  overflow-x: auto;
  border: 1px solid black;
  background-color: lightgray;
}

.table {
  background-color: transparent;
  min-width: 100%;
}

.table thead,
.table tbody {
  display: block;
}

.table thead {
  border-bottom: 1px solid black;
}

.table thead > tr {
  width: calc(100% - 12px);
}

.table tbody {
  max-height: 100px;
  overflow-x: hidden;
}

.table tr {
  display: flex;
}

.table td,
.table th {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 3px 10px;
  width: 100%;
  background-color: white;
}

.table th {
  background-color: lightgray;
}

.table tr:nth-child(2n) > td {
  background-color: #eeffee;
}

.table th {
  border-left: 1px solid black;
}

.table th:first-child {
  border-left: 0;
}

.table th:nth-child(3),
.table td:nth-child(3) {
  min-width: 30%;
}
</style>
