<template>
  <div class="ibox">
    <div class="ibox-title">
      <div class="search">
        <div class="d-flex align-items-center">
          <h2 class="mr-2">{{ $t("menu.logistics_load") }}</h2>
          <div class="mr-2">
            <town-filter
              :value="search.townId"
              @change="selectTown"
            ></town-filter>
          </div>
          <div class="mr-2">
            <date-range-picker
              v-model="dateRange"
              @change="fetchData"
            ></date-range-picker>
          </div>
        </div>
      </div>
    </div>

    <div class="ibox-content pl-0 pr-0">
      <div v-if="!loading" class="  ">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th>{{ $t("schedule.time") }}</th>
              <th
                v-for="(date, ddIndex) in dates"
                :key="'dd' + ddIndex"
                class="statusCell"
              >
                {{ formattedDateHeader(date) }} <br />
                <font-awesome-icon icon="file" />&nbsp;{{
                  getRequestCount(date)
                }}&nbsp;&nbsp; <font-awesome-icon icon="user" />&nbsp;{{
                  getAssigneesCount(date)
                }}
                <div :class="getRatioClass(date)">
                  <font-awesome-icon icon="chart-pie" />&nbsp;{{
                    getRatio(date)
                  }}
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(timeSlot, tIndex) in slots" :key="'t' + tIndex">
              <th class="time-cell statusCell">{{ timeSlot }}:00</th>
              <td
                v-for="(info, dIndex) in schedule.dates"
                :key="'h' + dIndex"
                :class="getCellClass(info.slots[timeSlot])"
              >
                <div class="d-flex">
                  <div v-if="info.slots[timeSlot]" class="count">
                    {{ info.slots[timeSlot] }}
                  </div>
                  <div class="time ml-auto">{{ timeSlot }}:00</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-else class="p-2">{{ $t("common.loading") }}</div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getAssigneeName, getUserName } from "@/modules/crm/common";
import {
  SCHEDULE_ACTIVE_DUTY_1,
  SCHEDULE_ACTIVE_DUTY_2,
} from "@/modules/pest/decorators/schedule";
import { MSK, SPB, DXB } from "@/modules/pest/decorators/towns";
import DateRangePicker from "@/components/shared/DateRangePicker";
import TownFilter from "@/components/shared/TownFilter.vue";

export default {
  name: "AssigneeSchedule",
  components: {
    TownFilter,
    DateRangePicker,
  },
  data() {
    return {
      schedule: {},
      MSK,
      SPB,
      DXB,
      search: {
        dateFrom: "",
        townId: MSK,
        dateTo: "",
      },
      dateFormat: "DD.MM.YYYY",
      townList: [],
      dates: [],
      datesLastStatus: [],
      assigneeLastStatus: [],
      slots: [],
      daysOfWeek: {
        0: this.$t("schedule.sunday_sh"),
        1: this.$t("schedule.monday_sh"),
        2: this.$t("schedule.tuesday_sh"),
        3: this.$t("schedule.wednesday_sh"),
        4: this.$t("schedule.thursday_sh"),
        5: this.$t("schedule.friday_sh"),
        6: this.$t("schedule.saturday_sh"),
      },
      loading: true,
    };
  },
  computed: {
    dateRange: {
      get() {
        return [this.search.dateFrom, this.search.dateTo];
      },
      set(val) {
        this.search.dateFrom = val[0];
        this.search.dateTo = val[1];
      },
    },
  },
  watch: {
    $route(to, from) {
      // this.checkUrl();
    },
  },
  mounted() {
    this.fetchData();
  },
  created: function () {
    this.search.dateFrom = dayjs().format("YYYY-MM-DD");
    this.search.dateTo = dayjs().add(14, "d").format("YYYY-MM-DD");
  },
  methods: {
    getAssigneeName,
    getUserName,
    formattedDateHeader(date) {
      let dayOfWeekI = dayjs(date).format("d");
      let dayOfWeek = this.daysOfWeek[dayOfWeekI];
      let day = dayjs(date).format("DD.MM");
      return day + ", " + dayOfWeek;
    },
    userDutyClass(info) {
      if (info.active === SCHEDULE_ACTIVE_DUTY_1) {
        return "duty1";
      }
      if (info.active === SCHEDULE_ACTIVE_DUTY_2) {
        return "duty2";
      }
    },
    fetchData: function () {
      let scheduleUrl = "/pest/schedule/busyness";
      this.loading = true;
      let params = {
        dateFrom: this.search.dateFrom,
        dateTo: this.search.dateTo,
        townId: this.search.townId,
      };
      this.$http
        .get(scheduleUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.schedule = response.data;
          this.slots = this.schedule.slots;
          this.dates = Object.keys(this.schedule.dates);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },

    selectTown(town) {
      this.search.townId = town;
      this.fetchData();
    },
    getCellClass(count) {
      let cellClass = "inactive";
      if (count >= 10) {
        cellClass = "warning";
      } else if (count > 0) {
        cellClass = "busy";
      }
      return [cellClass, "statusCell"];
    },

    getRatio(date) {
      let assigneesCount = this.getAssigneesCount(date);
      let requestCount = this.getRequestCount(date);
      return requestCount / 5;
    },
    getRatioClass(date) {
      let assigneesCount = this.getAssigneesCount(date);
      let requestCount = this.getRequestCount(date);
      let ratio = requestCount / 5;
      if (ratio + 1 > assigneesCount) {
        return "text-danger";
      }
      if (ratio + 3 > assigneesCount) {
        return "text-warning";
      }
      if (ratio < 3) {
        return "";
      }
      return "text-success";
    },
    getAssigneesCount(date) {
      return this.schedule.dates[date].assignees;
    },
    getRequestCount(date) {
      let count = 0;
      // console.log(this.schedule.dates[date]);
      if (date in this.schedule.dates) {
        let slots = this.schedule.dates[date].slots;
        for (let k in slots) {
          if (slots[k]) {
            count += parseInt(slots[k]);
          }
        }
      }
      return count;
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: auto;
}

.time-cell {
  width: 50px;
}

td {
  width: 85px;
}

td.busy {
  background: #35bea1;
}

td.warning {
  background: #f5be25;
}

td.duty2,
th.duty2 {
  background: #ee7981;
}

td.inactive {
  background: #d2cccd;
}

table thead tr th {
  min-width: 50px !important;
  padding-left: 5px;
  padding-right: 5px;
}

table thead tr {
  position: sticky;
  top: 0;
}

.count {
  font-weight: bold;
}

td .time {
  display: none;
}

tbody tr:hover td {
  //opacity: 0.6;
}

td:hover {
  //opacity: 1 !important;
}

td:hover .time {
  display: initial;
}

tbody tr:hover th {
  background: #a4a4a4;
}
</style>
