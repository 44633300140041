<template>
  <div>
    <div v-if="canAddFine" class="ibox">
      <div class="ibox-content">
        <div class="d-flex align-items-center">
          <h1>{{ $t("fines.fines") }}</h1>
          <router-link
            v-if="canAddFine"
            :to="{ name: 'pest-fine-create' }"
            class="btn btn-primary ml-2"
          >
            <font-awesome-icon icon="plus" />
            {{ $t("fines.add_fine") }}
          </router-link>
        </div>
        <div v-if="canAddFine" class="d-flex flex-wrap align-items-center">
          <b-button-group class="mt-2 mr-2">
            <town-filter
              :value="search.townId"
              @change="selectTown"
            ></town-filter>
          </b-button-group>
          <div class="mr-2 mt-2">
            <date-range-picker
              v-model="dateRange"
              @change="fetchData"
            ></date-range-picker>
          </div>
          <user-autocomplete
            v-model="search.employeeId"
            :default-value="
              getEmployeeName(search.employee, $t('requests.assignee'))
            "
            :type="TYPE_WORKER"
            class="mr-2 mt-2"
            @selected="selectEmployee"
          />

          <user-autocomplete
            v-model="search.managerId"
            :type="[
              TYPE_ADMIN,
              TYPE_LOGIST,
              TYPE_MANAGER,
              TYPE_FINE_MANAGER,
              TYPE_SHADOW,
            ]"
            :default-value="
              getEmployeeName(search.manager, $t('fines.who_assigned'))
            "
            class="mr-2 mt-2"
            @selected="selectManager"
          />

          <b-button-group class="mr-2 mt-2">
            <button
              v-b-tooltip.hover
              class="btn"
              :class="getStatusBtnClass('filterNew')"
              title="Новые"
              @click.prevent="setStatusBtn('filterNew')"
            >
              <font-awesome-icon icon="file" />
            </button>
            <button
              v-b-tooltip.hover
              class="btn"
              :class="getStatusBtnClass('filterProcessing')"
              :title="$t('fines.status_m_processing')"
              @click.prevent="setStatusBtn('filterProcessing')"
            >
              <font-awesome-icon icon="rotate" />
            </button>
            <button
              v-b-tooltip.hover
              class="btn"
              :class="getStatusBtnClass('filterVerified')"
              :title="$t('fines.status_m_verified')"
              @click.prevent="setStatusBtn('filterVerified')"
            >
              <font-awesome-icon icon="check" />
            </button>
            <button
              v-b-tooltip.hover
              class="btn"
              :class="getStatusBtnClass('filterDeclined')"
              :title="$t('fines.status_m_declined')"
              @click.prevent="setStatusBtn('filterDeclined')"
            >
              <font-awesome-icon icon="times" />
            </button>
          </b-button-group>
          <b-button-group class="mr-2 mt-2">
            <button
              v-b-tooltip.hover
              class="btn"
              :class="getPaidBtnClass('paid')"
              :title="$t('fines.status_m_paid')"
              @click.prevent="setPaidBtn('paid')"
            >
              {{ $t("fines.status_m_paid") }}
            </button>
            <button
              v-b-tooltip.hover
              class="btn"
              :class="getPaidBtnClass('notPaid')"
              :title="$t('fines.status_m_not_paid')"
              @click.prevent="setPaidBtn('notPaid')"
            >
              {{ $t("fines.status_m_not_paid") }}
            </button>
          </b-button-group>

          <b-button-group class="mr-2 mt-2">
            <button
              v-b-tooltip.hover
              class="btn"
              :class="getPaidBtnClass('deposited')"
              :title="$t('fines.status_m_deposited')"
              @click.prevent="setPaidBtn('deposited')"
            >
              {{ $t("fines.status_m_deposited") }}
            </button>
            <button
              v-b-tooltip.hover
              class="btn"
              :class="getPaidBtnClass('notDeposited')"
              :title="$t('fines.status_m_not_deposited')"
              @click.prevent="setPaidBtn('notDeposited')"
            >
              {{ $t("fines.status_m_not_deposited") }}
            </button>
          </b-button-group>
        </div>
      </div>
    </div>

    <div class="ibox">
      <div class="ibox-title d-none d-md-block">{{ $t("fines.fines") }}</div>
      <div class="ibox-content table-responsive">
        <b-overlay :show="loading" rounded="sm">
          <table class="table table-hover table-striped">
            <thead>
              <tr>
                <th></th>
                <th>{{ $t("fines.date") }}</th>
                <th v-if="canAddFine">{{ $t("fines.status") }}</th>
                <th>{{ $t("fines.sum") }}</th>
                <th v-if="canAddFine">{{ $t("fines.assignee") }}</th>
                <th>{{ $t("fines.comment") }}</th>
                <th v-if="canAddFine">{{ $t("fines.answer") }}</th>
                <th v-if="canAddFine">{{ $t("fines.who_assigned") }}</th>
                <th v-if="canAddFine">{{ $t("fines.who_checked") }}</th>
                <th v-if="canAddFine" class="paid-comment">
                  {{ $t("fines.paid") }}
                </th>
              </tr>
            </thead>
            <tbody v-if="fines && fines.length">
              <tr v-for="(fine, fIndex) in fines" :key="fIndex">
                <td>
                  <font-awesome-icon :icon="getFineStatusIcon(fine)" />&nbsp;
                </td>
                <td>
                  <router-link
                    v-if="canAddFine"
                    :to="{ name: 'pest-fine-update', params: { id: fine.id } }"
                    class=""
                  >
                    {{ getFineTime(fine) }}
                  </router-link>
                  <span v-else>
                    {{ getFineTime(fine) }}
                  </span>
                </td>
                <td v-if="canAddFine">
                  {{ getFineStatusTitle(fine) }}
                </td>
                <td>
                  {{ fine.sum }}
                </td>
                <td v-if="canAddFine">
                  {{ getUserName(fine.employee) }}
                </td>
                <td>
                  {{ fine.comment }}
                  <div v-if="!canAddFine">
                    {{ fine.review }}
                  </div>
                </td>
                <td v-if="canAddFine">
                  {{ fine.review }}
                </td>
                <td v-if="canAddFine">
                  {{ getUserName(fine.manager) }}
                </td>
                <td v-if="canAddFine">
                  {{ getUserName(fine.reviewer) }}
                </td>
                <td v-if="canAddFine">
                  <div class="mb-1">
                    <div v-if="fine.isPaid">
                      <font-awesome-icon
                        fixed-width
                        icon="fa-solid fa-money-bill"
                      />
                      {{ $t("fines.status_m_paid") }}
                      <div v-if="fine.payTime">
                        {{ getFinePayTime(fine) }}
                      </div>
                    </div>
                    <div v-else>
                      <font-awesome-icon
                        fixed-width
                        icon="fa-solid fa-money-bill"
                        class="text-muted"
                      />
                      {{ $t("fines.status_m_not_paid") }}
                    </div>
                  </div>
                  <div>
                    <div v-if="fine.isDeposited">
                      <font-awesome-icon
                        fixed-width
                        icon="fa-solid fa-cash-register "
                      />
                      {{ $t("fines.status_m_deposited") }}
                      <div v-if="fine.depositTime">
                        {{ getDepositTime(fine) }}
                      </div>
                    </div>
                    <div v-else>
                      <font-awesome-icon
                        fixed-width
                        icon="fa-solid fa-cash-register "
                        class="text-muted"
                      />
                      {{ $t("fines.status_m_not_deposited") }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td :colspan="canAddFine ? 10 : 8">
                  {{ $t("common.loading") }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-overlay>

        <b-pagination-nav
          v-if="pagination.count > 1"
          :link-gen="linkGen"
          :value="pagination.page"
          :number-of-pages="pagination.count"
          limit="10"
          use-router
          class="mt-2"
        >
        </b-pagination-nav>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getDepositTime,
  getFinePayTime,
  getFineStatusIcon,
  getFineStatusTitle,
  getFineTime,
  getFineTownTitle,
} from "@/modules/pest/decorators/fines";
import { getUserName } from "@/modules/crm/common";
import { MSK, SPB, DXB } from "@/modules/pest/decorators/towns.js";
import UserAutocomplete from "@/modules/users/views/user/UserAutocomplete.vue";
import {
  TYPE_ADMIN,
  TYPE_FINE_MANAGER,
  TYPE_LOGIST,
  TYPE_MANAGER,
  TYPE_WORKER,
  TYPE_SHADOW,
} from "@/modules/users/decorators/users.js";
import { canAddFines } from "@/modules/users/access/common.js";
import DateRangePicker from "@/components/shared/DateRangePicker";
import TownFilter from "@/components/shared/TownFilter.vue";

export default {
  name: "PestFineList",
  components: {
    TownFilter,
    DateRangePicker,
    UserAutocomplete,
  },
  data() {
    return {
      MSK,
      SPB,
      DXB,
      TYPE_ADMIN,
      TYPE_WORKER,
      TYPE_LOGIST,
      TYPE_MANAGER,
      TYPE_FINE_MANAGER,
      TYPE_SHADOW,
      fines: [],
      townList: [
        { value: 1, text: "Москва и область" },
        { value: 2, text: "Санкт-Петербург и область" },
      ],
      search: {
        townId: 2,
        dateFrom: "",
        dateTo: "",
        employeeId: null,
        employee: {},
        managerId: null,
        manager: {},
        name: "",
        filterNew: true,
        filterProcessing: true,
        filterVerified: true,
        filterDeclined: false,
        paid: true,
        notPaid: true,
        deposited: true,
        notDeposited: true,
      },
      loading: true,
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      fields: [
        {
          key: "statusIcon",
          label: " ",
          sortable: false,
        },
        {
          key: "time",
          label: "Дата",
          sortable: false,
        },
        {
          key: "status",
          label: this.$t("common.status"),
          sortable: false,
        },
        {
          key: "sum",
          label: "Сумма",
          sortable: false,
        },
        {
          key: "employee",
          label: "Сотрудник",
          sortable: false,
        },
        {
          key: "comment",
          label: this.$t("common.comment"),
          sortable: false,
        },
        {
          key: "review",
          label: "Ответ",
          sortable: false,
        },
        {
          key: "manager",
          label: "Кто назначил",
        },
        {
          key: "reviewer",
          label: "Кто проверил",
          sortable: false,
        },
        {
          key: "isPaid",
          label: "Оплачен",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    dateRange: {
      get() {
        return [this.search.dateFrom, this.search.dateTo];
      },
      set(val) {
        this.search.dateFrom = val[0];
        this.search.dateTo = val[1];
      },
    },
    canAddFine() {
      return canAddFines(this.$store.state.user);
    },

    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
  },
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  mounted: function () {
    this.fetchData();
  },
  created() {},
  methods: {
    getFineTime,
    getFinePayTime,
    getDepositTime,
    getUserName,
    getFineTownTitle,
    getFineStatusTitle,
    getFineStatusIcon,
    filterCheckboxChanged() {
      this.$nextTick(() => {
        this.fetchData();
      });
    },
    fetchData: function () {
      this.loading = true;

      let finesUrl = "/pest/fines";
      let params = {
        page: this.page,
        townId: this.search.townId,
        dateFrom: this.search.dateFrom,
        dateTo: this.search.dateTo,
        employeeId: this.search.employeeId,
        managerId: this.search.managerId,
        filterNew: this.search.filterNew ? 1 : 0,
        filterProcessing: this.search.filterProcessing ? 1 : 0,
        filterVerified: this.search.filterVerified ? 1 : 0,
        filterDeclined: this.search.filterDeclined ? 1 : 0,
        paid: this.search.paid ? 1 : 0,
        notPaid: this.search.notPaid ? 1 : 0,
        deposited: this.search.deposited ? 1 : 0,
        notDeposited: this.search.notDeposited ? 1 : 0,
      };
      this.$http
        .get(finesUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.fines = response.data;
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    handleGroupIndex(command) {
      this.sampleGroupIndex = command;
    },
    linkGen(pageNum) {
      return {
        name: "pest-fine-list",
        params: { page: pageNum },
      };
    },
    getStatusBtnClass(status) {
      if (this.search[status]) {
        return "btn-primary";
      } else {
        return "btn-outline-primary";
      }
    },
    setStatusBtn(status) {
      this.search[status] = this.search[status] ? 0 : 1;
      this.fetchData();
    },
    getPaidBtnClass(status) {
      if (this.search[status]) {
        return "btn-primary";
      } else {
        return "btn-outline-primary";
      }
    },
    setPaidBtn(status) {
      this.search[status] = this.search[status] ? 0 : 1;
      this.fetchData();
    },
    getTownClass(town) {
      if (this.search.townId === town) {
        return "btn-primary";
      } else {
        return "btn-outline-primary";
      }
    },
    selectTown(town) {
      this.search.townId = town;
      this.fetchData();
    },

    getEmployeeName(user, defaultValue) {
      return getUserName(user, defaultValue);
    },
    selectEmployee(data) {
      if (data && data.value) {
        this.search.employeeId = data.value.id;
        this.search.employee = data.value;
      } else {
        this.search.employeeId = null;
        this.search.employee = {};
      }
      this.$forceUpdate();
      this.fetchData();
    },
    selectManager(data) {
      if (data && data.value) {
        this.search.managerId = data.value.id;
        this.search.manager = data.value;
      } else {
        this.search.managerId = null;
        this.search.manager = {};
      }
      this.$forceUpdate();
      this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.search {
  border-top: 1px lightgray solid;
  border-bottom: 1px lightgray solid;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.mx-datepicker {
  width: 150px;
}

.paid-comment {
  width: 150px;
}
</style>
