<template>
  <div class="ibox">
    <div class="ibox-title">
      <div class="search">
        <h2 class="d-none d-md-block">{{ $t("schedule.my_schedule") }}</h2>
        <div class="d-flex">
          <div class="">
            <date-range-picker
              v-model="dateRange"
              @change="fetchData"
            ></date-range-picker>
          </div>
          <div class="">
            <b-button class="btn btn-primary ml-2" @click="fetchData">
              <font-awesome-icon icon="search" />&nbsp;{{
                $t("common.refresh")
              }}
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <div class="ibox-content pl-0 pr-0">
      <div v-if="!loading" class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>{{ $t("schedule.date") }}</th>
              <th>{{ $t("schedule.status") }}</th>
              <th>{{ $t("common.comment") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(date, ddIndex) in dates" :key="'dd' + ddIndex">
              <th>
                {{ formattedDateHeader(date) }}
              </th>
              <td :class="getStatusClass(schedule[date])"></td>
              <td>
                {{ getStatusComment(schedule[date]) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-else class="p-2">{{ $t("common.loading") }}</div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getUserName } from "@/modules/crm/common.js";

import {
  SCHEDULE_ACTIVE_ACTIVE,
  SCHEDULE_ACTIVE_DUTY_1,
  SCHEDULE_ACTIVE_DUTY_2,
} from "@/modules/pest/decorators/schedule.js";
import DateRangePicker from "@/components/shared/DateRangePicker.vue";

export default {
  name: "AssigneeSchedule",
  components: {
    DateRangePicker,
  },
  data() {
    return {
      schedule: {},
      search: {
        dateFrom: "",
        townId: 2,
        dateTo: "",
      },
      dateFormat: "DD.MM.YYYY",
      townList: [],
      dates: [],
      datesLastStatus: [],
      assigneeLastStatus: [],
      daysOfWeek: {
        0: this.$t("schedule.sunday_sh"),
        1: this.$t("schedule.monday_sh"),
        2: this.$t("schedule.tuesday_sh"),
        3: this.$t("schedule.wednesday_sh"),
        4: this.$t("schedule.thursday_sh"),
        5: this.$t("schedule.friday_sh"),
        6: this.$t("schedule.saturday_sh"),
      },
      loading: true,
    };
  },
  computed: {
    dateRange: {
      get() {
        return [this.search.dateFrom, this.search.dateTo];
      },
      set(val) {
        this.search.dateFrom = val[0];
        this.search.dateTo = val[1];
      },
    },
  },
  watch: {
    $route(to, from) {},
  },
  mounted() {
    this.fetchData();
  },
  created: function () {
    this.search.dateFrom = dayjs().format("DD.MM.YYYY");
    this.search.dateTo = dayjs().add(14, "d").format("DD.MM.YYYY");
    this.fetchData();
  },
  methods: {
    getUserName,
    getStatusClass(date) {
      let active = date.active;
      if (active === SCHEDULE_ACTIVE_ACTIVE) {
        return "active";
      } else if (active === SCHEDULE_ACTIVE_DUTY_1) {
        return "duty1";
      } else if (active === SCHEDULE_ACTIVE_DUTY_2) {
        return "duty2";
      } else {
        return "inactive";
      }
    },
    getStatusComment(date) {
      let active = date.active;
      if (active === SCHEDULE_ACTIVE_ACTIVE) {
        return this.$t("schedule.st_u_work");
      } else if (active === SCHEDULE_ACTIVE_DUTY_1) {
        return this.$t("schedule.st_u_duty_1");
      } else if (active === SCHEDULE_ACTIVE_DUTY_2) {
        return this.$t("schedule.st_u_duty_2");
      } else {
        return "";
      }
    },
    toggleDateStatus(date) {
      let lastStatus = this.datesLastStatus[date];
      let status = lastStatus ? 0 : 1;
      for (let info of this.schedule) {
        info.dates[date].active = status;
      }
      this.datesLastStatus[date] = status;
      this.saveDateStatus(date, status);
    },
    saveDateStatus(date, active) {
      let scheduleUrl = "/pest/schedule/set-date";
      let params = {
        date: date,
        active: active,
        townId: this.search.townId,
      };
      this.$http
        .put(scheduleUrl, params)
        .then((response) => {})
        .catch((error) => {
          this.$bvToast.toast(this.$t("common.error"), {
            title: this.$t("common.error_during_changing_status"),
            variant: "danger",
          });
          this.processRequestError(error);
        });
    },
    formattedDateHeader(date) {
      let dayOfWeekI = dayjs(date).day();
      let dayOfWeek = this.daysOfWeek[dayOfWeekI];
      let day = dayjs(date).format("DD.MM.YYYY");
      return day + ", " + dayOfWeek;
    },
    fetchData: function () {
      let scheduleUrl = "/pest/schedule/user";
      this.loading = true;
      let params = {
        dateFrom: this.search.dateFrom,
        dateTo: this.search.dateTo,
      };
      this.$http
        .get(scheduleUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.schedule = response.data.dates;
          this.dates = Object.keys(this.schedule);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: auto;
}

.user-name {
  width: 200px;
}

td,
th {
  width: 150px;
}

td.active {
  background: #35bea1;
}

td.inactive {
  background: #d2cccd;
}

td.duty1,
th.duty1 {
  background: #f5be25;
}

td.duty2,
th.duty2 {
  background: #ee7981;
}

th.statusCell,
td.statusCell {
  cursor: pointer;
}

th.statusCell,
td.statusCell {
  //width: 200px;
  //cursor: pointer;
}
</style>
