<template>
  <div>
    <strong>{{ $t("requests.contract") }}: </strong> {{ log.contractId }} <br />
    <strong>{{ $t("requests.cause") }}: </strong> {{ getConvertCause }}<br />
  </div>
</template>

<script>
import { getStatusName } from "@/modules/crm/decorators/statusDecorator.js";

export default {
  name: "RequestLogConvertedToContract",
  components: {},
  props: ["log"],
  data() {
    return {
      convertCauses: {
        ["manual"]: this.$t("logs.cause_manual"),
        ["stage_deal"]: this.$t("logs.cause_stage_deal"),
      },
    };
  },
  computed: {
    getConvertCause() {
      return this.convertCauses[this.log.cause];
    },
  },
  watch: {},
  methods: { getStatusName },
};
</script>

<style lang="scss" scoped></style>
