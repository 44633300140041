<template>
  <b-form
    v-if="dataLoaded"
    id="status-form"
    ref="status"
    :model="status"
    class="status-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="row">
      <div class="col-12">
        <b-form-group :label="$t('common.title')" prop="title">
          <b-form-input
            v-model="status.title"
            :placeholder="$t('common.title')"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group :label="$t('common.sort_order')" prop="title">
          <b-form-input v-model="status.order" placeholder="0"></b-form-input>
        </b-form-group>
        <div class="row">
          <div class="col-6">
            <b-form-group :label="$t('statuses.bg_color')" prop="color">
              <b-form-input v-model="status.color" type="color"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group :label="$t('statuses.font_color')" prop="textColor">
              <b-form-input
                v-model="status.textColor"
                type="color"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-12">
            <b-form-group
              :label="$t('statuses.card_text_color')"
              prop="cardColor"
            >
              <b-form-input
                v-model="status.cardColor"
                type="color"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <b-form-group :label="$t('statuses.settings')" prop="title">
          <b-form-checkbox v-model="status.hidden" value="1">
            {{ $t("statuses.hide_from_user") }}
          </b-form-checkbox>
        </b-form-group>

        <b-form-group :label="$t('statuses.logic')" prop="title">
          <b-form-checkbox v-model="status.newStatus" value="1">
            {{ $t("statuses.st_newStatus") }}
          </b-form-checkbox>

          <b-form-checkbox v-model="status.startWork" value="1">
            {{ $t("statuses.st_startWork") }}
          </b-form-checkbox>

          <b-form-checkbox v-model="status.duplicate" value="1">
            {{ $t("statuses.st_duplicate") }}
          </b-form-checkbox>

          <b-form-checkbox v-model="status.trash" value="1">
            {{ $t("statuses.st_trash") }}
          </b-form-checkbox>

          <b-form-checkbox v-model="status.moveToDeal" value="1">
            {{ $t("statuses.st_closeStatus") }}
          </b-form-checkbox>

          <b-form-checkbox
            v-if="false && status.closeStatus"
            v-model="status.termination"
            value="1"
          >
            {{ $t("statuses.st_termination") }}
          </b-form-checkbox>

          <b-form-checkbox v-model="status.closeStatus" value="1">
            {{ $t("statuses.st_moveToDeal") }}
          </b-form-checkbox>

          <b-form-checkbox v-model="status.autoInitialCall" value="1">
            {{ $t("statuses.st_autoInitialCall") }}
          </b-form-checkbox>

          <b-form-checkbox v-model="status.autoRepeatedCall" value="1">
            {{ $t("statuses.st_autoRepeatedCall") }}
          </b-form-checkbox>

          <b-form-checkbox v-model="status.withoutAnswer" value="1">
            {{ $t("statuses.st_withoutAnswer") }}
          </b-form-checkbox>
        </b-form-group>
      </div>
    </div>

    <div class="ibox-footer">
      <b-button variant="primary" @click="createOrUpdate">
        <font-awesome-icon icon="check" />
        {{ $t("common.save") }}
      </b-button>

      <b-button
        v-if="id !== 'new'"
        variant="outline-default"
        class="ml-2"
        @click="cancelUpdate"
      >
        <font-awesome-icon icon="arrow-left" />
        {{ $t("common.back_to_list") }}
      </b-button>

      <b-button
        v-if="status.id > 0"
        variant="danger"
        class="ml-2"
        @click="deleteStatus"
      >
        <font-awesome-icon icon="trash" />
        {{ $t("common.delete") }}
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { VueMaskDirective } from "v-mask";

export default {
  name: "StatusForm",
  directives: { mask: VueMaskDirective },
  components: {},
  props: ["statusModel", "dataLoaded"],
  data() {
    return {
      status: {},
      loading: false,
    };
  },
  computed: {
    id() {
      return this.status.id;
    },
  },
  watch: {
    $route(to, from) {},
    statusModel: {
      immediate: true,
      handler(obj) {
        this.status = Object.assign({}, obj);
      },
    },
  },
  created: function () {},
  mounted: function () {},
  methods: {
    processStatusVariable(status) {
      let props = {
        hidden: status.hidden,
        title: status.title,
        order: status.order,
        color: status.color,
        textColor: status.textColor,
        cardColor: status.cardColor,
        newStatus: status.newStatus,
        startWork: status.startWork,
        duplicate: status.duplicate,
        trash: status.trash,
        moveToDeal: status.moveToDeal,
        closeStatus: status.closeStatus,
        termination: status.termination,
        autoInitialCall: status.autoInitialCall,
        autoRepeatedCall: status.autoRepeatedCall,
        withoutAnswer: status.withoutAnswer,
      };
      if (status.id && status.id !== "new") {
        props.id = status.id;
      }
      return props;
    },
    update: function () {
      let updateUrl;
      updateUrl = "/crm/statuses/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.processStatusVariable(this.status))
        .then((response) => {
          this.loading = false;
          this.$bvToast.toast(this.$t("common.data_saved"), {
            title: this.$t("statuses.status_updated"),
            type: "success",
          });
          this.$emit(
            "update-status-info",
            this.processStatusVariable(this.status)
          );
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: this.$t("statuses.cant_delete_status"),
              type: "danger",
            });
          }
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "/crm/statuses";
      this.loading = true;
      this.$http
        .post(updateUrl, this.processStatusVariable(this.status))
        .then((response) => {
          this.status = response.data;
          this.$eventHub.$emit("update-status-info", this.status);
          this.$router.push({
            name: "crm-status-update",
            params: { id: this.status.id },
          });
          this.$bvToast.toast(this.$t("statuses.status_added"), {
            title: this.$t("statuses.status_added"),
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.error"), {
              title: this.$t("statuses.cant_create_status"),
              variant: "danger",
            });
          }
        });
    },
    deleteStatus() {
      if (confirm(this.$t("statuses.delete_status_question"))) {
        let deleteUrl = "/crm/statuses/" + this.status.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            // this.status.archived = 1;
            this.loading = false;
            this.$router.push({ name: "crm-status-list" });
            // this.$eventHub.$emit('update-status-info', this.status);
            this.$bvToast.toast(this.$t("statuses.status_deleted"), {
              title: this.$t("statuses.status_deleted"),
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast(this.$t("common.error"), {
                title: this.$t("statuses.cant_delete_status"),
                variant: "danger",
              });
            }
          });
      }
    },
    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById("status-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.id === "new") {
          this.create();
        } else {
          this.update();
        }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast(this.$t("common.form_has_errors"), {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
    cancelUpdate() {
      this.$router.push({ name: "crm-status-list" });
      // this.$emit('cancel-updating', this.status);
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}

.ibox-footer {
  bottom: 0;
}
</style>
