export const ru = {
  callCauses: {
    callCauses: "Причины звонка клиенту",
    callCause_list: "Перечень причина звонка",
    setting_hint: "Указываются исполнителем при звонке",
    callCause: "Причина звонка клиенту",
    add_callCause: "Добавить причину звонка клиенту",
    new_callCause: "Новый причины звонка клиенту",
    callCause_update: "Изменение причины звонка клиенту",
    callCause_updated: "Причина звонка клиенту отредактирована",
    cant_create_callCause: "Не удалось добавить причину звонка клиенту",
    cant_delete_callCause: "Не удалось удалить причину звонка клиенту",
    callCause_created: "Причина звонка клиенту добавлена",
    callCause_deleted: "Причина звонка клиенту удалена",
    delete_callCause_question:
      "Вы действительно хотите удалить причину звонка клиенту?",
  },
};
export const en = {
  callCauses: {
    callCauses: "Call causes",
    callCause_list: "Call cause list",
    setting_hint: "Assignee chooses when calling to client",
    callCause: "Call cause",
    add_callCause: "Add call cause",
    new_callCause: "New call cause",
    change_callCause: "Change call cause",
    callCause_update: "Update call cause",
    callCause_updated: "Call cause changed",
    cant_create_callCause: "Can't add call cause",
    cant_delete_callCause: "Can't delete call cause",
    callCause_created: "Call cause added",
    callCause_deleted: "Call cause deleted",
    delete_callCause_question: "Do you want to delete the call cause?",
  },
};
