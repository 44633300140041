<template>
  <div>
    <div v-if="showSuccess" class="panel panel-primary mb-4">
      <div class="panel-heading">
        {{ $t("deplosit.pledge_change_confirmation_title") }}
      </div>
      <div class="panel-body">
        {{ $t("deplosit.pledge_change_confirmation_text") }}
      </div>
      <button class="btn btn-primary mt-2" @click="closeSuccess">
        {{ $t("common.close") }}
      </button>
    </div>

    <div v-else class="ibox">
      <div class="ibox-content">
        <div>
          <div class="d-flex align-items-center">
            <div class="">
              <strong> {{ $t("deposit.pledge") }} </strong>
            </div>
            <div class="h5 pt-0 mt-0 mr-1 ml-auto">
              <span v-if="loading">{{ $t("common.loading") }}</span>
              <span v-else
                >{{ formatNumber(pledgeGoal) }} {{ $t("common.cur") }}</span
              >
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="">
              <strong> {{ $t("deposit.pledged_msg") }}</strong>
            </div>
            <div class="h5 pt-0 mt-0 mr-1 ml-auto">
              <span v-if="loading">{{ $t("common.loading") }}</span>
              <span v-else
                >{{ formatNumber(pledgeSum) }} {{ $t("common.cur") }}</span
              >
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="">
              <strong>{{ $t("deposit.pledge_left_msg") }}</strong>
            </div>
            <div class="h5 pt-0 mt-0 mr-1 ml-auto">
              <span v-if="loading">{{ $t("common.loading") }}</span>
              <span v-else
                >{{ formatNumber(pledgeDiff) }} {{ $t("common.cur") }}</span
              >
            </div>
          </div>
        </div>

        <div class="mt-2">
          <b-button
            v-if="false && !showForm && canChangePledge"
            variant="primary"
            @click.prevent="showForm = true"
          >
            Изменить сумму депозита
          </b-button>

          <b-button
            v-if="false && !showForm && !canChangePledge"
            variant="default"
            @click.prevent="showForm = true"
          >
            Изменить сумму депозита
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatNumber } from "@/modules/pest/decorators/common.js";

export default {
  name: "EmployeePledge",
  components: {},
  data() {
    return {
      showForm: false,
      showSuccess: false,
      pledgeGoal: 0,
      pledgeSum: 0,
      canChangePledge: true,
      loading: false,
    };
  },
  computed: {
    pledgeDiff() {
      return this.pledgeGoal - this.pledgeSum;
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },
  mounted: function () {
    this.fetchPledgeInfo();
  },

  methods: {
    formatNumber,
    checkUrl: function () {
      window.scrollTo(0, 0);
    },
    pledgeUpdated(newSum) {
      this.showSuccess = true;
      this.pledgeGoal = newSum;
    },
    fetchPledgeInfo: function () {
      this.loading = true;
      let pledgeUrl = "/pest/pledge/stats";
      let params = {};
      this.$http
        .get(pledgeUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.pledgeGoal = response.data.pledgeGoal;
          this.pledgeSum = response.data.pledgeSum;
          this.canChangePledge = response.data.canChangePledge;
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    closeSuccess() {
      this.showSuccess = false;
      this.showForm = false;
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  padding-inline-start: 20px;
}

.w200 {
  width: 200px;
}
</style>
