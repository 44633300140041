<template>
  <b-modal
    id="modal-request-call-notification"
    ref="modal"
    :title="$t('calls.schedule_call')"
    :cancel-title="$t('common.cancel')"
    :show-hour="true"
    :show-minute="true"
    :show-second="false"
    :ok-title="$t('calls.assign')"
    title-tag="h3"
    @ok.prevent="handleTaskModalOk"
  >
    <b-overlay :show="saving">
      <date-time-picker v-model="datetime" />
    </b-overlay>
  </b-modal>
</template>

<script>
import DateTimePicker from "@/components/shared/DateTimePicker";

export default {
  name: "RequestCallNotificationPopup",
  components: {
    DateTimePicker,
  },
  props: [],
  data() {
    return {
      saving: false,
      datetime: new Date(),
    };
  },
  computed: {},
  watch: {},
  created() {
    this.$eventHub.$on("request-call-notification-popup-show", this.openPopup);
  },
  beforeDestroy() {
    // this.$eventHub.$off('request-call-notification-popup-show');
  },
  methods: {
    handleSuccess(call) {
      this.$bvModal.hide("modal-request-call-notification");
      if (this.successHandler) {
        this.successHandler({ call: call });
      }
      this.successHandler = false;
      this.datetime = new Date();
    },
    handleTaskModalOk(bvModalEvt) {
      this.addCallNotification();
    },
    addCallNotification() {
      if (this.saving) {
        return;
      }
      this.saving = true;
      let createUrl = "/pest/call-notifications";
      this.$http
        .post(createUrl, {
          requestId: this.request.id,
          time: this.datetime,
        })
        .then((response) => {
          this.$bvToast.toast(this.$t("notifications.notification_added"), {
            title: this.$t("common.success"),
            variant: "success",
          });
          this.handleSuccess(response.data);
          this.saving = false;
        })
        .catch(() => {
          this.saving = false;
        });
    },
    selectAssigneeFromAutocomplete({ value }) {
      this.selectAssignee(value);
    },
    selectAssignee(assignee) {
      this.handleSuccess(assignee);
    },
    openPopup(info) {
      this.request = info.request;
      this.successHandler = info.success;
      this.cancelHandler = info.cancel;
      this.$bvModal.show("modal-request-call-notification");
    },
  },
};
</script>

<style lang="scss" scoped>
.update-duration-modal {
  position: absolute;
  //top: 10px;
  //left: 10px;
  border: 1px solid black;
  z-index: 10000;
  padding: 50px;
  background: #ffffff;
}

ul {
  list-style: none;
  padding: 0;
  font-size: larger;
}

ul li {
  cursor: pointer;
  padding: 5px 10px;
}

ul li:hover {
  background: rgba(215, 212, 212, 0.5);
}

li.active {
  background: lightblue;
}

.btn-row {
  height: 36px !important; // fix different size of button and autocomplete
}
</style>
