<template>
  <div>
    {{ $t("requests.request_sum") }}: {{ log.sum }}<br />
    {{ $t("requests.assignee") }}: {{ getUserName(log.employee) }}<br />

    <a href="#" @click.prevent="showIds = !showIds"> {{ $t("logs.ids") }}:</a>
    <div v-if="showIds">
      finesIds: {{ log.finesIds }}<br />
      requestIds: {{ log.requestIds }}<br />
    </div>
  </div>
</template>

<script>
import {
  calcProfitSum,
  equipmentSum,
  getDecisionTime,
  getRequestAssigneeName,
  getRequestClientName,
  getRequestCreateTime,
  getRequestDatetime,
  getRequestDeadline,
  getRequestDurationFormatted,
  getRequestManagerName,
  getRequestStatus,
  getRequestStatusTitle,
  getRequestTitle,
  getRequestUpdateTime,
  hasAnyResources,
  hasAnyServices,
  servicesSum,
  STATUS_DONE,
} from "@/modules/pest/decorators/requests";
import { VueMaskDirective } from "v-mask";
import { getUserName } from "@/modules/crm/common";

export default {
  name: "PaymentCreatedLogInfo",
  directives: { mask: VueMaskDirective },
  components: {},
  props: [
    "log",
    // 'short',
  ],
  data() {
    return {
      STATUS_DONE: STATUS_DONE,
      loading: false,
      showIds: false,
      showSums: false,
      closeRequest: false,
      decisionForm: false,
    };
  },
  computed: {
    id() {
      return this.request.id;
      // return this.$route.params.id !== undefined ? this.$route.params.id : 'new'
    },
    name() {
      let name = this.request.name
        ? this.request.name
        : this.$t("clients.new_client");
      return name ? name : "Клиент";
    },
  },
  watch: {},
  created: function () {},
  methods: {
    getRequestTitle,
    getRequestDatetime,
    getRequestDurationFormatted,
    getRequestStatusTitle,
    getRequestManagerName,
    getRequestAssigneeName,
    getRequestStatus,
    getRequestClientName,
    getRequestCreateTime,
    getRequestDeadline,
    getRequestUpdateTime,
    getUserName,
    getDecisionTime,
    hasAnyResources,
    hasAnyServices,
    servicesSum,
    equipmentSum,
    calcProfitSum,
  },
};
</script>

<style lang="scss">
.res-table {
  td:first-child {
    width: 140px;
  }
}
</style>
