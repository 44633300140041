import t from "@/i18n";

export const BLOCKED = 1;
export const UNBLOCKED = 2;
export const REGULAR = 0;

export const getUserTitle = function (user) {
  if (!user) {
    return t("common.not_set");
  }
  if (user.title) {
    return user.title;
  }
  let firstName = user.name || "";
  let lastName = user.surname || "";
  let patronymic = user.patronymic || "";
  let name = firstName + " " + lastName + " " + patronymic;
  name = name.trim().replace(/[-\s]+$/g, "");
  if (name && name !== "") {
    return name;
  }
  if (!name && user.username) {
    return user.username;
  } else {
    return t("common.without_name");
  }
};
