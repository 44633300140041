<template>
  <div id="app" class="gray-bg">
    <div id="nav">
      <page-header></page-header>
    </div>
    <div id="page-wrapper" class="pl-0 pr-0">
      <b-container fluid="" class=" ">
        <router-view />

        <div
          v-if="hashChanged && $root.env !== 'development'"
          class="refresh-container"
        >
          <div class="notification-header">
            <button
              type="button"
              class="close-refresh-modal"
              aria-label="Close"
            >
              <span aria-hidden="true"><i class="fal fa-times fa-sm"></i></span>
            </button>
          </div>
          <div class="notification-body">
            <div class="notification-button">
              <p class="text-center font12">
                Появилось обновление. Сохраните всю вашу текущую работу и
                нажмите кнопку "Обновиться"
              </p>
              <p class="text-center">
                <span class="font10">Без обновления могут быть ошибки</span>
              </p>
            </div>
            <div class="refresh-button text-center">
              <button class="btn btn-default" @click="reloadApp">
                Обновиться
              </button>
            </div>
          </div>
        </div>
      </b-container>

      <task-popup></task-popup>
    </div>
  </div>
</template>

<script>
import Header from "./components/layout/Header";
import TaskPopup from "@/modules/crm/views/tasks/TaskPopup";

import { refreshPageMixin } from "@/components/mixins/refresh-page.mixin";
import { isWorker } from "@/modules/users/access/common.js";
import { mapActions } from "vuex";

export default {
  components: {
    "page-header": Header,
    "task-popup": TaskPopup,
  },
  mixins: [refreshPageMixin],

  computed: {
    isWorker() {
      return isWorker(this.$store.state.user);
    },
    logged() {
      return !this.$store.state.user.isGuest;
    },
    username() {
      return this.$store.state.user.name;
    },
  },
  created() {
    this.$eventHub.$on("start", this.fetchSettings);
  },
  mounted() {
    if (this.isWorker) {
      document.body.classList.add("fixed-nav");
    }
    this.fetchSettings();
  },
  destroyed() {
    this.$eventHub.$off("start");
    if (this.isWorker) {
      document.body.classList.remove("fixed-nav");
    }
  },
  methods: {
    ...mapActions({
      setProp: "data/setProp",
    }),
    fetchTowns: function () {
      let townsUrl = "/pest/towns";
      this.$http
        .get(townsUrl)
        .then((response) => {
          this.setProp({ key: "towns", value: response.data });
          this.$eventHub.$emit("towns-updated");
        })
        .catch((error) => {});
    },
    fetchSettings() {
      this.fetchTowns();
    },
  },
};
</script>
<style>
#app {
}

.container {
}
</style>

<style lang="scss" scoped>
.refresh-container {
  width: 15%;
  position: fixed;
  bottom: 10px;
  right: 15px;
  background-color: #ffffff;
  padding: 25px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
}

.close-refresh-modal {
  position: absolute;
  right: 5px;
  top: 5px;
  border: none;
}

.top-navigation.fixed-nav #app,
.fixed-nav #app.top-navigation {
  margin-top: 70px !important;
}

@media (min-width: 768px) {
  #page-wrapper.board {
    min-height: inherit !important;
  }
}
</style>
