<template>
  <div>
    <b-form ref="user" :model="user" class="user-form">
      <div class="ibox">
        <div class="ibox-title d-flex align-items-center">
          <h1 class="">
            <router-link :to="returnUrlC" class="h4 text-muted">
              <font-awesome-icon icon="arrow-left" />
            </router-link>
            {{ name }}
            <span v-if="isWorker" class=""> ({{ user.percentString }}) </span>
          </h1>

          <div v-if="isWorker && user.teamHead" class="ml-4">
            {{ $t("users.team") }}: {{ getUserTitle(user.teamHead) }}
          </div>

          <div v-if="canUpdateUser" class="ml-4">
            <div class="row">
              <div class="col-md-12">
                <b-button variant="primary" @click="createOrUpdate">
                  <font-awesome-icon icon="check" />
                  {{ $t("common.save") }}
                </b-button>

                <a
                  v-if="id !== 'new'"
                  href=""
                  class="btn btn-outline btn-danger ml-2"
                  @click.prevent="remove"
                >
                  <font-awesome-icon icon="trash" />
                  {{ $t("common.delete") }}
                </a>

                <router-link
                  v-if="false"
                  :to="{ name: 'users-user-list' }"
                  class="btn btn-default ml-2"
                >
                  <font-awesome-icon icon="arrow-left" />
                  {{ $t("common.back_to_list") }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-overlay :show="loading" rounded="sm">
        <b-tabs content-class="mt-3">
          <b-tab :title="$t('users.personal_data')" active>
            <div class="row">
              <div class="col-md-4">
                <div class="ibox">
                  <div class="ibox-title">
                    <h5 class="h5">{{ $t("users.account") }}</h5>
                  </div>
                  <div class="ibox-content">
                    <b-form-group :label="$t('users.type')" prop="type">
                      <b-form-select
                        v-model="user.type"
                        :options="userTypesOptions"
                        required
                      ></b-form-select>
                    </b-form-group>

                    <b-form-group
                      :label="$t('users.new_password')"
                      prop="password"
                    >
                      <b-form-input
                        v-model="user.password"
                        type="password"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-checkbox v-model="user.enabled" class="mb-2"
                      >{{ $t("users.enabled") }}
                    </b-form-checkbox>

                    <div v-if="canSetClosedSettings">
                      <b-form-checkbox
                        v-model="user.canSeeClosedReports"
                        class="mb-2"
                      >
                        {{ $t("users.can_see_closed_reports") }}
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="user.canSeeServiceSections"
                        class="mb-2"
                      >
                        {{ $t("users.can_see_service_sections") }}
                      </b-form-checkbox>
                    </div>

                    <b-form-group
                      :label="$t('users.id_in_1c')"
                      prop="externalId"
                    >
                      <b-form-input
                        v-model="user.externalId"
                        disabled
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      :label="$t('users.id_in_telegram')"
                      prop="telegramChatId"
                    >
                      <b-form-input
                        v-model="user.telegramChatId"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      :label="$t('users.discount_card')"
                      prop="discountCard"
                    >
                      <b-form-input v-model="user.discountCard"></b-form-input>
                    </b-form-group>
                    <b-form-group :label="$t('users.project')" prop="projectId">
                      <multiply-autocomplete
                        ref="projectAutocomplete"
                        :get-data="getProjects"
                        :get-title="getProjectTitle"
                        @selected="selectMultiplyProjects"
                      ></multiply-autocomplete>
                    </b-form-group>
                  </div>
                </div>

                <div class="ibox">
                  <div class="ibox-content">
                    <b-form-group :label="$t('users.profile_photo')">
                      <file-upload-field @file-uploaded="fileUploaded" />
                      <div v-if="user.image">
                        <img
                          :src="user.image.url"
                          alt=""
                          class="profileImg mt-2"
                        />
                      </div>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="ibox">
                  <div class="ibox-title">
                    <h5 class="h5">{{ $t("users.personal_data") }}</h5>
                  </div>

                  <div class="ibox-content">
                    <b-form-group :label="$t('users.family')" prop="family">
                      <b-form-input v-model="user.surname"></b-form-input>
                    </b-form-group>

                    <b-form-group :label="$t('users.name')" prop="name">
                      <b-form-input v-model="user.name"></b-form-input>
                    </b-form-group>

                    <b-form-group
                      :label="$t('users.patronymic')"
                      prop="patronymic"
                    >
                      <b-form-input v-model="user.patronymic"></b-form-input>
                    </b-form-group>

                    <b-form-group :label="$t('users.email')" prop="email">
                      <b-form-input v-model="user.email"></b-form-input>
                    </b-form-group>
                    <b-form-group :label="$t('users.phone')" prop="phone">
                      <b-form-input v-model="user.phone"></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div v-if="user.type === TYPE_WORKER" class="ibox">
                  <div class="ibox-title">
                    <h5 class="h5">{{ $t("users.town") }}</h5>
                  </div>

                  <div class="ibox-content">
                    <town-autocomplete
                      ref="townAutocomplete"
                      :default-value="
                        user.townId ? getTownTitle(user.town) : ''
                      "
                      @selected="selectTown"
                    ></town-autocomplete>
                  </div>
                </div>

                <div v-if="user.type === TYPE_WORKER" class="ibox">
                  <div class="ibox-title">
                    <h5 class="h5">{{ $t("users.record") }}</h5>
                  </div>

                  <div class="ibox-content">
                    <b-form-group
                      :label="$t('users.current_record')"
                      prop="externalId"
                    >
                      <b-form-input v-model="user.record"></b-form-input>
                    </b-form-group>
                  </div>
                </div>

                <div v-if="[TYPE_WORKER].includes(user.type)" class="ibox">
                  <div class="ibox-title">
                    <h5 class="h5">{{ $t("payments.pledge") }}</h5>
                  </div>

                  <div class="ibox-content">
                    <b-form-group
                      :label="$t('users.pledge_goal')"
                      prop="externalId"
                    >
                      <b-form-input v-model="user.pledgeGoal"></b-form-input>
                    </b-form-group>
                    <b-form-group
                      :label="$t('users.pledge_sum')"
                      prop="externalId"
                    >
                      <b-form-input v-model="user.pledgeSum"></b-form-input>
                    </b-form-group>
                    <b-form-group
                      :label="$t('users.pledging')"
                      prop="externalId"
                    >
                      <b-button-group>
                        <b-button
                          :variant="
                            !user.disabledDepositGeo ? 'primary' : 'default'
                          "
                          @click.prevent="user.disabledDepositGeo = 0"
                          >{{ $t("users.pledging_shop") }}</b-button
                        >
                        <b-button
                          :variant="
                            user.disabledDepositGeo ? 'primary' : 'default'
                          "
                          @click.prevent="user.disabledDepositGeo = 1"
                          >{{ $t("users.pledging_anywhere") }}</b-button
                        >
                      </b-button-group>
                    </b-form-group>
                  </div>
                </div>

                <div
                  v-if="
                    [
                      TYPE_MANAGER,
                      TYPE_ADMIN,
                      TYPE_SHADOW,
                      TYPE_LOGIST,
                    ].includes(user.type)
                  "
                  class="ibox"
                >
                  <div class="ibox-title">
                    <h5 class="h5">{{ $t("users.voip_settings") }}</h5>
                  </div>

                  <div class="ibox-content">
                    <b-form-group
                      :label="$t('users.mango_number')"
                      prop="mangoNumber"
                    >
                      <b-form-input v-model="user.mangoNumber"></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="ibox-content">
                    <b-form-group
                      label="Внутренний номер в Zadarma"
                      prop="zadarmaNumber"
                    >
                      <b-form-input v-model="user.zadarmaNumber">
                      </b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab v-if="false" :title="$t('users.change_password')"></b-tab>
          <b-tab v-if="isWorker" :title="$t('users.equipment')">
            <div class="row">
              <div class="col-md-4">
                <div class="ibox">
                  <div class="ibox-title">
                    <h5 class="h5">{{ $t("users.equipment") }}</h5>
                  </div>
                  <div class="ibox-content">
                    <b-form-group
                      :label="$t('users.settings')"
                      prop="equipmentSum"
                    >
                      {{ $t("users.equipment_fixed_price_mgs") }}
                      <b-form-radio-group
                        v-model="user.ignoreCommonFixedEquipment"
                        :options="[
                          { value: 1, text: $t('users.only_own') },
                          { value: 0, text: $t('users.common') },
                        ]"
                      />
                      {{ $t("users.equipment_percent_price_mgs") }}
                      <b-form-radio-group
                        v-model="user.ignoreCommonPercentEquipment"
                        :options="[
                          { value: 1, text: $t('users.only_own') },
                          { value: 0, text: $t('users.common') },
                        ]"
                      />
                    </b-form-group>

                    <hr />
                    <div
                      v-for="(equipment, eqIndex) in user.equipments"
                      :key="'eq' + eqIndex"
                      class="d-flex align-items-center"
                    >
                      <!--                <b-form-group label="Стоимость " prop="equipmentSum">-->
                      <!--                  <b-form-input v-model="equipment.sum"></b-form-input>-->
                      <!--                </b-form-group>-->
                      <div class="">
                        <b-form-group
                          :label="$t('users.equipment')"
                          prop="equipmentSum"
                        >
                          <b-form-select
                            v-model="equipment.equipmentId"
                            :options="equipmentOptions"
                          ></b-form-select>
                        </b-form-group>
                      </div>

                      <div class="ml-2 pt-2">
                        <button
                          v-if="eqIndex > 0"
                          class="btn btn-danger btn-outline"
                          @click.prevent="removeEquipment(eqIndex)"
                        >
                          <font-awesome-icon icon="trash" fixed-width />
                        </button>
                        <button
                          v-else
                          class="btn btn-primary btn-outline"
                          @click.prevent="addEquipment"
                        >
                          <font-awesome-icon icon="plus" fixed-width />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab v-if="isWorker" :title="$t('users.pests')">
            <div class="row">
              <div class="col-md-4">
                <div class="ibox">
                  <div class="ibox-title">
                    <h5 class="h5">{{ $t("users.pests") }}</h5>
                  </div>
                  <div class="ibox-content">
                    <div v-if="user && user.pests.length > 0">
                      <div
                        v-for="(pest, rIndex) in user.pests"
                        :key="'r' + rIndex"
                        class="d-flex align-items-center"
                      >
                        <div class="">
                          <b-form-group :label="$t('pests.pest')" prop="title">
                            <b-form-select
                              v-model="pest.id"
                              :options="pestsList"
                              placeholder=""
                              class="pest-select"
                              tabindex="1"
                              @change="setPestTitle(pest)"
                            ></b-form-select>
                          </b-form-group>
                        </div>
                        <div class="ml-2 pt-2">
                          <button
                            v-if="rIndex > 0"
                            class="btn btn-danger btn-outline"
                            @click.prevent="removePest(rIndex)"
                          >
                            <font-awesome-icon icon="trash" fixed-width />
                          </button>
                          <button
                            v-else
                            class="btn btn-primary btn-outline"
                            @click.prevent="addPest"
                          >
                            <font-awesome-icon icon="plus" fixed-width />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab v-if="isWorker" :title="$t('users.resources')">
            <user-resources-list :user="user" />
          </b-tab>
          <b-tab v-if="isWorker" :title="$t('users.internship')">
            <div class="row">
              <div class="col-md-4">
                <div class="ibox">
                  <div class="ibox-title">
                    <h5 class="h5">{{ $t("users.internship") }}</h5>
                  </div>
                  <div class="ibox-content">
                    <b-form-group v-if="false" label="Цвет" prop="color">
                      <b-form-input
                        v-model="user.color"
                        type="color"
                      ></b-form-input>
                    </b-form-group>

                    <hr />
                    <b-form-group :label="$t('users.novice')" prop="isNovice">
                      <b-form-select
                        v-model="user.isNovice"
                        :options="noviceOptions"
                      />
                    </b-form-group>

                    <b-form-group :label="$t('users.novice')" prop="color">
                      <b-form-datepicker
                        v-model="user.noviceDateFrom"
                      ></b-form-datepicker>
                    </b-form-group>

                    <b-form-group :label="$t('users.work_shift_count')">
                      <b-form-input
                        v-model="user.noviceShiftCount"
                        placeholder=""
                        disabled
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab v-if="isWorker" :title="$t('users.team')">
            <div class="row">
              <div class="col-md-4">
                <div class="ibox">
                  <div class="ibox-title">
                    <h5 class="h5">{{ $t("users.team") }}</h5>
                  </div>
                  <div class="ibox-content">
                    <b-form-group
                      :label="$t('users.team_head')"
                      prop="teamHeadId"
                    >
                      <user-autocomplete
                        v-model="user.teamHeadId"
                        :type="TYPE_WORKER"
                        :default-value="getUserTitle(user.teamHead)"
                        @selected="selectTeamHead"
                      ></user-autocomplete>
                    </b-form-group>
                    <b-form-group
                      :label="$t('users.in_team_since')"
                      prop="color"
                    >
                      <date-picker v-model="user.teamSince"></date-picker>
                    </b-form-group>
                    <b-form-checkbox v-model="user.commonPercent" class="mb-2"
                      >{{ $t("users.regular_percent_mst") }}
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </b-overlay>
    </b-form>
  </div>
</template>

<script>
import {
  TYPE_ADMIN,
  TYPE_CLIENT,
  TYPE_LOGIST,
  TYPE_MANAGER,
  TYPE_SHADOW,
  TYPE_WORKER,
  typeSelectOptions,
} from "@/modules/users/decorators/users";
import { getClientName } from "@/modules/crm/decorators/client";
import { getPestTitle } from "@/modules/pest/decorators/pests";
import { getEquipmentTitle } from "@/modules/pest/decorators/equipments";
import { getUserTitle } from "@/modules/users/decorators/userDecorator.js";
import UserAutocomplete from "@/modules/users/views/user/UserAutocomplete.vue";
import { canUpdateUser } from "@/modules/users/access/common.js";
import { mapGetters } from "vuex";
import DatePicker from "@/components/shared/DatePicker";
import TownAutocomplete from "@/modules/pest/views/towns/TownAutocomplete.vue";
import { getTownTitle } from "@/modules/pest/decorators/towns";
import FileUploadField from "@/components/shared/FileUploadField.vue";
import UserResourcesList from "@/modules/users/views/user/UserResourcesList.vue";
import MultiplyAutocomplete from "@/components/shared/MultiplyAutocomplete.vue";
export default {
  name: "User",
  components: {
    UserResourcesList,
    FileUploadField,
    TownAutocomplete,
    UserAutocomplete,
    DatePicker,
    MultiplyAutocomplete,
  },
  data() {
    return {
      TYPE_WORKER,
      TYPE_MANAGER,
      TYPE_CLIENT,
      TYPE_SHADOW,
      TYPE_ADMIN,
      TYPE_LOGIST,
      typeSelectOptions,
      labelWidth: "150px",
      equipmentOptions: [],
      user: {
        id: null,
        name: "",
        surname: "",
        patronymic: "",
        username: "",
        email: "",
        phone: "",
        isNovice: false,
        enabled: true,
        commonPercent: false,
        clientId: "",
        client: "",
        externalId: "",
        telegramChatId: "",
        discountCard: "",
        pledgeGoal: "",
        pledgeSum: "",
        disabledDepositGeo: 0,
        townId: null,
        town: {},
        pests: [],
        projectIds: [],
      },
      loading: false,
      pestsList: [],
      noviceOptions: [
        { value: true, text: this.$t("common.yes") },
        { value: false, text: this.$t("common.no") },
      ],
      sampleGroup: "",
      sampleGroups: [
        {
          label: "Администраторы",
          value: "admin",
        },
        {
          label: "Менеджеры",
          value: "manager",
        },
      ],
      // rules: {
      //   name: [
      //     {required: true, message: 'Введите имя', trigger: 'blur'}
      //   ]
      // }
    };
  },
  computed: {
    ...mapGetters({
      canSetClosedSettings: "auth/canSetClosedSettings",
      canSeeTestSetting: "auth/canSeeTestSetting",
    }),
    userTypesOptions() {
      return [
        { value: null, text: this.$t("common.all") },
        ...typeSelectOptions(),
      ];
    },
    isWorker() {
      return (
        this.user && this.user.id !== "new" && this.user.type === TYPE_WORKER
      );
    },
    id() {
      return this.$route.params.id !== undefined
        ? this.$route.params.id
        : "new";
    },
    name() {
      let name = this.user.name ? this.user.name : this.user.username;
      return name ? name : this.$t("users.users");
    },
    clientName() {
      return this.user.clientId && this.user.client
        ? getClientName(this.user.client)
        : "";
    },
    returnUrlC() {
      if (this.$route.params.returnUrl) {
        return this.$route.params.returnUrl;
      }
      return { name: "users-user-list" };
    },
    canUpdateUser() {
      return canUpdateUser(this.$store.state.user);
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.fetchPests();
    this.fetchEquipmentOptions();

    this.checkUrl();
  },
  methods: {
    getProjects(search, resolve) {
      let pestUrl = "/pest/projects?enabled=1";
      let params = {
        search: search,
      };
      this.$http
        .get(pestUrl, {
          params: params,
        })
        .then((response) => {
          let data = response.data;
          resolve(data);
        })
        .catch((error) => {
          resolve([]);
        });
    },
    getProjectTitle(item) {
      if (item.title) {
        return item.title;
      }
    },
    selectMultiplyProjects(info) {
      this.user.projectIds = [];
      if (info) {
        for (let project of info) {
          this.user.projectIds.push(project.id);
        }
      }
    },
    getClientName,
    getUserTitle,
    getPestTitle,
    getTownTitle,
    getEquipmentTitle,
    checkUrl: function () {
      if (this.id === "new") {
        this.header = "Создать user";
      } else {
        this.fetchData();
      }
    },
    setUserData(data) {
      this.user = data;
      this.user.enabled = !!data.enabled;
      this.user.projectIds = this.user.userProjects;
      this.ensureBlankData();
      this.$refs.projectAutocomplete.selected = this.user.projects;
    },
    fetchEquipmentOptions: function () {
      this.equipmentOptions = [];
      let pestsUrl = "/pest/equipments";
      let params = {
        page: this.page,
      };
      this.equipmentOptions.push({ value: "", text: this.$t("common.no") });
      this.$http
        .get(pestsUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.equipmentOptions.push({
              value: item.id,
              text: this.getEquipmentTitle(item),
            });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    fetchPests: function () {
      this.pestsList = [];
      let pestsUrl = "/pest/pests";
      let params = {
        page: this.page,
      };
      this.$http
        .get(pestsUrl, {
          params: params,
        })
        .then((response) => {
          for (let item of response.data) {
            this.pestsList.push({
              value: item.id,
              text: this.getPestTitle(item),
            });
          }
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    fetchData: function () {
      this.loading = true;
      let usersUrl = "users/admin/" + this.id;
      this.$http
        .get(usersUrl)
        .then((response) => {
          this.loading = false;
          this.setUserData(response.data);
          this.header = this.user.email;
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
          this.$bvToast.toast(this.$t("common.error"), {
            title: this.$t("common.error"),
            type: "danger",
          });
        });
    },
    ensureBlankData() {
      if (!this.user.pests || !this.user.pests.length) {
        this.user.pests = [];
        let blankPest = {
          pestId: "",
        };
        this.user.pests.push(blankPest);
      }
      if (!this.user.equipments || !this.user.equipments.length) {
        this.user.equipments = [];
        let blankEquipment = {
          equipmentId: "",
        };
        this.user.equipments.push(blankEquipment);
      }

      if (!this.user.ignoreCommonFixedEquipment) {
        this.user.ignoreCommonFixedEquipment = 0;
      }
      if (!this.user.ignoreCommonPercentEquipment) {
        this.user.ignoreCommonPercentEquipment = 0;
      }
    },
    update: function () {
      let updateUrl;
      updateUrl = "users/admin/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.user)
        .then((response) => {
          this.setUserData(response.data);
          this.loading = false;
          this.$bvToast.toast(this.$t("common.data_saved"), {
            title: this.$t("users.user_updated"),
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            let message = this.getErrorMessage(error);
            this.$bvToast.toast(message, {
              title: this.$t("users.cant_update_user"),
              type: "danger",
            });
          }
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "users/admin";
      this.loading = true;
      this.$http
        .post(updateUrl, this.user)
        .then((response) => {
          this.user = response.data;
          this.$bvToast.toast(this.$t("users.users_added"), {
            title: this.$t("users.users_added"),
            variant: "success",
          });
          this.$router.push({
            name: "users-user-view",
            params: { id: this.user.id },
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            let message = this.getErrorMessage(error);
            this.$bvToast.toast(message, {
              title: this.$t("users.cant_create_user"),
              variant: "danger",
            });
          }
        });
    },
    createOrUpdate: function () {
      // this.$refs['user'].validate((valid) => {
      //   if (valid) {
      if (this.id === "new") {
        this.create();
      } else {
        this.update();
      }
      // } else {
      //   this.$bvToast.toast('Произошла неизвестная ошибка', {
      //     variant: 'danger'
      //   });
      //   console.log('error submit!!');
      //   return false
      // }
      // })
    },
    remove: function () {
      if (!confirm(this.$t("users.delete_user_question"))) {
        return false;
      }

      let deleteUrl = "users/admin/" + this.id;
      this.true = false;
      this.$http
        .delete(deleteUrl)
        .then((response) => {
          this.loading = false;
          this.$bvToast.toast(this.$t("users.users_deleted"), {
            variant: "success",
          });
          this.$router.push({ name: "users-user-list" });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("users.cant_delete_user"), {
              variant: "danger",
            });
          }
        });
    },
    // image upload

    getErrorMessage(data) {
      if ("errors" in data.response.data) {
        console.log(data.response.data.errors);
        return Object.values(data.response.data.errors).join("\n");
      } else {
        return "Неизвестная ошибка";
      }
    },
    removePest(index) {
      this.user.pests.splice(index, 1);
    },
    addPest() {
      let blankPest = {
        pestId: "",
      };
      this.user.pests.push(blankPest);
    },
    removeEquipment(index) {
      this.user.equipments.splice(index, 1);
    },
    addEquipment() {
      let blankEquipment = {
        equipmentId: "",
      };
      this.user.equipments.push(blankEquipment);
    },
    setPestTitle(pest) {
      let i = this.pestsList
        .map(function (e) {
          return e.value;
        })
        .indexOf(pest.id);
      pest.title = this.pestsList[i].text;
    },
    selectTeamHead(data) {
      if (data && data.value) {
        this.user.teamHead = data.value;
        this.user.teamHeadId = data.value.id;
      } else {
        this.user.teamHead = {};
        this.user.teamHeadId = null;
      }
      this.$forceUpdate();
    },
    selectTown(data) {
      if (data && data.value) {
        this.user.town = data.value;
        this.user.townId = data.value.id;
      } else {
        this.user.town = {};
        this.user.townId = null;
      }
      this.$forceUpdate();
    },
    fileUploaded({ file }) {
      this.user.imageId = file.id;
      this.user.image = file;
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.user-view {
  &__statistics {
    margin-bottom: 0;

    &__value {
      margin-bottom: 0;
    }
  }
}
.profileImg {
  max-height: 100px;
  max-width: 100px;
}
</style>
