<template>
  <div>
    <table class="table table-striped request-info">
      <tbody>
        <tr>
          <td class="title-column">
            <font-awesome-icon icon="list-ol" fixed-width />
            {{ $t("requests.number") }}
          </td>
          <td>
            {{ request.externalId }}
          </td>
        </tr>
        <tr>
          <td><font-awesome-icon icon="paperclip" fixed-width /> Проект</td>
          <td>
            {{ getRequestProjectTitle(request) }}
          </td>
        </tr>
        <tr>
          <td>
            <font-awesome-icon
              v-if="request.isOrganization"
              icon="building"
              fixed-width
            />
            <font-awesome-icon v-else icon="user" fixed-width />
            {{ $t("requests.client_name") }}
          </td>
          <td>
            <span v-if="request.clientId">
              {{ getRequestClientName(request) }}
              <router-link
                v-if="canOpenToAssignee"
                :to="{
                  name: 'crm-client-view',
                  params: { id: request.clientId },
                }"
                class=""
              >
                <font-awesome-icon
                  icon="external-link-square-alt"
                  fixed-width
                />
              </router-link>
            </span>
            <span v-else> {{ $t("common.not_set") }} </span>
          </td>
        </tr>
        <tr v-if="request.isOrganization && request.organizationTitle">
          <td>
            <font-awesome-icon icon="building" fixed-width />
            {{ $t("requests.type_organization") }}
          </td>
          <td>
            {{ request.organizationTitle }}
          </td>
        </tr>

        <tr v-if="request.isOrganization && request.organizationTitle">
          <td>
            <font-awesome-icon icon="money-bill" fixed-width />
            {{ $t("requests.payment_type") }}
          </td>
          <td>
            {{ getPaymentTypeTitle(request) }}
          </td>
        </tr>

        <tr>
          <td>
            <font-awesome-icon icon="clock" fixed-width />
            {{ $t("requests.time") }}
          </td>
          <td>
            {{ getRequestDatetime(request) }} –
            {{ getRequestDuration(request) }}
          </td>
        </tr>

        <tr v-if="request.town">
          <td>
            <font-awesome-icon icon="map-marker" fixed-width />
            {{ $t("requests.town") }}
          </td>
          <td>
            {{ request.town.title }}
          </td>
        </tr>
        <tr v-if="request.subway">
          <td>
            <font-awesome-icon icon="map" fixed-width />
            {{ $t("requests.address_subway") }}
          </td>
          <td>
            {{ request.subway }}
          </td>
        </tr>
        <tr>
          <td>
            <font-awesome-icon icon="map" fixed-width />
            {{ $t("requests.address") }}
          </td>
          <td>
            {{ request.address }}
          </td>
        </tr>
        <tr>
          <td>
            <font-awesome-icon icon="building" fixed-width />
            {{ $t("requests.address_appartment") }}
          </td>

          <td>
            <div class="d-flex">
              <div class="">
                <span v-if="canIViewAppartment">
                  <span v-if="request.appartment">{{
                    request.appartment
                  }}</span>
                  <span v-else>
                    {{ $t("requests.address_appartment_not_set") }}</span
                  >
                </span>
                <span v-else>
                  {{ $t("requests.appartment_is_forbidden") }}</span
                >
              </div>
            </div>
          </td>
        </tr>
        <tr v-if="canOpenToAssignee">
          <td>
            <font-awesome-icon icon="building" fixed-width />
            {{ $t("requests.appartment_visibility") }}
          </td>

          <td>
            <div class="d-flex">
              <div class="">
                <span v-if="canAssigneeViewAppartment">
                  <font-awesome-icon icon="check" fixed-width />
                  {{ $t("requests.appartment_is_shown_to_assignee") }}
                </span>
                <span v-else>
                  <font-awesome-icon icon="times" fixed-width />
                  {{ $t("requests.appartment_is_hidden_from_assignee") }}
                </span>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <font-awesome-icon icon="phone" fixed-width />
            {{ $t("requests.phone") }}
          </td>
          <td>
            <div class="d-flex">
              <div v-if="canViewPhone" class="">{{ request.phone }}</div>
              <div v-else class="">{{ $t("requests.phone_is_forbidden") }}</div>
            </div>
          </td>
        </tr>

        <tr v-if="request.contactPhone">
          <td>
            <font-awesome-icon icon="phone-volume" fixed-width />
            {{ $t("requests.phone") }}
          </td>
          <td>
            <div class="d-flex">
              <div v-if="canViewPhone" class="">{{ request.contactPhone }}</div>
              <div v-else class="">{{ $t("requests.phone_is_forbidden") }}</div>
            </div>
          </td>
        </tr>
        <tr v-if="request.contactPhone2">
          <td>
            <font-awesome-icon icon="phone-volume" fixed-width />
            {{ $t("requests.phone") }}
          </td>
          <td>
            <div class="d-flex">
              <div v-if="canViewPhone" class="">
                {{ request.contactPhone2 }}
              </div>
              <div v-else class="">{{ $t("requests.phone_is_forbidden") }}</div>
            </div>
          </td>
        </tr>
        <tr v-if="request.contactPhone3">
          <td>
            <font-awesome-icon icon="phone-volume" fixed-width />
            {{ $t("requests.phone") }}
          </td>
          <td>
            <div class="d-flex">
              <div v-if="canViewPhone" class="">
                {{ request.contactPhone3 }}
              </div>
              <div v-else class="">{{ $t("requests.phone_is_forbidden") }}</div>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <font-awesome-icon icon="money-bill" fixed-width />
            {{ $t("requests.sum") }}
          </td>
          <td>
            {{ request.sum }}
          </td>
        </tr>

        <tr v-if="request.warning">
          <td>
            <font-awesome-icon icon="exclamation-triangle" fixed-width />
            {{ $t("requests.warning") }}
          </td>
          <td>
            <span>{{ request.warning }}</span>
          </td>
        </tr>
        <tr v-if="request.pest">
          <td>
            <font-awesome-icon icon="bug" fixed-width />
            {{ $t("requests.pest") }}
          </td>
          <td :style="'color: ' + getRequestColor(request)">
            <span>{{ getPestTitle(request.pest) }}</span>
          </td>
        </tr>
        <tr v-if="request.facility">
          <td>
            <font-awesome-icon icon="building" fixed-width />
            {{ $t("requests.facility") }}
          </td>
          <td>
            <span>{{ getFacilityTitle(request.facility) }}</span>
          </td>
        </tr>

        <tr v-if="request.fromExchange">
          <td>
            <font-awesome-icon icon="hand-holding-usd" fixed-width />
            {{ $t("requests.extra") }}
          </td>
          <td>
            <span>{{ $t("requests.payment_exchange") }}</span>
          </td>
        </tr>

        <tr v-if="request.comment">
          <td>
            <font-awesome-icon icon="comment" fixed-width />
            {{ $t("requests.comment") }}
          </td>
          <td>
            <span>{{ request.comment }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  getDecisionTime,
  getPaymentTypeTitle,
  getRequestAssignee1stName,
  getRequestAssigneeName,
  getRequestClientName,
  getRequestColor,
  getRequestCreateTime,
  getRequestDatetime,
  getRequestDeadline,
  getRequestDuration,
  getRequestManagerName,
  getRequestStatus,
  getRequestStatusTitle,
  getRequestTitle,
  getRequestUpdateTime,
  STATUS_DONE,
} from "@/modules/pest/decorators/requests";
import { getUserName } from "@/modules/crm/common";
import { getPestTitle } from "@/modules/pest/decorators/pests";
import { getFacilityTitle } from "@/modules/pest/decorators/facilities";
import { getRequestProjectTitle } from "@/modules/pest/decorators/requests";
export default {
  name: "RequestLogCreatedInfo",
  components: {},
  props: ["request"],
  data() {
    return {
      STATUS_DONE: STATUS_DONE,
      loading: false,
      closeRequest: false,
      decisionForm: false,
      calling: false,
      opening: false,
      requestingAddress: false,
    };
  },
  computed: {
    id() {
      return this.request.id;
      // return this.$route.params.id !== undefined ? this.$route.params.id : 'new'
    },
    name() {
      let name = this.request.name
        ? this.request.name
        : this.$t("clients.new_client");
      return name ? name : "Клиент";
    },
    canAssigneeViewAppartment() {
      return this.request.assigneeCanSeeAddress;
    },
    canIViewAppartment() {
      return this.canViewPhone || this.request.assigneeCanSeeAddress;
    },
    canOpenToAssignee() {
      return this.canViewPhone;
    },
    canViewPhone() {
      return (
        !this.$store.state.user.isGuest &&
        this.$store.state.user.access.canViewContacts === true
      );
    },
  },
  watch: {
    $route(to, from) {
      this.showAddress();
    },
  },
  created: function () {},
  methods: {
    getRequestProjectTitle,
    getPestTitle,
    getRequestTitle,
    getRequestDatetime,
    getRequestDuration,
    getRequestStatusTitle,
    getRequestManagerName,
    getRequestAssigneeName,
    getRequestAssignee1stName,
    getRequestStatus,
    getRequestClientName,
    getRequestCreateTime,
    getRequestDeadline,
    getRequestUpdateTime,
    getUserName,
    getDecisionTime,
    getPaymentTypeTitle,
    getFacilityTitle,
    getRequestColor,
  },
};
</script>

<style lang="scss" scoped>
table.request-info td:first-child {
  min-width: 155px !important;
}

td.title-column {
}
</style>
