<template>
  <div v-if="dataLoaded">
    <!--Сделка-->
    <div class="ibox">
      <div class="ibox-title d-flex pr-4 toptitle">
        <div>
          <div :data-id="request.id" class="d-flex align-items-baseline">
            <h3>
              {{ getRequestStageTitle(request) }}:
              {{ getRequestNumber(request) }}
            </h3>
            <div v-if="request.id !== 'new'" class="ml-2 small">
              [{{ $t("requests.lead_created") }}
              {{ getRequestCreateDatetime(request) }}]
            </div>
          </div>
          <!--          <div v-if="request.source" class="ml-auto">-->
          <!--            <a :href="getRequestSourceUrl(request)" target="_blank">{{-->
          <!--              request.source.title-->
          <!--            }}</a>-->
          <!--          </div>-->
        </div>
        <div class="ml-auto">
          <request-call-notifications :request="request" />
        </div>
        <div class="ml-2">
          <button
            v-if="
              request.id !== 'new' && request.contractId && !request.hasNext
            "
            class="btn btn-primary"
            @click.prevent="createReturnVisit(request)"
          >
            <font-awesome-icon icon="reply" />
            {{ $t("requests.assign_follow_up_treatment") }}
          </button>
        </div>
        <div v-if="request.status === STATUS_DECLINE" class="ml-2">
          <button class="btn btn-warning" @click.prevent="confirmReanimation">
            <span v-if="reanimationLoading">
              <font-awesome-icon icon="rotate-right" spin />
            </span>
            <span v-else>
              <font-awesome-icon icon="rotate-right" />
            </span>
            {{ $t("requests.reopen_request") }}
          </button>
        </div>
      </div>
      <div class="ibox-content">
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <b-form-group :label="$t('requests.manager')" prop="managerId">
              {{ getRequestManagerName(request) }}
            </b-form-group>
          </div>
          <div class="col-sm-12 col-md-4">
            <b-form-group :label="$t('requests.status')" prop="leadStatusId">
              {{ getRequestStatus(request) }}
            </b-form-group>
          </div>

          <div class="col-sm-12 col-md-4">
            <b-form-group :label="$t('requests.assignee')" prop="assigneeId">
              {{ getRequestAssigneeName(request) }}
            </b-form-group>
            <b-form-group
              v-if="request.assigneeCancelCause"
              :label="$t('requests.assignee_cancel_cause')"
              prop="assigneeId"
            >
              {{ request.assigneeCancelCause }}
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <b-form-group :label="$t('requests.project')" prop="projectId">
              {{ getRequestProjectTitle(request) }}
            </b-form-group>
          </div>
          <div class="col-12 col-md-4">
            <b-form-group :label="$t('requests.client_name')" prop="title">
              {{ request.clientName }}
            </b-form-group>
          </div>
          <div v-if="request.status === STATUS_DECLINE" class="col-12 col-md-4">
            <b-form-group
              :label="$t('requests.rejection_comment')"
              prop="title"
            >
              {{ request.rejectionComment }}
            </b-form-group>
          </div>
        </div>

        <!--  новые контакты -->
        <fieldset>
          <legend
            id="__BVID__420__BV_label_"
            class="bv-no-focus-ring col-form-label pt-0"
          >
            {{ $t("requests.requests_contacts") }}
          </legend>
          <div
            v-if="request.communications && request.communications.length"
            class="d-flex flex-wrap"
          >
            <div
              v-for="(communication, cIndex) in request.communications"
              :key="'communication' + cIndex"
              class="contact-card px-3 pt-2 pb-2 mr-2 mb-2"
            >
              <div class=" ">
                <h4>{{ communication.clientName }}</h4>
              </div>
              <div>
                <ul class="pl-3">
                  <li
                    v-for="(phone, pIndex) in communication.phones"
                    :key="'phone' + cIndex + '-' + pIndex"
                    class="mb-2"
                  >
                    <div class="d-flex align-items-center">
                      <div class="ml-auto">{{ phone }}</div>
                      <div class="ml-2">
                        <button
                          v-b-tooltip.hover
                          class="btn btn-success btn-sm ml-auto"
                          :title="$t('calls.make_call_to_client')"
                          @click.prevent="
                            makeCallInternal(communication, phone)
                          "
                        >
                          <font-awesome-icon icon="phone" />
                          {{ $t("calls.make_call_to_client") }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>

    <!--Расчет стоимости-->
    <div class="ibox">
      <div class="ibox-title d-flex">
        <h3>{{ $t("requests.cost_calculation") }}</h3>
      </div>
      <div class="ibox-content">
        <div class="row">
          <div class="col-md-4">
            <b-form-group :label="$t('requests.town')" prop="townIds">
              {{ getTownTitle(request.town) }}
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group :label="$t('requests.pest')" prop="title">
              {{ getRequestPestName(request) }}
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group :label="$t('requests.facility')" prop="title">
              {{ getRequestFacilityName(request) }}
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <b-form-group :label="$t('requests.client_type')" prop="title">
              <b-button-group>
                <div v-if="request.clientType === CLIENT_TYPE_IND">
                  {{ $t("requests.type_individual") }}
                </div>
                <div v-else>
                  {{ $t("requests.type_organization") }}
                </div>
              </b-button-group>
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group
              v-if="request.clientType === CLIENT_TYPE_ORG"
              :label="$t('requests.payment_type')"
              prop="paymentType"
            >
              <div v-if="request.paymentType === PT_CASH">
                {{ $t("requests.payment_cash") }}
              </div>
              <div v-else>{{ $t("requests.payment_cashless") }}</div>
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group
              v-if="request.clientType === CLIENT_TYPE_ORG"
              :label="$t('requests.payment_received')"
              prop="clientPaymentReceived"
            >
              <div v-if="request.clientPaymentReceived">
                {{ $t("common.yes") }}
              </div>
              <div v-else>{{ $t("common.no") }}</div>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
              :label="$t('requests.payment_variant')"
              prop="fromExchange"
            >
              <div v-if="request.fromExchange">
                {{ $t("requests.payment_exchange") }}
              </div>
              <div v-else>{{ $t("requests.payment_regular") }}</div>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <b-form-group :label="$t('requests.known_sum')" prop="title">
              <div class="input-group">
                {{ request.knownSum }}
              </div>

              <!--              <b-form-input v-model="request.sum" placeholder="" number></b-form-input>-->
            </b-form-group>
          </div>

          <div class="col-md-2">
            <b-form-group :label="$t('requests.trip_distance')" prop="title">
              {{ request.tripDistance }}
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group :label="$t('requests.trip_sum')" prop="title">
              {{ request.tripSum }}
            </b-form-group>
          </div>
        </div>
      </div>
    </div>

    <!--Результаты просмотра-->
    <div v-if="!correctingResult" class="ibox">
      <div class="ibox-title pr-3">
        <div class="d-flex align-items-center">
          <h3>{{ $t("requests.results") }}</h3>
          <div class="ml-auto">
            <button
              class="btn btn-outline-primary"
              @click.prevent="toggleCorrectResult(true)"
            >
              {{ $t("requests.correct") }}
            </button>
          </div>
        </div>
      </div>
      <div class="ibox-content">
        <div class="row">
          <div class="col-4">
            <b-form-group
              v-if="hasAnyServices(request)"
              :label="$t('requests.services')"
            >
              <ul class="mb-0 pl-3">
                <li
                  v-for="(service, rIndex) in request.services"
                  :key="'r' + rIndex"
                >
                  {{ service.title }} – {{ formatNumber(service.price) }}
                </li>
              </ul>
            </b-form-group>
          </div>
          <div class="col-4">
            <b-form-group
              v-if="hasAnyResources(request)"
              :label="$t('requests.resources')"
            >
              <ul class="mb-0 pl-3">
                <li
                  v-for="(resource, rIndex) in request.resources"
                  :key="'r' + rIndex"
                >
                  {{
                    resource.resource
                      ? resource.resource.title
                      : $t("requests.without_resource")
                  }}
                  <span v-if="resource.count">
                    – {{ $t("requests.solution_volume_short") }}-
                    {{ resource.solutionVolume }}
                    {{ $t("common.unit_l") }},
                    {{ $t("requests.dosage_short") }}.-
                    {{ resource.dosage }}
                    {{ $t("common.unit_ml_l") }},
                    {{ $t("requests.consumption") }} - {{ resource.count
                    }}{{ $t("common.unit_ml") }}</span
                  >
                </li>
              </ul>
            </b-form-group>
          </div>
          <div class="col-4">
            <b-form-group :label="$t('requests.assignee')" prop="assigneeId">
              {{ getRequestAssigneeName(request) }}
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-2">
            <b-form-group :label="$t('requests.trip')" prop="title">
              <span v-if="request.tripSum || request.tripDistance">
                {{ formatNumber(request.tripSum) }}{{ $t("common.cur") }} –
                {{ request.tripDistance }}{{ $t("common.unit_km") }}
              </span>
              <span v-else>{{ $t("common.not_set") }}</span>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group
              v-if="request.tripBackSum || request.tripBackDistance"
              :label="$t('requests.trip_after_last_request')"
              prop="title"
            >
              <span v-if="request.tripBackSum || request.tripBackDistance">
                {{ formatNumber(request.tripBackSum) }}{{ $t("common.cur") }} –
                {{ request.tripBackDistance }}{{ $t("common.unit_km") }}
              </span>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-2">
            <b-form-group
              v-if="hasAnyServices(request)"
              :label="$t('requests.service_price')"
            >
              {{ formatNumber(servicesSum(request)) }}р
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group
              v-if="hasAnyProducts(request)"
              :label="$t('requests.products_price_net')"
            >
              {{ formatNumber(productSum(request)) }}{{ $t("common.cur") }} ({{
                formatNumber(productNetCost(request))
              }}
              {{ $t("common.cur") }})
            </b-form-group>
          </div>

          <div class="col-2">
            <b-form-group
              v-if="equipmentSum(request) > 0"
              :label="$t('requests.equipment')"
            >
              {{ formatNumber(equipmentSum(request)) }}р
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group
              v-if="hasAnyServices(request) > 0"
              :label="$t('requests.earned')"
            >
              <strong> {{ request.earned }}р</strong>
              <span v-if="request.earnPercent">
                ({{ request.earnPercent }} %)
              </span>
              <br />
              <span v-if="request.hasRecord">
                <font-awesome-icon
                  v-b-tooltip.hover
                  icon="star"
                  :title="$t('requests.record')"
                />
                {{ $t("requests.has_record") }}
              </span>
              <span v-else>{{ $t("requests.no_record") }}</span>
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group
              v-if="hasAnyServices(request)"
              :label="$t('requests.assignee_in_hand')"
            >
              {{ formatNumber(calcTotalProfitSum(request))
              }}{{ $t("common.cur") }}
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group
              v-if="hasAnyServices(request)"
              :label="$t('requests.assignee_in_hand')"
            >
              {{ formatNumber(request.paymentSum) }}{{ $t("common.cur") }}
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group
              v-if="hasAnyServices(request)"
              :label="$t('requests.is_paid')"
            >
              <div v-if="request.isPaid">{{ $t("common.yes") }}</div>
              <div v-else>{{ $t("common.no") }}</div>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-2">
            <b-form-group :label="$t('requests.follow_up_treatment')">
              <div v-if="request.repeatAppointed">
                {{ request.repeatDate }}
              </div>
              <div v-else>{{ $t("requests.follow_up_appointed") }}</div>
            </b-form-group>
          </div>
          <div class="col-3">
            <b-form-group :label="$t('requests.duration_min')" prop="title">
              {{ request.duration }}
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <b-form-group
              v-if="request.assigneeComment"
              :label="$t('requests.assignee_comment')"
            >
              <span>{{ request.assigneeComment }}</span>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group :label="$t('requests.documents')">
              <div class="files-block mb-2">
                <div
                  v-for="(file, fIndex) in request.resultFiles"
                  :key="'i' + fIndex"
                  class="d-flex"
                >
                  <a
                    v-b-tooltip.hover
                    class="mr-2"
                    :href="file.url"
                    :title="file.name"
                    target="_blank"
                  >
                    {{ file.name + "." + file.extension }}
                  </a>
                  <div class="ml-2 mr-2">{{ getDate(file.createTime) }}</div>
                  <div v-if="canDeleteResultFiles" class="ml-auto mr-2">
                    <font-awesome-icon
                      icon="trash"
                      @click="deleteResultFile(fIndex)"
                    />
                  </div>
                </div>
              </div>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>

    <!--Редактирование результатов -->
    <div v-if="!loading && correctingResult" class="ibox">
      <div class="ibox-title pr-3">
        <div class="d-flex align-items-center">
          <h3>{{ $t("requests.results_correction") }}</h3>
        </div>
      </div>

      <div class="ibox-content">
        <request-correct-result-form
          :request-model="request"
          :data-loaded="!loading"
          @request-corrected="afterRequestCorrected"
          @cancel-updating="toggleCorrectResult(false)"
        ></request-correct-result-form>
      </div>
    </div>

    <!--Блок исхода прозвона -->
    <div v-if="showAfterCallInfo && !enteringAftercallResults" class="ibox">
      <div class="ibox-title pr-3">
        <div class="d-flex align-items-center">
          <h3>{{ $t("requests.follow_up_call_result") }}</h3>
          <div class="ml-auto">
            <button
              class="btn btn-outline-primary"
              @click.prevent="toggleAftercallResult(true)"
            >
              {{ $t("requests.enter_results") }}
            </button>
          </div>
        </div>
      </div>

      <div class="ibox-content">
        <div class="row">
          <div class="col-4">
            <b-form-group :label="$t('requests.status')" prop="status">
              <span v-if="request.aftercall">
                {{ getAftercallStatusTitle(request.aftercall) }}
              </span>
              <span v-else>{{ $t("requests.without_follow_up_call") }}</span>
            </b-form-group>
          </div>

          <div
            v-if="
              request.aftercall &&
              request.aftercall.status === AFTERCALL_STATUS_NOT_REGISTERED
            "
            class="col-4"
          >
            <b-form-group
              :label="$t('requests.follow_up_call_reason')"
              prop="status"
            >
              {{ getAftercallFailStatusTitle(request.aftercall) }}
            </b-form-group>
          </div>
        </div>
        <div v-if="request.aftercall" class="row">
          <div class="col-4">
            <b-form-group :label="$t('requests.manager')" prop="managerId">
              {{ getAftercallManagerTitle(request.aftercall) }}
            </b-form-group>
          </div>
        </div>

        <b-form-group
          v-if="request.aftercall"
          :label="$t('requests.follow_up_call_comment')"
          prop="title"
        >
          {{ request.aftercall.comment }}
        </b-form-group>
      </div>
    </div>

    <!--Внесение результатов прозвона -->
    <div
      v-if="!loading && showAfterCallInfo && enteringAftercallResults"
      class="ibox"
    >
      <div class="ibox-title pr-3">
        <div class="d-flex align-items-center">
          <h3>{{ $t("requests.follow_up_call_result") }}</h3>
        </div>
      </div>

      <div class="ibox-content">
        <request-enter-aftercall-form
          :request-model="request"
          :data-loaded="!loading"
          @request-corrected="afterAftercallCorrected"
          @cancel-updating="toggleAftercallResult(false)"
        ></request-enter-aftercall-form>
      </div>
    </div>

    <!--Адрес-->
    <div class="ibox">
      <div class="ibox-title d-flex">
        <h3>{{ $t("requests.address") }}</h3>
      </div>
      <div class="ibox-content">
        <div class="row">
          <div class="col-md-3">
            <b-form-group :label="$t('requests.address_postcode')" prop="">
              {{ request.addressIndex ? request.addressIndex : " – " }}
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group :label="$t('requests.address_town')" prop="">
              {{ request.addressTown ? request.addressTown : " – " }}
            </b-form-group>
          </div>

          <div class="col-md-4">
            <b-form-group :label="$t('requests.address_street')" prop="">
              {{ request.address }}
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group
              v-if="showSubway"
              :label="$t('requests.address_subway')"
              prop="subwayId"
            >
              {{ getSubwayTitle(request.subway) }}
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-2">
            <b-form-group :label="$t('requests.address_house')" prop="">
              {{ request.addressHouse ? request.addressHouse : " – " }}
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group :label="$t('requests.address_block')" prop="">
              {{ request.addressBlock ? request.addressBlock : " – " }}
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group :label="$t('requests.address_entrance')" prop="">
              {{ request.addressEntrance ? request.addressEntrance : " – " }}
            </b-form-group>
          </div>

          <div class="col-md-2">
            <b-form-group
              :label="$t('requests.address_floor')"
              prop="appartment"
            >
              {{ request.addressFloor ? request.addressFloor : " – " }}
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group
              :label="$t('requests.address_appartment')"
              prop="appartment"
            >
              <template #label>
                {{ $t("requests.address_appartment") }}
                <request-appartment-visibility
                  class="ml-auto"
                  :request="request"
                  :assignee-can-see-address="request.assigneeCanSeeAddress"
                />
              </template>
              {{ request.appartment ? request.appartment : " – " }}
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group
              :label="$t('requests.address_intercom')"
              prop="appartment"
            >
              {{ request.addressIntercom ? request.addressIntercom : " – " }}
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8">
            <b-form-group
              :label="$t('requests.address_info')"
              prop="addressInfo"
            >
              {{ request.addressInfo ? request.addressInfo : " – " }}
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
              :label="$t('requests.address_coords')"
              prop="addressInfo"
            >
              {{ request.addressCoords ? request.addressCoords : " – " }}
            </b-form-group>
          </div>
        </div>

        <request-form-map
          v-if="false"
          ref="map"
          :town-id="request.townId"
          :coords="requests.addressCoords"
          @distance="distanceCalculated"
        />
      </div>
    </div>

    <!-- Выезды по цепочке -->
    <requests-sequence-history
      v-if="sequence && sequence.length > 1"
      :sequence="sequence"
      :request:="request"
    />

    <!--Время-->
    <div class="ibox">
      <div class="ibox-title d-flex">
        <h3>{{ $t("requests.time") }}</h3>
      </div>
      <div class="ibox-content">
        <div class="row">
          <div class="col-md-4">
            <b-form-group :label="$t('requests.date')" prop="duration">
              {{ getRequestDatetime(request) }}
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <b-form-group :label="$t('requests.description')" prop="comment">
              {{ request.comment }}
            </b-form-group>
          </div>

          <div class="col-12">
            <b-form-group :label="$t('requests.warning')" prop="warning">
              {{ request.warning }}
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueMaskDirective } from "v-mask";
import { getUserName } from "@/modules/crm/common";
import {
  getClientName,
  getClientNameWithContacts,
} from "@/modules/crm/decorators/client.js";
import { getStatusTitle } from "@/modules/crm/decorators/status.js";
import {
  calcProfitSum,
  CLIENT_TYPE_IND,
  CLIENT_TYPE_ORG,
  equipmentSum,
  getDate,
  getRequestAddressFormatted,
  getRequestAssigneeName,
  getRequestClientName,
  getRequestCreateDatetime,
  getRequestDatetime,
  getRequestFacilityName,
  getRequestManagerName,
  getRequestNumber,
  getRequestPestName,
  getRequestSourceUrl,
  getRequestStatus,
  hasAnyResources,
  hasAnyServices,
  PT_CASH,
  PT_CASHLESS,
  servicesSum,
  STAGE_DEAL,
  STATUS_DECLINE,
  STATUS_DONE,
  STATUS_PAID,
  getRequestStageTitle,
  calcTotalProfitSum,
  hasAnyProducts,
  productSum,
  productNetCost,
} from "@/modules/pest/decorators/requests.js";
import { TYPE_MANAGER } from "@/modules/users/decorators/users.js";
import {
  getTownTitle,
  DXB,
  MSK,
  MSK_C,
  SPB,
  SPB_C,
} from "@/modules/pest/decorators/towns.js";
import clone from "just-clone";
import { getSubwayTitle } from "@/modules/pest/decorators/subways.js";
import RequestFormMap from "@/modules/pest/views/requests/card/RequestFormMap.vue";
import { formatNumber } from "../../../../decorators/common.js";
import RequestCorrectResultForm from "@/modules/pest/views/requests/view/RequestCorrectResultForm";
import RequestAppartmentVisibility from "@/modules/pest/views/requests/dispatching/_request/view/RequestAppartmentVisibility";
import RequestEnterAftercallForm from "@/modules/pest/views/requests/view/RequestEnterAftercallForm";
import {
  AFTERCALL_STATUS_NOT_REGISTERED,
  getAftercallFailStatusTitle,
  getAftercallManagerTitle,
  getAftercallStatusTitle,
} from "@/modules/pest/decorators/aftercall";
import RequestCallNotifications from "@/modules/pest/views/requests/kanban/popup/RequestCallNotifications.vue";
import RequestsSequenceHistory from "@/modules/pest/views/requests/kanban/popup/RequestSequenceHistory.vue";
import * as Sentry from "@sentry/vue";
import { canDeleteResultFiles } from "@/modules/users/access/common";
import { getRequestProjectTitle } from "@/modules/pest/decorators/requests.js";

export default {
  name: "RequestPopupInfo",
  directives: { mask: VueMaskDirective },
  components: {
    RequestsSequenceHistory,
    RequestCallNotifications,
    RequestEnterAftercallForm,
    RequestAppartmentVisibility,
    RequestCorrectResultForm,
    RequestFormMap,
  },
  props: ["requestModel", "dataLoaded", "embedded", "sequence"],
  data() {
    return {
      STATUS_DECLINE,
      AFTERCALL_STATUS_NOT_REGISTERED,
      MSK,
      SPB,
      DXB,
      MSK_C,
      SPB_C,
      PT_CASHLESS,
      PT_CASH,
      CLIENT_TYPE_ORG,
      CLIENT_TYPE_IND,
      TYPE_MANAGER,
      loading: false,
      reanimationLoading: false,
      correctingResult: false,
      enteringAftercallResults: false,
      request: {
        clientType: CLIENT_TYPE_IND,
        stage: STAGE_DEAL,
        dateDay: "",
        sum: "",
        dateTime: 10,
        duration: 120,
        pestId: null,
        pest: null,
        address: null,
        addressInfo: null,
        addressCoords: null,
        appartment: null,
        townId: this.$store.state.user.profile.townId,
        managerId: this.$store.state.user.profile.id,
        manager: this.$store.state.user.profile,
      },
    };
  },
  computed: {
    showAfterCallInfo() {
      return [STATUS_DONE, STATUS_PAID].includes(this.request.status);
    },
    showSubway() {
      return [SPB, MSK].includes(this.request.townId);
    },
    addressRequired() {
      return [SPB_C, MSK_C].includes(this.request.townId);
    },
    showContactPhone1() {
      return this.request.contactPhone || this.showContactPhone1Field;
    },
    showContactPhone2() {
      return this.request.contactPhone2 || this.showContactPhone2Field;
    },
    clientName() {
      return this.request && this.request.clientId && this.request.client
        ? getClientName(this.request.client)
        : "";
    },
    managerName() {
      return this.request && this.request.managerId && this.request.manager
        ? getUserName(this.request.manager)
        : "";
    },
    assigneeName() {
      return this.request && this.request.assigneeId && this.request.assignee
        ? getUserName(this.request.assignee)
        : "";
    },
    id() {
      return this.request.id;
    },
  },
  watch: {
    requestModel: {
      immediate: true,
      handler(obj) {
        this.request = clone(obj);
      },
    },
  },
  created() {},
  methods: {
    getRequestStageTitle,
    getRequestProjectTitle,
    productNetCost,
    productSum,
    hasAnyProducts,
    canDeleteResultFiles,
    getDate,
    getRequestAssigneeName,
    getAftercallStatusTitle,
    getAftercallFailStatusTitle,
    getAftercallManagerTitle,
    formatNumber,
    equipmentSum,
    hasAnyResources,
    hasAnyServices,
    servicesSum,
    getRequestAddressFormatted,
    getRequestNumber,
    getRequestCreateDatetime,
    getTownTitle,
    calcProfitSum,
    calcTotalProfitSum,
    getRequestPestName,
    getRequestManagerName,
    getRequestClientName,
    getRequestFacilityName,
    getClientName,
    getUserName,
    getStatusTitle,
    getSubwayTitle,
    getClientNameWithContacts,
    getRequestDatetime,
    getRequestStatus,
    getRequestSourceUrl,
    deleteResultFile(fileIndex) {
      let delUrl = "/pest/request/delete-result-file/";
      this.$http
        .post(delUrl, {
          requestId: this.request.id,
          fileId: this.request.resultFiles[fileIndex]["id"],
        })
        .then(() => {
          this.request.resultFiles.splice(fileIndex, 1);
          this.$bvToast.toast(this.$t("files.file_deleted"), {
            variant: "success",
          });
        })
        .catch(() => {
          this.$bvToast.toast(this.$t("common.error"), {
            variant: "danger",
          });
        });
    },
    clientNameF() {
      return this.request && this.request.clientId && this.request.client
        ? getClientName(this.request.client)
        : "";
    },
    confirmReanimation() {
      if (confirm(this.$t("requests.confirm_request_return"))) {
        this.$emit("reanimate-request", this.request);
      }
    },
    cancelUpdate() {
      this.$emit("cancel-updating", this.request);
    },
    onReset(evt) {
      evt.preventDefault();
    },
    toggleCorrectResult(updating) {
      this.correctingResult = updating;
    },
    afterRequestCorrected(request) {
      console.log(request);
      this.$emit("request-changed", request);
      this.toggleCorrectResult(false);
    },
    toggleAftercallResult(updating) {
      this.enteringAftercallResults = updating;
    },
    afterAftercallCorrected(request) {
      this.$emit("request-changed", request);
      this.toggleAftercallResult(false);
    },
    createReturnVisit(request) {
      this.$emit("add-new-request", request);
    },
    makeCallInternal(communication, phone) {
      console.log(communication);
      if (this.calling) {
        return;
      }
      let callUrl = "/pest/request/manager-call/" + this.request.id;
      this.calling = true;
      this.$http
        .get(callUrl, {
          params: {
            phone: phone,
            clientId: communication.clientId,
          },
        })
        .then((response) => {
          this.$bvToast.toast(this.$t("calls.calling"), {
            title: "Ожидайте",
            variant: "success",
          });
          this.calling = false;
          // if (successCallback) {
          //   successCallback();
          // }
        })
        .catch((error) => {
          let message = this.$t("requests.cant_make_the_call");
          if (error.response.data.error) {
            message = error.response.data.error;
          }
          this.$bvToast.toast(message, {
            title: this.$t("common.error"),
            variant: "danger",
          });
          this.calling = false;
          Sentry.captureException(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-card {
  border: 1px solid #e5e6e7;
  width: 250px;
  min-width: 250px;
}

.contact-card ul {
  list-style: none;
  margin: 0;
  padding: 0 !important;
}

.contact-card ul .ml-auto:first-child {
  margin: 0px !important;
}
@media (max-width: 640px) {
  .toptitle {
    display: block !important;
  }
  .toptitle > div {
    margin: 5px auto !important;
  }
}
</style>
