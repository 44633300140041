import { getPestTitle } from "@/modules/pest/decorators/pests";
import { getAccountTitle } from "@/modules/direct/decorators/accounts";
import { townOptions } from "@/modules/pest/decorators/towns";
import dayjs from "dayjs";
import t from "@/i18n";

const getReportTitle = function (report) {
  if (!report) {
    return t("common.not_set");
  }
  return [
    "#" + report.id,
    getReportDate(report),
    getAccountTitle(report.account),
  ].join(", ");
};

export { getReportTitle };

export const getReportPestName = function (report) {
  if (!report.pest) {
    return t("common.not_set");
  }
  return getPestTitle(report.pest);
};

export const getReportDate = function (report) {
  if (!report.date) {
    return t("common.not_set");
  }
  return dayjs(report.date).format("DD.MM.YYYY");
};

export const getReportRegionName = function (report) {
  if (!report.regionId || !townOptions[report.regionId]) {
    return t("common.not_set");
  }
  return townOptions[report.regionId].text;
};

export const getReportAccountName = function (report) {
  if (!report.account) {
    return t("common.not_set");
  }
  return getAccountTitle(report.account);
};

export const STATUS_NEW = 0; // новый
export const STATUS_QUEUED = 2; // в очереди на запрос
export const STATUS_REQUESTED = 4; // отправлен запрос
export const STATUS_WAITING = 6; // ожидание офлайн отчета
export const STATUS_RECEIVED = 8; // отчет получен
export const STATUS_PROCESSING = 10; // отчет обрабатывается
export const STATUS_DONE = 12; // отчет обработан

export const statusTitles = {
  [STATUS_NEW]: "Новый",
  [STATUS_QUEUED]: "В очереди на запрос",
  [STATUS_REQUESTED]: "Отправлен запрос",
  [STATUS_WAITING]: "Ожидание офлайн отчета",
  [STATUS_RECEIVED]: "Отчет получен",
  [STATUS_PROCESSING]: "Отчет обрабатывается",
  [STATUS_DONE]: "Отчет обработан",
};

export const statusOptions = Object.entries(statusTitles).map(([k, v]) => {
  return { id: k, text: v };
});

export const getReportStatusTitle = function (report) {
  if (!report.status) {
    return t("common.not_set");
  }
  return statusTitles[report.status];
};
