export const ru = {
  stats: {
    stats: "Статистика",
    total: "Итого",
    name: "Сотрудник",
    count: "Общее число заявок",
    countNew: "Новых заявок",
    countSuccess: "Закрытых",
    countDeclined: "Отмененных",
    firstTimeCount: "Количество первичных заявок",
    firstTimeSum: "Общая сумма первичных заявок",
    averageFirstTimeSum: "Средняя сумма первичных заявок",
    secondTimeCount: "Количество вторичных заявок",
    secondTimeSum: "Общая сумма вторичных заявок",
    averageSecondTimeSum: "Средняя сумма вторичных заявок",
    recordCount: "Количество рекордов",
    recordSum: "Сумма рекордов",
    paymentCashlessCount: "Количество безналов",
    paymentCashlessSum: "Сумма безналов",
    tripSum: "Транспортные расходы",
    averageTripSum: "Средние транспортные расходы",
    averageRecordSum: "Средний чек рекорда",
    tripDistance: "Пройденное расстояние (км)",
    equipmentSum: "Аренда оборудования",
    fineSum: "Штрафы",
    profitSum: "Заработано мастером",
    teamPercentSum: "Бонус от команды",
    teamBonusSum: "Бонус от команды",
    toPaySum: "Общая сумма к сдаче",
    toPaySumLeft: "Осталось сдать",
    paymentSum: "Сдал фактически",
    myData: "Мои данные",
    myTeam: "Моя команда",
    totalSum: "Сумма заявок",
  },
};
export const en = {
  stats: {
    stats: "Stats",
    total: "Total",
    name: "Name",
    count: "Total request count",
    countNew: "New requests",
    countSuccess: "Closed",
    countDeclined: "Canceled",
    firstTimeCount: "First time count",
    firstTimeSum: "First time sum",
    averageFirstTimeSum: "Average first time sum",
    secondTimeCount: "Follow-ups count",
    secondTimeSum: "Follow-ups sum",
    averageSecondTimeSum: "Average follow-ups sum",
    recordCount: "Records count",
    recordSum: "Records sum",
    paymentCashlessCount: "Cashless payment count",
    paymentCashlessSum: "Cashless payment sum",
    tripSum: "Trip sum",
    averageTripSum: "Average trip sum",
    averageRecordSum: "Average record sum",
    tripDistance: "Trip distance (km)",
    equipmentSum: "Equipment rent",
    fineSum: "Fines",
    profitSum: "Master earn",
    teamPercentSum: "Team bonus",
    teamBonusSum: "Team bonus",
    toPaySum: "Total sum to pay",
    toPaySumLeft: "Sum to pay left",
    paymentSum: "Paid",
    myData: "My data",
    myTeam: "My team",
    totalSum: "Total sum",
  },
};
