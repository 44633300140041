<template>
  <div>{{ $t("requests.appartment_is_hidden_from_assignee") }}</div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";

export default {
  name: "LogAppartmentForbidden",
  components: {},
  props: ["log"],
  data() {
    return {
      show: false,
    };
  },
  computed: {},
  watch: {
    log: {
      immediate: true,
      handler(obj) {},
    },
  },
  methods: {
    getUserName,
    toggle() {
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss" scoped></style>
