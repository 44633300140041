import { getUserName } from "@/modules/crm/common.js";
import dayjs from "dayjs";
import t from "@/i18n";

export const getMessageTime = function (message) {
  return dayjs.unix(message.timeUpdated).format("DD.MM.YYYY HH:mm");
};
export const getMessageTitle = function (message) {
  if (!message || !message.title) {
    return t("common.not_set");
  }
  return message.title;
};

const STATUS_NEW = 0;
const STATUS_OK = 1;
const STATUS_NOT_SENT = -9;
const STATUS_SENT = 2;
const STATUS_RECEIVED = 3;
const STATUS_ERROR = -2;

export const statusTitles = {
  [STATUS_NEW]: "Новое",
  [STATUS_OK]: "Ок",
  [STATUS_NOT_SENT]: "Не удалось отправить",
  [STATUS_SENT]: "Отправлен",
  [STATUS_RECEIVED]: "Получено",
  [STATUS_ERROR]: "Ошибка",
};
export const getMessageStatusTitle = function (message) {
  return statusTitles[message.status];
};

export const getMessageUserName = function (message) {
  if (!message.user) {
    return "Не определен";
  }
  return getUserName(message.user);
};
