export const ru = {
  logistics: {
    msg_no_working_at_time: "Исполнитель не работает в это время",
    msg_time_is_not_scheduled: "Время не задано в расписании",
    msg_assignee_without_skill:
      "Исполнитель не может обрабатывать вредителей по данной заявке",
    msg_no_skill: "Нет квалификации",
    msg_assignee_updated: "Исполнитель изменен",
    msg_assignee_not_updated: "Не удалось изменить исполнителя",
    msg_time_cannot_be_updated: "Не удалось изменить время",
  },
};
export const en = {
  logistics: {
    msg_no_working_at_time: "Assignee is not working at this time",
    msg_time_is_not_scheduled: "Time is not set in schedule",
    msg_assignee_without_skill:
      "Assignee can't handle pests withing this request",
    msg_no_skill: "No required skills",
    msg_assignee_updated: "Assignee changed",
    msg_assignee_not_updated: "Assignee not changed",
    msg_time_cannot_be_updated: "Time cannot be changed",
  },
};
