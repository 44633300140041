export const ru = {
  sources: {
    sources: "Источники",
    source_list: "Перечень источников",
    settings_hint: "Метки с Roistat",
    source: "Источник",
    add_source: "Добавить источник",
    new_source: "Новый источник",
    source_update: "Изменение источника",
    source_updated: "Источник отредактирован",
    cant_create_source: "Не удалось добавить источник",
    cant_delete_source: "Не удалось удалить источник",
    source_created: "Источник добавлен",
    source_deleted: "Источник удален",
    delete_source_question: "Вы действительно хотите удалить источник?",
    type: "Тип",
    target: "Назначение",
    search_only_by_phone: "Поиск только по телефону",
    project: "Проект",
    without_source: "Без источника",

    type_roistat: "Ройстат",
    type_manual: "Ручной источник",

    target_lead: "Leads",
    target_contract: "Contracts",
  },
};
export const en = {
  sources: {
    sources: "Sources",
    source_list: "Source list",
    settings_hint: "Roistat labels",
    source: "Source",
    add_source: "Add source",
    new_source: "New source",
    change_source: "Change source",
    source_update: "Update source",
    source_updated: "Source changed",
    cant_create_source: "Can't add source",
    cant_delete_source: "Can't delete source",
    source_created: "Source added",
    source_deleted: "Source deleted",
    delete_source_question: "Do you want to delete the source?",
    type: "Type",
    target: "Target",
    search_only_by_phone: "Search only by phone",
    project: "Project",
    without_source: "Без источника",

    type_roistat: "Roistat",
    type_manual: "Manual source",

    target_lead: "Лиды",
    target_contract: "Заявки",
  },
};
