export const ru = {
  rejectCauses: {
    rejectCauses: "Причины отказа",
    rejectCause_list: "Перечень причин отказа",
    setting_hint: "Указываются при отмене заявки",
    rejectCause: "Причина отказа",
    add_rejectCause: "Добавить причину отказа",
    new_rejectCause: "Новый причину отказа",
    rejectCause_update: "Изменение причины отказа",
    rejectCause_updated: "Причина отказа отредактирована",
    cant_create_rejectCause: "Не удалось добавить причину отказа",
    cant_delete_rejectCause: "Не удалось удалить причину отказа",
    rejectCause_created: "Причина отказа добавлена",
    rejectCause_deleted: "Причина отказа удалена",
    delete_rejectCause_question:
      "Вы действительно хотите удалить причину отказа?",
    other_reason_label: "Другие (показывать окно ввода)",
  },
};
export const en = {
  rejectCauses: {
    rejectCauses: "Reject causes",
    rejectCause_list: "Reject cause list",
    setting_hint: "Choosing on request cancellation",
    rejectCause: "Reject cause",
    add_rejectCause: "Add reject cause",
    new_rejectCause: "New reject cause",
    change_rejectCause: "Change reject cause",
    rejectCause_update: "Update reject cause",
    rejectCause_updated: "Reject cause changed",
    cant_create_rejectCause: "Can't add reject cause",
    cant_delete_rejectCause: "Can't delete reject cause",
    rejectCause_created: "Reject cause added",
    rejectCause_deleted: "Reject cause deleted",
    delete_rejectCause_question: "Do you want to delete the reject сause?",
    other_reason_label: "Other, show comment field",
  },
};
