<template>
  <b-form
    id="data-record-form"
    ref="record"
    :model="record"
    class="data-record-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="div">
      <div class="row">
        <div class="col-12">
          <b-form-group :label="$t('common.title')" prop="title">
            <b-form-input
              v-model="record.title"
              :placeholder="$t('common.title')"
              tabindex="1"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group label="Описание" prop="title">
            <b-form-textarea
              v-model="record.comment"
              placeholder="Описание"
              tabindex="1"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-button variant="primary" @click="createOrUpdate">
            <font-awesome-icon icon="check" />
            {{ $t("common.save") }}
          </b-button>

          <a
            v-if="record.id !== 'new'"
            href=""
            class="btn btn-outline btn-danger ml-2"
            @click.prevent="deleteRecord"
          >
            <font-awesome-icon icon="trash" />
            Удалить
          </a>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import { VueMaskDirective } from "v-mask";

export default {
  name: "DataRecordForm",
  directives: { mask: VueMaskDirective },
  components: {},
  props: ["record"],
  data() {
    return {
      phoneMask: false,
      loading: false,
    };
  },
  computed: {
    id() {
      return this.record.id;
    },
    name() {
      let name = this.record.name ? this.record.name : "Новый вредитель";
      return name ? name : "Вредитель";
    },
  },
  watch: {},
  created: function () {},
  methods: {
    getUserName,
    update: function () {
      let updateUrl;
      updateUrl = "/record/records/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.record)
        .then((response) => {
          this.record = response.data;
          this.loading = false;
          this.$eventHub.$emit("update-record-record-info", this.record);
          // this.$router.push({name: 'data-record-view', params: {id: this.record.id}});
          this.$bvToast.toast(this.$t("common.data_saved"), {
            title: "Вредитель отредактирован",
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: this.$t("common.error_during_saving"),
              type: "danger",
            });
          }
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "/record/records";
      this.loading = true;
      this.$http
        .post(updateUrl, this.record)
        .then((response) => {
          this.record = response.data;
          this.$eventHub.$emit("update-record-record-info", this.record);
          this.$router.push({
            name: "data-record-update",
            params: { id: this.record.id },
          });
          this.$bvToast.toast("Вредитель добавлен", {
            title: "Вредитель добавлен",
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.error"), {
              title: "Не удалось добавить вредитель",
              variant: "danger",
            });
          }
        });
    },
    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById("data-record-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast(this.$t("common.data_is_saving"), {
            title: this.$t("common.please_wait"),
            variant: "info",
          });
          return;
        }
        if (this.id === "new") {
          this.create();
        } else {
          this.update();
        }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast(this.$t("common.form_has_errors"), {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
    deleteRecord() {
      if (confirm("Вы действительно хотите удалить вредитель?")) {
        let deleteUrl = "/record/records/" + this.record.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            this.loading = false;
            this.$router.push({ name: "data-record-list" });
            this.$bvToast.toast("Вредитель удален", {
              title: "Вредитель удален",
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast(this.$t("common.error"), {
                title: "Не удалось удалить вредитель",
                variant: "danger",
              });
            }
          });
      }
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}
</style>
