<template>
  <div>
    <table class="table table-striped request-info">
      <tbody>
        <!--          <tr>-->
        <!--            <td>-->
        <!--              Статус-->
        <!--            </td>-->
        <!--            <td>-->
        <!--              {{ getRequestStatus(request) }}-->
        <!--            </td>-->
        <!--          </tr>-->
        <tr>
          <td class="title-column">
            <font-awesome-icon icon="list-ol" fixed-width />
            {{ $t("requests.number") }}
          </td>
          <td>
            {{ getRequestNumber(request) }}
          </td>
        </tr>
        <tr v-if="request.isOrganization && request.organizationTitle">
          <td>
            <font-awesome-icon icon="building" fixed-width />
            {{ $t("requests.type_organization") }}
          </td>
          <td>
            {{ request.organizationTitle }}
          </td>
        </tr>

        <tr v-if="request.isOrganization">
          <td>
            <font-awesome-icon icon="money-bill" fixed-width />
            {{ $t("requests.payment_type") }}
          </td>
          <td>
            {{ getPaymentTypeTitle(request) }}
          </td>
        </tr>

        <tr>
          <td>
            <font-awesome-icon icon="user-cog" fixed-width />
            {{ $t("requests.assignee") }}
          </td>
          <td>
            <span v-if="request.assigneeId">
              {{ getRequestAssigneeName(request) }}
              <router-link
                v-if="false"
                :to="{
                  name: 'users-user-view',
                  params: { id: request.assigneeId },
                }"
                class=""
              >
                <font-awesome-icon
                  icon="external-link-square-alt"
                  fixed-width
                />
              </router-link>
            </span>
            <span v-else>{{ $t("common.not_set") }}</span>
          </td>
        </tr>

        <tr v-if="request.assignee1stId">
          <td>
            <font-awesome-icon icon="user-cog" fixed-width />
            {{ $t("requests.assignee1st") }}
          </td>
          <td>
            <span v-if="request.assignee1stId">
              {{ getRequestAssignee1stName(request) }}
              <router-link
                v-if="false"
                :to="{
                  name: 'users-user-view',
                  params: { id: request.assignee1stId },
                }"
                class=""
              >
                <font-awesome-icon
                  icon="external-link-square-alt"
                  fixed-width
                />
              </router-link>
            </span>
            <span v-else>{{ $t("common.not_set") }}</span>
          </td>
        </tr>

        <tr>
          <td>
            <font-awesome-icon icon="clock" fixed-width />
            {{ $t("requests.time") }}
          </td>
          <td>
            {{ getRequestDatetime(request) }} –
            {{ getRequestDuration(request) }}
          </td>
        </tr>

        <tr v-if="request.town">
          <td>
            <font-awesome-icon icon="map-marker" fixed-width />
            {{ $t("requests.town") }}
          </td>
          <td>
            {{ request.town.title }}
          </td>
        </tr>
        <tr v-if="request.subwayOld">
          <td>
            <font-awesome-icon icon="map" fixed-width />
            {{ $t("requests.address_subway_old") }}
          </td>
          <td>
            {{ request.subwayOld }}
          </td>
        </tr>
        <tr v-if="request.subway">
          <td>
            <font-awesome-icon icon="map" fixed-width />
            {{ $t("requests.address_subway") }}
          </td>
          <td>
            {{ getSubwayTitle(request.subway) }}
          </td>
        </tr>
        <tr>
          <td>
            <font-awesome-icon icon="map" fixed-width />
            {{ $t("requests.address") }}
          </td>
          <td>
            <span v-if="isWorker" v-html="getMapLink(request)"></span>
            <span v-else>
              {{ getRequestAddressFormatted(request) }}
            </span>
          </td>
        </tr>
        <tr v-if="request.addressInfo">
          <td>
            <font-awesome-icon icon="map" fixed-width />
            {{ $t("requests.address_info_add") }}
          </td>
          <td>
            {{ request.addressInfo }}
          </td>
        </tr>
        <tr v-if="false">
          <td>
            <font-awesome-icon icon="map" fixed-width />
            {{ $t("requests.address_coords") }}
          </td>
          <td>
            {{ request.addressCoords }}
          </td>
        </tr>

        <tr
          v-if="
            request.addressEntrance &&
            (showAppartmentAfterRequest || canSeeAppartment)
          "
        >
          <td>
            <font-awesome-icon icon="door-open" fixed-width />
            {{ $t("requests.address_entrance") }}
          </td>
          <td>{{ request.addressEntrance }}</td>
        </tr>
        <tr
          v-if="
            request.addressFloor &&
            (showAppartmentAfterRequest || canSeeAppartment)
          "
        >
          <td>
            <font-awesome-icon icon="list-ol" fixed-width />
            {{ $t("requests.address_floor") }}
          </td>
          <td>{{ request.addressFloor }}</td>
        </tr>

        <tr
          v-if="
            request.addressIntercom &&
            (showAppartmentAfterRequest || canSeeAppartment)
          "
        >
          <td>
            <font-awesome-icon icon="phone" fixed-width />
            {{ $t("requests.address_intercom") }}
          </td>
          <td>{{ request.addressIntercom }}</td>
        </tr>

        <tr>
          <td>
            <font-awesome-icon icon="building" fixed-width />
            {{ $t("requests.address_appartment") }}
          </td>
          <td>
            <div class=" ">
              <div v-if="canIViewAppartment">
                <div v-if="showAppartmentAfterRequest || canSeeAppartment">
                  <span v-if="request.appartment">{{
                    request.appartment
                  }}</span>
                  <span v-else>{{
                    $t("requests.address_appartment_not_set")
                  }}</span>
                </div>
                <div v-else class="d-flex">
                  <div>{{ $t("requests.click_to_see_appartment") }}</div>
                  <div class="text-right" style="width: 200px">
                    <a
                      v-if="!requestingAddress"
                      class="btn btn-primary btn-sm"
                      @click.prevent="requestToShowAddress"
                    >
                      <font-awesome-icon icon="map" />
                      {{ $t("requests.show_to_me") }}
                    </a>
                    <a v-else class="btn btn-primary btn-sm" @click.prevent>
                      <font-awesome-icon spin icon="rotate" />
                      {{ $t("common.loading") }}
                    </a>
                  </div>
                </div>
              </div>
              <div v-else>{{ $t("requests.appartment_is_forbidden") }}</div>

              <div v-if="!canIViewAppartment" class="ml-auto">
                <a
                  v-if="!requestingAddress"
                  class="btn btn-primary btn-sm"
                  @click.prevent="requestToShowAddress()"
                >
                  <font-awesome-icon icon="map" />
                  {{ $t("requests.show_to_me") }}
                </a>
                <a v-else class="btn btn-primary btn-sm" @click.prevent>
                  <font-awesome-icon spin icon="rotate" />
                  {{ $t("common.loading") }}
                </a>
              </div>
            </div>
          </td>
        </tr>

        <tr v-if="canOpenToAssignee">
          <td>
            <font-awesome-icon icon="building" fixed-width />
            {{ $t("requests.appartment_visibility") }}
          </td>

          <td>
            <div class="d-flex">
              <div class="">
                <span v-if="canAssigneeViewAppartment">
                  <font-awesome-icon icon="check" fixed-width />
                  {{ $t("requests.appartment_is_shown_to_assignee") }}
                </span>
                <span v-else>
                  <font-awesome-icon icon="times" fixed-width />
                  {{ $t("requests.appartment_is_hidden_from_assignee") }}
                </span>
              </div>
              <div class="ml-auto">
                <div v-if="canOpenToAssignee">
                  <button
                    v-if="!opening"
                    class="btn btn-primary btn-sm"
                    @click.prevent="
                      toggleToAssignee(!canAssigneeViewAppartment)
                    "
                  >
                    <span v-if="canAssigneeViewAppartment">
                      <font-awesome-icon icon="eye-slash" />
                      {{ $t("requests.hide_from_assignee") }}
                    </span>
                    <span v-else>
                      <font-awesome-icon icon="eye" />
                      {{ $t("requests.open_to_assignee") }}
                    </span>
                  </button>
                  <button
                    v-else
                    class="btn btn-primary btn-sm"
                    @click.prevent=""
                  >
                    <font-awesome-icon spin icon="rotate" />
                    <span v-if="canAssigneeViewAppartment">
                      {{ $t("requests.opening_appartment") }}
                    </span>
                    <span v-else> {{ $t("requests.hiding_appartment") }} </span>
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>

        <tr v-for="(pInfo, pIndex) in phones" :key="'p' + pIndex">
          <td>
            <font-awesome-icon icon="phone" fixed-width />
            {{ $t("requests.phone") }}
          </td>
          <td>
            <div class="d-flex">
              <div>
                <div v-if="canViewPhone" class="">{{ pInfo.phone }}</div>
                <div class="">{{ pInfo.clientName }}</div>
              </div>
              <div v-if="request.id !== 'new'" class="ml-auto">
                <button
                  v-if="calling"
                  class="btn btn-success btn-sm"
                  @click.prevent=""
                >
                  <font-awesome-icon spin icon="rotate" />
                  {{ $t("requests.calling") }}
                </button>

                <button
                  v-else
                  v-b-modal.modal-call-cause
                  class="btn btn-success btn-sm"
                  @click.prevent="setCallInfo(pInfo.cIndex, pInfo.pIndex)"
                >
                  <font-awesome-icon icon="phone" />
                  {{ $t("requests.make_call") }}
                </button>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <font-awesome-icon icon="money-bill" fixed-width />

            {{ $t("requests.known_sum_manager") }}
          </td>
          <td>
            {{ request.knownSum }}
          </td>
        </tr>

        <tr v-if="request.warning">
          <td>
            <font-awesome-icon icon="exclamation-triangle" fixed-width />
            {{ $t("requests.warning") }}
          </td>
          <td>
            <span>{{ request.warning }}</span>
          </td>
        </tr>
        <tr v-if="request.pest">
          <td>
            <font-awesome-icon icon="bug" fixed-width />
            {{ $t("requests.pest") }}
          </td>
          <td :style="'color: ' + getRequestColor(request)">
            <span>{{ getPestTitle(request.pest) }}</span>
          </td>
        </tr>
        <tr v-if="request.facility">
          <td>
            <font-awesome-icon icon="building" fixed-width />
            {{ $t("requests.facility") }}
          </td>
          <td>
            <span>{{ getFacilityTitle(request.facility) }}</span>
          </td>
        </tr>

        <tr v-if="request.fromExchange">
          <td>
            <font-awesome-icon icon="hand-holding-usd" fixed-width />
            {{ $t("requests.extra") }}
          </td>
          <td>
            <span> {{ $t("requests.payment_exchange") }}</span>
          </td>
        </tr>

        <tr v-if="request.comment">
          <td>
            <font-awesome-icon icon="comment" fixed-width />
            {{ $t("common.comment") }}
          </td>
          <td style="word-break: break-word">
            <span>{{ request.comment }}</span>
          </td>
        </tr>
        <!--          <tr>-->
        <!--            <td>-->
        <!--              Дата создания-->
        <!--            </td>-->
        <!--            <td>-->
        <!--              {{ getRequestCreateTime(request) }}-->
        <!--            </td>-->
        <!--          </tr>-->
      </tbody>
    </table>

    <b-modal
      id="modal-call-cause"
      ref="modal"
      :title="$t('requests.specify_call_cause')"
      :cancel-title="$t('common.cancel')"
      :ok-title="$t('requests.make_call')"
      title-tag="h3"
      @ok="makeCall"
    >
      <request-call-cause :call="call"></request-call-cause>
    </b-modal>
  </div>
</template>

<script>
import {
  getCommunicationPhoneList,
  getDecisionTime,
  getPaymentTypeTitle,
  getRequestAddressFormatted,
  getRequestAssignee1stName,
  getRequestAssigneeName,
  getRequestClientName,
  getRequestColor,
  getRequestCreateTime,
  getRequestDatetime,
  getRequestDeadline,
  getRequestDuration,
  getRequestManagerName,
  getRequestNumber,
  getRequestStatus,
  getRequestStatusTitle,
  getRequestTitle,
  getRequestUpdateTime,
  PT_CASHLESS,
  STATUS_DONE,
} from "@/modules/pest/decorators/requests.js";
import { VueMaskDirective } from "v-mask";
import { getUserName } from "@/modules/crm/common.js";
import { getPestTitle } from "@/modules/pest/decorators/pests.js";
import RequestCallCause from "@/modules/pest/views/requests/view/RequestCallCause.vue";
import { getFacilityTitle } from "@/modules/pest/decorators/facilities.js";
import {
  canSeeRequestAppartment,
  canViewPhone,
  isWorker,
} from "@/modules/users/access/common.js";
import * as Sentry from "@sentry/vue";
import { getSubwayTitle } from "../../../decorators/subways";

export default {
  name: "RequestInfo",
  directives: { mask: VueMaskDirective },
  components: {
    "request-call-cause": RequestCallCause,
  },
  props: ["request"],
  data() {
    return {
      PT_CASHLESS,
      STATUS_DONE,
      loading: false,
      closeRequest: false,
      decisionForm: false,
      calling: false,
      opening: false,
      requestingAddress: false,
      showAppartmentAfterRequest: false,
      call: {
        communicationIndex: false,
        phoneIndex: false,
        callCauseId: null,
        callCauseText: "",
      },
    };
  },
  computed: {
    id() {
      return this.request.id;
      // return this.$route.params.id !== undefined ? this.$route.params.id : 'new'
    },
    name() {
      let name = this.request.name
        ? this.request.name
        : this.$t("clients.new_client");
      return name ? name : this.$t("clients.client");
    },
    canAssigneeViewAppartment() {
      return this.request.assigneeCanSeeAddress;
    },
    canSeeAppartment() {
      return canSeeRequestAppartment(this.$store.state.user);
    },
    canIViewAppartment() {
      return this.canViewPhone || this.request.assigneeCanSeeAddress;
    },
    canOpenToAssignee() {
      return this.canViewPhone;
    },
    canViewPhone() {
      return canViewPhone(this.$store.state.user);
    },
    phones() {
      return getCommunicationPhoneList(this.request);
    },
  },
  watch: {
    request: {
      immediate: true,
      handler(request) {},
    },
  },
  created: function () {},
  methods: {
    isWorker,
    getSubwayTitle,
    getPestTitle,
    getRequestTitle,
    getRequestDatetime,
    getRequestDuration,
    getRequestStatusTitle,
    getRequestManagerName,
    getRequestAssigneeName,
    getRequestAssignee1stName,
    getRequestStatus,
    getRequestClientName,
    getRequestCreateTime,
    getRequestDeadline,
    getRequestUpdateTime,
    getUserName,
    getDecisionTime,
    getPaymentTypeTitle,
    getFacilityTitle,
    getRequestColor,
    getRequestAddressFormatted,
    getRequestNumber,
    showToMe() {},
    // makeCall() {
    //   this.$emit('make-call');
    // },
    coordsForLink(request) {
      if (!request.addressCoords) {
        return "";
      }
      let coords = { lat: "", lng: "" };
      if (
        (request.addressCoords.lng ?? false) &&
        (request.addressCoords.lat ?? false)
      ) {
        coords = request.addressCoords;
      } else {
        try {
          coords = JSON.parse(request.addressCoords);
        } catch (e) {
          console.log("addressCoords not parsable");
          return "";
        }
      }
      return coords.lng + "," + coords.lat;
    },
    getMapLink(request) {
      if (this.coordsForLink(request)) {
        return (
          "<a href='" +
          "https://yandex.ru/maps/?ll=" +
          this.coordsForLink(request) +
          "&text=" +
          encodeURIComponent(this.getRequestAddressFormatted(request)) +
          "' target='_blank'>" +
          this.getRequestAddressFormatted(request) +
          "</a>"
        );
      } else {
        return (
          "<a href='" +
          "https://yandex.ru/maps/?text=" +
          encodeURIComponent(this.getRequestAddressFormatted(request)) +
          "' target='_blank'>" +
          this.getRequestAddressFormatted(request) +
          "</a>"
        );
      }
    },
    requestToShowAddress() {
      if (!navigator.geolocation) {
        console.log(this.$t("geo.geolocation_is_not_supported"));
        this.$bvToast.toast(this.$t("geo.geolocation_is_not_blocked"), {
          title: this.$t("common.error"),
          variant: "danger",
        });
        return;
      }
      if (this.requestingAddress) {
        return false;
      }
      this.requestingAddress = true;
      let requestUrl = "/pest/request/appartment/" + this.request.id;
      let bearer = this.$store.state.user.authKey;
      let $this = this;
      navigator.geolocation.getCurrentPosition(
        function (position) {
          let lat = position.coords.latitude;
          let lng = position.coords.longitude;
          let params = {
            coords: { lat: lat, lng: lng },
          };
          $this.$http
            .put(requestUrl, params, {
              headers: { Authorization: "Bearer " + bearer },
            })
            .then((response) => {
              if (response.data.status === "ok") {
                $this.request.appartment = response.data.appartment;
                $this.request.addressEntrance = response.data.addressEntrance;
                $this.request.addressFloor = response.data.addressFloor;
                $this.request.addressIntercom = response.data.addressIntercom;
                $this.request.assigneeCanSeeAddress = 1;
                $this.showAppartmentAfterRequest = true;
                $this.$bvToast.toast(this.$t("requests.appartment_is_shown"), {
                  title: this.$t("requests.access_granted"),
                  variant: "success",
                });
              } else {
                $this.$bvToast.toast(
                  this.$t("requests.you_have_no_access_to_appartment"),
                  {
                    title: this.$t("common.error"),
                    variant: "danger",
                  }
                );
              }
              $this.requestingAddress = false;
            })
            .catch((error) => {
              $this.requestingAddress = false;
              $this.$bvToast.toast(
                this.$t("requests.you_have_no_access_to_appartment"),
                {
                  title: this.$t("common.error"),
                  variant: "danger",
                }
              );
              Sentry.captureException(error, {
                message: "Error on http request",
                requestId: $this.request.id,
                errorCode: error.code,
                errorMessage: error.message,
                errorRequest: $this.request,
              });
            });
        },
        function (error) {
          $this.requestingAddress = false;
          $this.$bvToast.toast(this.$t("requests.geolocation_is_not_working"), {
            title: this.$t("common.error"),
            variant: "danger",
          });
          Sentry.captureException(error, {
            message: "Error on getCurrentPosition",
            requestId: $this.request.id,
            errorCode: error.code,
            errorMessage: error.message,
            errorRequest: $this.request,
          });
        }
      );
    },
    setCallInfo(cIndex, pIndex) {
      this.call.communicationIndex = cIndex;
      this.call.phoneIndex = pIndex;
    },
    makeCall(evt) {
      let form = document.getElementById("call-cause-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (!valid) {
        evt.preventDefault();
        return;
      }
      let call = this.call;
      this.makeCallInternal(call, function () {
        call.communicationIndex = "";
        call.phoneIndex = "";
        call.callCauseId = "";
        call.callCauseText = "";
      });
    },
    makeCallInternal(call, successCallback) {
      if (this.calling) {
        return;
      }
      let callUrl = "/pest/request/call/" + this.request.id;
      this.calling = true;
      this.$http
        .get(callUrl, {
          params: call,
        })
        .then((response) => {
          this.$bvToast.toast(this.$t("requests.processing_the_call"), {
            title: this.$t("calls.please_wait"),
            variant: "success",
          });
          this.calling = false;
          if (successCallback) {
            successCallback();
          }
        })
        .catch((error) => {
          this.calling = false;
          let message = this.$t("requests.cant_make_the_call");
          if (error.response.data.error) {
            message = error.response.data.error;
          }
          this.$bvToast.toast(message, {
            title: this.$t("common.error"),
            variant: "danger",
          });
          Sentry.captureException(error);
        });
    },
    toggleToAssignee(status) {
      if (status === undefined) {
        status = true;
      }

      if (this.opening) {
        return;
      }
      let callUrl = "/pest/request/addr/" + this.request.id;
      this.opening = true;
      this.$http
        .post(callUrl, {
          status: status,
        })
        .then((response) => {
          this.request.assigneeCanSeeAddress = status;
          if (status) {
            this.$bvToast.toast(
              this.$t("requests.appartment_is_shown_to_assignee"),
              {
                title: this.$t("requests.access_closed"),
                variant: "success",
              }
            );
          } else {
            this.$bvToast.toast(
              this.$t("requests.appartment_is_hidden_from_assignee"),
              {
                title: this.$t("requests.access_granted"),
                variant: "success",
              }
            );
          }

          this.opening = false;
        })
        .catch((error) => {
          this.request.assigneeCanSeeAddress = status;
          this.$bvToast.toast(this.$t("reqeuests.cant_open_the_appartment"), {
            title: this.$t("common.error"),
            variant: "danger",
          });
          this.opening = false;
          Sentry.captureException(error, {
            code: error.code,
            message: error.message,
            request: this.request,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
table.request-info {
  word-break: break-word;
}
table.request-info td:first-child {
  min-width: 115px !important;
}
</style>
