import { getStatusTitle } from "@/modules/crm/decorators/status";
import { getUserName } from "@/modules/crm/common";
import dayjs from "dayjs";
import { getTownTitle } from "@/modules/pest/decorators/towns";
import { EQUIPMENT_PAYMENT_PERCENT } from "@/modules/pest/decorators/equipments";
import { getClientName } from "@/modules/crm/decorators/client.js";
import { getPestTitle } from "@/modules/pest/decorators/pests.js";
import { getFacilityTitle } from "@/modules/pest/decorators/facilities.js";
import { getSourceTitle } from "@/modules/pest/decorators/sources.js";
import { PERCENT_TYPE_MANUAL } from "./pests.js";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import { getSubwayTitle } from "@/modules/pest/decorators/subways";
import { getProjectTitle } from "@/modules/pest/decorators/project";
dayjs.extend(utc);
dayjs.extend(duration);
import t from "@/i18n";

const DATE_2000_01_01 = 946688461;

export const STATUS_NEW = 1;
export const STATUS_DISPATCHED = 2;
export const STATUS_PROGRESS = 4;
export const STATUS_DONE = 9;
export const STATUS_PAID = 8;
export const STATUS_DECLINE = -1;
export const STATUS_DECLINE_LEAD_BOARD = 100;
export const STATUS_UNKNOWN = -999;

export const PT_CASHLESS = 880;
export const PT_CASH = 878;

export const CLIENT_TYPE_ORG = 2;
export const CLIENT_TYPE_IND = 3;

export const STAGE_LEAD = 2;
export const STAGE_DEAL = 1;

export const emptyRequest = {
  id: "new",
  clientType: CLIENT_TYPE_IND,
  title: "",
  paymentType: null,
  parentId: "",
  groupId: "",
  tripDistance: "",
  tripSum: "",
  leadStatusId: null,
  leadStatus: {},
  managerId: null,
  manager: {},
};

export const statusTitles = {
  1: t("requests_statuses.r_new"),
  2: t("requests_statuses.r_dispatched"),
  4: t("requests_statuses.r_in_work"),
  8: t("requests_statuses.r_paid"),
  9: t("requests_statuses.r_success"),
  "-1": t("requests_statuses.r_failed"),
  "-999": t("requests_statuses.r_not_set"),

  // STATUS_NEW: "requests_statuses.order_new",
  // 1: "Оформлен заказ",
  // 2: "Распределена",
  // 4: "В работе",
  // 8: "Оплачена",
  // 9: "Заказ успешен",
  // "-1": "Заказ провален",
  // "-999": "[Не проставлен]",
};
export const statusColors = {
  1: "#00b7e3",
  2: "",
  4: "",
  8: "",
  9: "#009202",
  "-1": "#ee0909",
  "-999": "",
};

export const getRequestStatusTitle = function (request) {
  if (!request.status) {
    return t("common.not_set");
  }
  return getStatusTitle(request.status);
};

export const getRequestNumber = function (request) {
  if (!request) {
    return "";
  }
  let number = request.id;
  if (request.externalId) {
    number += " (" + request.externalId + ")";
  }
  return number;
};

export const getRequestTitle = function (request) {
  if (!request) {
    return t("common.not_set");
  }
  let id = getRequestNumber(request);
  if (request.title) {
    return "#" + id + " " + request.title;
  }
  if (request.client) {
    return "#" + id + " " + getRequestClientName(request);
  }
  return "[Без названия]";
};

export const getRequestDisplayableSum = function (request) {
  if (request.sum) {
    return request.sum;
  }
  if (request.knownSum) {
    return request.knownSum;
  }
  return "";
};
export const getRequestTownTitle = function (request) {
  return getTownTitle(request.town);
};
export const getRequestManagerName = function (request) {
  return getUserName(request.manager);
};
export const getRequestSourceName = function (request) {
  return getSourceTitle(request.source);
};
export const getRequestAssigneeName = function (request) {
  return getUserName(request.assignee);
};

export const getRequestAssignee1stName = function (request) {
  return getUserName(request.assignee1st);
};

export const getDatetime = function (time) {
  if (!time || time < DATE_2000_01_01) {
    // < 2000 года для заявок с неверно проставленным временем
    return t("common.not_set");
  }
  return dayjs.unix(time).utcOffset("+03:00").format("DD.MM.YYYY HH:mm");
};

export const getDate = function (time) {
  if (!time || time < DATE_2000_01_01) {
    // < 2000 года для заявок с неверно проставленным временем
    return t("common.not_set");
  }
  return dayjs.unix(time).format("DD.MM.YYYY");
};

export const getRequestDatetime = function (request) {
  return getDatetime(request.time);
};

export const getRequestCreateDatetime = function (request) {
  return getDatetime(request.createTime);
};
export const getRequestDuration = function (request) {
  let duration = request.duration;
  let hours = Math.floor(duration / 60);
  let minutes = duration - hours * 60;
  let str = hours + ":";
  if (minutes === 0) {
    str += "00";
  } else if (minutes < 10) {
    str += minutes + "0";
  } else {
    str += minutes;
  }
  return str;
};
export const getRequestDate = function (request) {
  // < 2000 года для заявок с неверно проставленным временем
  if (!request.time || request.time < DATE_2000_01_01) {
    return "";
  }
  return dayjs(request.shiftDate).format("DD.MM.YYYY");
};
export const getRequestTimeHours = function (request, format = "HH") {
  return dayjs.unix(request.time).utcOffset("+03:00").format(format);
};
export const getRequestTime = function (request) {
  // < 2000 года для заявок с неверно проставленным временем
  if (!request.time || request.time < DATE_2000_01_01) {
    return "";
  }
  return dayjs.unix(request.time).format("HH:mm");
};
export const getRequestDurationFormatted = function (request) {
  return dayjs
    .utc(dayjs.duration(request.duration, "minutes").asMilliseconds())
    .format("HH:mm");
};

export const getRequestStatus = function (request) {
  if (request.stage === STAGE_LEAD) {
    return getRequestLeadStatus(request);
  }

  if (!request.status) {
    return t("common.not_set");
  }
  return statusTitles[request.status];
};

export const getRequestLeadStatus = function (request) {
  if (!request.leadStatus) {
    return t("common.not_set");
  }
  return getStatusTitle(request.leadStatus);
};

export const getRequestStatusColor = function (request) {
  if (!request.status) {
    return "";
  }
  return statusColors[request.status];
};

export const getRequestBorderType = function (request) {
  if (request.status === STATUS_DECLINE) {
    return "dashed";
  } else {
    return "solid";
  }
};

export const getRequestColor = function (request) {
  if (request.status === STATUS_DECLINE) {
    return "#ee0909";
  }
  if (!request.pest || !request.pest.color) {
    return "";
  }
  return request.pest.color;
};

export const getRequestClientName = function (request) {
  if (request.clientName) {
    return request.clientName;
  }
  if (!request.client) {
    return t("common.not_set");
  }
  if (request.clientName) {
    return request.clientName;
  }
  return getClientName(request.client);
};

export const getRequestPestName = function (request) {
  if (!request.pest) {
    return t("common.not_set");
  }
  return getPestTitle(request.pest);
};

export const getRequestSubwayName = function (request) {
  if (!request.subway) {
    return t("common.not_set");
  }
  return getSubwayTitle(request.subway);
};

export const getRequestFacilityName = function (request) {
  if (!request.facility) {
    return t("common.not_set");
  }
  return getFacilityTitle(request.facility);
};

export const getRequestProjectTitle = function (request) {
  if (!request.project) {
    return t("common.not_set");
  }
  return getProjectTitle(request.project);
};
export const getRequestCreateTime = function (request) {
  return dayjs.unix(request.timeCreated).format("DD.MM.YYYY");
};

export const getPaymentTypeTitle = function (request, string) {
  if (!request) {
    return "";
  }
  // noinspection EqualityComparisonWithCoercionJS
  if (request.paymentType == PT_CASHLESS) {
    return t("requests.payment_cashless");
  }
  // noinspection EqualityComparisonWithCoercionJS
  if (request.paymentType == PT_CASH) {
    return t("requests.payment_cash");
  }
  return "";
};

export const checkFilter = function (request, string) {
  string = string.trim().toLowerCase();
  if (!string) {
    return true;
  }

  return (
    (request.phone && request.phone.toLowerCase().includes(string)) ||
    getCommunicationPhoneList(request).filter(
      (p) => p && p.phone && p.phone.includes(string)
    ).length > 0 ||
    (request.externalId && request.externalId.toLowerCase().includes(string)) ||
    (request.id && request.id.toString().toLowerCase().includes(string)) ||
    (request.contactPhone &&
      request.contactPhone.toLowerCase().includes(string)) ||
    (request.address && request.address.toLowerCase().includes(string)) ||
    (request.client &&
      request.client.title &&
      request.client.title.toLowerCase().includes(string)) ||
    (request.client &&
      request.client.firstName &&
      request.client.firstName.toLowerCase().includes(string)) ||
    (request.client &&
      request.client.lastName &&
      request.client.lastName.toLowerCase().includes(string)) ||
    (request.client &&
      request.client.patronymic &&
      request.client.patronymic.toLowerCase().includes(string))
  );
};
export const getRequestDeadline = function (request) {
  return request.deadline ? dayjs(request.deadline).format("DD.MM.YYYY") : "";
};
export const getRequestUpdateTime = function (request) {
  return dayjs.unix(request.timeUpdated).format("DD.MM.YYYY");
};
export const getRequestCallNotification = function (request) {
  if (
    !request ||
    !request.callNotifications ||
    !request.callNotifications.length
  ) {
    return "";
  }
  let first = request.callNotifications[0];
  let time = dayjs.unix(first.time).format("DD.MM.YYYY в HH:mm");
  return "Назначен звонок <br>" + time;
};

/**
 * Как далеко назначенное уведомление
 * @param request
 * @returns {boolean}
 */
export const isCallNotificationSoon = function (request) {
  if (
    !request ||
    !request.callNotifications ||
    !request.callNotifications.length
  ) {
    return false;
  }
  let time = null;
  for (let notification of request.callNotifications) {
    if (!time || time > notification.time) {
      time = notification.time;
    }
  }
  let curTime = dayjs().unix();
  let diff = 60 * 15;
  return time - curTime <= diff;
};

export const getDecisionTime = function (request) {
  return dayjs.unix(request.decisionTime).format("DD.MM.YYYY HH:mm:ss");
};

export const hasAnyResources = function (request) {
  if (request.resources && request.resources.length > 0) {
    for (let resource of request.resources) {
      if (resource.id) {
        return true;
      }
    }
  }
  return false;
};

export const recordString = function (request) {
  let diff = recordDiff(request);
  if (diff <= 0) {
    return "Новый рекорд";
  } else {
    return diff;
  }
};

export const recordTotalString = function (request) {
  let diff = recordTotalDiff(request);
  if (diff <= 0) {
    return "Новый рекорд";
  } else {
    return diff;
  }
};
export const recordDiff = function (request, recordSum) {
  if (!recordSum) {
    recordSum = 12000;
  }
  if (request.knownSum) {
    let requestMinRecord = (request.knownSum * 1.3).toFixed(2);
    if (requestMinRecord > recordSum) {
      recordSum = requestMinRecord;
    }
  }
  return recordSum - servicesSum(request);
};
/**
 * Расчет до рекорда с учетом проданных товаров
 * @param request
 * @param recordSum
 * @returns {number}
 */
export const recordTotalDiff = function (request, recordSum) {
  if (!recordSum) {
    recordSum = 12000;
  }
  if (request.knownSum) {
    let requestMinRecord = (request.knownSum * 1.3).toFixed(2);
    if (requestMinRecord > recordSum) {
      recordSum = requestMinRecord;
    }
  }
  return recordSum - totalSum(request);
};
export const toPaySumCashless = function (request) {
  let earned = Number(request.earned ? request.earned : 0);
  let equipmentSum = Number(request.equipmentSum ? request.equipmentSum : 0);
  let tripSum = Number(request.tripSum ? request.tripSum : 0);
  let tripBackSum = Number(request.tripBackSum ? request.tripBackSum : 0);
  let productCost = Number(productNetCost(request));
  return -(earned - equipmentSum + tripSum + tripBackSum + productCost);
};

export const toPaySum = function (request) {
  let serviceSum = Number(servicesSum(request));
  serviceSum += Number(productSum(request));
  let earned = Number(request.earned ? request.earned : 0);
  let equipmentSum = Number(request.equipmentSum ? request.equipmentSum : 0);
  let tripSum = Number(request.tripSum ? request.tripSum : 0);
  let tripBackSum = Number(request.tripBackSum ? request.tripBackSum : 0);
  let productCost = Number(productNetCost(request));
  return (
    serviceSum - (earned - equipmentSum + tripSum + tripBackSum + productCost)
  );
};
export const toPayTotalSum = function (request) {
  let totalsSum = Number(totalSum(request));
  let earned = Number(request.earned ? request.earned : 0);
  let equipmentSum = Number(request.equipmentSum ? request.equipmentSum : 0);
  let tripSum = Number(request.tripSum ? request.tripSum : 0);
  let tripBackSum = Number(request.tripBackSum ? request.tripBackSum : 0);
  return totalsSum - (earned - equipmentSum + tripSum + tripBackSum);
};

export const paymentSum = function (request) {
  if (request.isCashless) {
    return toPaySumCashless(request);
  } else {
    return toPaySum(request);
  }
};

export const paymentTotalSum = function (request) {
  if (request.isCashless) {
    return toPaySumCashless(request);
  } else {
    return toPayTotalSum(request);
  }
};
export const calcEquipmentSum = function (request) {
  if (!request.equipmentInfo) {
    return 0;
  }
  if (request.equipmentInfo.paymentType === EQUIPMENT_PAYMENT_PERCENT) {
    let sum = earnSum(request);
    let percent = request.equipmentInfo.percent;
    return ((sum / 100) * percent).toFixed(0);
  } else {
    return request.equipmentInfo.price;
  }
};
export const equipmentSum = function (request) {
  if (!request) {
    return 0;
  }
  if (!("equipmentSum" in request)) {
    return 0;
  }
  return request.equipmentSum;
};

export const servicesSum = function (request) {
  let sum = 0;
  if (request.services && request.services.length > 0) {
    for (let service of request.services) {
      if (service.price) {
        sum += parseInt(service.price);
      }
    }
  }
  return sum;
  // return request.services.reduce((a, b) => a + (b.price || 0), 0);
};

/**
 * Подсчет суммы проданных товаров
 * @param request
 * @returns {number}
 */
export const productSum = function (request) {
  let sum = 0;
  if (request.products && request.products.length > 0) {
    for (let product of request.products) {
      if (product.price) {
        sum += parseFloat(product.price);
      }
    }
  }
  return sum;
};

/**
 * Подсчет себестоимости проданных товаров
 * @param request
 * @returns {number}
 */
export const productNetCost = function (request) {
  let netCost = 0;
  if (request.products && request.products.length) {
    for (let product of request.products) {
      if (product.cost) {
        netCost += parseFloat(product.cost);
      }
    }
  }
  return netCost;
};

/**
 * Подсчет маржи с продажи товара
 * @param request
 * @returns {number}
 */
export const productMarginSum = function (request) {
  return productSum(request) - productNetCost(request);
};
/**
 * Cумма товаров и услуг
 * @param request
 * @returns {number}
 */
export const totalSum = function (request) {
  return servicesSum(request) + productSum(request);
};

/**
 * Заработано на продаже товаров
 * @param request
 * @returns {string}
 */
export const earnProductSum = function (request) {
  let recordSum = parseInt(request.recordSum);
  let percent = request.earnPercent;
  if (!request.fromExchange && totalSum(request) >= recordSum) {
    percent = request.recordPercent;
  }

  return (productMarginSum(request) / 100) * percent;
};

/**
 * Процент для ручной оценки
 * Данные в любом случае будут пересчитаны на бекенде
 * @param request
 */
export const getEarnPercentManual = function (request) {
  if (
    !request.fromExchange &&
    request.pest &&
    request.pest.percentType === PERCENT_TYPE_MANUAL
  ) {
    if (request.hasRecord) {
      return request.pest.percentRegular;
    } else {
      return request.pest.percentRecord;
    }
  }

  if (!request.wereNovice) {
    let percent = 30;
    if (request.hasRecord) {
      percent = 50;
    }
    if (request.isAssigneeInTeam) {
      percent -= 5;
    }
    return percent;
  } else {
    if (request.hasRecord) {
      return 40;
    } else {
      return 20;
    }
  }
};
export const earnSum = function (request) {
  let serviceSum = parseInt(servicesSum(request));
  let recordSum = parseInt(request.recordSum);
  let percent = request.earnPercent;
  if (!request.fromExchange && totalSum(request) >= recordSum) {
    percent = request.recordPercent;
  }
  let tripSum = parseFloat(request.tripSum) + parseFloat(request.tripBackSum);
  serviceSum -= tripSum;
  return (serviceSum / 100) * percent;
};

/**
 * Расчет оплаты мастеру с учетом товаров
 * @param request
 * @returns {string}
 */
export const earnTotalSum = function (request) {
  return earnSum(request) + earnProductSum(request);
};
export const calcProfitSum = function (request) {
  let earned = earnSum(request);
  let equipmentSum = calcEquipmentSum(request);
  let profit = parseFloat(earned) - parseFloat(equipmentSum);
  return profit.toFixed(0);
};

export const calcNetProfit = function (request) {
  let earned = earnTotalSum(request);
  let equipmentSum = calcEquipmentSum(request);
  let netProfit = parseFloat(earned) - parseFloat(equipmentSum);
  return netProfit.toFixed(0);
};
/**
 * Расчет прибыли мастера с учетом товаров и дороги
 * @param request
 * @returns {string}
 */
export const calcTotalProfitSum = function (request) {
  let earned = earnTotalSum(request);
  let equipmentSum = calcEquipmentSum(request);
  let productCost = parseFloat(productNetCost(request));
  let tripSum = parseFloat(request.tripSum) + parseFloat(request.tripBackSum);
  let profit =
    parseFloat(earned) -
    parseFloat(equipmentSum) +
    parseFloat(productCost) +
    parseFloat(tripSum);
  return profit.toFixed(0);
};
export const earnSumWithPresetRecord = function (request) {
  let serviceSum = parseInt(servicesSum(request));
  let percent = getEarnPercentManual(request);
  return ((serviceSum / 100) * percent).toFixed(0);
};

/**
 * Расчет выплаты мастеру с учетом товаров и процентом, установленным вручную
 * @param request
 * @returns {string}
 */
export const earnTotalSumWithPresetRecord = function (request) {
  let tripSum = parseFloat(request.tripSum) + parseFloat(request.tripBackSum);
  let totalsSum = totalSum(request) - productNetCost(request) - tripSum;
  let percent = getEarnPercentManual(request);
  return ((totalsSum / 100) * percent).toFixed(0);
};
export const recalculateProfitSum = function (request) {
  let earned = earnSumWithPresetRecord(request);
  let equipmentSum = calcEquipmentSum(request);
  let profit = parseFloat(earned) - parseFloat(equipmentSum);
  return profit.toFixed(0);
};
/**
 * Перерасчет с учетом ручного процента
 * @param request
 * @returns {string}
 */
export const recalculateTotalProfitSum = function (request) {
  let earned = earnTotalSumWithPresetRecord(request);
  let equipmentSum = calcEquipmentSum(request);
  let profit = parseFloat(earned) - parseFloat(equipmentSum);
  return profit.toFixed(0);
};

export const hasAnyServices = function (request) {
  if (request.services && request.services.length > 0) {
    for (let service of request.services) {
      if (service.id && service.price) {
        return true;
      }
    }
  }
  return false;
};

export const hasAnyProducts = function (request) {
  if (request.products && request.products.length > 0) {
    for (let product of request.products) {
      if (product.id && product.price) {
        return true;
      }
    }
  }
  return false;
};
export const getAddressFormatted = function (address) {
  return address ? address.split("|")[0] : "";
};

/**
 *
 * @param request
 * @returns {string}
 */
export const getRequestAddressFormatted = function (request) {
  let fields = [
    request.addressTown,
    request.address ? request.address.split("|")[0] : "",
    request.addressHouse ? "д." + request.addressHouse : "",
    request.addressBlock ? "к." + request.addressBlock : "",
    request.addressInfo,
  ];
  return fields.filter((n) => n).join(", ");
};

export const isClosed = function (request) {
  return [
    STATUS_DONE,
    STATUS_PAID,
    STATUS_DECLINE,
    STATUS_DECLINE_LEAD_BOARD,
  ].includes(request.status);
};
import router from "@/router";
// import Vue from "vue";
export const getRequestPopupTitle = function (request) {
  if (!request) {
    return t("common.error");
  }

  if (request.contractId > 0) {
    return t("requests.contract_n") + request.contractId;
  } else {
    return getRequestStageTitle(request);
  }
};

export const getRequestStageTitle = function (request) {
  if (!request) {
    return t("common.error");
  }
  if (request.id === "new") {
    if (request.contractId > 0) {
      if (request.stage === STAGE_LEAD) {
        return t("requests.new_request");
      } else {
        return t("requests.new_deal");
      }
    } else {
      return t("requests.new_lead");
    }
  } else {
    if (request.contractId > 0) {
      if (request.stage === STAGE_LEAD) {
        return t("requests.request_n") + getRequestNumber(request);
      } else {
        return t("requests.deal_n") + getRequestNumber(request);
      }
    } else {
      return t("requests.lead_n") + getRequestNumber(request);
    }
  }
};
export const getRequestSourceUrl = function (request) {
  if (!request || !request.sourceUrl) {
    return "";
  }
  let url = request.sourceUrl ? request.sourceUrl : request.source.title;
  if (url.substring(0, 8) !== "https://") {
    url = "https://" + url;
  }
  return url;
};

export const getCommunicationPhoneList = function (request, includeDefault) {
  let phones = [];
  if (!request.communications || !request.communications.length) {
    if (includeDefault) {
      return [
        {
          cIndex: 0,
          pIndex: 0,
          phone: request.phone,
          clientName: "communication.clientName",
        },
      ];
    }
    return [];
  }
  for (let communication of request.communications) {
    let pIndex = 0;
    for (let phone of communication.phones) {
      phones.push({
        cIndex: communication.id,
        pIndex: pIndex,
        phone: phone,
        clientName: communication.clientName,
      });
      pIndex++;
    }
  }
  return phones;
};
