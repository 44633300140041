<template>
  <div class="ibox">
    <div class="ibox-content">
      <h1>Отчет</h1>
      <div class="mb-2"></div>

      <div class="search">
        <div class="d-flex align-items-center">
          <div class="mr-2">
            <b-form-group label="Имя">
              <b-form-input v-model="search.name" tabindex="1"></b-form-input>
            </b-form-group>
          </div>

          <div class="mr-2">
            <b-form-group label="Аккаунт" prop="accountId">
              <multiply-autocomplete
                ref="accountAutocomplete"
                :get-data="fetchAccountData"
                :get-title="getAccountTitle"
                @selected="selectMultipleAccount"
              ></multiply-autocomplete>
            </b-form-group>
          </div>

          <div class="mr-2">
            <b-form-group label="Дата" prop="date">
              <date-picker v-model="search.date"></date-picker>
            </b-form-group>
          </div>

          <div v-if="false" class="mr-2">
            <b-form-group label="Вредитель" prop="pestId">
              <multiply-autocomplete
                ref="pestAutocomplete"
                :get-data="fetchPestData"
                :get-title="getPestTitle"
                @selected="selectMultiplePest"
              ></multiply-autocomplete>
            </b-form-group>
          </div>

          <div v-if="false" class="mr-2">
            <b-form-group label="Регион" prop="pestId">
              <multiply-autocomplete
                ref="townAutocomplete"
                :get-data="fetchRegionData"
                :get-title="getRegionTitle"
                @selected="selectMultipleRegion"
              ></multiply-autocomplete>
            </b-form-group>
          </div>

          <div class="mr-2">
            <b-form-group label="Статус" prop="status">
              <multiply-autocomplete
                ref="townAutocomplete"
                :get-data="fetchStatusData"
                :get-title="getStatusTitle"
                @selected="selectMultipleStatus"
              ></multiply-autocomplete>
            </b-form-group>
          </div>

          <div class="align-self-center mt-2 mr-2">
            <b-button class="btn btn-primary ml-1" @click="fetchData">
              <font-awesome-icon icon="search" />&nbsp;Искать
            </b-button>
          </div>

          <div v-if="!loading" class="mt-2 mr-2">
            {{ $t("board_common.pagination_total") }}: {{ pagination.total }}
          </div>
        </div>
      </div>

      <b-table
        v-if="reports.length"
        responsive
        striped
        hover
        condensed
        :items="reports"
        :fields="fields"
        :busy="loading"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle table-spinner"></b-spinner>
            <strong> {{ $t("common.loading") }}</strong>
          </div>
        </template>

        <template #cell(title)="data">
          <router-link
            :to="{ name: 'direct-report-update', params: { id: data.item.id } }"
            class=""
          >
            {{ data.item.title ? data.item.title : $t("common.without_title") }}
          </router-link>
        </template>

        <template #cell(date)="data">
          {{ getReportDate(data.item) }}
        </template>
        <template #cell(account)="data">
          {{ getReportAccountName(data.item) }}
        </template>
        <template #cell(regionId)="data">
          {{ getReportRegionName(data.item) }}
        </template>
        <template #cell(status)="data">
          {{ getReportStatusTitle(data.item) }}
        </template>
      </b-table>
    </div>

    <b-pagination-nav
      v-if="pagination.count > 1"
      :link-gen="linkGen"
      :value="pagination.page"
      :number-of-pages="pagination.count"
      limit="10"
      use-router
      class="mt-2"
    >
    </b-pagination-nav>
  </div>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import {
  getReportAccountName,
  getReportDate,
  getReportPestName,
  getReportRegionName,
  getReportStatusTitle,
  statusOptions,
} from "@/modules/direct/decorators/reports";
import MultiplyAutocomplete from "@/components/shared/MultiplyAutocomplete";
import { getAccountTitle } from "@/modules/direct/decorators/accounts";
import { getPestTitle } from "@/modules/pest/decorators/pests";
import { MSK, SPB, DXB } from "@/modules/pest/decorators/towns";
import DatePicker from "@/components/shared/DatePicker";

export default {
  name: "DirectReportList",
  components: { MultiplyAutocomplete, DatePicker },
  data() {
    return {
      reports: [{}],
      search: {
        title: "",
        status: [],
        date: null,
        accountId: null,
        pestId: null,
        regionId: null,
      },
      loading: true,
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      header: "Отчета",
      fields: [
        {
          key: "title",
          label: this.$t("common.title"),
          sortable: false,
        },
        {
          key: "date",
          label: "Дата",
          sortable: false,
        },
        {
          key: "account",
          label: "Аккаунт",
          sortable: false,
        },
        {
          key: "status",
          label: this.$t("common.status"),
          sortable: false,
        },
        {
          key: "regionId",
          label: "Регион",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
  },
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  mounted: function () {
    this.fetchData();
  },
  created() {},
  methods: {
    getUserName,
    getReportAccountName,
    getAccountTitle,
    getReportStatusTitle,
    getPestTitle,
    getReportDate,
    getReportPestName,
    getReportRegionName,
    fetchData: function () {
      this.loading = true;

      let reportsUrl = "/direct/reports";
      let params = {
        page: this.page,
        title: this.search.name,
        status: this.search.status,
        date: this.search.date,
        accountId: this.search.accountId,
        pestId: this.search.pestId,
        regionId: this.search.regionId,
      };
      this.$http
        .get(reportsUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.reports = response.data;
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    handleGroupIndex(command) {
      this.sampleGroupIndex = command;
    },
    linkGen(pageNum) {
      return {
        name: "direct-report-list",
        params: { page: pageNum },
      };
    },

    fetchAccountData(search, resolve) {
      let accountUrl = "/direct/accounts";
      let params = {
        search: search,
      };
      this.$http
        .get(accountUrl, {
          params: params,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve([]);
        });
    },
    selectMultipleAccount(info) {
      this.search.accountId = [];
      if (info) {
        for (let account of info) {
          this.search.accountId.push(account.id);
        }
      }
      this.fetchData();
    },

    fetchPestData(search, resolve) {
      let pestUrl = "/pest/pests";
      let params = {
        search: search,
      };
      this.$http
        .get(pestUrl, {
          params: params,
        })
        .then((response) => {
          let data = [{ id: -1, title: "Не назначен" }, ...response.data];
          resolve(data);
        })
        .catch((error) => {
          resolve([]);
        });
    },
    selectMultiplePest(info) {
      this.search.pestId = [];
      if (info) {
        for (let pest of info) {
          this.search.pestId.push(pest.id);
        }
      }
      this.fetchData();
    },

    fetchRegionData(search, resolve) {
      resolve([
        {
          id: -1,
          text: "Не назначено",
        },
        {
          id: MSK,
          text: "Москва",
        },
        {
          id: SPB,
          text: "Санкт-Петербург",
        },
      ]);
    },
    getRegionTitle(region) {
      return region.text;
    },
    selectMultipleRegion(info) {
      this.search.regionId = [];
      if (info) {
        for (let region of info) {
          this.search.regionId.push(region.id);
        }
      }
      this.fetchData();
    },

    fetchStatusData(search, resolve) {
      resolve(statusOptions);
    },
    getStatusTitle(status) {
      return status.text;
    },
    selectMultipleStatus(info) {
      this.search.status = [];
      if (info) {
        for (let status of info) {
          this.search.status.push(status.id);
        }
      }
      this.fetchData();
    },
  }, //methods
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.search {
  border-top: 1px lightgray solid;
  border-bottom: 1px lightgray solid;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
