export const ru = {
  statuses: {
    bg_color: "Цвет фона",
    font_color: "Цвет текста",
    card_text_color: "Цвет текста в карточке",
    settings: "Настройки",
    hide_from_user: "Скрыть от пользователей",
    logic: "Логика обработки",

    new: "Новый",
    declined: "Отклоненные",
    undispatched: "Нераспределенные",

    st_closeStatus: "Лид провален",
    st_autoInitialCall: "Первичный автодозвон",
    st_newStatus: "Для новых заявок",
    st_startWork: "В работе",
    st_duplicate: "Дубль",
    st_trash: "Спам \\ Повторный звонок",
    st_termination: "Расторжение",
    st_moveToDeal: "Перенести в сделки",
    st_autoRepeatedCall: "Автодозвон при отсутствии ответа",
    st_withoutAnswer: "Без ответа",
    st_hidden: "Скрыт от пользователей",

    statuses: "Статусов",
    status_list: "Перечень статусов",
    status: "Статус",
    title: "Название",
    add_status: "Добавить статус",
    new_status: "Новый статус",
    status_update: "Изменение статус",
    status_updated: "Статус отредактирован",
    cant_create_status: "Не удалось добавить статус",
    cant_delete_status: "Не удалось удалить статус",
    status_created: "Статус добавлен",
    status_deleted: "Статус удален",
    delete_status_question: "Вы действительно хотите удалить статус?",
  },
};
export const en = {
  statuses: {
    bg_color: "Background color",
    font_color: "Font color",
    card_text_color: "Card text color",
    settings: "Settings",
    hide_from_user: "Hide from user",
    logic: "Processing logic",
    new: "New",
    declined: "Declined",
    undispatched: "Undispatched",

    st_closeStatus: "Request failed",
    st_autoInitialCall: "Auto initial call",
    st_newStatus: "New status",
    st_startWork: "Start work",
    st_duplicate: "Duplicate",
    st_trash: "Trash",
    st_termination: "Termination",
    st_moveToDeal: "Move to deal",
    st_autoRepeatedCall: "Auto repeated call",
    st_withoutAnswer: "Without answer",
    st_hidden: "Hidden",

    statuses: "Statuses",
    status_list: "Status list",
    status: "Status",
    title: "Title",
    add_status: "Add status",
    new_status: "New status",
    change_status: "Change status",
    status_update: "Update status",
    status_updated: "Status changed",
    cant_create_status: "Can't add status",
    cant_delete_status: "Can't delete status",
    status_created: "Status added",
    status_deleted: "Status deleted",
    delete_status_question: "Do you want to delete the status?",
  },
};
