<template>
  <div class="request-page">
    <div class="ibox d-none d-md-block">
      <div class="ibox-title">
        <b-row>
          <b-col>
            <h1>
              <a href="#" class="ml-1 mr-1 h4" @click.prevent="goBack">
                <font-awesome-icon icon="arrow-left" />
              </a>
              {{ requestName }}
              <span>
                <small class="request-status">
                  {{ requestStatus }}
                </small>
              </span>

              <small v-if="!loading && canUpdateRequest()">
                <a
                  href=""
                  class="h6 ml-2"
                  @click.prevent="toggleUpdate(!updating)"
                >
                  <font-awesome-icon v-if="updating" icon="eye" />
                  <font-awesome-icon v-else icon="edit" />
                </a>
                <a href="" class="h6 ml-2" @click.prevent="deleteRequest">
                  <font-awesome-icon icon="trash" />
                </a>
              </small>
            </h1>
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="ibox">
          <div class="ibox-title d-flex pr-4">
            <div class="">
              <h5>{{ $t("requests.info") }}</h5>
            </div>
            <div class="ml-auto">
              <a href="#" @click.prevent="goBack"> {{ $t("common.close") }} </a>
            </div>
          </div>

          <div class="ibox-content">
            <request-info
              v-if="!loading && !updating"
              :request="request"
            ></request-info>
            <request-form
              v-if="!loading && updating"
              :request-model="request"
              :data-loaded="!loading"
              @update-request-info="afterUpdate"
              @cancel-updating="toggleUpdate(false)"
            ></request-form>
          </div>
        </div>

        <!-- Выезды по цепочке -->
        <requests-sequence-history
          v-if="sequence && sequence.length > 1"
          :request:="request"
          :sequence="sequence"
        />
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6">
        <div v-if="showResultsBlock" class="ibox">
          <div class="ibox-title">
            <h5>{{ $t("requests.results") }}</h5>
          </div>

          <div class="ibox-content">
            <!--              <h4>Результат</h4>-->
            <request-result-info
              v-if="!updatingResult"
              :request="request"
            ></request-result-info>

            <request-result-form
              v-if="!loading && updatingResult"
              ref="request-result-form"
              :request-model="request"
              :data-loaded="!loading"
              @update-request-info="afterRequestUpdate"
              @cancel-updating="toggleUpdateResult(false)"
              @open-resource-popup-form="openResourcePopupForm"
            ></request-result-form>
            <request-correct-form
              v-if="!loading && correctingResult && canCorrect"
              :request-model="request"
              :data-loaded="!loading"
              @request-corrected="afterRequestCorrected"
              @cancel-updating="toggleCorrectResult(false)"
            ></request-correct-form>

            <div class="d-flex">
              <div v-if="!correctingResult" class="md-2 mr-2">
                <a href="" @click.prevent="toggleUpdateResult(!updatingResult)">
                  <button v-if="false" class="btn btn-primary btn-sm">
                    <font-awesome-icon icon="eye" />
                    {{ $t("common.cancel") }}
                  </button>
                  <button
                    v-if="!updatingResult && canSetResults"
                    class="btn btn-primary btn-sm"
                  >
                    <font-awesome-icon icon="edit" />
                    {{ $t("requests.enter_results") }}
                  </button>
                </a>
              </div>

              <div
                v-if="!updatingResult && requestClosed && canCorrect"
                class="md-2"
              >
                <a
                  href=""
                  @click.prevent="toggleCorrectResult(!correctingResult)"
                >
                  <button v-if="false" class="btn btn-primary btn-sm">
                    <font-awesome-icon icon="eye" />
                    {{ $t("common.cancel") }}
                  </button>
                  <button
                    v-if="!correctingResult"
                    class="btn btn-primary btn-sm"
                  >
                    <font-awesome-icon icon="edit" />
                    {{ $t("requests.results_correction") }}
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-5">
        <div v-if="!updating" class="ibox mb-2">
          <div class="ibox-title" :class="blockHeadClass('comments')">
            <h4 class="" @click.prevent="toggleBlock('comments')">
              {{ $t("common.comments") }}
              <small>
                <font-awesome-icon v-if="blockShown('comments')" icon="minus" />
                <font-awesome-icon v-else icon="plus" />
              </small>
            </h4>
          </div>
          <div class="ibox-content pb-1" :class="blockClass('comments')">
            <request-comments
              v-if="!loading"
              :type="COMMENT_TYPE_REQUEST"
              :model="request"
            ></request-comments>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div v-if="!updating && canSeeRequestHistory" class="ibox mb-2">
          <div
            class="ibox-title d-flex align-items-center"
            :class="blockHeadClass('logs')"
          >
            <div>
              <h4 class="" @click.prevent="toggleBlock('logs')">
                {{ $t("requests.requests_history") }}
                <small>
                  <font-awesome-icon v-if="blockShown('logs')" icon="minus" />
                  <font-awesome-icon v-else icon="plus" />
                </small>
              </h4>
            </div>
            <div class="ml-auto">
              <a href="" @click.prevent="reloadHistory">
                <font-awesome-icon icon="rotate" />
              </a>
            </div>
          </div>
          <div class="ibox-content pb-1" :class="blockClass('logs')">
            <request-logs
              v-if="!loading"
              ref="request-logs"
              :item-id="request.id"
            ></request-logs>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-resource-form"
      ref="modal-resource-form"
      :title="$t('resources.add_new_resource')"
      :cancel-title="$t('common.cancel')"
      :ok-title="$t('common.save')"
      title-tag="h3"
      :ok-disabled="checkResourceTitle"
      @ok="handleResourceModal"
    >
      <resource-form
        :modal="true"
        :resource="resource"
        :from-results="true"
      ></resource-form>
    </b-modal>
  </div>
</template>

<script>
import RequestInfo from "./view/RequestInfo.vue";
import RequestResultInfo from "./view/RequestResultInfo.vue";
import Comments from "@/modules/crm/views/comment/Comments";
import RequestForm from "@/modules/pest/views/requests/view/RequestForm.vue";
import ResourceForm from "@/modules/pest/views/resources/ResourceForm.vue";
import {
  getRequestNumber,
  getRequestStatus,
  getRequestTitle,
  STAGE_DEAL,
  STATUS_DECLINE,
  STATUS_DONE,
} from "@/modules/pest/decorators/requests";
import RequestResultForm from "@/modules/pest/views/requests/view/RequestResultForm.vue";
import RequestLogs from "@/modules/pest/views/logs/RequestLogs";
import RequestCorrectForm from "@/modules/pest/views/requests/view/RequestCorrectResultForm.vue";
import {
  canCorrectRequests,
  canSeeRequestHistory,
  canSetRequestResults,
} from "@/modules/users/access/common.js";
import { mapActions } from "vuex";
import RequestsSequenceHistory from "@/modules/pest/views/requests/kanban/popup/RequestSequenceHistory.vue";
import { COMMENT_TYPE_REQUEST } from "@/modules/crm/common";

export default {
  name: "RequestView",
  components: {
    RequestsSequenceHistory,
    RequestCorrectForm,
    RequestLogs,
    "request-info": RequestInfo,
    "request-result-info": RequestResultInfo,
    "request-comments": Comments,
    "request-form": RequestForm,
    "request-result-form": RequestResultForm,
    ResourceForm,
  },
  data() {
    return {
      resource: {
        id: "new",
        title: "",
      },
      loadingResource: false,
      tabIndex: 0,
      updating: false,
      sequence: false,
      updatingResult: false,
      correctingResult: false,
      COMMENT_TYPE_REQUEST,
      currentResourceIndex: false,
      STAGE_DEAL,
      request: {
        id: "new",
        managerId: "",
        managerName: "",
        number: "",
        groupId: "",
        group: "",
        manager: "",
        clientId: "",
        client: "",
        deadline: "",
        assigneeCanSeeAddress: false,
      },
      blocks: {
        logs: true,
        comments: true,
        documents: false,
        "client-comments": false,
      },
      loading: true,
      loadingRequests: false,
    };
  },
  computed: {
    id() {
      return this.$route.params.id !== undefined
        ? this.$route.params.id
        : "new";
    },
    checkResourceTitle() {
      return this.resource.title.length ? false : true;
    },
    requestName() {
      return getRequestTitle(this.request);
    },
    requestStatus() {
      return getRequestStatus(this.request);
    },
    requestClosed() {
      return [STATUS_DONE, STATUS_DECLINE].includes(this.request.status);
    },
    canCorrect() {
      return canCorrectRequests(this.$store.state.user);
    },
    canSetResults() {
      return canSetRequestResults(this.$store.state.user);
    },
    showResultsBlock() {
      return this.request.stage === STAGE_DEAL;
    },
    canSeeRequestHistory() {
      return canSeeRequestHistory(this.$store.state.user);
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },
  mounted() {
    this.setPageTitle({
      pageTitle: this.$t("common.loading"),
    });
  },

  methods: {
    ...mapActions({
      setPageTitle: "user/setPageTitle",
    }),
    handleResourceModal() {
      this.loadingResource = true;
      let updateUrl;
      updateUrl = "/pest/resource/create-from-result?requestId=" + this.id;
      this.$http
        .post(updateUrl, this.resource)
        .then((response) => {
          let item = response.data;
          this.$refs["request-result-form"].resourcesList.push({
            value: item.id,
            text: item.title,
          });
          let currentResourceIndex = this.currentResourceIndex;
          this.$refs["request-result-form"].request.resources[
            currentResourceIndex
          ].resourceId = item.id;
          this.resource.title = "";
          this.resource.unit = "";
          this.resource.comment = "";
          this.$bvToast.toast(this.$t("resources.resource_created"), {
            title: this.$t("resources.resource_created"),
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.error"), {
              title: this.$t("resources.cant_create_resource"),
              variant: "danger",
            });
          }
        });
    },
    onTabSelect() {
      if (this.tabIndex === 0) {
        this.$refs.contacts.fetchData();
      }
    },
    checkUrl: function () {
      if (this.id === "new") {
        this.header = "Создать клиента";
      } else {
        this.fetchData();
        this.fetchRequests();
      }
      window.scrollTo(0, 0);
    },
    openResourcePopupForm(rIndex) {
      this.currentResourceIndex = rIndex;
      this.$bvModal.show("modal-resource-form");
    },
    fetchData: function () {
      this.request.phone = "";
      let requestsUrl = "/pest/requests/" + this.id;
      this.loading = true;
      this.$http
        .get(requestsUrl)
        .then((response) => {
          this.loading = false;
          this.request = response.data;
          this.ensureBlankData();
          this.showAddress();
          this.header = this.request.email;

          this.setPageTitle({
            pageTitle:
              this.$t("requests.request_n") + getRequestNumber(this.request),
          });
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    fetchRequests() {
      this.loadingRequests = true;
      let requestsUrl = "/pest/request/sequence";
      let params = {
        id: this.id,
      };
      this.$http
        .get(requestsUrl, {
          params: params,
        })
        .then((response) => {
          this.loadingRequests = false;
          this.sequence = response.data.requests;
          this.sequence.sort((a, b) => {
            return a.indexNumber - b.indexNumber;
          });
          this.$emit("sequence-loaded", this.sequence);
        })
        .catch((error) => {
          this.loadingRequests = false;
          this.processRequestError(error);
        });
    },

    blockHeadClass(block) {
      if (!this.blocks[block]) {
        return "collapsed";
      }
      return "";
    },
    blockClass(block) {
      if (!this.blocks[block]) {
        return "d-none";
      }
      return "";
    },
    blockShown(block) {
      return this.blocks[block];
    },
    toggleBlock(block) {
      this.blocks[block] = !this.blocks[block];
    },
    toggleUpdate(updating) {
      this.updating = updating;
    },
    toggleUpdateResult(updating) {
      if (updating) {
        if (!this.request.equipmentSum && this.request.equipmentUserSum) {
          this.request.equipmentSum = this.request.equipmentUserSum;
        }
      }
      this.updatingResult = updating;
    },
    toggleCorrectResult(updating) {
      if (updating) {
        if (!this.request.equipmentSum && this.request.equipmentUserSum) {
          this.request.equipmentSum = this.request.equipmentUserSum;
        }
      }
      this.correctingResult = updating;
    },
    afterUpdate(request) {
      this.request = request;
      this.toggleUpdate(false);
    },
    afterRequestUpdate(request) {
      this.request = request;
      this.toggleUpdateResult(false);
      if (this.canSeeRequestHistory) {
        this.$refs["request-logs"].fetchData();
      }
    },
    afterRequestCorrected(request) {
      this.request = request;
      this.toggleCorrectResult(false);
      if (this.canSeeRequestHistory) {
        this.$refs["request-logs"].fetchData();
      }
    },
    deleteRequest() {
      if (confirm(this.$t("requests.confirm_request_delete"))) {
        let deleteUrl = "/pest/requests/" + this.request.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            // this.client.archived = 1;
            this.loading = false;
            this.$router.push({ name: "pest-request-list" });
            this.$bvToast.toast(this.$t("requests.request_deleted"), {
              title: this.$t("requests.request_deleted"),
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast(this.$t("common.error"), {
                title: this.$t("requests.cant_delete_request"),
                variant: "danger",
              });
            }
          });
      }
    },

    ensureBlankData() {
      if (!this.request.resources.length) {
        this.request.resources = [];
        let blankResource = {
          resourceId: "",
          title: "",
          count: "",
        };
        this.request.resources.push(blankResource);
      }
      if (!this.request.services.length) {
        this.request.services = [];
        let blankService = {
          serviceId: "",
          title: "",
          service: { id: "" },
          count: "",
        };
        this.request.services.push(blankService);
      }
      if (!this.request.products.length) {
        this.request.products = [];
        let blankProduct = {
          id: "",
          title: "",
          count: "",
        };
        this.request.products.push(blankProduct);
      }
    },

    canUpdateRequest() {
      return (
        !this.$store.state.user.isGuest &&
        this.$store.state.user.access.canUpdateRequests
      );
    },

    showAddress() {
      if (!navigator.geolocation) {
        this.$bvToast.toast(this.$t("geo.geolocation_is_not_blocked"), {
          title: this.$t("common.error"),
          variant: "danger",
        });
        return;
      }
      if (this.requestingAddress) {
        return false;
      }
      this.requestingAddress = true;
      let coordsUrl = "/pest/request/coords/" + this.request.id;
      let bearer = this.$store.state.user.authKey;
      let vue = this;
      navigator.geolocation.getCurrentPosition(function (position) {
        // Текущие координаты.
        let lat = position.coords.latitude;
        let lng = position.coords.longitude;
        let params = {
          coords: {
            lat: lat,
            lng: lng,
          },
        };
        vue.$http
          .put(coordsUrl, params, {
            headers: { Authorization: "Bearer " + bearer },
          })
          .then((response) => {
            if (response.data.status === "ok") {
              vue.request.appartment = response.data.appartment;
              vue.request.addressEntrance = response.data.addressEntrance;
              vue.request.addressFloor = response.data.addressFloor;
              vue.request.addressIntercom = response.data.addressIntercom;
              vue.request.assigneeCanSeeAddress = 1;
              // vue.$bvToast.toast('Номер квартиры открыт', {
              //   title: 'Доступ получен',
              //   variant: 'success'
              // });
            } else {
              // vue.$bvToast.toast('Вы находитесь слишком далеко от объекта', {
              //   title: 'Ошибка',
              //   variant: 'danger'
              // });
            }
            vue.requestingAddress = false;
          })
          .catch((error) => {
            vue.requestingAddress = false;
          });
      });
    },
    reloadHistory() {
      this.$refs["request-logs"].fetchData();
    },
    goBack() {
      this.$router.go(-1);
    },
  }, //methods
};
</script>

<style lang="scss" scoped>
.card-body {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.block-head {
  border: 1px solid #e0dfdf;
  border-bottom: 0;
  background: #f5f5f5;
}

.block-head.collapsed {
  border-bottom: 1px solid #e0dfdf;
}

.block-head h4 {
  padding: 5px;
  padding-bottom: 10px;
  margin-top: 5px;
  margin-bottom: 0;
}

.block {
  //margin-bottom: 10px;
}

.request-status {
  font-size: small;
}
</style>
