import t from "@/i18n";

export const TYPE_SHADOW = -2;
export const TYPE_ADMIN = 1;
export const TYPE_LOGIST = 2;
export const TYPE_WORKER = 3;
export const TYPE_CLIENT = 4;
export const TYPE_MANAGER = 5;
export const TYPE_FINE_MANAGER = 6;

export const typeTitles = {
  [TYPE_SHADOW]: "type_shadow",
  [TYPE_ADMIN]: "type_admin",
  [TYPE_LOGIST]: "type_logist",
  [TYPE_MANAGER]: "type_manager",
  [TYPE_FINE_MANAGER]: "type_fine_manager",
  [TYPE_WORKER]: "type_worker",
  [TYPE_CLIENT]: "type_client",
};

export const typeSelectOptions = function () {
  return [
    // {'value': TYPE_SHADOW, 'text': 'Поддержка'},
    { value: TYPE_ADMIN, text: t("users." + "type_admin") },
    { value: TYPE_LOGIST, text: t("users." + "type_logist") },
    { value: TYPE_MANAGER, text: t("users." + "type_manager") },
    { value: TYPE_FINE_MANAGER, text: t("users." + "type_fine_manager") },
    { value: TYPE_WORKER, text: t("users." + "type_worker") },
    // {'value': TYPE_CLIENT, 'text': 'Клиент'},
  ];
};

export const getTypeTitle = function (user) {
  return t("users." + typeTitles[user.type]);
};
