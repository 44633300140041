import { ru as commonRUMessages } from "@/messages/common";
import { en as commonENMessages } from "@/messages/common";
import { ru as boardCommonRUMessages } from "@/messages/board_common";
import { en as boardCommonENMessages } from "@/messages/board_common";
import { ru as requestsRUMessages } from "@/messages/requests";
import { en as requestsENMessages } from "@/messages/requests";
import { ru as requestsCommonRUMessages } from "@/messages/requests_common";
import { en as requestsCommonENMessages } from "@/messages/requests_common";
import { ru as requestsStatusesRUMessages } from "@/messages/requests_statuses";
import { en as requestsStatusesENMessages } from "@/messages/requests_statuses";
import { ru as menuRUMessages } from "@/messages/menu";
import { en as menuENMessages } from "@/messages/menu";
import { ru as callsRUMessages } from "@/messages/calls";
import { en as callsENMessages } from "@/messages/calls";
import { ru as notificationsRUMessages } from "@/messages/notifications";
import { en as notificationsENMessages } from "@/messages/notifications";
import { ru as searchRUMessages } from "@/messages/search";
import { en as searchENMessages } from "@/messages/search";
import { ru as logisticsRUMessages } from "@/messages/logistics";
import { en as logisticsENMessages } from "@/messages/logistics";
import { ru as statusesRUMessages } from "@/messages/statuses";
import { en as statusesENMessages } from "@/messages/statuses";
import { ru as depositRUMessages } from "@/messages/deposit";
import { en as depositENMessages } from "@/messages/deposit";
import { ru as projectsRUMessages } from "@/messages/projects";
import { en as projectsENMessages } from "@/messages/projects";
import { ru as townsRUMessages } from "@/messages/towns";
import { en as townsENMessages } from "@/messages/towns";
import { ru as usersRUMessages } from "@/messages/users";
import { en as usersENMessages } from "@/messages/users";
import { ru as paymentsRUMessages } from "@/messages/payments";
import { en as paymentsENMessages } from "@/messages/payments";

import { en as callCausesENMessages } from "@/messages/call-causes";
import { ru as callCausesRUMessages } from "@/messages/call-causes";
import { en as aftercallFailsENMessages } from "@/messages/aftercall-fails";
import { ru as aftercallFailsRUMessages } from "@/messages/aftercall-fails";
import { en as equipmentENMessages } from "@/messages/equipment";
import { ru as equipmentRUMessages } from "@/messages/equipment";
import { en as facilitiesENMessages } from "@/messages/facilities";
import { ru as facilitiesRUMessages } from "@/messages/facilities";
import { en as finesENMessages } from "@/messages/fines";
import { ru as finesRUMessages } from "@/messages/fines";
import { en as penaltiesENMessages } from "@/messages/penalties";
import { ru as penaltiesRUMessages } from "@/messages/penalties";
import { en as pestsENMessages } from "@/messages/pests";
import { ru as pestsRUMessages } from "@/messages/pests";
import { en as productsENMessages } from "@/messages/products";
import { ru as productsRUMessages } from "@/messages/products";
import { en as regionsENMessages } from "@/messages/regions";
import { ru as regionsRUMessages } from "@/messages/regions";
import { en as rejectCausesENMessages } from "@/messages/reject-causes";
import { ru as rejectCausesRUMessages } from "@/messages/reject-causes";
import { en as resourcesENMessages } from "@/messages/resources";
import { ru as resourcesRUMessages } from "@/messages/resources";
import { en as servicesENMessages } from "@/messages/services";
import { ru as servicesRUMessages } from "@/messages/services";
import { en as shopResourceENMessages } from "@/messages/shop-resource";
import { ru as shopResourceRUMessages } from "@/messages/shop-resource";
import { en as sourcesENMessages } from "@/messages/sources";
import { ru as sourcesRUMessages } from "@/messages/sources";
import { en as subwaysENMessages } from "@/messages/subways";
import { ru as subwaysRUMessages } from "@/messages/subways";
import { en as scheduleENMessages } from "@/messages/schedule";
import { ru as scheduleRUMessages } from "@/messages/schedule";
import { en as placesENMessages } from "@/messages/places";
import { ru as placesRUMessages } from "@/messages/places";
import { en as messagesENMessages } from "@/messages/messages";
import { ru as messagesRUMessages } from "@/messages/messages";
import { en as logsENMessages } from "@/messages/logs";
import { ru as logsRUMessages } from "@/messages/logs";
import { en as clientsENMessages } from "@/messages/clients";
import { ru as clientsRUMessages } from "@/messages/clients";
import { en as filesENMessages } from "@/messages/files";
import { ru as filesRUMessages } from "@/messages/files";
import { en as settingsENMessages } from "@/messages/settings";
import { ru as settingsRUMessages } from "@/messages/settings";
import { en as statsENMessages } from "@/messages/stats";
import { ru as statsRUMessages } from "@/messages/stats";
import { en as cpoENMessages } from "@/messages/cpo";
import { ru as cpoRUMessages } from "@/messages/cpo";
import { en as commentsENMessages } from "@/messages/comments";
import { ru as commentsRUMessages } from "@/messages/comments";
import { en as geoENMessages } from "@/messages/geo";
import { ru as geoRUMessages } from "@/messages/geo";

const messages = {
  ru: {
    message: {
      hello: "Привет",
    },
    ...commonRUMessages,
    ...menuRUMessages,
    ...boardCommonRUMessages,
    ...requestsRUMessages,
    ...requestsCommonRUMessages,
    ...requestsStatusesRUMessages,
    ...callsRUMessages,
    ...notificationsRUMessages,
    ...searchRUMessages,
    ...logisticsRUMessages,
    ...statusesRUMessages,
    ...depositRUMessages,
    ...projectsRUMessages,
    ...townsRUMessages,

    ...aftercallFailsRUMessages,
    ...callCausesRUMessages,
    ...equipmentRUMessages,
    ...facilitiesRUMessages,
    ...finesRUMessages,
    ...penaltiesRUMessages,
    ...pestsRUMessages,
    ...productsRUMessages,
    ...regionsRUMessages,
    ...rejectCausesRUMessages,
    ...resourcesRUMessages,
    ...servicesRUMessages,
    ...shopResourceRUMessages,
    ...sourcesRUMessages,
    ...subwaysRUMessages,
    ...usersRUMessages,
    ...scheduleRUMessages,
    ...placesRUMessages,
    ...messagesRUMessages,
    ...paymentsRUMessages,
    ...logsRUMessages,
    ...clientsRUMessages,
    ...filesRUMessages,
    ...settingsRUMessages,
    ...statsRUMessages,
    ...cpoRUMessages,
    ...commentsRUMessages,
    ...geoRUMessages,
  },
  en: {
    message: {
      hello: "Hello",
    },
    ...commonENMessages,
    ...menuENMessages,
    ...boardCommonENMessages,
    ...requestsENMessages,
    ...requestsCommonENMessages,
    ...requestsStatusesENMessages,
    ...callsENMessages,
    ...notificationsENMessages,
    ...searchENMessages,
    ...logisticsENMessages,
    ...statusesENMessages,
    ...depositENMessages,
    ...projectsENMessages,
    ...townsENMessages,

    ...aftercallFailsENMessages,
    ...callCausesENMessages,
    ...equipmentENMessages,
    ...facilitiesENMessages,
    ...finesENMessages,
    ...penaltiesENMessages,
    ...pestsENMessages,
    ...productsENMessages,
    ...regionsENMessages,
    ...rejectCausesENMessages,
    ...resourcesENMessages,
    ...servicesENMessages,
    ...shopResourceENMessages,
    ...sourcesENMessages,
    ...subwaysENMessages,
    ...usersENMessages,
    ...scheduleENMessages,
    ...placesENMessages,
    ...messagesENMessages,
    ...paymentsENMessages,
    ...logsENMessages,
    ...clientsENMessages,
    ...filesENMessages,
    ...settingsENMessages,
    ...statsENMessages,
    ...cpoENMessages,
    ...commentsENMessages,
    ...geoENMessages,
  },
};
export default messages;
