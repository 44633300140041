export const ru = {
  resources: {
    resources: "Материалы",
    resource_list: "Перечень материалов в CRM",
    resource: "Материал",
    add_resource: "Добавить материал",
    new_resource: "Новый материал",
    resource_update: "Изменение материала",
    resource_updated: "Материал отредактирован",
    cant_create_resource: "Не удалось добавить материал",
    cant_delete_resource: "Не удалось удалить материал",
    resource_created: "Материал добавлен",
    resource_deleted: "Материал удален",
    visibility: "Видимость",
    goods_from_shop: "Товары из магазина",
    linked_resources: "Связанные материалы",
    add_linked_resource: "Добавить связанный материал",
    no_linked_resource_yet: "Пока нет связанных материалов",
    delete_resource_question: "Вы действительно хотите удалить материал?",
    add_new_resource: "Добавить новый препарат",
  },
};
export const en = {
  resources: {
    resources: "Resources",
    resource_list: "Resource list in CRM",
    resource: "Resource",
    add_resource: "Add resource",
    new_resource: "New resource",
    change_resource: "Change resource",
    resource_update: "Update resource",
    resource_updated: "Resource changed",
    cant_create_resource: "Can't add resource",
    cant_delete_resource: "Can't delete resource",
    resource_created: "Resource added",
    resource_deleted: "Resource deleted",
    visibility: "Visibility",
    goods_from_shop: "Goods from shop",
    linked_resources: "Linked resources",
    no_linked_resource_yet: "No linked resource yet",
    add_linked_resource: "Add linked resource",
    delete_resource_question: "Do you want to delete the resource?",
    add_new_resource: "Add new resource",
  },
};
