<template>
  <b-navbar
    :fixed="getFixed"
    toggleable="lg"
    type="dark"
    variant="dark"
    class="top-menu mb-3 py-0"
  >
    <b-navbar-brand v-if="!isWorker" href="#">CRM</b-navbar-brand>
    <div class="mobile-page-title d-sm-block d-md-none">{{ curPageTitle }}</div>
    <b-navbar-nav
      v-if="logged && canSeeOwnNotifications"
      class="ml-auto d-md-none"
    >
      <b-nav-item
        v-if="canSeeOwnNotifications"
        :active="
          isMenuItemActive('notifications-notification-user-list') ||
          unreadNotificationCount > 0
        "
        :to="{ name: 'notifications-notification-user-list' }"
        @click="setMenuItem('notifications-notification-user-list')"
      >
        <font-awesome-icon icon="bell" />
        <span
          v-if="unreadNotificationCount > 0"
          class="label label-primary ml-2"
          >{{ unreadNotificationCount }}</span
        >
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <hr class="d-lg-none w-100 my-0" />

    <b-collapse v-if="logged" id="nav-collapse" is-nav class="metismenu">
      <b-navbar-nav>
        <!--        <b-nav-item @click="setMenuItem('dashboard')"
                            :active="isMenuItemActive('dashboard')"
                            :to="{ name: 'crm-client-list' }">Главная</b-nav-item>-->
        <template v-if="canSeeKanban">
          <b-nav-item
            v-if="canSeeKanban"
            :active="isMenuItemActive('pest-request-board-global')"
            :to="{
              name: 'pest-request-board-global',
              params: { global: true },
            }"
            @click="setMenuItem('pest-request-board-global')"
          >
            <span class="text-uppercase">
              {{ $t("menu.kanban") }}
            </span>
          </b-nav-item>
        </template>

        <b-nav-item-dropdown
          v-if="
            canSeeRequestDispatchingBoard ||
            canSeeRequestMap ||
            canSeeSchedule ||
            canSeeSchedule
          "
          right
          :class="{
            active: isSubmenuItemActive([
              'request-dispatching-board',
              'pest-request-map',
              'pest-time-busyness',
              'pest-assignee-day-schedule',
            ]),
          }"
        >
          <template #button-content>
            <span class="text-uppercase">
              {{ $t("menu.logistics") }}
            </span>
          </template>
          <b-dropdown-item
            v-if="canSeeRequestDispatchingBoard"
            :to="{ name: 'request-dispatching-board' }"
            @click="setMenuItem('request-dispatching-board')"
            >{{ $t("menu.logistics_distribution") }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canSeeRequestMap && false"
            :to="{ name: 'pest-request-map' }"
            @click="setMenuItem('pest-request-map')"
            >{{ $t("menu.logistics_map") }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canSeeSchedule"
            :to="{ name: 'pest-time-busyness' }"
            @click="setMenuItem('pest-time-busyness')"
            >{{ $t("menu.logistics_load") }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canSeeSchedule"
            :to="{ name: 'pest-assignee-day-schedule' }"
            @click="setMenuItem('pest-assignee-day-schedule')"
            >{{ $t("menu.logistics_daily_schedule") }}
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown
          v-if="canSeeSchedule"
          :class="{
            active: isSubmenuItemActive([
              'pest-assignee-schedule',
              'pest-employee-current-request-list',
              'pest-employee-current-request-list',
            ]),
          }"
          right
        >
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <span class="text-uppercase">
              {{ $t("menu.schedule") }}
            </span>
          </template>
          <b-dropdown-item
            :to="{ name: 'pest-assignee-schedule' }"
            :active="isMenuItemActive('pest-assignee-schedule')"
            @click="setMenuItem('pest-assignee-schedule')"
            >{{ $t("menu.schedule_assignees") }}
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item
          v-if="canSeeRequestList && isWorker"
          :active="isMenuItemActive('pest-employee-current-request-list')"
          :to="{ name: 'pest-employee-current-request-list' }"
          @click="setMenuItem('pest-employee-current-request-list')"
          >{{ $t("menu.requests_in_work") }}</b-nav-item
        >

        <b-nav-item-dropdown
          v-if="!isWorker && (canSeeFines || canSeePayments)"
          :class="{
            active: isSubmenuItemActive([
              'pest-fine-list',
              'pest-settlements',
              'pest-penalty-list',
              'pest-deposit-list',
            ]),
          }"
          right
        >
          <template #button-content>
            <span class="text-uppercase">
              {{ $t("menu.finances") }}
            </span>
          </template>
          <b-dropdown-item
            v-if="canSeeFines"
            :active="isMenuItemActive('pest-fine-list')"
            :to="{ name: 'pest-fine-list' }"
            @click="setMenuItem('pest-fine-list')"
            >{{ $t("menu.finances_fines") }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canSeePayments"
            :active="isMenuItemActive('pest-settlements')"
            :to="{ name: 'pest-settlements' }"
            @click="setMenuItem('pest-settlements')"
            >{{ $t("menu.finances_settlements") }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canSeeFines"
            :active="isMenuItemActive('pest-penalty-list')"
            :to="{ name: 'pest-penalty-list' }"
            @click="setMenuItem('pest-penalty-list')"
            >{{ $t("menu.finances_penalty_list") }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canSeeDeposits"
            :active="isMenuItemActive('pest-deposit-list')"
            :to="{ name: 'pest-deposit-list' }"
            @click="setMenuItem('pest-deposit-list')"
            >{{ $t("menu.finances_deposit_list") }}
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown
          v-if="canSeePayments || canSeeSettings || canSeeManagerReport"
          :class="{
            active: isSubmenuItemActive([
              'pest-stats',
              'pest-report-cpo',
              'direct-ad-report',
              'pest-report-managers',
            ]),
          }"
          right
        >
          <template #button-content>
            <span class="text-uppercase">
              {{ $t("menu.analytics") }}
            </span>
          </template>
          <b-dropdown-item
            v-if="canSeeSettings"
            :active="isMenuItemActive('pest-stats')"
            :to="{ name: 'pest-stats' }"
            @click="setMenuItem('pest-stats')"
            >{{ $t("menu.analytics_stats") }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canSeeClosedReports"
            :active="isMenuItemActive('pest-report-cpo')"
            :to="{ name: 'pest-report-cpo' }"
            @click="setMenuItem('pest-report-cpo')"
            >{{ $t("menu.analytics_report_cpo") }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canSeeClosedReports"
            :active="isMenuItemActive('direct-ad-report')"
            :to="{ name: 'direct-ad-report' }"
            @click="setMenuItem('direct-ad-report')"
            >{{ $t("menu.analytics_ad_report") }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canSeeManagerReport"
            :active="isMenuItemActive('pest-report-managers')"
            :to="{ name: 'pest-report-managers' }"
            @click="setMenuItem('pest-report-managers')"
            >{{ $t("menu.analytics_report_managers") }}
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item
          v-if="showHeaderUserMenu"
          :active="isMenuItemActive('users-user-list')"
          :to="{ name: 'users-user-list' }"
          @click="setMenuItem('users-user-list')"
        >
          <span class="text-uppercase">
            {{ $t("menu.users") }}
          </span>
        </b-nav-item>

        <b-nav-item
          v-if="canSeeRequestList && isWorker"
          :active="isMenuItemActive('pest-own-schedule')"
          :to="{ name: 'pest-own-schedule' }"
          @click="setMenuItem('pest-own-schedule')"
          >{{ $t("menu.my_schedule") }}
        </b-nav-item>
        <b-nav-item
          v-if="canSeeOwnStats"
          :active="isMenuItemActive('pest-employee-archive-request-list')"
          :to="{ name: 'pest-employee-archive-request-list' }"
          @click="setMenuItem('pest-employee-archive-request-list')"
          >{{ $t("menu.my_closed_request") }}
        </b-nav-item>
        <b-nav-item
          v-if="canSeeOwnStats"
          :active="isMenuItemActive('pest-own-stats')"
          :to="{ name: 'pest-own-stats' }"
          @click="setMenuItem('pest-own-stats')"
          >{{ $t("menu.my_stats") }}
        </b-nav-item>

        <b-nav-item
          v-if="canSeeFines && isWorker"
          :active="isMenuItemActive('pest-fine-list')"
          :to="{ name: 'pest-fine-list' }"
          @click="setMenuItem('pest-fine-list')"
          >{{ $t("menu.my_fines") }}
        </b-nav-item>

        <b-nav-item
          v-if="canSeeFines && isWorker"
          :active="isMenuItemActive('pest-penalties-list')"
          :to="{ name: 'pest-penalties-list' }"
          @click="setMenuItem('pest-penalties-list')"
          >{{ $t("menu.my_fine_list") }}
        </b-nav-item>

        <b-nav-item-dropdown
          v-if="isWorker"
          right
          :class="{ active: isMenuItemActive('pest-deposit-employee') }"
          :active="isMenuItemActive('pest-deposit-employee')"
        >
          <template #button-content> {{ $t("menu.deposits") }}</template>
          <b-dropdown-item
            :active="isMenuItemActive('pest-deposit-employee-create')"
            :to="{ name: 'pest-deposit-employee-create' }"
            @click="setMenuItem('pest-deposit-employee')"
            >{{ $t("menu.deposits_hand_over") }}
          </b-dropdown-item>
          <b-dropdown-item
            :active="isMenuItemActive('pest-deposit-employee-list')"
            :to="{ name: 'pest-deposit-employee-list' }"
            @click="setMenuItem('pest-deposit-employee')"
            >{{ $t("menu.deposits_history") }}
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item
          v-if="isWorker"
          :active="isMenuItemActive('pest-employee-pledge')"
          :to="{ name: 'pest-employee-pledge' }"
          @click="setMenuItem('pest-employee-pledge')"
          >{{ $t("menu.my_pledge") }}</b-nav-item
        >

        <b-nav-item
          v-if="canSeeOwnNotifications"
          :active="isMenuItemActive('notifications-notification-user-list')"
          :to="{ name: 'notifications-notification-user-list' }"
          @click="setMenuItem('notifications-notification-user-list')"
          >{{ $t("menu.my_notifications") }}
          <span
            v-if="unreadNotificationCount"
            class="label label-primary ml-2"
            >{{ unreadNotificationCount }}</span
          >
        </b-nav-item>

        <b-nav-item-dropdown
          v-if="!isWorker && canSeeAllNotifications"
          :class="{
            active: isSubmenuItemActive([
              'notifications-notification-all-list',
              'notifications-message-list',
            ]),
          }"
        >
          <template #button-content>
            <span class="text-uppercase">
              {{ $t("menu.messages") }}
            </span>
          </template>
          <b-dropdown-item
            v-if="canSeeFines"
            :active="isMenuItemActive('notifications-notification-all-list')"
            :to="{ name: 'notifications-notification-all-list' }"
            @click="setMenuItem('notifications-notification-all-list')"
            >{{ $t("menu.messages_notifications") }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canSeePayments"
            :active="isMenuItemActive('notifications-message-list')"
            :to="{ name: 'notifications-message-list' }"
            @click="setMenuItem('notifications-message-list')"
            >{{ $t("menu.messages_messages") }}
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item
          v-if="canSeeSettings"
          :active="isMenuItemActive('settings')"
          :to="{ name: 'settings' }"
          class="text-uppercase"
          @click="setMenuItem('settings')"
          >{{ $t("menu.settings") }}
        </b-nav-item>

        <b-nav-item-dropdown
          v-if="canSeeServiceSections"
          :class="{
            active: isSubmenuItemActive(['service-dashboard']),
          }"
        >
          <template #button-content>
            <span class="text-uppercase">
              {{ $t("menu.maintenance") }}
            </span>
          </template>
          <b-dropdown-item
            :to="{ name: 'service-dashboard' }"
            :active="isMenuItemActive('service-dashboard')"
            @click="setMenuItem('service-dashboard')"
            >{{ $t("menu.maintenance_requests") }}
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <!--                <b-nav-item :to="{ name: 'request-list' }" v-if="canViewRequests">Заявки</b-nav-item>-->
      </b-navbar-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-lg-auto align-items-lg-center">
        <b-nav-item-dropdown right class=" ">
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <font-awesome-icon icon="language" />
          </template>

          <b-dropdown-item @click="setLang('en')">English</b-dropdown-item>
          <b-dropdown-item @click="setLang('ru')">Русский</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown right class="left-border">
          <!-- Using 'button-content' slot -->
          <template #button-content>
            {{ username }}
          </template>

          <b-dropdown-item @click="logout">{{
            $t("users.log_out")
          }}</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
    <b-collapse v-else id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item to="/login">{{ $t("users.log_in") }}</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getUserName } from "@/modules/crm/common";
import {
  canSeeAllNotifications,
  canSeeClientList,
  canSeeDeposits,
  canSeeFines,
  canSeeKanban,
  canSeeAllKanbans,
  canSeeManagerReport,
  canSeeOwnNotifications,
  canSeeOwnSchedule,
  canSeeOwnStats,
  canSeePayments,
  canSeeReports,
  canSeeRequestList,
  canSeeRequestMap,
  canSeeSchedule,
  canSeeSettings,
  isGuest,
  isWorker,
  showHeaderUserMenu,
} from "@/modules/users/access/common.js";

export default {
  name: "Header",
  components: {},
  data() {
    return {
      activeMenuItem: "dashboard",
      unreadNotificationCount: 0,
      projects: [],
    };
  },
  created() {
    this.$eventHub.$on("select-header-menu-item", this.onSelectMenuItem);
    if (this.logged && this.canSeeOwnNotifications) {
      this.fetchUnreadCount();
      setTimeout(this.fetchUnreadCount, 60000);
    }
    this.fetchProjects();
    this.$eventHub.$on("update-unread-count", this.fetchUnreadCount);
  },
  computed: {
    ...mapGetters({
      canSeeClosedReports: "auth/canSeeClosedReports",
      canSeeServiceSections: "auth/canSeeServiceSections",
      canSeeRequestDispatchingBoard: "auth/canSeeRequestDispatchingBoard",
    }),
    getFixed() {
      return this.isWorker ? "top" : "";
    },
    getUserProject() {
      return this.$store.state.user.profile.projectId;
    },
    logged() {
      return !isGuest(this.$store.state.user);
    },
    curLang() {
      const langs = {
        ru: "Русский",
        en: "English",
      };
      return langs[this.$root.$i18n.locale];
    },
    curPageTitle() {
      if (isGuest(this.$store.state.user)) {
        return "";
      }
      return this.$store.state.user.pageTitle;
    },
    isWorker() {
      return isWorker(this.$store.state.user);
    },
    username() {
      if (this.$store.state.user.profile) {
        return getUserName(this.$store.state.user.profile);
      } else {
        return this.$store.state.user.name;
      }
    },
    canSeeSettings() {
      return canSeeSettings(this.$store.state.user);
    },
    canSeeManagerReport() {
      return canSeeManagerReport(this.$store.state.user);
    },
    canSeeKanban() {
      return canSeeKanban(this.$store.state.user);
    },
    canSeeAllKanbans() {
      return canSeeAllKanbans(this.$store.state.user);
    },
    canSeeClientList() {
      return canSeeClientList(this.$store.state.user);
    },
    canSeeRequestList() {
      return canSeeRequestList(this.$store.state.user);
    },
    canSeeRequestMap() {
      return canSeeRequestMap(this.$store.state.user);
    },
    canSeeSchedule() {
      return canSeeSchedule(this.$store.state.user);
    },
    canSeeOwnSchedule() {
      return canSeeOwnSchedule(this.$store.state.user);
    },
    canSeeOwnStats() {
      return canSeeOwnStats(this.$store.state.user);
    },
    canSeeFines() {
      return canSeeFines(this.$store.state.user);
    },
    canSeeDeposits() {
      return canSeeDeposits(this.$store.state.user);
    },
    canSeeReports() {
      return canSeeReports(this.$store.state.user);
    },
    canSeeOwnNotifications() {
      return canSeeOwnNotifications(this.$store.state.user);
    },
    canSeePayments() {
      return canSeePayments(this.$store.state.user);
    },
    showHeaderUserMenu() {
      return showHeaderUserMenu(this.$store.state.user);
    },
    canSeeAllNotifications() {
      return canSeeAllNotifications(this.$store.state.user);
    },
  },

  mounted: function () {
    this.activeMenuItem = this.$router.currentRoute.name;
  },

  methods: {
    ...mapActions({
      setToken: "auth/setToken",
      userLogout: "user/logout",
    }),
    setLang(lang) {
      this.$root.$i18n.locale = lang;
      let root = this.$root;
      this.$nextTick(() => root.$forceUpdate());
    },
    logout: function () {
      this.setToken(null);
      this.userLogout();
      this.$router.push({ name: "users-login" });
    },
    setMenuItem(item) {
      return (this.activeMenuItem = item);
    },
    isMenuItemActive(item) {
      if (Array.isArray(item)) {
        return item.includes(this.activeMenuItem);
      }
      return this.activeMenuItem === item;
    },
    isSubmenuItemActive(items) {
      return items.includes(this.activeMenuItem);
    },
    onSelectMenuItem(item) {
      this.setMenuItem(item);
    },
    fetchProjects() {
      let projectsUrl = "/pest/projects?enabled=1";
      this.$http
        .get(projectsUrl)
        .then((response) => {
          response.data.forEach((element) => {
            this.projects.push({ id: element.id, title: element.title });
          });
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    fetchUnreadCount: function () {
      let itemsUrl = "/notifications/notification/unread-count";
      this.$http
        .get(itemsUrl)
        .then((response) => {
          this.unreadNotificationCount = response.data.unreadCount;
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
  },
};
</script>

<style scoped>
.nav-link {
  font-weight: 500 !important;
  font-size: 14px !important;
}

a.dropdown-toggle {
  padding-top: 10px !important;
}

.top-navigation .navbar-brand {
  padding: 15px 15px;
}

.mobile-page-title {
  /*padding: 15px 25px;*/
  font-size: 18px;
  line-height: 20px;
  color: white;
}

li.nav-item.b-nav-dropdown > a.nav-link {
  font-weight: 500 !important;
  font-size: 14px !important;
}

a.dropdown-toggle {
  padding-top: 8px !important;
}
</style>
