<template>
  <div class="ibox">
    <div class="ibox-content">
      <h1>{{ $t("sources.source_list") }}</h1>
      <div class="mb-2">
        <router-link
          :to="{ name: 'pest-source-create' }"
          class="btn btn-primary"
        >
          <font-awesome-icon icon="plus" />
          {{ $t("sources.add_source") }}
        </router-link>
      </div>

      <div class="search">
        <div class="d-flex flex-wrap align-items-center">
          <b-form-group :label="$t('common.title')" class="mr-2">
            <b-form-input v-model="search.title" tabindex="1"></b-form-input>
          </b-form-group>

          <b-form-group :label="$t('sources.type')" prop="title" class="mr-2">
            <b-form-select
              v-model="search.type"
              :options="typeOptions"
              tabindex="1"
            ></b-form-select>
          </b-form-group>

          <div class="mt-2 mr-2">
            <b-button class="btn btn-primary ml-1" @click="fetchData">
              <font-awesome-icon icon="search" />&nbsp;{{ $t("search.find") }}
            </b-button>
          </div>

          <div v-if="!loading" class="mt-2 mr-2">
            {{ $t("board_common.pagination_total") }}: {{ pagination.total }}
          </div>
        </div>
      </div>
      <b-table
        v-if="sources.length"
        responsive
        striped
        hover
        condensed
        :items="sources"
        :fields="fields"
        :busy="loading"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle table-spinner"></b-spinner>
            <strong>{{ $t("common.loading") }}</strong>
          </div>
        </template>

        <template #cell(title)="data">
          <router-link
            :to="{ name: 'pest-source-update', params: { id: data.item.id } }"
            class=""
          >
            {{ data.item.title ? data.item.title : $t("common.without_title") }}
          </router-link>
        </template>
        <template #cell(type)="data">
          {{ getSourceTypeTitle(data.item) }}
        </template>
        <template #cell(target)="data">
          <div
            v-if="
              data.item.type === TYPE_MANUAL &&
              data.item.target === TARGET_IN_CONTRACT
            "
          >
            Для заявок
          </div>
        </template>
        <template #cell(searchOnlyByPhone)="data">
          {{ data.item.searchOnlyByPhone ? $t("common.yes") : $t("common.no") }}
        </template>
        <template #cell(projectId)="data">
          {{ projectName(data.item.projectId) }}
        </template>
      </b-table>
    </div>

    <b-pagination-nav
      v-if="pagination.count > 1"
      :link-gen="linkGen"
      :value="pagination.page"
      :number-of-pages="pagination.count"
      limit="10"
      use-router
      class="mt-2"
    >
    </b-pagination-nav>
  </div>
</template>

<script>
import {
  getSourceTypeTitle,
  sourceTypeOptions,
  TARGET_IN_CONTRACT,
  TYPE_MANUAL,
  TYPE_ROISTAT,
} from "@/modules/pest/decorators/sources";

export default {
  name: "PestSourceList",
  components: {},
  data() {
    return {
      TYPE_ROISTAT,
      TYPE_MANUAL,
      TARGET_IN_CONTRACT,
      sources: [],
      typeOptions: [
        { value: null, text: this.$t("common.all") },
        ...sourceTypeOptions(),
      ],
      projectOptions: [],
      search: {
        name: "",
        type: null,
        target: null,
      },
      loading: true,
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      fields: [
        {
          key: "title",
          label: this.$t("common.title"),
          sortable: false,
        },
        {
          key: "type",
          label: this.$t("sources.type"),
          sortable: false,
        },
        {
          key: "target",
          label: this.$t("sources.target"),
          sortable: false,
        },
        {
          key: "comment",
          label: this.$t("common.description"),
          sortable: false,
        },
        {
          key: "searchOnlyByPhone",
          label: this.$t("sources.search_only_by_phone"),
          sortable: true,
        },
        {
          key: "projectId",
          label: this.$t("sources.project"),
          sortable: false,
        },
      ],
    };
  },
  computed: {
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
  },
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  mounted: function () {
    this.fetchProjectSelectOptions();
    this.fetchData();
  },
  created() {},
  methods: {
    projectName(projectId) {
      let title = "";
      this.projectOptions.forEach(function (option) {
        if (option.id === projectId) {
          title = option.title;
        }
      });
      if (title) {
        return title;
      }
      return "[нет проекта]";
    },
    fetchProjectSelectOptions: function () {
      this.projectOptions = [];
      let projectUrl = "/pest/projects?enabled=1";
      this.$http
        .get(projectUrl)
        .then((response) => {
          for (let item of response.data) {
            this.projectOptions.push({
              id: item.id,
              title: item.title,
            });
          }
          console.log(this.projectOptions);
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },
    getSourceTypeTitle,
    fetchData: function () {
      this.loading = true;

      let sourcesUrl = "/pest/sources";
      let params = {
        page: this.page,
        type: this.search.type,
        target: this.search.target,
      };
      this.$http
        .get(sourcesUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.sources = response.data;
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    handleGroupIndex(command) {
      this.sampleGroupIndex = command;
    },
    linkGen(pageNum) {
      return {
        name: "pest-source-list",
        params: { page: pageNum },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.search {
  border-top: 1px lightgray solid;
  border-bottom: 1px lightgray solid;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
