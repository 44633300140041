<template>
  <b-overlay v-if="loadingStats" :show="true" rounded="sm" class="pt-4 pb-4" />

  <div v-else>
    <div class="ibox mt-2">
      <div class="ibox-title pr-3 pt-2 pb-1">
        <div class="d-flex">
          <div>{{ $t("deposit.deposit_sum") }}</div>
          <div class="ml-auto">{{ formatNumber(totalDepositSum) }} руб.</div>
        </div>
        <div class="d-flex mt-2">
          <div>{{ $t("deposit.requests_in_work") }}</div>
          <div class="ml-auto">{{ requestCount }}</div>
        </div>

        <div v-if="false && !canAddClient()" class="h6">
          <div v-if="false" class="d-flex">
            <div class="">Оборот:</div>
            <div class="ml-auto">
              <strong> {{ formatNumber(totalSum) }} </strong>
            </div>
          </div>
          <div class="d-flex">
            <div class="">Заработано:</div>
            <div class="ml-auto">
              <strong> {{ formatNumber(profitSum) }} </strong>
            </div>
          </div>
          <div class="d-flex">
            <div class="">Штрафы:</div>
            <div class="ml-auto">
              <strong> {{ formatNumber(finesSum) }} </strong>
            </div>
          </div>
          <div class="d-flex">
            <div class="">К выплате:</div>
            <div class="ml-auto">
              <strong> {{ formatNumber(sumToPay) }} </strong>
            </div>
          </div>
          <div class="d-flex">
            <div class="">Средний чек</div>
            <div class="ml-auto" :class="getAverageSumClass">
              <strong> {{ formatNumber(average) }} </strong>
            </div>
          </div>
        </div>

        <div class="mb-2"></div>
      </div>

      <div
        v-if="showTelegramSingupWindow"
        class="panel panel-warning mt-4 mb-4"
      >
        <div class="panel-heading">
          <font-awesome-icon icon="exclamation-triangle" />
          {{ $t("notifications.follow_up_msg") }}
        </div>
        <div class="panel-body">
          <p v-html="$t('notifications.follow_up_text')"></p>
        </div>
      </div>
    </div>

    <div v-if="initialPledgeRequired" class="panel panel-warning mt-4 mb-4">
      <div class="panel-heading">
        <font-awesome-icon icon="exclamation-triangle" />

        {{ $t("notifications.initial_pledge_required_title") }}
      </div>
      <div class="panel-body">
        <p>
          {{ $t("notifications.initial_pledge_required_msg") }}
        </p>
      </div>
    </div>

    <div v-if="blocked === BLOCKED" class="panel panel-danger mt-4 mb-4">
      <div class="panel-heading">
        <font-awesome-icon icon="exclamation-triangle" />
        {{ $t("notifications.blocked_title") }}
      </div>
      <div class="panel-body">
        <p>
          {{ $t("notifications.blocked_msg") }}
        </p>
      </div>
    </div>
    <div v-else-if="blocked === UNBLOCKED" class="panel panel-danger mt-4 mb-4">
      <div class="panel-heading">
        <font-awesome-icon icon="lock-open" />
        {{ $t("notifications.unblocked_title") }}
      </div>
      <div class="panel-body">
        <p>
          {{ $t("notifications.unblocked_msg") }}
        </p>
      </div>
    </div>

    <div
      v-else-if="isHalfPledgeUsed && !initialPledgeRequired"
      class="panel panel-warning mt-4 mb-4"
    >
      <div class="panel-heading">
        <font-awesome-icon icon="exclamation-triangle" />

        {{ $t("notifications.blocked_title") }}
      </div>
      <div class="panel-body">
        <p>
          {{ $t("notifications.blocked_msg") }}
        </p>
      </div>
    </div>
    <request-list
      :blocked="blocked"
      tab="currentEmployeeList"
      @loaded="loaded"
    ></request-list>
  </div>
</template>

<script>
import {
  getRequestAssigneeName,
  getRequestClientName,
  getRequestCreateTime,
  getRequestDatetime,
  getRequestDeadline,
  getRequestManagerName,
  getRequestStatus,
  getRequestTitle,
  getRequestTownTitle,
  getRequestUpdateTime,
  servicesSum,
} from "@/modules/pest/decorators/requests";
import { getAssigneeName } from "@/modules/crm/common";
import RequestList from "@/modules/pest/views/requests/RequestList.vue";
import {
  BLOCKED,
  REGULAR,
  UNBLOCKED,
} from "@/modules/users/decorators/userDecorator.js";

export default {
  name: "EmployeeCurrentRequestList",
  components: { RequestList },
  data() {
    return {
      BLOCKED,
      UNBLOCKED,
      REGULAR,
      totalDepositSum: 0,
      loadingStats: true,
      totalSum: 0,
      profitSum: 0,
      sumToPay: 0,
      finesSum: 0,
      average: 0,
      count: 0,
      telegramUrl:
        "https://t.me/" + import.meta.env.VITE_ASSIGNEE_BOT + "?start=",
      telegramChatId: null,
      initialPledgeRequired: false,
      isPledgeInsufficient: false,
      isHalfPledgeUsed: false,
      isPledgeLimitExceeded: false,
      blocked: true,
      requestCount: 0,
    };
  },
  computed: {
    showTelegramSingupWindow() {
      return !this.canAddClient() && !this.loadingStats && !this.telegramChatId;
    },
  },
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  mounted: function () {
    if (!this.canAddClient()) {
      this.fetchSum();
    }
    this.fetchDepositData();
  },
  created() {},
  methods: {
    getAssigneeName,
    servicesSum,
    getRequestTitle,
    getRequestDatetime,
    getRequestManagerName,
    getRequestAssigneeName,
    getRequestStatus,
    getRequestClientName,
    getRequestCreateTime,
    getRequestDeadline,
    getRequestUpdateTime,
    getRequestTownTitle,
    formatNumber(value, key, item) {
      return new Intl.NumberFormat("ru-RU").format(value);
    },
    canAddClient() {
      return (
        !this.$store.state.user.isGuest &&
        this.$store.state.user.access.canViewContacts === true
      );
    },

    fetchDepositData: function () {
      this.loadingStats = true;

      let depositsUrl = "/pest/deposit/stats";
      let params = {};
      this.$http
        .get(depositsUrl, {
          params: params,
        })
        .then((response) => {
          this.loadingStats = false;
          this.totalDepositSum = response.data.totalSum;
          this.initialPledgeRequired = response.data.initialPledgeRequired;
          this.isPledgeInsufficient = response.data.isPledgeInsufficient;
          this.isHalfPledgeUsed = response.data.isHalfPledgeUsed;
          this.isPledgeLimitExceeded = response.data.isPledgeLimitExceeded;
          this.blocked = response.data.blocked;
        })
        .catch((error) => {
          this.loadingStats = false;
          this.processRequestError(error);
        });
    },
    fetchSum: function () {
      this.loadingStats = true;
      let requestsUrl = "/pest/requests/my-info";
      let params = {};
      this.$http
        .get(requestsUrl, {
          params: params,
        })
        .then((response) => {
          this.loadingStats = false;
          this.totalSum = response.data.totalSum;
          this.profitSum = response.data.profitSum;
          this.sumToPay = response.data.sumToPay;
          this.finesSum = response.data.finesSum;
          this.average = response.data.average;
          this.count = response.data.count;
          this.telegramChatId = response.data.telegramChatId;
          this.phone = response.data.phone;
          this.telegramUrl += response.data.phone;
        })
        .catch((error) => {
          this.loadingStats = false;

          this.processRequestError(error);
        });
    },
    loaded(info) {
      this.requestCount = info.total;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

.managerFilter {
  max-width: 200px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.request-organization {
  font-size: smaller;
  color: gray;
}

.actions-cell {
  max-width: 50px;
}

.text-success {
  color: #28a745 !important;
}
</style>
