export const ru = {
  penalties: {
    penalties: "Штрафы",
    penalty_list: "Перечень штрафов",
    penalty: "Штраф",
    add_penalty: "Добавить штраф",
    new_penalty: "Новый штраф",
    penalty_update: "Изменение штрафа",
    penalty_updated: "Штраф отредактирован",
    cant_create_penalty: "Не удалось добавить штрафа",
    cant_delete_penalty: "Не удалось удалить штрафа",
    penalty_created: "Штраф добавлен",
    penalty_deleted: "Штраф удален",
    delete_penalty_question: "Вы действительно хотите удалить штраф?",
    dismissal: "Увольнение",
    sum: "Сумма",
  },
};
export const en = {
  penalties: {
    penalties: "Penalties",
    penalty_list: "Penalty list",
    penalty: "Penalty",
    add_penalty: "Add penalty",
    new_penalty: "New penalty",
    change_penalty: "Change penalty",
    penalty_update: "Update penalty",
    penalty_updated: "Penalty changed",
    cant_create_penalty: "Can't add penalty",
    cant_delete_penalty: "Can't delete penalty",
    penalty_created: "Penalty added",
    penalty_deleted: "Penalty deleted",
    delete_penalty_question: "Do you want to delete the penalty?",
    dismissal: "Dismissal",
    sum: "Sum",
  },
};
