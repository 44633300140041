export const ru = {
  shopResources: {
    shopResources: "Материалы в магазине",
    shopResource_list: "Перечень материалов в магазине",
    shopResource: "Материал в магазине",
    add_shopResource: "Добавить материал в магазине",
    new_shopResource: "Новый материал в магазине",
    shopResource_update: "Изменение материала в магазине",
    shopResource_updated: "Материал в магазине отредактирован",
    cant_create_shopResource: "Не удалось добавить материал в магазине",
    cant_delete_shopResource: "Не удалось удалить материал в магазине",
    shopResource_created: "Материал в магазине добавлен",
    shopResource_deleted: "Материал в магазине удален",
    delete_shopResource_question:
      "Вы действительно хотите удалить материал в магазине?",
    load_from_csv: "Загрузить из файла, csv",
    article: "Артикул",
    resource_in_crm: "Материал в CRM",
    unlink: "отвязать",
  },
};
export const en = {
  shopResources: {
    shopResources: "Shop resources",
    shopResource_list: "Shop resource list",
    shopResource: "Shop resource",
    add_shopResource: "Add shop resource",
    new_shopResource: "New shop resource",
    change_shopResource: "Change shop resource",
    shopResource_update: "Update shop resource",
    shopResource_updated: "Shop resource changed",
    cant_create_shopResource: "Can't add shop resource",
    cant_delete_shopResource: "Can't delete shop resource",
    shopResource_created: "Shop resource added",
    shopResource_deleted: "Shop resource deleted",
    delete_shopResource_question: "Do you want to delete the shop resource?",
    load_from_csv: "Load from csv files",
    article: "Article",
    resource_in_crm: "Resource in crm",
    unlink: "unlink",
  },
};
