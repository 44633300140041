export const ru = {
  payments: {
    settlements: "Взаиморасчеты",
    payments: "Платежи",
    payment_list: "Перечень платежей",
    payment_history: "Перечень платежей",
    payment: "Платеж",
    add_payment: "Добавить платеж",
    new_payment: "Новый платеж",
    payment_update: "Изменение платежа",
    payment_updated: "Платеж отредактирован",
    cant_create_payment: "Не удалось добавить платеж",
    payment_created: "Платеж добавлен",
    payments_history: "История платежей",
    sum: "Сумма",
    sum_to_pay: "Сумма к оплате",
    raw_cost: "Себестоимость",
    pledge: "Депозит",
  },
};
export const en = {
  payments: {
    settlements: "Settlements",
    payments: "Payments",
    payment_list: "Payment list",
    payment_history: "Payment history",
    payment: "Payment",
    payment_number: "Roistat payment number",
    add_payment: "Add payment",
    new_payment: "New payment",
    change_payment: "Change payment",
    payment_update: "Update payment",
    payment_updated: "Payment changed",
    cant_create_payment: "Can't add payment",
    payment_created: "Payment added",
    payments_history: "Payments history",
    sum: "Sum",
    sum_to_pay: "Sum to pay",
    raw_cost: "Raw cost",
    pledge: "Pledge",
  },
};
