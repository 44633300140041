<template>
  <div class="ibox">
    <div class="ibox-content">
      <h1>{{ $t("pests.pest_list") }}</h1>
      <div class="mb-2">
        <router-link :to="{ name: 'pest-pest-create' }" class="btn btn-primary">
          <font-awesome-icon icon="plus" />
          {{ $t("pests.add_pest") }}
        </router-link>
      </div>

      <table v-if="pests.length" class="table table-striped">
        <thead>
          <tr>
            <th></th>
            <th>{{ $t("common.title") }}</th>
            <th>{{ $t("pests.percent") }}</th>
            <th>{{ $t("common.description") }}</th>
            <th>{{ $t("common.sort_order") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(pest, pIndex) in pests" :key="pIndex">
            <td class="color-col">
              <div
                class="p-1 color-box"
                :style="{ background: pest.color }"
              ></div>
            </td>
            <td>
              <router-link
                :to="{ name: 'pest-pest-update', params: { id: pest.id } }"
                class=""
              >
                {{ pest.title ? pest.title : $t("common.without_title") }}
              </router-link>
            </td>
            <td>
              <span v-if="pest.percentType === PERCENT_TYPE_MANUAL">
                {{ pest.percentRegular }} / {{ pest.percentRecord }}
              </span>
            </td>
            <td>
              {{ pest.comment }}
              <div v-if="pest.service === SERVICE_DOUBLE">
                {{ getPestsDoubleNames(pest) }}
              </div>
            </td>
            <td>
              {{ pest.order }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-pagination-nav
      v-if="pagination.count > 1"
      :link-gen="linkGen"
      :value="pagination.page"
      :number-of-pages="pagination.count"
      limit="10"
      use-router
      class="mt-2"
    >
    </b-pagination-nav>
  </div>
</template>

<script>
import {
  getPestsDoubleNames,
  PERCENT_TYPE_MANUAL,
  SERVICE_DOUBLE,
} from "../../decorators/pests.js";

export default {
  name: "PestPestList",
  components: {},
  data() {
    return {
      PERCENT_TYPE_MANUAL,
      SERVICE_DOUBLE,
      pests: [],
      search: {
        name: "",
      },
      loading: true,
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
    };
  },
  computed: {
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
  },
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  mounted: function () {
    this.fetchData();
  },
  created() {},
  methods: {
    getPestsDoubleNames,
    fetchData: function () {
      this.loading = true;

      let pestsUrl = "/pest/pests";
      let params = {
        page: this.page,
        service: true,
      };
      this.$http
        .get(pestsUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.pests = response.data;
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    handleGroupIndex(command) {
      this.sampleGroupIndex = command;
    },
    linkGen(pageNum) {
      return {
        name: "pest-pest-list",
        params: { page: pageNum },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.color-col {
  width: 20px;
}

.color-box {
  width: 20px;
  height: 20px;
}
</style>
