<template>
  <div class="mt-2">
    <div v-if="stats && stats.length" class="d-flex pest-stats-list flex-wrap">
      <div
        v-for="(stat, sIndex) in stats"
        :key="sIndex"
        class="pest-stat mr-2"
        :style="{ border: '3px solid  ', 'border-color': getBgColor(stat) }"
      >
        {{ stat.pest.title }}:
        <strong>{{ stat.countFirst }} | {{ stat.countOther }}</strong>
      </div>
    </div>
    <div v-else-if="stats && !stats.length"></div>
    <div v-else>{{ $t("common.loading") }}</div>
  </div>
</template>

<script>
import { STATUS_DECLINE } from "@/modules/crm/decorators/lead";

export default {
  name: "DispatchingStatsByPest",
  components: {},
  props: {
    requests: {},
  },
  data() {
    return {
      stats: null,
    };
  },
  computed: {},
  watch: {
    requests: {
      immediate: true,
      handler(value) {
        this.countStats();
      },
    },
  },
  created: function () {},
  methods: {
    getTextColor(stat) {
      if (!stat.pest.color) {
        return "black";
      }
      let hex = stat.pest.color.substring(1);
      let bigint = parseInt(hex, 16);
      let r = (bigint >> 16) & 255;
      let g = (bigint >> 8) & 255;
      let b = bigint & 255;
      const brightness = Math.round((r * 299 + g * 587 + b * 114) / 1000);
      return brightness > 125 ? "black" : "white";
    },
    getBgColor(stat) {
      if (stat.pest.color) {
        return stat.pest.color;
      }
      return false;
    },
    countStats() {
      // console.log("counting stats");
      // console.log(this.requests);
      let stats = {};
      for (let request of this.requests) {
        if (request.status === STATUS_DECLINE) {
          continue;
        }
        if (!(request.pestId in stats)) {
          stats[request.pestId] = {
            pest: request.pest,
            countFirst: 0,
            countOther: 0,
          };
        }
        if (request.isFirstTime) {
          stats[request.pestId].countFirst++;
        } else {
          stats[request.pestId].countOther++;
        }
      }
      this.stats = stats ? Object.values(stats) : false;
      this.stats.sort((a, b) => {
        return b.countFirst - a.countFirst;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pest-stats-list {
}

.pest-stat {
  font-size: 12px;
  text-shadow: none;
  white-space: nowrap;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.pest-stat:not(:last-child)::after {
}
</style>
