<template>
  <b-form
    id="notifications-message-form"
    ref="message"
    :model="message"
    class="notifications-message-form"
    novalidate
    @submit.prevent="createOrUpdate"
    @reset="onReset"
  >
    <div class="div">
      <div class="row">
        <div class="col-12">
          <b-form-group :label="$t('messages.message')" prop="title">
            <b-form-textarea
              v-model="message.text"
              :placeholder="$t('messages.message')"
              required
              tabindex="1"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group
            :label="$t('messages.send_to_any_assignee_in_town_msg')"
            prop="allManagers"
          >
            <b-button-group>
              <button
                v-if="townList.includes(MSK)"
                :class="getWorkersMSKClass"
                @click.prevent="clickSelectMSKMasters"
              >
                Москва
              </button>
              <button
                v-if="townList.includes(SPB)"
                :class="getWorkersSPBClass"
                @click.prevent="clickSelectSPBMasters"
              >
                Санкт-Петербург
              </button>
              <button
                v-if="townList.includes(DXB)"
                :class="getWorkersDBXClass"
                @click.prevent="clickSelectDBXMasters"
              >
                Dubai
              </button>
            </b-button-group>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group
            :label="$t('messages.send_to_individual_recipients_msg')"
            prop="managerId"
          >
            <multiply-autocomplete
              ref="userAutocomplete"
              :get-data="fetchUserData"
              :get-title="getUserName"
              required
              @selected="selectMultipleUsers"
            ></multiply-autocomplete>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div v-if="message.allMskSelected || message.allSpbSelected">
            <b-button
              variant="primary"
              @click.prevent="showConfirmation = true"
            >
              <font-awesome-icon icon="paper-plane" />
              {{ $t("messages.send_to_all_assignees") }}
            </b-button>
            <b-button
              v-if="showConfirmation"
              :disabled="loading"
              variant="danger"
              class="ml-2"
              @click.prevent="createOrUpdate"
            >
              <font-awesome-icon icon="paper-plane" />
              {{ $t("messages.confirm_send_to_all") }}
            </b-button>
          </div>

          <b-button
            v-else
            :disabled="loading"
            variant="primary"
            @click.prevent="createOrUpdate"
          >
            <font-awesome-icon icon="paper-plane" />
            {{ $t("messages.send_message") }}
          </b-button>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import { VueMaskDirective } from "v-mask";
import MultiplyAutocomplete from "@/components/shared/MultiplyAutocomplete.vue";
import {
  MSK,
  SPB,
  DXB,
  MSK_C,
  SPB_C,
} from "@/modules/pest/decorators/towns.js";
import TownFilter from "@/components/shared/TownFilter.vue";

export default {
  name: "NotificationsMessageForm",
  directives: { mask: VueMaskDirective },
  components: { MultiplyAutocomplete },
  props: [],
  data() {
    return {
      MSK,
      SPB,
      DXB,
      message: {
        text: "",
        recipientsIds: [],
        allSpbSelected: false,
        allMskSelected: false,
        allDbxSelected: false,
      },
      recipients: [],
      phoneMask: false,
      loading: false,
      townId: null,
      townList: [],
      showConfirmation: false,
    };
  },
  computed: {
    id() {
      return this.message.id;
    },
    managerName() {
      return this.message.managerId && this.message.manager
        ? getUserName(this.client.manager)
        : "";
    },
    getWorkersMSKClass() {
      return this.message.allMskSelected
        ? "btn btn-primary"
        : "btn btn-outline-primary";
    },
    getWorkersSPBClass() {
      return this.message.allSpbSelected
        ? "btn btn-primary"
        : "btn btn-outline-primary";
    },
    getWorkersDBXClass() {
      return this.message.allDbxSelected
        ? "btn btn-primary"
        : "btn btn-outline-primary";
    },
  },
  watch: {},
  created: function () {
    this.fetchTowns();
  },
  methods: {
    getUserName,
    send: function () {
      let updateUrl;
      updateUrl = "/notifications/messages";
      this.loading = true;
      this.$http
        .post(updateUrl, this.message)
        .then((response) => {
          this.message = response.data;
          this.$eventHub.$emit(
            "update-notifications-message-info",
            this.message
          );
          this.$toast.open({
            message: this.$t("messages.message_sent"),
            type: "success",
          });
          this.$router.push({ name: "notifications-message-list" });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$toast.open({
              message: this.$t("messages.message_is_not_sent"),
              type: "danger",
            });
          }
        });
    },
    createOrUpdate: function () {
      let form = document.getElementById("notifications-message-form");
      let valid = form.checkValidity();
      if (!this.message.text) {
        valid = false;
        this.$toast.open({
          message: "Напишите текст",
          type: "danger",
        });
      }
      if (
        !this.message.allMskSelected &&
        !this.message.allSpbSelected &&
        !this.message.allDbxSelected &&
        (!this.message.recipientsIds || this.message.recipientsIds.length === 0)
      ) {
        valid = false;
        this.$toast.open({
          message: this.$t("messages.select_recipients"),
          type: "danger",
        });
      }
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$toast.open({
            message: this.$t("common.please_wait"),
            type: "info",
          });
          return;
        }
        this.send();
        form.classList.remove("was-validated");
      } else {
        this.$toast.open({
          message: this.$t("common.form_has_errors"),
          type: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },
    getManagerName(user) {
      return getUserName(user);
    },
    handleManagerSubmit(result) {
      this.message.managerId = result.id;
    },
    getResultManagerAutocompleteValue(user) {
      return this.getManagerName(user);
    },
    searchManager(input) {
      return new Promise((resolve) => {
        // this.error = null;
        let clientsUrl = "/users/admin/search";
        this.loading = true;
        this.$http
          .get(clientsUrl, {
            params: { term: input },
          })
          .then((response) => {
            this.loading = false;
            resolve(response.data);
            // this.client = response.data;
            // this.header = this.client.email;
          })
          .catch((error) => {
            this.loading = false;
            this.processRequestError(error);
            resolve([]);
          });
      });
    },

    selectMultipleUsers(info) {
      this.message.recipientsIds = info ? info.map((i) => i.id) : [];
      this.recipients = info;
    },

    fetchUserData(search, resolve) {
      let usersUrl = "/users/admin/search";
      let params = {
        term: search,
      };

      if (this.type) {
        params.type = this.type;
      }

      this.$http
        .get(usersUrl, {
          params: params,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve([]);
        });
    },
    fetchTowns: function () {
      let townUrl = "/pest/towns";
      let params = {};
      this.$http
        .get(townUrl, {
          params: params,
        })
        .then((response) => {
          this.townList = response.data.map((t) => t.id);
        })
        .catch((error) => {
          this.processRequestError(error);
        });
    },

    clickSelectMSKMasters() {
      this.message.allMskSelected = !this.message.allMskSelected;
      this.showConfirmation = false;
    },
    clickSelectSPBMasters() {
      this.message.allSpbSelected = !this.message.allSpbSelected;
      this.showConfirmation = false;
    },
    clickSelectDBXMasters() {
      this.message.allDbxSelected = !this.message.allDbxSelected;
      this.showConfirmation = false;
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}
</style>
