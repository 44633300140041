<template>
  <b-form
    v-if="dataLoaded"
    id="request-correct-form"
    ref="request"
    :model="request"
    class="request-correct-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="div mb-2">
      <div class="row">
        <div class="col-4">
          <b-form-group :label="$t('requests.status')" prop="status">
            <b-form-select
              v-model="status"
              :options="aftercallStatusOptions"
              tabindex="1"
              required
            />
          </b-form-group>
        </div>

        <div v-if="status === AFTERCALL_STATUS_NOT_REGISTERED" class="col-4">
          <b-form-group label="Причина" prop="status">
            <universal-autocomplete
              :default-value="fail ? fail.title : 'Выберите причину'"
              :add-new="false"
              :get-title="getAftercallFailTitle"
              :get-data="getClientsForRequest"
              @selected="selectFail"
            ></universal-autocomplete>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <b-form-group :label="$t('requests.manager')" prop="managerId">
            <user-autocomplete
              v-model="managerId"
              :type="TYPE_MANAGER"
              :default-value="getUserName(manager)"
              @selected="selectManager"
            ></user-autocomplete>
          </b-form-group>
        </div>
      </div>

      <b-form-group :label="$t('requests.comment')" prop="title">
        <b-form-textarea
          v-model="comment"
          tabindex="1"
          required
        ></b-form-textarea>
      </b-form-group>

      <b-button variant="primary" @click="createOrUpdate('ok')">
        <font-awesome-icon icon="check" />
        {{ $t("common.save") }}
      </b-button>

      <b-button
        v-if="id !== 'new'"
        variant="outline-primary"
        class="ml-2"
        @click="cancelUpdate"
      >
        <font-awesome-icon icon="times" />
        {{ $t("common.cancel") }}
      </b-button>
    </div>
  </b-form>
</template>
<script>
import { TYPE_MANAGER } from "@/modules/users/decorators/users.js";
import { VueMaskDirective } from "v-mask";
import { getAftercallFailTitle } from "@/modules/pest/decorators/aftercall-fail";
import {
  AFTERCALL_STATUS_NOT_REGISTERED,
  AFTERCALL_STATUS_WITHOUT,
  aftercallStatusOptions,
} from "@/modules/pest/decorators/aftercall";
import UniversalAutocomplete from "@/components/shared/UniversalAutocomplete";
import UserAutocomplete from "@/modules/users/views/user/UserAutocomplete";
import { getUserName } from "@/modules/crm/common";

export default {
  name: "RequestEnterAftercallForm",
  directives: { mask: VueMaskDirective },
  components: { UserAutocomplete, UniversalAutocomplete },
  props: ["requestModel", "dataLoaded"],
  data() {
    return {
      AFTERCALL_STATUS_NOT_REGISTERED,
      TYPE_MANAGER,
      aftercallStatusOptions,
      loading: false,
      status: null,
      failId: null,
      fail: null,
      manager: null,
      managerId: null,
      comment: "",
    };
  },
  computed: {
    id() {
      return this.request.id;
    },
  },
  watch: {
    requestModel: {
      immediate: true,
      handler(obj) {
        this.request = Object.assign({}, obj);
        if (this.request && this.request.aftercall) {
          this.status = this.request.aftercall.status;
          this.failId = this.request.aftercall.failId;
          this.fail = this.request.aftercall.fail;
          this.manager = this.request.aftercall.manager;
          this.managerId = this.request.aftercall.managerId;
          this.comment = this.request.aftercall.comment;
        } else {
          this.managerId = this.$store.state.user.id;
          this.manager = this.$store.state.user.profile;
          this.status = AFTERCALL_STATUS_WITHOUT;
        }
      },
    },
  },
  created() {},
  methods: {
    getAftercallFailTitle,
    getUserName,
    update: function () {
      let updateUrl;
      updateUrl = "/pest/request/aftercall/" + this.id;
      if (this.loading) {
        return;
      }
      this.loading = true;
      let data = {
        status: this.status,
        failId: this.failId,
        managerId: this.managerId,
        comment: this.comment,
      };
      this.$http
        .post(updateUrl, data)
        .then((response) => {
          this.loading = false;
          this.$emit("request-corrected", response.data);
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("requests.cant_update_request"), {
              title: this.$t("common.error"),
              request: "danger",
            });
          }
        });
    },
    createOrUpdate: function () {
      let form = document.getElementById("request-correct-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast(this.$t("common.data_is_saving"), {
            title: this.$t("common.please_wait"),
            variant: "info",
          });
          return;
        }
        this.update();
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast(this.$t("common.form_has_errors"), {
          variant: "danger",
        });
        return false;
      }
    },
    getClientsForRequest(search, resolve) {
      let statusesUrl = "/pest/aftercall-fails";
      this.$http
        .get(statusesUrl)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve([]);
        });
    },
    selectFail({ value }) {
      if (value.id) {
        this.fail = value;
        this.failId = value.id;
      } else {
        this.fail = {};
        this.failId = null;
      }
    },
    selectManager(data) {
      if (data && data.value) {
        this.manager = data.value;
        this.managerId = data.value.id;
      } else {
        this.manager = data.value;
        this.managerId = data.value.id;
      }
      this.$forceUpdate();
    },
    cancelUpdate() {
      this.$emit("cancel-updating", this.request);
    },
    onReset(evt) {
      evt.preventDefault();
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}

.resource-select {
  min-width: 150px;
}

.resource-count {
  width: 50px;
}

.service-select {
  min-width: 150px;
}

.service-count {
  width: 50px;
}
</style>
