<template>
  <div class="row">
    <div class="col-md-6 col-lg-6 col-sm-12">
      <div class="ibox">
        <div class="">
          <div class="ibox-title">
            <h5 class="h5">{{ $t("users.resources_at_masters_hand") }}</h5>
          </div>
          <div class="ibox-content">
            <button
              v-if="false"
              class="btn btn-primary"
              @click.prevent="fetchData"
            >
              Reload
            </button>
            <b-overlay :show="loading" rounded="sm">
              <table class="table table-condensed table-striped table-bordered">
                <thead>
                  <tr>
                    <th>{{ $t("users.resources") }}</th>
                    <th>{{ $t("users.resources_h_prompt") }}</th>
                  </tr>
                </thead>
                <tbody v-if="items.length">
                  <tr
                    v-for="(item, index) in items"
                    :key="'p' + index"
                    class=""
                  >
                    <td>{{ item.resource.title }}</td>
                    <td>
                      {{ item.amount ? item.amount : 0 }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr v-if="loading">
                    <td colspan="7">{{ $t("common.loading") }}</td>
                  </tr>
                  <tr v-else>
                    <td colspan="7">{{ $t("users.no_materials") }}</td>
                  </tr>
                </tbody>
              </table>
            </b-overlay>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-lg-6 col-sm-12">
      <div class="ibox">
        <div class="ibox-title">
          <h5 class="h5">{{ $t("users.changing_resources") }}</h5>
        </div>

        <div class="ibox-content">
          <b-overlay :show="updating" rounded="sm">
            <b-form @submit.prevent="submit">
              <div class="d-flex align-items-center">
                <b-form-group :label="$t('users.resource')" class="mr-2">
                  <universal-autocomplete
                    ref="resourceAutocomplete"
                    :get-title="(p) => p.title"
                    :get-data="getResources"
                    :default-value="
                      resource && resource.title ? resource.title : ''
                    "
                    @selected="selectResource"
                  />
                </b-form-group>

                <b-form-group
                  :label="$t('users.resources_h_prompt')"
                  class="mr-2"
                >
                  <b-form-input
                    v-model="amount"
                    placeholder="10, 100, -100, -15.5"
                    tabindex="1"
                  ></b-form-input>
                </b-form-group>

                <div>
                  <button
                    class="btn btn-primary mt-2"
                    :disabled="!resourceId || !amount"
                    @click.prevent="submit"
                  >
                    {{ $t("common.save") }}
                  </button>
                </div>
              </div>
            </b-form>
          </b-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import UniversalAutocomplete from "@/components/shared/UniversalAutocomplete.vue";

export default {
  name: "UserResourcesList",
  components: { UniversalAutocomplete },
  props: ["user"],
  data() {
    return {
      pagination: {
        totalCount: 0,
        pageNumber: 1,
        pageCount: 0,
        pageSize: 10,
      },
      loading: true,
      updating: false,
      resource: null,
      resourceId: null,
      amount: null,
      items: [],
    };
  },
  computed: {},
  watch: {
    $route(to, from) {
      this.pagination.pageNumber =
        this.$route.params.page !== undefined ? this.$route.params.page : 1;
      this.fetchData();
    },
  },

  created: function () {},

  mounted: function () {
    this.checkUrl();
  },

  methods: {
    dayjs,
    fetchData: function () {
      this.items = [];
      this.loading = true;

      let usersUrl = "/pest/user-resources";
      let params = {
        all: 1,
        userId: this.user.id,
      };
      this.$http
        .get(usersUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.items = response.data;
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    checkUrl: function () {
      window.scrollTo(0, 0);
      this.fetchData();
    },
    submit() {
      let updateUrl;
      updateUrl = "/pest/user-resources";
      if (!this.resourceId || !this.amount) return;
      if (this.updating) return;
      this.updating = true;
      let data = {
        amount: this.amount,
        resourceId: this.resourceId,
        userId: this.user.id,
      };
      this.$http
        .post(updateUrl, data)
        .then((response) => {
          this.$bvToast.toast(this.$t("resources.resource_created"), {
            title: this.$t("resources.resource_created"),
            variant: "success",
          });
          this.updating = false;
          this.fetchData();
          this.amount = "";
          this.resource = null;
          this.resourceId = null;
        })
        .catch((error) => {
          this.updating = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.error"), {
              title: this.$t("resources.cant_create_resource"),
              variant: "danger",
            });
          }
        });
    },
    getResources(search, resolve) {
      let statusesUrl = "/pest/resources";
      this.$http
        .get(statusesUrl, {
          params: { search: search },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve([]);
        });
    },

    selectResource({ value }) {
      if (value.id) {
        this.resource = value;
        this.resourceId = value.id;
      } else {
        this.resource = {};
        this.resourceId = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
