<template>
  <b-form
    id="sms-message-form"
    ref="message"
    :model="message"
    class="sms-message-form"
    novalidate
    @submit="createOrUpdate"
    @reset="onReset"
  >
    <div class="div">
      <div class="row">
        <div class="col-12">
          <b-form-group :label="$t('common.title')" prop="title">
            <b-form-input
              v-model="message.title"
              :placeholder="$t('common.title')"
              tabindex="1"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-button variant="primary" @click="createOrUpdate">
            <font-awesome-icon icon="check" />
            {{ $t("common.save") }}
          </b-button>

          <a
            v-if="message.id !== 'new'"
            href=""
            class="btn btn-outline btn-danger ml-2"
            @click.prevent="deleteMessage"
          >
            <font-awesome-icon icon="trash" />
            Удалить
          </a>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import { getUserName } from "@/modules/crm/common";
import { VueMaskDirective } from "v-mask";

export default {
  name: "MessageForm",
  directives: { mask: VueMaskDirective },
  components: {},
  props: ["message"],
  data() {
    return {
      phoneMask: false,
      loading: false,
    };
  },
  computed: {
    id() {
      return this.message.id;
    },
    name() {
      let name = this.message.name ? this.message.name : "Новое сообщение";
      return name ? name : "Сообщение";
    },
  },
  watch: {},
  created: function () {},
  methods: {
    getUserName,
    update: function () {
      let updateUrl;
      updateUrl = "/sms/messages/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.message)
        .then((response) => {
          this.message = response.data;
          this.loading = false;
          this.$eventHub.$emit("update-sms-message-info", this.message);
          // this.$router.push({name: 'sms-message-view', params: {id: this.message.id}});
          this.$bvToast.toast(this.$t("common.data_saved"), {
            title: "Сообщение отредактировано",
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: this.$t("common.error_during_saving"),
              type: "danger",
            });
          }
        });
    },
    create: function () {
      let updateUrl;
      updateUrl = "/sms/messages";
      this.loading = true;
      this.$http
        .post(updateUrl, this.message)
        .then((response) => {
          this.message = response.data;
          this.$eventHub.$emit("update-sms-message-info", this.message);
          this.$router.push({
            name: "sms-message-update",
            params: { id: this.message.id },
          });
          this.$bvToast.toast(this.$t("messages.message_created"), {
            title: this.$t("messages.message_created"),
            variant: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.error"), {
              title: this.$t("messages.cant_create_message"),
              variant: "danger",
            });
          }
        });
    },
    createOrUpdate: function (event) {
      event.preventDefault();
      let form = document.getElementById("sms-message-form");
      let valid = form.checkValidity();
      form.classList.add("was-validated");
      if (valid) {
        if (this.loading) {
          this.$bvToast.toast(this.$t("common.data_is_saving"), {
            title: this.$t("common.please_wait"),
            variant: "info",
          });
          return;
        }
        if (this.id === "new") {
          this.create();
        } else {
          this.update();
        }
        form.classList.remove("was-validated");
      } else {
        this.$bvToast.toast(this.$t("common.form_has_errors"), {
          variant: "danger",
        });
        return false;
      }
    },
    onReset(evt) {
      evt.preventDefault();
      return false;
    },

    handleTownSubmit(result) {
      this.message.townId = result.id;
    },
    getResultTownAutocompleteValue(town) {
      return this.getTownTitle(town);
    },
    searchTown(input) {
      return new Promise((resolve) => {
        // this.error = null;
        let categoriesUrl = "/sms/towns/search";
        this.loadingTownsList = true;
        this.$http
          .get(categoriesUrl, {
            params: { term: input },
          })
          .then((response) => {
            this.loadingTownsList = false;
            resolve(response.data);
            // this.task = response.data;
            // this.header = this.task.email;
          })
          .catch((error) => {
            this.loadingTownsList = false;
            this.processRequestError(error);
            resolve([]);
          });
      });
    },
    deleteMessage() {
      if (confirm(this.$t("messages.delete_message_question"))) {
        let deleteUrl = "/sms/messages/" + this.town.id;
        this.$http
          .delete(deleteUrl)
          .then((response) => {
            this.loading = false;
            this.$router.push({ name: "sms-town-list" });
            this.$bvToast.toast(this.$t("messages.message_deleted"), {
              title: this.$t("messages.message_deleted"),
              variant: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            if (!this.processRequestError(error)) {
              this.$bvToast.toast(this.$t("common.error"), {
                title: this.$t("messages.cant_delete_message"),
                variant: "danger",
              });
            }
          });
      }
    },
    selectTown(data) {
      if (data && data.value) {
        this.message.town = data.value;
        this.message.townId = data.value.id;
      } else {
        this.message.town = {};
        this.message.townId = null;
      }
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss">
tr.archived td {
  text-decoration: line-through;
}

.autocomplete-input {
  background: #fff !important;
}
</style>
