<template>
  <div>
    <div v-if="isSuccessMessageVisible" class="panel panel-primary mb-4">
      <div class="panel-heading">
        <font-awesome-icon icon="check" />
        {{ $t("deposit.pledge_change_confirmation_title") }}
      </div>
      <div class="panel-body">
        {{ $t("deposit.dps_msg_sum") }}
        <strong>{{ successInfo.totalSum }}</strong> {{ $t("common.cur") }}
        {{ $t("deposit.dps_msg_period") }}
        <strong>{{ successInfo.dateFrom }}</strong> –
        <strong>{{ successInfo.dateTo }}</strong>
        {{ $t("deposit.dps_msg_sum_success") }}

        <button
          class="btn btn-primary mt-2"
          @click="isSuccessMessageVisible = false"
        >
          {{ $t("common.close") }}
        </button>
      </div>
    </div>

    <div v-if="!isSuccessMessageVisible" class="pest-deposit-page">
      <div class="panel panel-warning mb-4">
        <div class="panel-heading">
          <!--          <font-awesome-icon icon="warning"/>-->
          {{ $t("notifications.instruction_title") }}
        </div>
        <div class="panel-body">
          <ul v-html="$t('notifications.instruction_msg')"></ul>
        </div>
      </div>

      <div v-if="!isSuccessMessageVisible">
        <pest-deposit-form
          :deposit="deposit"
          @success="showSuccessMessage"
        ></pest-deposit-form>
      </div>
    </div>
  </div>
</template>

<script>
import DepositForm from "./DepositForm";

export default {
  name: "DepositEmployeeCreate",
  components: {
    "pest-deposit-form": DepositForm,
  },
  data() {
    return {
      isSuccessMessageVisible: false,
      deposit: {
        id: "new",
        title: "",
      },
      successInfo: {
        dateFrom: "",
        dateTo: "",
        fineSum: "",
        pledgeSum: "",
        requestSum: "",
        totalSum: "",
      },
      loading: false,
    };
  },
  computed: {
    id() {
      return "new";
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    checkUrl: function () {
      window.scrollTo(0, 0);
    },

    showSuccessMessage(data) {
      this.isSuccessMessageVisible = true;
      this.successInfo = data;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  padding-inline-start: 20px;
}
</style>
