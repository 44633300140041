export const ru = {
  fines: {
    fines: "Штрафы",
    fine_list: "Перечень штрафов",
    fine: "Штраф",
    fine_n: "Штраф №",
    add_fine: "Добавить штраф",
    new_fine: "Новый штраф",
    fine_update: "Изменение штрафа",
    fine_updated: "Штраф отредактирован",
    cant_create_fine: "Не удалось добавить штраф",
    cant_delete_fine: "Не удалось удалить штраф",
    fine_created: "Штраф добавлен",
    fine_deleted: "Штраф удален",
    result: "Результат проверки",
    delete_fine_question: "Вы действительно хотите удалить штраф?",

    status_m_processing: "В обработке",
    status_m_verified: "Подтвержденные",
    status_m_declined: "Отклоненные",
    status_m_paid: "Оплаченные",
    status_m_not_paid: "Неоплаченные",
    status_m_deposited: "В кассе",
    status_m_not_deposited: "Не в кассе",

    status_p_process: "Перевести в статус проверки",
    status_p_processing: "В обработке",
    status_p_verified: "Подтвердить",
    status_p_decline: "Отклонить",
    status_p_cancel: "Отменить подтверждение",
    cant_change_status: "Не удалось поменять статус",
    fine_confirmed: "Штраф подтвержден",
    fine_declined: "Штраф отклонен",

    checking: "Проверка",
    date: "Дата",
    status: "Статус",
    sum: "Сумма",
    assignee: "Сотрудник",
    comment: "Комментарий",
    answer: "Ответ",
    who_assigned: "Кто назначил",
    assigned_with_sum: "назначен на сумму",
    who_checked: "Кто проверил",
    paid: "Оплачен",
  },
};
export const en = {
  fines: {
    fines: "Fines",
    fine_list: "Fine list",
    fine: "Fine",
    fine_n: "Fine #",
    add_fine: "Add fine",
    new_fine: "New fine",
    change_fine: "Change fine",
    fine_update: "Update fine",
    fine_updated: "Fine changed",
    cant_create_fine: "Can't add fine",
    cant_delete_fine: "Can't delete fine",
    fine_created: "Fine added",
    fine_deleted: "Fine deleted",
    result: "Result",
    delete_fine_question: "Do you want to delete the fine?",

    status_m_processing: "Processing",
    status_m_verified: "Verified",
    status_m_declined: "Declined",
    status_m_paid: "Paid",
    status_m_not_paid: "Not paid",
    status_m_deposited: "Deposited",
    status_m_not_deposited: "Not deposited",

    status_p_process: "Start verification",
    status_p_processing: "Processing",
    status_p_verified: "Confirm",
    status_p_decline: "Decline",
    status_p_cancel: "Cancel verification",
    cant_change_status: "Can't change status",
    fine_confirmed: "Fine confirmed",
    fine_declined: "Fine declined",

    checking: "Checking",
    date: "Date",
    status: "Status",
    sum: "Sum",
    assignee: "Technician",
    comment: "Comment",
    answer: "Answer",
    who_assigned: "Who assigned",
    assigned_with_sum: "assigned, sum",
    who_checked: "Who checked",
    paid: "Paid",
  },
};
