export const ru = {
  subways: {
    subways: "Метро",
    subway_list: "Перечень метро",
    subway: "Метро",
    add_subway: "Добавить метро",
    new_subway: "Новый метро",
    subway_update: "Изменение метро",
    subway_updated: "Метро отредактировано",
    cant_create_subway: "Не удалось добавить метро",
    cant_delete_subway: "Не удалось удалить метро",
    subway_created: "Метро добавлено",
    subway_deleted: "Метро удалено",
    delete_subway_question: "Вы действительно хотите удалить метро?",
  },
};
export const en = {
  subways: {
    subways: "Subways",
    subway_list: "Subway list",
    subway: "Subway",
    add_subway: "Add subway",
    new_subway: "New subway",
    change_subway: "Change subway",
    subway_update: "Update subway",
    subway_updated: "Subway changed",
    cant_create_subway: "Can't add subway",
    cant_delete_subway: "Can't delete subway",
    subway_created: "Subway added",
    subway_deleted: "Subway deleted",
    delete_subway_question: "Do you want to delete the subway?",
  },
};
