<template>
  <div class="pest-fine-page">
    <div class="ibox">
      <div class="ibox-title pb-3">
        <div class="d-flex">
          <div class="ml-1 mr-2 mt-2">
            <router-link :to="{ name: 'pest-fine-list' }" class="h4 text-muted">
              <font-awesome-icon icon="arrow-left" />
            </router-link>
          </div>
          <div>
            <h1>{{ fine.title }}</h1>
          </div>
        </div>
      </div>
      <div class="ibox-content">
        <pest-fine-info :fine="fine" @update-prop="updateProp"></pest-fine-info>
      </div>
    </div>
  </div>
</template>

<script>
import FineInfo from "./FineInfo";
import { getUserName } from "@/modules/crm/common";
import { FINE_STATUS_NEW } from "@/modules/pest/decorators/fines";

export default {
  name: "Fine",
  components: {
    "pest-fine-info": FineInfo,
  },
  data() {
    return {
      tabIndex: 0,
      fine: {
        id: "new",
        status: FINE_STATUS_NEW,
        requestIds: [],
      },
      loading: false,
    };
  },
  computed: {
    id() {
      return this.$route.params.id !== undefined
        ? this.$route.params.id
        : "new";
    },
  },
  watch: {
    $route(to, from) {
      this.checkUrl();
    },
  },
  created: function () {
    this.checkUrl();
  },

  methods: {
    getUserName,
    checkUrl: function () {
      this.fetchData();
      window.scrollTo(0, 0);
    },

    fetchData() {
      this.fine.phone = "";
      let finesUrl = "/pest/fines/" + this.id;
      this.loading = true;
      this.$http
        .get(finesUrl)
        .then((response) => {
          this.loading = false;
          this.fine = response.data;
          this.fine.managerName = this.fine.manager.username;
          this.header = this.fine.email;
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    updateProp(e) {
      this.update();
    },
    update: function () {
      let updateUrl;
      updateUrl = "/pest/fines/" + this.id;
      this.loading = true;
      this.$http
        .put(updateUrl, this.fine)
        .then((response) => {
          this.fine = response.data;
          this.loading = false;
          this.$eventHub.$emit("update-pest-fine-info", this.fine);
          this.$bvToast.toast(this.$t("common.data_saved"), {
            title: this.$t("fines.fine_updated"),
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          if (!this.processRequestError(error)) {
            this.$bvToast.toast(this.$t("common.data_not_saved"), {
              title: this.$t("common.error_during_saving"),
              type: "danger",
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ibox-title {
  padding: 15px 15px 8px 15px;
}
</style>
