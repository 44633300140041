export const ru = {
  board_common: {
    assigned_calls: "Назначенные звонки",
    pagination_total: "Найдено",
    operators_dashboard: "Дашборд операторов",
    add_request: "Добавить лид",
    load_more: "Загрузить еще",
    loading: "Загружается",
  },
};
export const en = {
  board_common: {
    assigned_calls: "Assigned calls",
    pagination_total: "Total",
    operators_dashboard: "Operators dashboard",
    add_request: "Add request",
    load_more: "Load more",
    loading: "Loading",
  },
};
