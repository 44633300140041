export const ru = {
  cpo: {
    cpo_report_by_creation_date: "Отчет CPO по дате создания",
    hide_source_with_zeros: "Скрыть источники с нулевыми значениями",
    show_source_with_zeros: "Показать источники с нулевыми значениями",
    hide: "Скрыть",
    report_is_not_ready_yet: "Отчет еще не построен",
    row_title: "Тип вредителя / Источник / Исполнитель",

    roi: "ROI,%",
    cpoDirect: "СРО успех первичка",
    cpl: "CPL первичка",
    count: "Общее количество первичных сделок",
    newCount: "Кол-во незакрытых первичных сделок",
    failedCount: "Количество проваленных первичных сделок",
    successAverageSum: "Средний чек успешной первичной сделки",
    totalNetCostSum: "Общая себестоимость первичных сделок",
    totalFirstProfitSum: "Общая чистая прибыль первичных сделок",
    secondaryCount: "Общее количество повторных сделок",
    secondaryNewCount: "Кол-во незакрытых повторных сделок",
    secondaryFailedCount: "Количество проваленных повторных сделок",
    secondarySuccessAverageSum: "Средний чек успешной повторной сделки",
    secondaryNetCostSum: "Общая себестоимость повторных сделок",
    secondaryProfitSum: "Общая чистая прибыль повторных сделок",
    totalProfitSum: "Итоговая чистая прибыль",
    conversionToSecondary: "Конверсия в повторные продажи, %",
  },
};
export const en = {
  cpo: {
    cpo_report_by_creation_date: "CPO report by creation date",
    hide_source_with_zeros: "Hide source with zeros",
    show_source_with_zeros: "Show source with zeros",
    hide: "Hide",
    report_is_not_ready_yet: "Report is not ready yet",
    row_title: "Pest/ Source / Master",

    roi: "ROI,%",
    cpoDirect: "СРО of first successful ",
    cpl: "CPL of first treatment request",
    count: "Total first treatment request count",
    newCount: "Unclosed first treatment requests count",
    failedCount: "Failed first treatment requests count",
    successAverageSum: "First treatment successful requests average check",
    totalNetCostSum: "Total first treatment requests net cost",
    totalFirstProfitSum: "Total first treatment requests profit",

    secondaryCount: "Total follow-up treatment request count",
    secondaryNewCount: "Unclosed follow-up treatment requests count",
    secondaryFailedCount: "Failed follow-up treatment requests count",
    secondarySuccessAverageSum:
      "Follow-up treatment successful requests average check",
    secondaryNetCostSum: "Total follow-up treatment requests net cost",
    secondaryProfitSum: "Total follow-up treatment requests profit",

    totalProfitSum: "Total profit sum",
    conversionToSecondary: "Follow-up treatment conversion, %",
  },
};
