<template>
  <div v-if="show" class="dashboard">
    <b-overlay :show="loading" rounded="sm">
      <table class="table table-striped mb-0">
        <tbody>
          <tr v-for="(info, iIndex) in stats" :key="iIndex" class="userRow">
            <td class="align-top">
              <img v-if="iIndex === 0" :src="imgPlace1" class="placeImg" />
              <img v-if="iIndex === 1" :src="imgPlace2" class="placeImg" />
              <img v-if="iIndex === 2" :src="imgPlace3" class="placeImg" />
            </td>
            <td class="align-middle">
              <div
                v-if="info.user.image"
                :style="{
                  'background-image': 'url(' + info.user.image.url + ')',
                }"
                class="img-circle userImage"
              ></div>
              <div v-else class="circle"></div>
            </td>
            <td class="align-middle userName">{{ getUserTitle(info.user) }}</td>
            <td
              class="align-top p-0 speedometerCell"
              style="position: relative"
            >
              <div class="speedometerWrapper">
                <img
                  :src="imgSpeedometer"
                  alt="speedometer"
                  class="imgSpeedometer"
                />

                <div
                  class="conversion-circle"
                  :class="getCircleColorClass(info.conversion)"
                ></div>
                <div
                  class="conversion"
                  :class="getTextColorClass(info.conversion)"
                >
                  {{ info.conversion }}%
                </div>
              </div>
            </td>
            <td class="align-middle">
              <font-awesome-icon icon="sack-dollar" />
              {{ info.total }}
            </td>
          </tr>
        </tbody>
      </table>

      <button
        v-if="false"
        class="btn btn-primary btn-xs"
        @click.prevent="fetchData"
      >
        обновить
      </button>
    </b-overlay>
  </div>
</template>

<script>
import imgPlace1 from "../../../../../assets/img/managers/place1.png";
import imgPlace2 from "../../../../../assets/img/managers/place2.png";
import imgPlace3 from "../../../../../assets/img/managers/place3.png";
import imgSpeedometer from "../../../../../assets/img/managers/speedometer.png";
import { getUserTitle } from "@/modules/users/decorators/userDecorator";

export default {
  name: "ManagerDashboardPopup",
  components: {},
  data() {
    return {
      loading: false,
      imgPlace1,
      imgPlace2,
      imgPlace3,
      imgSpeedometer,
      stats: [],
    };
  },
  computed: {},
  props: {
    show: {
      type: Boolean,
      defaultValue: false,
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(show) {
        if (show) {
          this.fetchData();
        }
      },
    },
  },
  created: function () {
    this.fetchData();
  },
  methods: {
    getUserTitle,
    getCircleColorClass(conversion) {
      if (conversion < 30) {
        return "conversion-bad-bg";
      }
      if (conversion > 60) {
        return "conversion-good-bg";
      }
      return "conversion-normal-bg";
    },
    getTextColorClass(conversion) {
      if (conversion < 30) {
        return "conversion-bad-text";
      }
      if (conversion > 60) {
        return "conversion-good-text";
      }
      return "conversion-normal-text";
    },
    fetchData: function () {
      let itemsUrl = "/pest/report/performance";
      this.loading = true;
      this.$http
        .get(itemsUrl)
        .then((response) => {
          this.stats = response.data.results;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  position: absolute;
  top: 80px;
  right: 15px;
  border: 1px solid grey;
  background: #ffffff;
  min-height: 100px;
  min-width: 386px;
  z-index: 2;
}
.table {
  min-height: 100px;
}

.circle {
  height: 50px;
  width: 50px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.placeImg {
  max-width: 50px;
}

.userName {
  max-width: 100px;
  vertical-align: center;
}

.imgSpeedometer {
  position: relative;
  max-height: 100px;
  z-index: 10;
}

.conversion {
  position: absolute;
  top: 32px;
  right: 28px;
  font-size: 1.3rem;
  z-index: 100;
  font-weight: bold;
}

.conversion-circle {
  z-index: 5;
  position: absolute;
  top: 12px;
  left: 11px;
  width: 78px;
  height: 78px; /* as the half of the width */
  background-color: #392f2c;
  border-radius: 100px;
}

.conversion-bad-bg {
  background-color: rgba(173, 5, 5, 0.5);
}

.conversion-normal-bg {
  background-color: rgba(224, 177, 7, 0.5);
}

.conversion-good-bg {
  background-color: rgba(13, 148, 6, 0.5);
}

.conversion-bad-text {
  color: rgba(152, 34, 34);
}

.conversion-normal-text {
  color: rgba(224, 177, 7);
}

.conversion-good-text {
  color: rgb(10, 101, 5);
}

.userRow {
  height: 74px !important;
  overflow-y: hidden;
}

.speedometerWrapper {
  height: 72px !important;
  overflow-y: hidden;
}

.speedometerCell {
  overflow-y: hidden;
}
.userImage {
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center center;
}
</style>
