export const ru = {
  schedule: {
    schedule: "Расписание",
    my_schedule: "Мое расписание",
    sunday_sh: "Вс",
    monday_sh: "Пн",
    tuesday_sh: "Вт",
    wednesday_sh: "Ср",
    thursday_sh: "Чт",
    friday_sh: "Пт",
    saturday_sh: "Сб",
    date: "Дата",
    time: "Время",
    load: "Нагрузка",
    status: "Статус",

    st_off: "Не&nbsp;работает",
    st_work: "Работает",
    st_duty_1: "Деж.&nbsp;1",
    st_duty_2: "Деж.&nbsp;2",
    st_day_off: "Выходной",

    st_u_work: "Рабочий день",
    st_u_off: "Не рабочий день",
    st_u_duty_1: "Запасной дежурный",
    st_u_duty_2: "Дежурный",

    today: "Сегодня",
    yesterday: "Вчера",
    tomorrow: "Завтра",
    last_week: "Неделя",
    last_month: "Месяц",
    last_year: "Год",
    till_today: "До сегодня",
    schedule_updated: "Расписание обновлено",
    cant_save_user: "Не удалось отредактировать пользователя",
  },
};
export const en = {
  schedule: {
    schedule: "Schedule",
    my_schedule: "My schedule",
    sunday_sh: "Sun",
    monday_sh: "Mon",
    tuesday_sh: "Tue",
    wednesday_sh: "Wed",
    thursday_sh: "Thu",
    friday_sh: "Fri",
    saturday_sh: "Sat",
    date: "Date",
    time: "Time",
    load: "Load",
    status: "Status",

    st_off: "No work",
    st_work: "Working",
    st_duty_1: "Duty&nbsp;1",
    st_duty_2: "Duty&nbsp;2",
    st_day_off: "Day off",

    today: "Today",
    yesterday: "Yesterday",
    tomorrow: "Tomorrow",
    last_week: "Last week",
    last_month: "Last month",
    last_year: "Last year",
    till_today: "Till today",
    schedule_updated: "Schedule updated",
    cant_save_user: "Can't save user",
  },
};
