<template>
  <div class="ibox">
    <div class="ibox-title d-flex align-items-center">
      <h1>{{ $t("messages.messages") }}</h1>
      <div class="ml-2">
        <router-link
          :to="{ name: 'notifications-message-create' }"
          class="btn btn-primary"
        >
          <font-awesome-icon icon="plus" />
          {{ $t("messages.send_message") }}
        </router-link>
      </div>
    </div>
    <div class="ibox-content">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>{{ $t("messages.time") }}</th>
            <th>{{ $t("messages.sender") }}</th>
            <th>{{ $t("messages.messages") }}</th>
            <th>{{ $t("messages.recipients") }}</th>
          </tr>
        </thead>
        <tbody v-if="messages.length">
          <tr v-for="(message, mIndex) in messages" :key="'m' + mIndex">
            <td>{{ getMessageCreateDatetime(message) }}</td>
            <td>{{ getMessageUserName(message) }}</td>
            <td>{{ message.text }}</td>
            <td>
              <div v-if="message.allMskSelected">
                {{ $t("messages.to_all_in") }} Москве:
                {{ message.allMskCount }} {{ $t("messages.ppl") }}
              </div>
              <div v-if="message.allSpbSelected">
                {{ $t("messages.to_all_in") }} Санкт-Петербурге:
                {{ message.allSpbCount }} {{ $t("messages.ppl") }}
              </div>
              <div v-if="message.loading">
                <font-awesome-icon icon="rotate" spin />
              </div>
              <div v-else>
                <div
                  v-if="message.users"
                  class="d-flex flex-wrap align-items-center"
                >
                  <div
                    v-for="(info, uIndex) in message.users"
                    :key="'i' + uIndex"
                    class="mr-2 recipient"
                  >
                    <font-awesome-icon v-if="info.readTime" icon="check" />
                    <font-awesome-icon v-else icon="arrow-right" />
                    {{ getUserName(info.user) }}
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <th colspan="4">{{ $t("messages.no_sent_messages") }}</th>
          </tr>
        </tbody>
      </table>
    </div>

    <b-pagination-nav
      v-if="pagination.count > 1"
      :link-gen="linkGen"
      :value="pagination.page"
      :number-of-pages="pagination.count"
      limit="10"
      use-router
      class="mt-2"
    >
    </b-pagination-nav>
  </div>
</template>

<script>
import {
  getMessageCreateDatetime,
  getMessageUserName,
} from "@/modules/notifications/decorators/messages.js";

import { getUserName } from "@/modules/crm/common.js";

export default {
  name: "NotificationsMessageList",
  components: {},
  data() {
    return {
      messages: [],
      search: {
        name: "",
      },
      loading: true,
      pagination: {
        size: 10,
        total: 0,
        count: 0,
      },
      fields: [
        {
          key: "title",
          label: this.$t("common.title"),
          sortable: false,
        },
        {
          key: "comment",
          label: "Описание",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    page() {
      return this.$route.params.page !== undefined
        ? this.$route.params.page
        : 1;
    },
  },
  watch: {
    $route(val) {
      this.fetchData();
    },
  },
  mounted: function () {
    this.fetchData();
  },
  created() {},
  methods: {
    getMessageCreateDatetime,
    getMessageUserName,
    getUserName,
    processRecipients() {
      let ids = [];
      for (let message of this.messages) {
        message.loading = true;
        ids.push(message.id);
      }
      this.fetchRecipientsData(ids);
    },
    fetchRecipientsData(ids) {
      this.loading = true;

      let messagesUrl = "/notifications/message/recipients";
      let params = {
        ids: ids,
      };
      this.$http
        .get(messagesUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          let data = response.data;
          console.log(data);
          for (let message of this.messages) {
            message.users = data[message.id];
            message.loading = false;
          }
          this.$forceUpdate();
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    fetchData: function () {
      this.loading = true;

      let messagesUrl = "/notifications/messages";
      let params = {
        page: this.page,
      };
      this.$http
        .get(messagesUrl, {
          params: params,
        })
        .then((response) => {
          this.loading = false;
          this.messages = response.data;
          this.processRecipients();
          this.pagination.size = parseInt(
            response.headers["x-pagination-per-page"]
          );
          this.pagination.total = parseInt(
            response.headers["x-pagination-total-count"]
          );
          this.pagination.count = parseInt(
            response.headers["x-pagination-page-count"]
          );
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.loading = false;
          this.processRequestError(error);
        });
    },
    handleGroupIndex(command) {
      this.sampleGroupIndex = command;
    },
    linkGen(pageNum) {
      return {
        name: "notifications-message-list",
        params: { page: pageNum },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.table-spinner {
  padding-right: 10px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.search {
  border-top: 1px lightgray solid;
  border-bottom: 1px lightgray solid;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.recipient:not(:last-child)::after {
  content: ",";
}
</style>
