<template>
  <div class="d-flex">
    <div
      v-if="
        request && request.callNotifications && request.callNotifications.length
      "
      class="d-flex mr-2"
    >
      <button class="btn btn-danger mr-2" @click.prevent>
        {{ $t("calls.make_call_to_client") }}
      </button>

      <div
        v-for="(call, cIndex) in request.callNotifications"
        :key="'c' + cIndex"
        class="btn-group mr-2"
      >
        <button class="btn btn-outline-danger btn-no-hover" @click.prevent>
          {{ callTime(call, cIndex) }} &nbsp;
        </button>
        <button
          class="btn btn-outline-danger"
          @click.prevent="removeCall(call, cIndex)"
        >
          <font-awesome-icon v-if="removing" fixed-width icon="rotate" spin />
          <font-awesome-icon v-else fixed-width icon="times" />
        </button>
      </div>
    </div>
    <button
      v-if="request.id !== 'new'"
      class="btn btn-outline btn-outline-primary"
      @click.prevent="openPopup"
    >
      <font-awesome-icon icon="plus" />
      {{ $t("calls.schedule_call") }}
    </button>

    <request-call-notification-popup />
  </div>
</template>

<script>
import dayjs from "dayjs";
import RequestCallNotificationPopup from "@/modules/pest/views/requests/kanban/popup/RequestCallNotificationPopup";

export default {
  name: "RequestCallNotifications",
  components: { RequestCallNotificationPopup },
  props: {
    request: {
      type: Object,
    },
  },
  data() {
    return {
      removing: false,
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  methods: {
    callTime(call) {
      return dayjs.unix(call.time).format("DD.MM в HH:mm");
    },
    removeCall(call, cIndex) {
      if (this.removing) {
        return;
      }
      this.removing = true;
      let deleteUrl = "/pest/call-notifications/" + call.id;
      this.$http
        .delete(deleteUrl, {})
        .then((response) => {
          this.$bvToast.toast(this.$t("notifications.notifications_deleted"), {
            title: this.$t("common.success"),
            variant: "success",
          });
          this.removing = false;
          this.request.callNotifications.splice(cIndex, 1);
        })
        .catch(() => {
          this.removing = false;
        });
    },
    openPopup() {
      let $this = this;
      this.$eventHub.$emit("request-call-notification-popup-show", {
        request: this.request,
        success: ({ call }) => {
          console.log(call);
          $this.request.callNotifications.push(call);
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
